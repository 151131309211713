import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AvatarModule } from 'ngx-avatars';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { StorageService } from '../../shared-services/storage.service';
import { ActivityService } from '../../shared-services/activity.service';
import { JobService } from '../../shared-services/job.service';
import { CommentService } from '../../shared-services/comment.service';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';
import { EditSpotsDialogComponent } from '../../shared/edit-spots-detail-dialog/edit-spots-detail-dialog';
import { DeleteFutureDialogComponent } from './../../shared/delete-future-dialog/delete-future-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomsanitizerPipe } from '../../pipes/domsanitizer.pipe';
import { VolunteerService } from '../../shared-services/volunteer.service';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { TranslateService } from '@ngx-translate/core';
import * as moment_ from 'moment';

const moment = moment_;
@Component({
  selector: 'app-edit-spots-detail',
  templateUrl: './edit-spots-detail.component.html',
  styleUrls: ['./edit-spots-detail.component.scss']
})

export class EditSpotsDetailComponent implements OnInit {

    // set tooltip defaults
    spotDetailTooltip:string = '';

    tippyProps: NgxTippyProps = {
      allowHTML: true,
      trigger: 'click',
      arrow: true,
      placement: 'auto',
      maxWidth: 230,
    };

  constructor(private storageService: StorageService,
    private activityService: ActivityService,
    private jobService: JobService,
    private commentService: CommentService,
    private volunteerService: VolunteerService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tippyService: NgxTippyService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private sanitize: DomsanitizerPipe) { }

  job: {};
  activity: {};
  userId: number;
  userName: string;
  jobType: string;
  jobName: string;
  jobDate: string;
  jobQuantity: number;
  jobStartTime: any;
  jobEndTime: any;
  isFull: boolean = false;
  accessKey: string;
  commentTotal: number;
  comments: [];
  commentForm: FormGroup;
  totalAssignment: number = 0;
  openSpots: any;
  loading: boolean = true;
  commentLoading: boolean = false;
  creating:boolean = false;
  backText: string;

  ngOnInit() {
    this.job = this.storageService.get('job');
    this.activity = this.storageService.get('activity');
    if (this.storageService.get('user')) {
      this.userId = this.storageService.get('user')['id'];
      this.userName = this.storageService.get('user')['name'];
      this.accessKey = this.storageService.get('user')['accesskey'];

    }
    //remove unnecessary local storage variables
    this.storageService.removeMultiple(['irregularDaysSelected', 'originalRepeat', 'timeValues', 'jobName', 'jobType', 'jobQuantity', 'jobDesc']);
    // initialize form
    this.commentForm = new FormGroup({
      comment: new FormControl('', Validators.required)
    })

    if (this.job) {
      //refresh job
      this.getJob();
      //get comments
      this.commentService.getJobComment(this.activity['id'], this.job['id']).subscribe(
        response => {
          if (response) {
            this.comments = response.body['data'];
            this.commentTotal = response.body['count'];
          }
        }
      )
    } else {
      //job was not found, maybe deleted on desktop. Go to job list page
      this.router.navigateByUrl('edit/spot/' + this.activity['id']);
    }

    //remove unnecessary local storage values that can cause conflict
    this.storageService.remove('shiftSummary');
    this.storageService.remove('serverSummary');
    this.storageService.remove('repeatSummary');
    this.storageService.remove('repeatingJobs');
    this.storageService.remove('repeatingJSONData');

    //get nav query params
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params['mode'] === 'create') {
        this.creating = true;
        this.storageService.set('jobPageVisited', true);
      }
      // console.log(' this.creating ',  this.creating)
    })

    //set back button text
    if (window.location.hash.indexOf('create') !== -1) {
      this.backText = '_CreateWizard_';
    } else {
      this.backText = '_ViewSpot_';
    }
  }

  ngAfterViewInit() {
    this.setContentForTooltip();
    this.cdr.detectChanges();
  }

  setContentForTooltip() {
      this.translate.get('_ViewSpotInfo_').subscribe((text) => {
        this.tippyService.setContent('spotDetailTooltip', text);
    })
  }

  getJob() {
    this.loading = true;
    this.jobService.get(this.job['id'], this.activity['id']).subscribe(
      response => {
        if (response && response.body['status']) {
          this.loading = false;
          this.job = response.body['data'];
          this.storageService.set('job', this.job, null);
          this.jobName = this.job['name'];
          this.jobDate = this.activity['kind'] === 'dated' && this.job['date'] !== '2038/01/01' ? this.job['date'] : null;
          this.jobQuantity = this.job['quantity'] || 1;
          this.jobType = this.job['jobtype'] == 1 ? 'To Bring' : 'To Do';
          //get start and end times
          if (this.job['starthour'] !== -1) {         
            this.jobStartTime = moment(this.job['starthour'], "h").format("h") + ':' + moment(this.job['startminute'], "mm").format("mm") + moment(this.job['starthour'], "h mm a").format("a");
            if (this.job['endhour'] !== -1) {
              this.jobEndTime = moment(this.job['endhour'], "h").format("h") + ':' + moment(this.job['endminute'], "mm").format("mm") + moment(this.job['endhour'], "h mm a").format("a");
            }
          }

          // recalculate total assignment
          this.getTotalAssignment(this.job);
        } else {
          // //job was not found, maybe deleted on desktop. Go to job list page
          this.router.navigateByUrl('edit/spot/' + this.activity['id']);

        }
      }
    )
  }

  getTotalAssignment(job) {
    this.totalAssignment = job.jobassignments.reduce((accum, current) => {
      return accum + parseInt(current.quantity);
    }, 0)
    if (job.quantity !== -1 && job.quantity === this.totalAssignment) {
      // job is full
      this.isFull = true;
    } else {
      this.isFull = false;
    }
    if (job.quantity !== -1) {
      this.openSpots = this.jobQuantity - this.totalAssignment;
    } else {
      this.openSpots = 'unlimited';
    }
  }

  addComment() {
    this.commentLoading = true;
    var comment = this.commentForm.get('comment').value;
    this.commentService.post(this.accessKey, this.activity['id'], this.job['id'], comment).subscribe(
      response => {
        if (response) {
          //clear comment input & refresh list
          this.commentForm.reset();
          this.refreshComments();
        }
      }
    )
  }

  editSpotDialog(qty, jobId) {
    let repeatingJob: boolean = false;
    repeatingJob = this.job['repeating'];
    const dialogRef = this.dialog.open(EditSpotsDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      autoFocus: false,
      data: {
        quantity: qty,
        openSpots: this.openSpots,
        activityId: this.activity['id'],
        jobId: jobId,
        repeating: repeatingJob ? true : false,
        title: 'Edit Spots',
        message: 'Edit the Participant Spot'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result['deletedJob'] === true) {
          //refresh job
          this.getJob();
        } else if (result['numSpot'] > 0) {
          //update spot
          this.updateJobAssignment(jobId, result['numSpot']);
        }
      }

    })
  }

  updateJobAssignment(jobId, qty) {
    this.jobService.updateJobAssignment(this.activity['id'], jobId, qty).subscribe(
      response => {
        if (response) {
          //refresh job
          this.getJob();
        }
      }
    )
  }

  deleteCommentDialog(comment) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      autoFocus: false,
      data: {
        isConfirm: true,  //confirm dialog
        isContinue: true,
        buttonText: '_Delete_',
        buttonCancelText: '_Cancel_',
        title: '_CommentsModalTableDeleteConfirmTitle_',
        message: '_CommentsModalTableDeleteConfirm_'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'continue') {
        this.deleteComment(comment);
      }
    })
  }

  deleteComment(comment) {
    this.commentLoading = true;
    this.commentService.delete(this.activity['id'], this.accessKey, comment.id).subscribe(
      response => {
        if (response.status) {
          //refresh comment list
          this.refreshComments();
        }
      }
    )
  }

  refreshComments() {
    this.commentService.getJobComment(this.activity['id'], this.job['id']).subscribe(
      response => {
        if (response) {
          this.commentLoading = false;
          this.comments = response.body['data'];
          this.commentTotal = response.body['count'];
        }
      }
    )
  }

  addParticipant() {
    this.router.navigateByUrl('edit_select_participant/' + this.job['id'], {skipLocationChange: true});
  }

  sanitizeSms(phone) {
    return this.sanitize.transform(phone)
  }

  editJob() {
    if (this.creating) {
      this.router.navigate(['edit_job/' + this.job['id']], { queryParams: { mode: 'create' }});

    } else {
      this.router.navigate(['edit_job/' + this.job['id']]);
    }
  }

  viewParticipant(volId) {
    this.volunteerService.query(this.activity['id'], volId).subscribe(
      response => {
        if (response.status) {
          let route = this.router,
            activityId = this.activity['id'];
          this.storageService.process('participant', response.body['volunteer'], function () {
            route.navigateByUrl('edit_participant_detail/' + activityId, { skipLocationChange: true });
          });
        }
      }
    )
  }

  removeJobDialog(forceParam?) {
    const dialogRef = this.dialog.open(DeleteFutureDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      autoFocus: false,
      data: {
        type: 'delete job',
        jobAssignment: this.job['jobassignments'].length ? true : false,
        repeating: this.job['repeating'] ? true : false,
        force: forceParam ? true : false,
        param: forceParam
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteJob(result);
      }
    })
  }

  deleteJob(res) {
    let data = {
      id: this.job['id'],
      selected_activity: this.activity['id'],
      force: false
    };
    if (res.type === 'future') {
      data['delete_all'] = true;
    }
    if (res.force === true) {
      data['force'] = true;
    }
    this.jobService.deleteJob(data).subscribe(result => {
      if (result) {
        if (result.body['force_required']) {
          //force is required, show alert again
          this.removeJobDialog(result.body['show_params'][0]);
        } else if (result.body['status']) {
          if (this.creating) {
            //go back to create wizard
            // this.storageService.set('jobPageVisited', true);
            this.router.navigateByUrl('create');
          } else {
            //go back to list of jobs
            this.router.navigateByUrl('edit/spot/' + this.activity['id']);
          }
        }
      }
    })
  }
}

