import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CreateSignupComponent } from './create-signup.component';
// import { AddThemeComponent } from './add-theme/add-theme.component';
import { ShowPreviewComponent } from './show-preview/show-preview.component';
import { DonePageComponent } from './done-page/done-page.component';

@NgModule({
    imports: [
        CommonModule,
        AppRoutingModule,
        SharedModule
    ],
    declarations: [
        CreateSignupComponent,
        ShowPreviewComponent,
        DonePageComponent
    ],
    exports: [
        CommonModule,
        AppRoutingModule,
        SharedModule
    ]
})
export class CreateSignupModule { }
