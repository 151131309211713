import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { JobService } from '../../shared-services/job.service';

@Component({
  selector: 'app-edit-spots-detail-dialog',
  templateUrl: './edit-spots-detail-dialog.component.html',
  styleUrls: ['./edit-spots-detail-dialog.component.scss']
})
export class EditSpotsDialogComponent implements OnInit {

  title: string;
  message: string;
  editJobAlert: FormGroup;
  quantity: number;
  maxOpenSpots: number;
  currSpotValue:number;      //to calculate increment and decrement if spot is manually entered
  spotsLeft: any;
  repeating: boolean = false;
  incrDisabled: boolean = false;    //disable add icon
  decrDisabled: boolean = false;    //disable remove icon
  deleteJob: boolean = false;       //show the delete buttons?
  params: {};
  msgParam: {};
  activityId: string;
  jobId: string;
  showError: boolean = false;
  currMaxSpots: number = 0;
  isUnlimited: boolean = false;

  constructor (
    private jobService: JobService,
    public dialogRef: MatDialogRef<EditSpotsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {
    }

  ngOnInit() {
      this.title = this.data.title ? this.data.title : null;
      this.message = this.data.message ? this.data.message : null;
      this.quantity = this.data.quantity ? this.data.quantity : 1;
      this.activityId = this.data.activityId ? this.data.activityId : null;
      this.jobId = this.data.jobId ? this.data.jobId : null;
      this.repeating = this.data.repeating ? true : false;
      this.currSpotValue = this.quantity;
      this.isUnlimited =  this.data.openSpots == 'unlimited';
      if (!this.isUnlimited) {
        this.maxOpenSpots = this.data.openSpots;
        this.spotsLeft = this.maxOpenSpots;
        if (this.maxOpenSpots === 0) {
          this.incrDisabled = true;
        }
      } else {
        this.spotsLeft = 'unlimited';
      }

      this.editJobAlert = new FormGroup ({
        num: new FormControl(this.quantity, [Validators.required])
      })
  }


  // calcRemainingSpots(event) {
  //   //called when value is manually entered
  //   let newVal = parseInt(event.target.value);
  //   this.currMaxSpots = !this.isUnlimited ? this.maxOpenSpots + this.quantity : 9999;
  //   if (!this.isUnlimited) {
  //     this.spotsLeft = this.currMaxSpots;
  //    } else {
  //     this.spotsLeft = 'unlimited';
  //   }
  //   if (newVal === 0) {
  //     //show delete messages
  //     this.deleteJob = true; 
  //     this.decrDisabled = true;
  //     this.incrDisabled = false;
  //     this.showError = false;
  //   } else if (!newVal) {
  //     //value is blank
  //     this.showError = true;
  //     this.decrDisabled = true;      
  //     this.incrDisabled = true;
  //   } else if (newVal) {
  //     //value is valid
  //     this.decrDisabled = false;
  //     this.deleteJob = false; 
  //     if (newVal === this.currMaxSpots) {
  //       this.showError = false;
  //       this.incrDisabled = true;
  //       this.spotsLeft = 0;
  //     } else if (newVal > this.currMaxSpots) {
  //       //value is more than total open spots, show error
  //       this.incrDisabled = true;
  //       this.showError = true;
  //       this.spotsLeft = this.currMaxSpots;
  //     } else {
  //       //value is less than total open spots
  //       if (!this.isUnlimited) {
  //         this.showError = false;
  //         this.incrDisabled = false;
  //         this.decrDisabled = false;
  //         this.spotsLeft =  this.currMaxSpots - newVal;
  //       }
  //     }
  //   } 
  // }

  spotCalc(type, event?) {
    let newSpot;
    this.currMaxSpots = !this.isUnlimited ? this.maxOpenSpots + this.quantity : 9999;

    if (type == 'increase') {
      newSpot = this.editJobAlert.get('num').value + 1;
    } else if (type === 'decrease') {
      newSpot = this.editJobAlert.get('num').value - 1;
    } else if (type === 'keypress') {
      newSpot = Math.abs(parseInt(event.target.value));
    }
    if (newSpot == 0) {
      this.deleteJob = true;
      this.decrDisabled = true;
      this.incrDisabled = false;
      this.showError = true;
      this.spotsLeft = !this.isUnlimited ? this.currMaxSpots : 'unlimited';
      this.editJobAlert.patchValue({ num: 0 });
    } else if (newSpot > this.currMaxSpots) {
      //value is greater than max, show error
      this.showError = true;
      this.incrDisabled = true;
      this.decrDisabled = false;
      this.deleteJob = false;
      this.spotsLeft = !this.isUnlimited ? this.currMaxSpots : 'unlimited';
      this.editJobAlert.patchValue({ num: newSpot });
    }
    else {
      //new val is not 0
      this.deleteJob = false;
      if (!this.isUnlimited) {
        //spots are not unlimited
        if (newSpot < this.currMaxSpots) {
          //value entered is less than max spot
          this.incrDisabled = false;
          if (newSpot !== 0) {
            //value is not 0
            this.deleteJob = false;
            this.decrDisabled = false;
            this.spotsLeft = this.currMaxSpots - newSpot;
            this.editJobAlert.patchValue({ num: newSpot });
            this.showError = false;
          }
          // this.editJobAlert.patchValue({num: newSpot});
        } else if (newSpot == this.currMaxSpots) {
          //value is max spots
          this.incrDisabled = true;
          this.decrDisabled = false;
          this.showError = false;
          this.spotsLeft = 0;
          this.editJobAlert.patchValue({ num: newSpot });
        } 
      } else {
        //spots are unlimited
        this.spotsLeft = 'unlimited';
        this.showError = false;
        this.incrDisabled = false;
        this.decrDisabled = false;
        this.editJobAlert.patchValue({ num: newSpot });
      }
    }
  }

  saveForm() {
    this.dialogRef.close({
      numSpot: this.editJobAlert.get('num').value
    });
  }

  delete() {
      this.jobService.deleteJobAssignment(this.activityId, this.jobId).subscribe(
        response => {
          this.dialogRef.close({
            deletedJob: true
          });
        }
      )
  }

  continue() {
    this.dialogRef.close('continue');
  }
  
  goBack() {
    window.history.back();
  }
  
  closeOrCancel() {
    this.dialogRef.close();
  }
}
