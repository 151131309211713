import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StorageService } from '../../shared-services/storage.service';

@Component({
  selector: 'app-number-spinner',
  templateUrl: './number-spinner.component.html',
  styleUrls: ['./number-spinner.component.scss']
})
export class NumberSpinnerComponent implements OnInit {

  constructor(private storageService: StorageService) { }

  showError: boolean = false;
  spinnerForm: FormGroup;
  currentQty: number;
  isDisabled: boolean = false;
  isUnlimited: boolean = false;

  //output and input values passed to and from parent controller
  @Input() min: number;
  @Input() max: number;
  @Input() quantity: number;
  @Input() showUnlimited: string;
  @Output() update = new EventEmitter();
  @Output() error = new EventEmitter();

  ngOnInit() {
    if (this.quantity == -1) {
      this.isDisabled = true;
      this.isUnlimited = true;
    } else {
      this.currentQty = this.quantity || 1;
      this.isDisabled = false;
      this.isUnlimited = false;

    }
    this.spinnerForm = new FormGroup({
      formQuantity: new FormControl({ value: this.currentQty, disabled: this.isUnlimited }),
      unlimitedSpots: new FormControl(this.isUnlimited)
    })
    // console.log('max is ', this.max, 'min is ', this.min, 'qty is ', this.quantity, 'showUnlimited', this.showUnlimited);
  }

  setVal(val) {
    let maxLength = 4;
    if (val) {
      if (val > 9999) {
        val = Math.abs(parseInt(val.toString().slice(0, maxLength)));
        this.spinnerForm.patchValue({ formQuantity: val });
        this.showError = false;
      } else if (val >= this.min) {
        //emit the quantity
        this.update.emit(val);
        this.showError = false;
      } else {
        if (Math.sign(val) == -1) {
          //val is negative, turn to positive and accept it
          val = Math.abs(parseInt(val));
          this.spinnerForm.patchValue({ formQuantity: val });
          this.update.emit(val);
          this.showError = false;
        } else {
        //val is 0
        this.showError = true;
        this.error.emit({error: true});
        }
      }
    } else {
      //val is empty
      this.showError = true;
      this.error.emit({error: true});
    }
  }

  increaseSpot() {
    if (!this.isUnlimited) {
      let val = this.spinnerForm.get('formQuantity').value,
        maxLength = 4,
        newSpot;
      if (val) {
        if (val < this.max) {
          newSpot = parseInt(val) + 1;
          this.spinnerForm.patchValue({ formQuantity: newSpot });
          if (newSpot > 0) {
            this.showError = false;
            this.update.emit(newSpot);
          }
        } else if (val > 9999) {
          //max is 9999, dont go above
          val = parseInt(val.toString().slice(0, maxLength));
          this.spinnerForm.patchValue({ formQuantity: val });
        }
      } else {
        //no value, default to 1
        newSpot = 1;
        this.spinnerForm.patchValue({ formQuantity: newSpot });
        this.showError = false;
      }
    }
  }

  decreaseSpot() {
    if (!this.isUnlimited) {
      let val = this.spinnerForm.get('formQuantity').value,
        newSpot;
      if (val > 1) {
        newSpot = parseInt(val) - 1;
        this.spinnerForm.patchValue({ formQuantity: newSpot });
        this.update.emit(newSpot);
        this.showError = false;
      }
    }
  }

  toggleSpotInput(event) {
    let val;
    if (event.checked) {
      this.spinnerForm.controls['formQuantity'].disable();
      this.isUnlimited = true;
      this.update.emit({ isUnlimited: true });
    } else {
      // default to 1
      val = 1;
      this.spinnerForm.controls['formQuantity'].enable();
      this.spinnerForm.patchValue({ formQuantity: 1 })
      this.isUnlimited = false;
      // emit value
      this.update.emit(val);
    }
  }
}
