import { Component, OnInit } from '@angular/core';
import { AlertDialogComponent } from './../../shared/alert-dialog/alert-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StorageService } from '../../shared-services/storage.service';
import { ActivityService } from './../../shared-services/activity.service';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-edit-participant-info',
  templateUrl: './edit-participant-info.component.html',
  styleUrls: ['./edit-participant-info.component.scss']
})
export class EditParticipantInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
