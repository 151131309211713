<app-header icon="back" title="_MessageParticipants_" showNav="false" backText="_Spots_" changed="{{changedVal}}">
</app-header>

<div class="messages-page body-container">
  <div *ngIf="!selectedParticipants.length" class="message-header">{{'_SelectParticipantsToMsg_' | translate}}</div>
  <div *ngIf="selectedParticipants.length" class="message-header">{{'_EnterParticipantsMsg_' | translate}}</div>
  <div class="recipients" (click)="selectRecipients()">
    <span *ngIf="selectedParticipants.length">{{selectedParticipants.length}} Participant(s)</span>
    <span *ngIf="!selectedParticipants.length">Select Participants</span>
    <span><i class="material-icons">keyboard_arrow_right</i></span>
  </div>
  <section class="container">
    <!-- message section -->
    <form [formGroup]="messagesForm">
      <div class="form-group">
        <label for="Subject"><small>{{'_Subject_'| translate}}</small></label>
        <input type="text" class="form-control" formControlName="editorSubject" name="editorSubject" required>
      </div>
      <label for="Subject"><small>{{'_Message_'| translate}}</small></label>
      <div class="form-group">
        <quill-editor formControlName="editor" [modules]="editorConfig" (onContentChanged)="getMaxLength($event)"
          placeholder="Send a direct message...">
        </quill-editor>
        <span class="ql-editor-count"
          *ngIf="messagesForm.get('editor').value != null">{{editorCount}}/{{editorMaxLength}}</span>
        <span class="ql-editor-count" *ngIf="messagesForm.get('editor').value == null">0/{{editorMaxLength}}</span>
      </div>
      <div class="text"><small>Recipients will automatically receive a link to the SignUp with this message.</small></div>
      <button type="submit" [disabled]="!messagesForm.valid || formError" class="btn btn-blue btn-block mt-4 mb-4" (click)="onEditorSubmit()">Send</button>
    </form>
  </section>
</div>