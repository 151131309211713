<div class="alert-dialog">
  <div *ngIf="isSuccess" class="success-dialog">
    <!-- success form -->
    <a (click)="closeOrCancel()" class="c-pointer"><img src="assets/images/close-btn.png" alt=""></a>
    <!-- <img src="assets/images/close-btn.png" alt="" (click)="closeOrCancel()" class="c-pointer"> -->

    <h2 mat-dialog-title class="success-header">{{title}}</h2>
    <mat-dialog-content>
      <p>{{message}}</p>
    </mat-dialog-content>
  </div>

  <div *ngIf="isExtraFieldForm" class="show-input-div">
    <!-- extra field form -->
    <h2 mat-dialog-title>{{fieldTitle | translate}}</h2>
    <mat-dialog-content [formGroup]="dialogForm">
      <p class="label-name">{{'_ExtraFieldName_' | translate}}</p>
      <mat-form-field>
        <mat-placeholder class="placeholder">{{'_AdditionalInfoEx_' | translate}}</mat-placeholder>
        <input type="text" class="form-control" matInput maxLength="40" formControlName="formInput"
          [matAutocomplete]="auto">
      </mat-form-field>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let item of suggestions" [value]="item">
          <span>{{item}}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-checkbox formControlName="isRequired">{{'_Required_' | translate}}
      </mat-checkbox>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button class="btn btn-blue edit-update-btn" [disabled]="!dialogForm.valid"
        (click)="saveForm()">{{'_Save_' | translate}}</button>
      <button (click)="closeOrCancel()" class="cancel-btn-no-border">{{'_Cancel_' | translate}}</button>
    </mat-dialog-actions>
  </div>

  <div *ngIf="isOrgForm" class="org_form">
      <!-- organizer form -->
      <h2 mat-dialog-title>{{fieldTitle | translate}}</h2>
      <mat-dialog-content [formGroup]="dialogForm">
        <!-- organizer email -->
        <mat-form-field>
            <p class="label-name">{{'_Email_' | translate}}</p>
          <input type="text" 
          class="form-control" 
          matInput maxLength="40" 
          formControlName="email"
          (blur) = "verifyUser($event)">
          <div *ngIf="showError" class="error-div" >{{errorMsg | translate:errorParams}}</div>
        </mat-form-field>
        <!-- organizer name -->
        <mat-form-field>
          <p class="label-name">{{'_Name_' | translate}}</p>
          <input type="text" class="form-control" matInput maxLength="40" formControlName="name">
        </mat-form-field>
        <!-- organizer phone number -->
        <mat-form-field>
          <p class="label-name">{{'_PhoneNumber_' | translate}}</p>
          <input type="text" class="form-control" matInput maxLength="40" formControlName="phone">
        </mat-form-field>
        <!-- organizer title -->
        <mat-form-field>
          <p class="label-name">{{'_Title_' | translate}}</p>
          <input type="text" class="form-control" matInput maxLength="40" formControlName="title">
        </mat-form-field>
      </mat-dialog-content>

        <mat-dialog-actions align="end">
          <button (click)="closeOrCancel()" class="cancel-btn-no-border">{{'_Cancel_' | translate}}</button>
          <button class="btn btn-blue edit-update-btn" [disabled]="!formIsValid"
          (click)="saveOrgForm()">{{'_Save_' | translate}}</button>
      </mat-dialog-actions>
    </div>
</div>
