import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../shared-services/storage.service';
import { UtilitiesService } from '../../shared-services/utilities.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivityService } from '../../shared-services/activity.service';
import { ReportingService } from './../../shared-services/reporting.service';
import { ErrorHandlerService } from '../../shared-services/error-handler.service';
import { UserService } from '../../shared-services/user.service';

import { AlertDialogComponent } from './../../shared/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Browser } from '@capacitor/browser';

// declare var cordova: any;

@Component({
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.scss']
})
export class EditDetailComponent implements OnInit {

  constructor(private storageService: StorageService,
    private utilitiesService: UtilitiesService,
    private translate: TranslateService,
    private activityService: ActivityService,
    private reportingService: ReportingService,
    private errorHandlerService:ErrorHandlerService,
    private userService: UserService,
    private router: Router,
    public dialog: MatDialog) { }

  activity: {};
  user: {};
  userIsPremium: boolean = false;
  primaryOrg: string;
  activityThemeFolder: string;
  title: string;
  category: string;
  description: string;
  location: string;
  primaryName: string;
  primaryEmail: string;
  assistOrganizers: string[] = [];
  loading: boolean = false;
  hasSpots: boolean = false;
  timeZone: string;
  datedSignup: boolean = false;

  //reports
  reportForm: FormGroup;
  whosComing: boolean = false;
  signUpSheet: boolean = false;
  tracking: boolean = false;
  export: boolean = false;
  checkInEnabled: boolean = false;
  formIsValid: boolean = false;

  share: boolean;
  showNames: boolean;
  allowSwap: boolean;
  signupOthers: boolean;
  hideComment: boolean;
  time24hr: boolean;
  nag: boolean;
  limitSpotsActive: boolean;

  //participant info
  requirePhone: boolean;
  customField: string[] = [];

  //notifications
  emailPrefs: {};

  //array of dropdown values 
  sendRemindersArr: string[] = [];
  notifySpotsChangedArr: string[] = [];
  commentsSummaryArr: string[] = [];
  notifySignUpArr: string[] = [];
  notifyCancelArr: string[] = [];
  notifyFullSignupArr: string[] = [];

  //selected value
  sendReminders: string;
  notifySpotsChanged: string;
  commentsSummary: string;
  notifySignUp: string;
  notifyCancel: string;
  notifyFullSignup: string;
  addExtraDetails: string;

  //show less description text;
  showLess: boolean;
  //loading gif
  notifSpinner: boolean;

  ngOnInit() {
    this.notifSpinner = true;
    //show less description text by default
    this.showLess = true;
    this.activity = this.storageService.get('activity');
    this.timeZone = this.activity['timezone'];
    this.user = this.storageService.get('user');
    this.datedSignup = this.activity['kind'] === 'nodate' ? false : true;
    //cleanup previous values
    this.storageService.remove('this.extraFieldArr');      
    if (!this.activity || !this.user) {
      //no activity or user data, log out
      this.userService.logout().subscribe(
        () => {
          this.router.navigateByUrl('/organizer/login');  
          this.storageService.reset();  
          this.storageService.set('full_login', false, null);  
        }
      )
    } else {
      if (this.user) {
        if (this.user['premium']) {
          this.userIsPremium = true;
        } else {
          this.userIsPremium = false;
        }  
        // reload activity in case there are changes
        this.refreshActivityData();
        //call api to check if activity has spots
        this.activityService.hasSpots(this.activity['id']).subscribe(result => {
          if (result && result.body['status']) {
            this.hasSpots = result.body['hasSpots'];
          }
        })
      }
      this.initReportForm();
    }
  }

  refreshActivityData() {
    this.activityService.orgActivityQuery(this.activity['id']).subscribe(
      response => {
        if (response.status) {
          JSON.stringify(response);
          this.storageService.process('activity', response.body['data']);
          this.activity = response.body['data'];
          this.activityThemeFolder = this.activity['theme']['folder'];

          //basic info
          this.title = this.activity['title'];
          this.category = this.activity['activitytype'];
          this.description = this.activity['description'];
          this.location = this.activity['location'];
          this.share = this.activity['share'];
          this.showNames = this.activity['shownames'];
          this.allowSwap = this.activity['allowswap'];
          this.signupOthers = this.activity['signup_others'];
          this.hideComment = this.activity['hide_comments'];
          this.time24hr = this.activity['time_24hr'];
          this.nag = this.activity['nag'];
          if (this.userIsPremium) {
            if (this.activity['activity_limit'] && Object.keys(this.activity['activity_limit']).length > 0) {
              this.limitSpotsActive = true;
            } else {
              this.limitSpotsActive = false;
            }
          }

          //participant info
          this.requirePhone = this.activity['require_phone'];

          //get primary organizer
          this.activityService.getOrganizers(this.activity['id']).subscribe(
            response => {
              if (response) {
                response.body['data'].map(item => {
                  if (item['primary'] == true) {
                    this.primaryName = item['name'];
                    this.primaryEmail = item['email'];
                    this.storageService.set('primary_organizer', item['user_id'], null);
                  } else {
                    //array of other organizers
                    this.assistOrganizers.push(item);
                  }
                })
                this.storageService.set('organizersArr', response.body['data'], null);
              }
            },
            error => {
              this.errorHandlerService.resolve(error.status);  
            }
            
          )
          this.getEmailPreferences(this.activity);
        }
      },
      error => {
        this.errorHandlerService.resolve(error.status);  
      }
    )
  }

  initReportForm() {
    this.reportForm = new FormGroup({
      whosComingComments: new FormControl(false),
      customFields: new FormControl(false),
      participantDetails: new FormControl(false),
      checkInSheet: new FormControl(false),
      customFieldsTracking: new FormControl(false),
      participantHours: new FormControl(false)
    })
  }

  getEmailPreferences(activity) {
    this.utilitiesService.getEmailPreferences(activity['id']).subscribe(
      response => {
        this.emailPrefs = response.body;
        //Send reminders to participants
        forkJoin([
          this.translate.get('_NoDayReminder_'),
          this.translate.get('_OneDayReminder_'),
          this.translate.get('_TwoDayReminder_'),
          this.translate.get('_ThreeDayReminder_'),
          this.translate.get('_FourDayReminder_'),
          this.translate.get('_FiveDayReminder_'),
          this.translate.get('_SixDayReminder_'),
          this.translate.get('_SevenDayReminder_')
        ]).subscribe(
          response => {
            this.storageService.set('email_prefs', this.emailPrefs, null);
            this.sendRemindersArr.push(response[0], response[1], response[2], response[3], response[4], response[5], response[6], response[7]);
            this.notifSpinner = false;
            // console.log(this.sendRemindersArr);
            this.sendReminders = this.sendRemindersArr[this.emailPrefs['reminders']];
            this.storageService.set('send_reminders', this.sendRemindersArr, null);
          }
        )

        //Notify participants when spots are changed
        forkJoin([
          this.translate.get('_Yes_'),
          this.translate.get('_No_')
        ]).subscribe(
          response => {
            this.notifySpotsChangedArr.push(response[0], response[1]);
            this.notifySpotsChanged = this.notifySpotsChangedArr[this.emailPrefs['changemessages']];
            this.storageService.set('notify_spots_changed', this.notifySpotsChangedArr, null);
          }
        )

        // Send comments summary
        forkJoin([
          this.translate.get('_DailySummary_'),
          this.translate.get('_Immediately_')
        ]).subscribe(
          response => {
            this.commentsSummaryArr.push(response[0], response[1]);
            this.commentsSummary = this.commentsSummaryArr[this.emailPrefs['comments']];
            this.storageService.set('comment_summary', this.commentsSummaryArr, null);
          }
        )

        //Notify me when someone signs up
        forkJoin([
          this.translate.get('_No_'),
          this.translate.get('_NotifyOnSignupAlways_'),
          this.translate.get('_NotifyOnSignupOneDay_'),
          this.translate.get('_NotifyOnSignupTwoDay_'),
          this.translate.get('_NotifyOnSignupThreeDay_'),
          this.translate.get('_NotifyOnSignupFiveDay_'),
          this.translate.get('_NotifyOnSignupOneWeek_')
        ]).subscribe(
          response => {
            this.notifySignUpArr.push(response[0], response[1], response[2], response[3], response[4], '', response[5], '', response[6]);
            this.notifySignUp = this.notifySignUpArr[this.emailPrefs['signups']];
            this.storageService.set('notify_signup', this.notifySignUpArr, null);
          }
        )

        // Notify me when someone cancels
        forkJoin([
          this.translate.get('_AfterReminderEmail_'),
          this.translate.get('_CancelAnytime_')
        ]).subscribe(
          response => {
            this.notifyCancelArr.push(response[0], response[1]);
            this.notifyCancel = this.notifyCancelArr[this.emailPrefs['cancellations']];
            this.storageService.set('notify_cancel', this.notifyCancelArr, null);
          }
        )

        // Notify me when this SignUp is full
        forkJoin([
          this.translate.get('_No_'),
          this.translate.get('_Yes_')
        ]).subscribe(
          response => {
            this.notifyFullSignupArr.push(response[0], response[1]);
            this.notifyFullSignup = this.notifyFullSignupArr[this.emailPrefs['full']];
            this.storageService.set('notify_full_signup', this.notifyFullSignupArr, null);
          }
        )

        // Add extra details to all reminders
        this.addExtraDetails = this.emailPrefs['reminder_message'];

        //get extra details
        activity['custom_fields'].map((item) => {
          this.customField.push(item);
        })
      },
      error => {
        this.errorHandlerService.resolve(error.status);  
      }
      )
  }

  generateLink() {
    var link;
    this.utilitiesService.generateLink(this.activity['id']).subscribe(
      response => {
        //save current user object because it changes when you to participant view
        this.storageService.set('userObject',this.storageService.get('user'));
        link = response.body['longlink'] + '&amp;popup=true&amp;preview=true';
        if (this.storageService.get('isCordovaApp') && this.storageService.get('isCordovaApp') === true) {
          // cordova.InAppBrowser.open(link, '_system', 'location=yes');
          Browser.open({ url: link });
        } else {
          window.location.href = link;
        }
      },
      error => {
        this.errorHandlerService.resolve(error.status);  
      }
    )
  }

  toggleDesc(show) {
    this.showLess = show;
  }

  //EDIT MODE
  editSignupInfo() {
    let route = this.router, activity = this.activity;
    this.storageService.set('selectedActivity', activity, function() {
      route.navigateByUrl('edit_signup_info/' + activity['id']);
    });
  }

  editParticipantInfo() {
    this.router.navigateByUrl('edit_participant_info/' + this.activity['id']);
  }

  editOrganizerInfo() {
    this.router.navigateByUrl('edit_organizer_info/' + this.activity['id']);
  }

  editThemeInfo() {
    this.router.navigateByUrl('edit_theme_info/' + this.activity['id']);
  }

  editSettings() {
    this.router.navigateByUrl('edit_notification_settings/' + this.activity['id']);
  }

  editTimeZone() {
    this.router.navigateByUrl('edit_timezone/' + this.activity['id']);
  }

  selectWhosComing() {
    this.whosComing = true;
    this.signUpSheet = false;
    this.tracking = false;
    this.export = false;
    this.formIsValid = true;
  }

  selectSignUpSheet() {
    this.signUpSheet = true;
    this.whosComing = false;
    this.tracking = false;
    this.export = false;
    this.formIsValid = true;

  }

  selectTracking() {
    this.tracking = true;
    this.whosComing = false;
    this.signUpSheet = false;
    this.export = false;
    this.formIsValid = true;
  }

  selectExport() {
    this.export = true;
    this.whosComing = false;
    this.signUpSheet = false;
    this.tracking = false;
    this.formIsValid = true;
  }

  onReportSubmit() {
    this.loading = true;
    let options = {};
    if (this.export) {
      options = {
        selected_activity: this.activity['id'],
        email_it: true
      }
      this.reportingService.export(options).subscribe(response => {
        if (response.status) {
          this.loading = false;
          this.showConfAlert();
        }
      },      
      error => {
        this.errorHandlerService.resolve(error.status);  
      })
    } else {
      //its either who's coming, signup sheet or roster and hours tracking
      if (this.whosComing) {
        options = {
          selected_activity: this.activity['id'],
          include_comments: this.reportForm.get('whosComingComments').value,
          start_date: this.activity['startdate'] === '2038/01/01' ? 'undefined' : this.activity['startdate'] ,
          end_date: this.activity['enddate'] === '2038/01/01' ? 'undefined' : this.activity['enddate'] ,

          email_it: true
        }
        //send info to server
        this.reportingService.printSummary(options).subscribe(response => {
          if (response.status) {
            this.loading = false;
            this.showConfAlert();
          }
        },
        error => {
          this.errorHandlerService.resolve(error.status);  
        })
      } else if (this.signUpSheet) {
        options = {
          selected_activity: this.activity['id'],
          hide_contact_info: this.reportForm.get('participantDetails').value,
          include_extra_fields: this.reportForm.get('customFields').value,
          hide_empty_spots: this.reportForm.get('checkInSheet').value,
          start_date: this.activity['startdate'],
          end_date: this.activity['enddate'],
          email_it: true
        }

        //send info to server
        this.reportingService.printSignup(options).subscribe(response => {
          if (response.status) {
            this.loading = false;
            this.showConfAlert();
          }
        },
        error => {
          this.errorHandlerService.resolve(error.status);  
        })
      } else {
        //roster and hours tracking
        options = {
          selected_activity: this.activity['id'],
          include_extra_fields: this.reportForm.get('customFieldsTracking').value,
          email_it: true
        }
        if (this.userIsPremium) {
          options['include_hours'] =  this.reportForm.get('participantHours').value;
        }
        //send info to server
        this.reportingService.printRoster(options).subscribe(response => {
          if (response.status) {
            this.loading = false;
            this.showConfAlert();
          }
        },
        error => {
          this.errorHandlerService.resolve(error.status);  
        })
      }
    }
  }

  showConfAlert() {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      data: {
        isSuccess: true,    //success dialog
        title: 'Success', 
        message: 'Your report will be sent shortly.'
      }
    });
  }
}


