import {Injectable} from "@angular/core";
import {Router, NavigationEnd} from "@angular/router";

declare var gtag:Function;
declare var ga:Function;

@Injectable()
export class GoogleAnalyticsService {

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        try {
          ga('set', 'page', '/m' + event.urlAfterRedirects);
          ga('send', 'pageview');
          // GA4
          gtag('event', 'page_view', {
            page_path: '/m' + event.urlAfterRedirects,
          });

        } catch {}
      }
    });
   }

  public emitEvent(eventCategory: string,
                   eventAction: string,
                   eventLabel: string = null,
                   eventValue: number = null) {
    try {
      ga('send', 'event', {
        eventCategory: eventCategory,   //usually button
        eventLabel: eventLabel,         //the link name or attribute
        eventAction: eventAction,       //usually click
        eventValue: eventValue          //can be left blank
      });
      // GA4
      gtag('event', eventAction, {
        'event_category': eventCategory,
        'event_label': eventLabel,
        'value': eventValue
      });

    } catch {};
  }
}
