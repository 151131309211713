import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ForgotPswdService {

  constructor(private http: HttpClient,
    @Inject('BASE_API_URL') private apiURL:string) { }

  orgForgotPswd(data) {
    return this.http.post(this.apiURL + '/api/users/forgot_password', data, {observe: 'response'});
  };

}
