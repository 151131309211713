import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private http: HttpClient,
    @Inject('BASE_API_URL') private apiURL:string) {}
    
  generateLink(activityId) {
    var data = {
      'selected_activity': activityId
    }
    return this.http.get(this.apiURL + '/api/utilities/generate_invitation_link/', {params: data, observe: 'response'});
  }
 
  getEmailPreferences(activityId) {
    var data = {
      'selected_activity': activityId
    }
    return this.http.get(this.apiURL + '/api/utilities/get_email_preferences', {params: data, observe: 'response'});
  }

  setEmailPreferences(data) {
    return this.http.post(this.apiURL + '/api/utilities/set_email_preferences', data, {params: data, observe: 'response'});
  }

  sendDraftInvite(activityId) {
    var data = {
      invitation_message: '',
      selected_activity: activityId,
      wampum_message: ''
    }
    return this.http.post(this.apiURL + '/api/messages/send_draft_invitation_only', data, {observe: 'response'});
  }
  
}
