import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { AvatarModule } from 'ngx-avatars';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UtilitiesService } from '../../shared-services/utilities.service';
import { VolunteerService } from '../../shared-services/volunteer.service';
import { StorageService } from '../../shared-services/storage.service';
import { FilterPipe } from '../../pipes/filter.pipe';
import { Browser } from '@capacitor/browser';

// declare var cordova: any;

@Component({
  selector: 'app-edit-participants',
  templateUrl: './edit-participants.component.html',
  styleUrls: ['./edit-participants.component.scss']
})
export class EditParticipantComponent implements OnInit {

  participants$;
  volunteerId: number;
  activity: {};
  inputSearch;
  loading: boolean = true;
  isCordovaApp:boolean = false;

  constructor(private router:Router,
              private volunteerService: VolunteerService,
              private utilitiesService: UtilitiesService,
              private storageService: StorageService) { }

  ngOnInit() {
    //check if app is run in browser or the mobile app
    if (this.storageService.get('isCordovaApp') && this.storageService.get('isCordovaApp') === true) {
      this.isCordovaApp = true;
    }
    this.activity = this.storageService.get('activity');
    this.participants$ = this.volunteerService.get(this.activity['id'])
    .pipe(map(res => {
      this.loading = false;
      return res.body['data'];
    }))
  }

  goToParticipantDetail(participant) {
    let route = this.router;
    let activityId = this.activity['id'];
    this.storageService.process('participant', participant, function() {
      route.navigateByUrl('edit_participant_detail/' + activityId, {skipLocationChange: true});
    });
  }

  generateLink() {
    var link;
    this.utilitiesService.generateLink(this.activity['id']).subscribe(
      response => {
        //save current user object because it changes when you to participant view
        this.storageService.set('userObject',this.storageService.get('user'));
        link = response.body['longlink'] + '&amp;popup=true&amp;preview=true';
        if (this.isCordovaApp) {
          // cordova.InAppBrowser.open(link, '_system', 'location=yes');
          Browser.open({ url: link });        
        } else {
          window.location.href = link;
        }
      }
    )
  }
}
