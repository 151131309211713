import { Component, Input, Output, OnDestroy, OnInit, forwardRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { StorageService } from '../../shared-services/storage.service';
import { ActivityService } from './../../shared-services/activity.service';
import { AlertDialogComponent } from './../../shared/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-edit-participant-info',
  templateUrl: './add-edit-participant-info.component.html',
  styleUrls: ['./add-edit-participant-info.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddEditParticipantInfoComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddEditParticipantInfoComponent),
      multi: true
    }
  ]
})

export class AddEditParticipantInfoComponent implements ControlValueAccessor, OnInit, OnDestroy {

  editParticipantForm: FormGroup;
  subscriptions: Subscription[] = [];
  activity: {};
  user: {};
  phoneRequired: boolean = false;
  extraFieldArr: string[] = [];
  extraFieldSelected: string[] = [];
  extraFieldToDelete: string[] = [];
  maxExtraFields: number;
  changedVal: boolean = false;
  fieldSuggestions: [];
  loading: boolean = false;
  creating: boolean = false;
  editing: boolean = false;
  isOwner: boolean = false;

  get value() {
    return this.editParticipantForm.value;
  }

  set value(value) {
    this.editParticipantForm.patchValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get emailControl() {
    return this.editParticipantForm.controls.email;
  }

  // set tooltip defaults
  nagTooltip: string = '';

  tippyProps: NgxTippyProps = {
    allowHTML: true,
    trigger: 'click',
    arrow: true,
    placement: 'auto',
    maxWidth: 230,
  };

  constructor(private formBuilder: FormBuilder,
    private storageService: StorageService,
    private activityService: ActivityService,
    private router: Router,
    private tippyService: NgxTippyService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef) {
  }

  @Input() mode: string;

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value) {
    this.value = value ? value : '';
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.editParticipantForm.valid ? null : { profile: { valid: false } };
  }


  ngOnInit() {
    this.user = this.storageService.get('user');
    this.activity = this.storageService.get('activity');
    if (this.activity) {
      this.phoneRequired = this.activity['require_phone'];
      this.refreshActivityData();
    } else {
      //no activity because signup is just being created
      this.phoneRequired = true;
    }
    this.storageService.set('require_phone', this.phoneRequired);

    //creating or editing
    if (this.mode === 'create') {
      this.creating = true;
      this.isOwner = true;
    } else if (this.mode === 'edit') {
      this.editing = true;
      if (this.user && this.activity) {
        //check if user is the activity owner
        if (this.activity['org_email'] === this.user['email']) {
          this.isOwner = true;
        } else {
          this.isOwner = false;
        }
      }
    }
    // console.log(this.mode)

    //get max extra fields
    if (this.user) {
      if (this.user['premium'] && this.user['premium_data']) {
        this.maxExtraFields = this.user['premium_data'].features[1].count;
      } else {
        //user is not premium
        this.maxExtraFields = 1;
      }
    }

    this.editParticipantForm = this.formBuilder.group({
      requirePhone: [this.phoneRequired]
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.editParticipantForm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
    this.formValueChanged();
  }

  ngAfterViewInit() {
    this.setContentForTooltip();
    this.cdr.detectChanges();
  }

  setContentForTooltip() {
    this.translate.get('_AddFieldInfoNew_').subscribe((text) => {
      this.tippyService.setContent('addFieldInfo', text);
    })
    this.translate.get('_AddFieldHelpNew_').subscribe((text) => {
      this.tippyService.setContent('addFieldHelp', text);
    })
  }

  refreshActivityData() {
    this.loading = true;
    this.activityService.orgActivityQuery(this.activity['id']).subscribe(
      response => {
        if (response.status) {
          JSON.stringify(response);
          this.storageService.process('activity', response.body['data']);
          this.activity = response.body['data'];
          this.phoneRequired = this.activity['require_phone'];
          //refresh from server
          if (this.activity && this.activity['custom_fields'].length) {
            this.loading = false;
            this.activity['custom_fields'].forEach(item => {
              item['order'] = this.extraFieldArr.length + 1;
              this.extraFieldArr.push(item);
            });
            //sort by order in ui
            this.extraFieldArr.sort(function (a, b) {
              return a['order'] - b['order'];
            });
            this.storageService.set('origExtraFields', this.extraFieldArr);
          }
          this.loading = false;
        }
      },
    )
  }

  isPhoneRequired(event) {
    if (event.checked) {
      this.phoneRequired = true;
    } else {
      this.phoneRequired = false;
    }
    this.storageService.set('require_phone', this.phoneRequired);
  }

  manageQuestions() {
    //save activity changes first
    let urlMode: string,
      phoneRequired = this.editParticipantForm.get('requirePhone').value ? true : false;
    this.storageService.set('require_phone', phoneRequired);
    if (this.creating) {
      urlMode = 'create';
    } else {
      urlMode = 'edit';
    }
    this.router.navigate(['/extra-special-fields'], { queryParams: { mode: urlMode } });
  }

  cancelChanges() {
    // window.history.back();
    this.router.navigateByUrl('edit/detail/' + this.activity['id']);
  }

  openFieldDialog(field?, idx?: number) {
    //open dialog box to add or edit extra fields
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      autoFocus: false,
      data: {
        isExtraFieldForm: true,  //form dialog,
        isEdit: typeof idx == "undefined" ? false : true,
        showChkbox: true,
        chkboxLabel: 'required',
        formInput: field && field.title ? field.title : null,
        required: field && field.required ? field.required : null,
        suggestions: this.fieldSuggestions ? this.fieldSuggestions : null
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      var index, order;
      if (result.title) {
        result['required'] = result.required ? true : false;
        if (typeof idx !== "undefined") {
          //edit mode
          result['field_number'] = field.field_number;
          result['order'] = idx + 1;
          // replace value
          this.extraFieldArr.splice(idx, 1, result);
        } else {
          //add item to array
          result['field_number'] = this.extraFieldArr.length + 1;
          result['order'] = this.extraFieldArr.length + 1;
          this.extraFieldArr.push(result);
        }
        this.changedVal = true;
        if (this.creating) {
          this.storageService.set('extraFields', this.extraFieldArr);
        }
      }
    });
  }

  removeExtraField(idx) {
    this.extraFieldToDelete.push(this.extraFieldArr[idx]);
    this.extraFieldArr.splice(idx, 1);
    this.changedVal = true;
    if (this.creating) {
      this.storageService.set('extraFields', this.extraFieldArr);
    }
  }

  drop(event: CdkDragDrop<number[]>) {
    moveItemInArray(this.extraFieldArr, event.previousIndex, event.currentIndex);
    //set new item orders in array when dragged and dropped
    this.extraFieldArr.forEach((item, idx) => {
      item['order'] = idx + 1
    })
    this.changedVal = true;
    if (this.creating) {
      this.storageService.set('extraFields', this.extraFieldArr);
    }
  }

  formValueChanged() {
    //check for value changes
    this.editParticipantForm.valueChanges.subscribe(val => {
      this.changedVal = true;
    })
  }

  onSubmit(field?) {
    if (!this.activity) {
      this.activity = this.storageService.get('activity');
    }
    var activityData = {
      require_phone: this.editParticipantForm.get('requirePhone').value ? true : false,
      additional_fields: this.extraFieldArr,
      additional_fields_to_be_deleted: this.extraFieldToDelete,
      selected_activity: this.activity['id'],
      id: this.activity['id']
    };
    if (this.extraFieldToDelete.length) {
      activityData['additional_fields_to_be_deleted'] = this.extraFieldToDelete;
    }
    this.activityService.updateActivity(activityData).subscribe(
      response => {
        if (response.status) {
          this.storageService.set('activity', response.body['data'], null);
          if (field) {
            //go to manage questions page
            this.manageQuestions();
          } else {
            //go to details page
            this.router.navigateByUrl('edit/detail/' + this.activity['id']);
          }
        }
      }
    )
  }

}
