<app-header icon="home" title="{{'_ProfileSettings_'| translate}}" showNav="false"></app-header>

<div class="side-padding settings-page body-container">
    <form #settingsForm = "ngForm" (ngSubmit) ="onSettingsSubmit(settingsForm)">
      <div class="form-group">
        <label for="name"><small>{{'_Name_'| translate}}<span class="asterisk"> *</span></small></label>
        <input type="text" class="form-control" name="name" [(ngModel)]="settingsName" #name = "ngModel" required>
        <div *ngIf="formSubmitted && name.errors?.required" class="error"> <small><span translate>_VALIDATION_REQUIRED_ERROR_</span></small></div>
      </div>
      <div class="form-group">
        <label for="email"><small>{{'_EmailAddress_'| translate}}<span class="asterisk"> *</span></small></label>
        <input type="email" class="form-control" name="email"  [(ngModel)]="settingsEmail" #email = "ngModel" required  pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$">
        <div *ngIf="formSubmitted && email.errors?.required" class="error"> <small><span translate>_VALIDATION_REQUIRED_ERROR_</span></small></div>
        <div *ngIf="formSubmitted && email.errors?.pattern" class="error"> <small><span translate>_VALIDATION_EMAIL_ERROR_</span></small></div>
      </div>
      <div class="form-group pswd-input">
        <label for="password"><small>{{'_ChangePassword_'| translate}}</small></label>
        <input [type]="showPswd ? 'text' : 'password'" class="form-control" name="password" [(ngModel)]="settingsPassword" #password = "ngModel" minlength="6">
        <a *ngIf="!this.showPswd" class="pswd-btn" (click) = "showPassword()">{{'Show' | translate}}</a>
        <a *ngIf="this.showPswd" class="pswd-btn" (click) = "showPassword()">{{'Hide' | translate}}</a>
        <!-- <div *ngIf="formSubmitted && password.errors?.required" class="error"> <small><span translate>_VALIDATION_REQUIRED_ERROR_ </span> </small></div> -->
        <div *ngIf="formSubmitted && password.errors?.minlength" class="error"> <small translate>_PASSWORD_MALFORMED_</small></div>
      </div>
      <div class="form-group">
        <label for="tel"><small>{{'_PhoneNumber_'| translate}}</small></label>
        <input type="tel" class="form-control" name="phone" [(ngModel)]="settingsPhone" #tel = "ngModel">
      </div>
      <div>
        <p class="m-0"><small>Account Type:</small></p>
        <p class="account-type">{{UserType}}<span *ngIf = "UserDate"> - Expires {{UserDate | date: 'EEE MMM d, yyyy'}}</span></p>
      </div>
      <button type="submit"  [disabled]="formSubmitted" class="btn btn-blue btn-block mb-2">{{'_Save_'| translate}}</button>
      <a (click) = "goBack()" class="cancel-link c-pointer">{{'_Cancel_'| translate}}</a>
      </form>
</div>