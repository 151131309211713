import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepperModule, MatStepper } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { StorageService } from '../shared-services/storage.service';
import { ActivityService } from '../shared-services/activity.service';
import { UtilitiesService } from '../shared-services/utilities.service';
import { AddEditJobComponent } from '../shared/add-edit-job/add-edit-job.component';
import { AlertDialogComponent } from './../shared/alert-dialog/alert-dialog.component';
import { AddEditThemesComponent } from './../shared/add-edit-themes/add-edit-themes.component';
import { ShowPreviewComponent } from './show-preview/show-preview.component';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../shared-services/user.service';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { NgxTippyService } from 'ngx-tippy-wrapper';

declare var google: any;

@Component({
    selector: 'app-create-signup',
    templateUrl: './create-signup.component.html',
    styleUrls: ['./create-signup.component.scss']
})

export class CreateSignupComponent implements OnInit {

  user: {};
  basicInfo: {};
  activity: {};
  participantInfo: {};
  orgArray: any[] = [];
  userIsPremium: boolean = false;
  categoryArr: string[] = [];
  newSpotArr: string[] = [];
  previousUrl: string;
  step1Completed: boolean = false;
  step2Completed: boolean = false;
  step3Completed: boolean = false;
  step4Completed: boolean = false;
  loading: boolean = false;
  hasSpots: false;  //used to check if activity spots have been created
  hasLimit: boolean = false;  //is there a limit set on page load?
  limitNum: number;
  createFormGroup: FormGroup;
  showError: boolean = false;
  setUpKind: string;
  activityCategoryHelp: string;  //helper text for category
  backText: string = '_Dashboard_';
  changedVal: boolean = false;
  isLinear: boolean = false;
  submitted:boolean = false;
  nagToolTip: any;
  selectedLocation: string;

  //editor
  editorContent: string;
  editorMaxLength: number = 1000;
  editorCount: number = 0;
  options: {};  //google maps option

  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', { 'align': [] }, 'link'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ]
  }

    // set tooltip defaults
    nagTooltip:string = '';

    tippyProps: NgxTippyProps = {
      allowHTML: true,
      trigger: 'click',
      arrow: true,
      placement: 'auto',
      maxWidth: 230,
    };

  @ViewChild('stepper', {static: true}) stepper: MatStepper;
  @ViewChild(AddEditThemesComponent, {static: true}) theme: AddEditThemesComponent;

  constructor(private storageService: StorageService,
    private activityService: ActivityService,
    private utilitiesService: UtilitiesService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private translate: TranslateService,
    private tippyService: NgxTippyService,
    private cdr: ChangeDetectorRef,
    private _bottomSheet: MatBottomSheet) { }

    @Input() updateLocation: string;

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    //remove unneeded local storage
    if (this.storageService.get('selectedActivity')) {
      this.storageService.remove('selectedActivity');
    }

    if (this.storageService.get('jobPageVisited') || this.storageService.get('completeSignup')) {
      //are you coming in from the job page?
      this.stepper.selectedIndex = 2;
      this.step1Completed = true;
      this.step2Completed = true;
      this.checkActivitySpots();
    } else if (this.storageService.get('goToStep2')) {
      //are you coming from manage extra fields page
      this.stepper.selectedIndex = 1;
      this.step1Completed = true;
    }

    this.basicInfo = this.storageService.get('basicInfo');
    this.participantInfo = this.storageService.get('participantInfo');

    //get user status
    this.user = this.storageService.get('user');
    if (this.user) {
      if (this.user['premium']) {
        this.userIsPremium = true;
      } else {
        this.userIsPremium = false;
      }
    } else {
      //no user object, log out
      this.userService.logout().subscribe(
        () => {
          this.router.navigateByUrl('/organizer/login');  
          this.storageService.reset();  
          this.storageService.set('full_login', false, null);  
        }
      )
    }

    this.createFormGroup = this.formBuilder.group({
      formArray: this.formBuilder.array([
        this.formBuilder.group({
          kind: [this.basicInfo ? this.basicInfo['kind'] : '', Validators.required],
          category: [this.basicInfo ? this.basicInfo['category'] : this.categoryArr, Validators.required],
          name: [this.basicInfo ? this.basicInfo['name'] : '', Validators.required],
          welcomeMsgEditor: [this.basicInfo ? this.basicInfo['welcomeMsgEditor'] : ''],
          location: [this.basicInfo ? this.basicInfo['location'] : ''],
          allowShare: [this.basicInfo ? this.basicInfo['allowShare'] : true],
          showNames: [this.basicInfo ? this.basicInfo['showNames'] : true],
          allowSwap: [this.basicInfo ? this.basicInfo['allowSwap'] : true],
          allowSuo: [this.basicInfo ? this.basicInfo['allowSuo'] : false],
          hideComment: [this.basicInfo ? this.basicInfo['hideComment'] : false],
          time24hr:[this.basicInfo ? this.basicInfo['time24hr'] : false],
          spots_created_by_participants:[this.basicInfo ? this.basicInfo['spots_created_by_participants'] : false],
          limitSpots: [this.basicInfo ? this.basicInfo['hasLimits'] : false],
          limitNum: [{ value: 1, disabled: !this.hasLimit }],
          limitType: [{ value: 'limitBySignup', disabled: !this.hasLimit }]
        }),
        this.formBuilder.group({
          requirePhone: [this.participantInfo ? this.participantInfo['requirePhone'] : true],
        })
      ])
    });

    //get categories
    this.activityService.getActivityTypes().subscribe(response => {
      if (response) {
        let categoryData = response.body['data'];
        categoryData.forEach(item => {
          this.categoryArr.push(item);
        })
        this.translate.get('_ActivityCategoryHelp_').subscribe((text) => {
          this.nagToolTip = text;
        })
      }
    })

    if (this.user) {
      this.setOrganizer();
    }
    //monitor form changes
    this.formValueChanged();
    //remove from storage
    setTimeout(() => {
      this.storageService.remove('goToStep2');
    }, 1000);
}

  onUpdateLocation(event) {
    if (typeof event == 'object'&& event['location'] ) {
      console.log('emitted event is', event['location'])
      this.selectedLocation = event['location'];
      // this.createFormGroup.patchValue({ 'location': event['location'] });
    }
  }


ngAfterViewInit() {
  this.setContentForTooltip();
  this.cdr.detectChanges(); 
}

setContentForTooltip() {
    this.translate.get('_NagHelp_').subscribe((text) => {
      this.tippyService.setContent('nagHelp', text);
  })
}


  get formArray(): AbstractControl | null {
    return this.createFormGroup.get('formArray');
  }

  // handleAddressChange(event) {
  //   this.createFormGroup.patchValue({ 'location': event.formatted_address });
  // }

  formValueChanged() {
    this.createFormGroup.valueChanges.subscribe(val => {
      this.changedVal = true;
    })
  }

  goBack(stepper: MatStepper){
    stepper.previous();
}

  goForward(stepper: MatStepper){
    this.stepper.selected.completed = true;
    stepper.next();
  }

  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  setSignupKind(event) {
    this.setUpKind = event.target.value;
  }

  onUpdate(event) {
    if (event === 'disable-spinner') {
      this.loading = false;
    } else if (event === 'enable-spinner') {
      this.loading = true;
    }
  }

  checkActivitySpots() {
    // this.loading = true;
    this.activity = this.storageService.get('activity');
    if (this.activity) {
      //call api to check if activity has spots
      this.activityService.hasSpots(this.activity['id']).subscribe(result => {
        if (result && result.body['status']) {
          this.hasSpots = result.body['hasSpots'];
          // this.loading = false;
          if (this.hasSpots) {
            this.step3Completed = true;
            // this.isLinear = false;
          }
        }
      })
    }
    setTimeout(() => {
      this.storageService.removeMultiple(['jobPageVisited', 'completeSignup']);
    }, 1000);
  }


  showDialog(val) {
    let alertMsg: string;
    if (val === 'contribonly') {
      alertMsg = 'Go to desktop for a contribution only SignUp.';
    }
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      data: {
        isSuccess: true,    //success dialog
        title: 'Message',
        message: alertMsg
      }
    });
  }

  resetForm() {
    this.createFormGroup.reset();
    this.storageService.removeMultiple(['basicInfo', 'extraFields', 'participantInfo']);
    this.stepper.reset();
    this.step1Completed = false;
    this.step2Completed = false;
    this.step3Completed = false;
  }

  setOrganizer() {
    let orgInfo = {
      email: this.user['email'],
      name: this.user['name'],
      orderBy: 0,
      phone: this.user['phonenumber'],
      primary: true,
      title: "",
      validated: true,
    }
    this.orgArray.push(orgInfo);
  }

  updateHelperText(val) {
    let helperText;

    this.categoryArr.forEach(item => {
      if (item['value'] === val) {
        helperText = item['details']['activity_type_help'];
        this.translate.get(helperText).subscribe((text) => {
          this.activityCategoryHelp = text;
        })
      }
    })
  }

  getMaxLength(e) {
    if (e.editor.root.innerText.length > this.editorMaxLength) {
      e.editor.deleteText(this.editorMaxLength, e.editor.root.innerText.length);
      this.editorCount = this.editorMaxLength;
    } else {
      this.editorCount = e.editor.root.innerText.length;
    }
  }

  onStepChange(event: StepperSelectionEvent) {
    let stepIdx = event.selectedIndex;
    let prevStepIdx = event.previouslySelectedIndex;

    // console.log('selectedIndex ', event);
    //target previous steps
    if (prevStepIdx == 0) {
      this.step1Completed = true;
    }
    if (prevStepIdx == 1) {
      this.step2Completed = true;
    }

    if (stepIdx == 2) {
      //check if activity has spots
      this.checkActivitySpots();
      this.submitted = false;
    }
    if (stepIdx == 3) {
      this.sendDraftInvite();
      this.theme.init();
      // this.isLinear = true;
    }
  }

  validateStepper(stepper: MatStepper) {
    // console.log(stepper)
    if (stepper.selected['stepControl']['invalid']) {
      this.showError = true;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.showError = false;
      if (stepper.selectedIndex === 0) {
        this.saveBasicData();
      }
      stepper.next();
    }
   }

  toggleLimit(event) {
    //enable or disable limit input if checkbox is checked            
    let limitStatus = this.createFormGroup.controls.formArray['controls'][0];
    if (event.checked) {
      limitStatus.controls['limitNum'].enable();
      limitStatus.controls['limitType'].enable();
    } else {
      limitStatus.controls['limitNum'].disable();
      limitStatus.controls['limitType'].disable();
    }
  }

  addNewSpot() {
    //cleanup residual local storage values
    let items = ['jobName', 'jobDesc', 'timeValues', 'quickfillShifts', 'repeatingJobs', 'origRepeatingJobs', 'timeInterval', 'irregularDaysSelected', 'jobQuantity', 'originalRepeat', 'repeatingJSONData', 'shiftSummary', 'serverSummary', 'repeatSummary'];
    this.storageService.removeMultiple(items, () => {
      this.router.navigate(['/add_new_spot'], { queryParams: { backToPage: 'create' }});
    })
  }

  saveBasicData() {
    let formValue = this.createFormGroup.controls.formArray.get([0]).value;
    let data = {
      kind: formValue.kind,
      category: formValue.category,
      name: formValue.name,
      welcomeMsgEditor: formValue.welcomeMsgEditor,
      location: this.selectedLocation,
      allowShare: formValue.allowShare,
      showNames: formValue.showNames,
      allowSwap: formValue.allowSwap,
      allowSuo: formValue.allowSuo,
      hideComment: formValue.hideComment,
      time24hr:formValue.time24hr,
      spots_created_by_participants:formValue.spots_created_by_participants,
      hasLimits: formValue.limitSpots,
      limitNum: formValue.limitNum,
      limitType: formValue.limitType
    }
    this.storageService.set('basicInfo', data, null);
  }

  submitFormData(stepper: MatStepper) {
    this.loading = true;
    this.submitted = true;
    let formValue = this.createFormGroup.controls.formArray.get([0]).value;

    //clear info that we dont need anymore
    this.storageService.removeMultiple(['activity', 'job', 'basicInfo']);
    
    if (stepper.selected['stepControl']['invalid']) {
      this.showError = true;
      this.loading = false;
      this.submitted = false;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.showError = false;
      this.submitted = true;
      this.loading = false;
      let activity = {},
      jobassignments_per_activity = 0,
      jobassignments_per_day = 0,
      jobassignments_per_job = 0,
      jobassignments_per_week = 0;

      //limit spots is checked
      if (formValue.limitType === 'limitBySignup') {
        jobassignments_per_activity = formValue.limitNum;
      } else if (formValue.limitType === 'limitByDay') {
        jobassignments_per_day = formValue.limitNum;
      } else if (formValue.limitType === 'limitByWeek') {
        jobassignments_per_week = formValue.limitNum;
      }

        let data = {
          accept_wampum: formValue.kind !== 'contribonly' ? false : true,
          activity_limit: formValue.limitNum,
          activitytype: formValue.category,
          additional_fields: [],
          additional_fields_to_be_deleted: [],
          allowswap: formValue.allowSwap,
          description: formValue.welcomeMsgEditor || "",
          hide_comments: formValue.hideComment,
          kind: formValue.kind,
          limit_checked: formValue.limitSpots,
          limitType: formValue.limitType,
          location: this.selectedLocation,
          spots_created_by_participants: formValue.spots_created_by_participants,
          organizers: this.orgArray,
          school_address: null,
          school_id: 0,
          school_name: null,
          school_zipcode: null,
          share: formValue.allowShare,
          shownames: formValue.showNames,
          signup_others: formValue.allowSuo,
          time_24hr: formValue.time24hr,
          title: formValue.name
        }

        //submit form and get activity id
        this.activityService.createActivity(data).subscribe(response => {
          if (response && response.body['status']) {
            activity = response.body['data'];
            this.storageService.set('active_activity_id', response.body['data']['id']);
            //if limit spots is checked, call limit spots create api
            if (formValue.limitSpots) {
              this.activityService.createActivityLimits(response.body['data']['id'], jobassignments_per_activity, jobassignments_per_job, jobassignments_per_day, jobassignments_per_week).subscribe(
                response => {
                  if (response.status) {
                    //add activity limits to activity json and reset form
                    activity['activity_limit'] = response.body['activity_limit'];
                    this.storageService.set('activity', activity, null);
                    stepper.next();
                  }
                }
              )
            } else {
              //just save activity in local storage and reset form
              this.storageService.set('activity', activity, null);
              stepper.next();
            }
            this.loading = false;
          }
        })
    }
  }

  goToSpots(stepper) {
    let activity = this.storageService.get('activity'),
      phoneData = this.storageService.get('require_phone'),
      data = {
        id: activity['id'],
        require_phone: phoneData,
        selected_activity: activity['id']
      };
    // console.log('requirePhone', phoneData['requirePhone'])
    this.activityService.updateActivity(data).subscribe(
      response => {
        if (response.status && response.body['data']) {
          this.storageService.set('activity', response.body['data'], null);
          stepper.next();
        }
      }
    )
  }

  // updateFormData(stepper: MatStepper) {
  //   let participantData = this.createFormGroup.controls.formArray.get([1]).value;
  //   let data = {
  //     require_phone: participantData.requirePhone
  //   }
  // }

  sendDraftInvite() {
    //after leaving the spot page, make api calls
    this.utilitiesService.sendDraftInvite(this.activity['id']).subscribe(
      response => {
        this.utilitiesService.generateLink(this.activity['id']).subscribe();
      }
    );
  }

  openPreview() {
    this._bottomSheet.open(ShowPreviewComponent);
  }

  goToDonePage() {
    let data, activity = this.storageService.get('activity');
    data = activity;
    //get updated activity
    this.activityService.orgActivityQuery(activity['id']).subscribe(
      response => {
        if (response.body['status']) {
          this.storageService.set('activity', response.body['data']);
          //remove local storage items no longer needed
          this.storageService.removeMultiple(['selectedBadgeType']);
          this.router.navigate(['/done-page']);
        }
      }
    )
  }
}
