import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-delete-future-dialog',
  templateUrl: './delete-future-dialog.component.html',
  styleUrls: ['./delete-future-dialog.component.scss']
})
export class DeleteFutureDialogComponent implements OnInit {

  type: string;
  jobAssignment: boolean;
  repeating: boolean;
  force: boolean = false;
  forceParam: object;
  forceConfirmed: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteFutureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {
  }

  ngOnInit() {
    this.type = this.data.type || null;
    this.jobAssignment = this.data.jobAssignment ? true : false;
    this.repeating = this.data.repeating ? true : false;
    this.force = this.data.force ? true : false;
    if (this.data.param) {
      this.forceParam = { 0: this.data.param }
    }
  }

  toggleforceConfirm(event) {
    if (event.checked) {
      this.forceConfirmed = true;
    } else {
      this.forceConfirmed = false;
    }
  }

  delete(val) {
    this.dialogRef.close({
      type: val,
      force: this.forceConfirmed || null
    });
  }
  
  closeOrCancel() {
    this.dialogRef.close();
  }

}
