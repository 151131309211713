import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StorageService } from '../../shared-services/storage.service';
import { ActivityService } from './../../shared-services/activity.service';
import { UtilitiesService } from '../../shared-services/utilities.service';
import { forkJoin } from 'rxjs';
import { ErrorHandlerService } from '../../shared-services/error-handler.service';

@Component({
  selector: 'app-edit-notification-settings',
  templateUrl: './edit-notification-settings.component.html',
  styleUrls: ['./edit-notification-settings.component.scss']
})
export class EditNotificationSettingsComponent implements OnInit {

  editSettingsForm: FormGroup;
  changedVal: boolean = false;
  isOrganizer: boolean = false;
  activity: {};
  user: {};
  emailPrefs: {};
  //array of dropdown values 
  sendRemindersArr: string[] = [];
  notifySpotsChangedArr: string[] = [];
  commentsSummaryArr: string[] = [];
  notifySignUpArr: string[] = [];
  notifyCancelArr: string[] = [];
  notifyFullSignupArr: string[] = [];

  //selected value
  sendReminders: string;
  notifySpotsChanged: string;
  commentsSummary: string;
  notifySignUp: string;
  notifyCancel: string;
  notifyFullSignup: string;
  addExtraDetails: string;

  constructor(private storageService: StorageService,
    private activityService: ActivityService,
    private utilitiesService: UtilitiesService,
    private errorHandlerService:ErrorHandlerService) { }
    
  ngOnInit() {
    let pryOrgId = this.storageService.get('primary_organizer');
    this.activity = this.storageService.get('activity');
    this.user = this.storageService.get('user');
    this.isOrganizer = pryOrgId === this.user['id'];
    this.emailPrefs = this.storageService.get('email_prefs');
    this.sendRemindersArr = this.storageService.get('send_reminders');
    this.notifySpotsChangedArr = this.storageService.get('notify_spots_changed');
    this.commentsSummaryArr = this.storageService.get('comment_summary');
    this.notifySignUpArr = this.storageService.get('notify_signup');
    this.notifyCancelArr = this.storageService.get('notify_cancel');
    this.notifyFullSignupArr = this.storageService.get('notify_full_signup');

    //show previously selected values
    this.sendReminders = this.sendRemindersArr[this.emailPrefs['reminders']];
    this.notifySpotsChanged = this.notifySpotsChangedArr[this.emailPrefs['changemessages']];
    this.commentsSummary = this.commentsSummaryArr[this.emailPrefs['comments']];
    this.notifySignUp = this.notifySignUpArr[this.emailPrefs['signups']];
    this.notifyCancel = this.notifyCancelArr[this.emailPrefs['cancellations']];
    this.notifyFullSignup = this.notifyFullSignupArr[this.emailPrefs['full']];
    this.addExtraDetails = this.emailPrefs['reminder_message'];

    //initialize form
    this.editSettingsForm = new FormGroup({
      sendReminders: new FormControl({value: this.sendReminders, disabled: !this.isOrganizer}),
      notifyChange: new FormControl({value: this.notifySpotsChanged, disabled: !this.isOrganizer}),
      commentSummary: new FormControl(this.commentsSummary),
      notifySignup: new FormControl(this.notifySignUp),
      notifyCancel: new FormControl(this.notifyCancel),
      notifyFullSignup: new FormControl(this.notifyFullSignup),
      extraFields: new FormControl({value: this.addExtraDetails, disabled: !this.isOrganizer})
    })
    this.formValueChanged();
  }

  cancelChanges() {
    window.history.back();
  }

  formValueChanged() {
    //check for value changes
    this.editSettingsForm.valueChanges.subscribe(val => {
      this.changedVal = true;
    })

  } 
  onSubmit() {
    //get values
    var reminderVal= this.sendRemindersArr.indexOf(this.editSettingsForm.get('sendReminders').value),
        changeVal= this.notifySpotsChangedArr.indexOf(this.editSettingsForm.get('notifyChange').value),
        commentVal= this.commentsSummaryArr.indexOf(this.editSettingsForm.get('commentSummary').value),
        signupVal= this.notifySignUpArr.indexOf(this.editSettingsForm.get('notifySignup').value),
        cancellationVal= this.notifyCancelArr.indexOf(this.editSettingsForm.get('notifyCancel').value),
        fullVal= this.notifyFullSignupArr.indexOf(this.editSettingsForm.get('notifyFullSignup').value);

    var activityData = {
      selected_activity: this.activity['id'],
      reminders: reminderVal,
      changemessages: changeVal,
      comments: commentVal,
      signups: signupVal,
      cancellations: cancellationVal,
      full: fullVal,
      reminder_message: this.editSettingsForm.get('extraFields').value
    };
    // console.log('activityData', activityData)
    this.utilitiesService.setEmailPreferences(activityData).subscribe(
      response => {
        if (response.status) {
          this.storageService.set('email_prefs', response.body['data'], null);
          window.history.back();
        }
      },
      error => {
        this.errorHandlerService.resolve(error.status);  
      }
    )
  }
}
