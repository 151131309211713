<app-header icon="back" title="_EditSignUpInfoHeader_" showNav="false" backText="_Details_"
  changed="{{changedVal}}"></app-header>
<div class="edit-signup-info-container body-container">
  <form [formGroup]="editSignupForm">
    <div class="container">
      <div class="form-group">
        <label class="edit-label-name">{{'_Name_'| translate}}</label>
        <input type="text" class="form-control" formControlName="name" maxLength="40" required>
        <span class="helper-text" [innerHTML]="'_ActivityNameHelp_' | translate"></span>
      </div>
      <div class="form-group">
        <!-- category-->
        <label class="edit-label-name">{{'_ActivityCategory_'| translate}}</label>
        <select class="form-control" formControlName="category">
          <option *ngFor="let item of categoryArr" [value]="item">{{item}}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="edit-label-name">{{'_WelcomeField_'| translate}}</label>
        <!-- editor -->
        <quill-editor formControlName="editor" [modules]="editorConfig" (onContentChanged)="getMaxLength($event)"
          (onEditorCreated)="editorCreated($event)">
        </quill-editor>
        <span class="ql-editor-count" *ngIf="editSignupForm.get('editor').value != null">
          {{editorCount}}/{{editorMaxLength}}
        </span>
        <span class="ql-editor-count" *ngIf="editSignupForm.get('editor').value == null">0/{{editorMaxLength}}</span>

        <span class="helper-text  mt-3 d-block" [innerHTML]="'_ActivityWelcomeHelp_' | translate"></span>
      </div>
      <app-google-places-autocomplete  (updateLocation)="onUpdateLocation($event)"></app-google-places-autocomplete>
      <div class="edit-label-name">Options</div>

      <section class="options-section">
        <!-- checkboxes -->
        <div class="form-group">
          <mat-checkbox formControlName="allowShare">
            <label>{{'_ShareInviteWithOthers_' | translate}}</label>
          </mat-checkbox>
        </div>
        <div class="form-group">
          <mat-checkbox formControlName="showNames">
            <label>{{'_SeeWhoResponded_' | translate}}</label>
          </mat-checkbox>
        </div>
        <div class="form-group">
          <mat-checkbox formControlName="allowSwap">
            <label>{{'_AllowSwaps_' | translate}}</label>
          </mat-checkbox>
        </div>
        <div class="form-group">
          <mat-checkbox formControlName="allowSuo">
            <label>{{'_AllowSignUpOthers_' | translate}}</label>
          </mat-checkbox>
        </div>
        <div class="form-group">
          <mat-checkbox formControlName="hideComment">
            <label>{{'_HideComments_' | translate}}</label>
          </mat-checkbox>
        </div>
        <div class="form-group">
          <mat-checkbox formControlName="time24hr">
            <label>{{'_24HrTime_' | translate}}</label>
          </mat-checkbox>
        </div>
        <div class="form-group">
          <mat-checkbox formControlName="nag">
            <label>{{'_nag_' | translate}}
              <button type="button" class="tooltip-btn" ngxTippy tippyName="nagHelp" [tippyProps]="tippyProps">
                <i class="material-icons info-icon">info</i>
              </button>
            </label>

          </mat-checkbox>
        </div>
        <!-- limit spots, only show if user is premium -->
        <div class="form-group">
          <ng-container *ngIf="userIsPremium">
            <mat-checkbox formControlName="limitSpots" class="edit-signup-limit-spots" (change)="toggleLimit($event)">
              <label>{{'_LimitSpotsByNumber_' | translate}}</label>
            </mat-checkbox>
            <input type="number" formControlName="limitNum" class="input-limit-spots">
            <span class="limit-text">{{'_LimitSpotsText_' | translate}}
              <select class="limit-dropdown" formControlName="limitType">
                <option value="limitBySignup" selected="selected">{{'_LimitSpotsOptionSignup_' | translate}}</option>
                <option value="limitByDay">{{'_LimitSpotsOptionDay_' | translate}}</option>
                <option value="limitByWeek">{{'_LimitSpotsOptionWeek_' | translate}}</option>
              </select>
            </span>
            <div *ngIf="limitError" class="error">{{'_VALIDATION_MIN_ERROR_' | translate: errorParam}}</div>
          </ng-container>
        </div>
      </section>
    </div>
    <div class="button-bottom">
      <button type="button" [disabled]="!editSignupForm.valid" class="btn btn-blue edit-update-btn"
        (click)="onSignUpSubmit()">{{'_Update_' | translate}}</button>
      <div class="edit-cancel-btn" (click)=cancelChanges()>{{'_Cancel_' | translate}}</div>
    </div>
  </form>
</div>