import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StorageService } from '../../shared-services/storage.service';
import { EditShiftTimeDialogComponent } from '../../shared/edit-shift-time-dialog/edit-shift-time-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'app-edit-spot-shifts',
  templateUrl: './edit-spot-shifts.component.html',
  styleUrls: ['./edit-spot-shifts.component.scss']
})
export class EditSpotShiftsComponent implements OnInit {

  job: {};
  jobTitle: string;
  timeValues: any;
  jobQuantity: number;
  spotNumText: string;
  showError: boolean = false;
  timeError: boolean = false;
  changedVal: boolean = false;
  shiftBreakForm: FormGroup;
  creating:boolean = false;  //are we creating or editing?

  //job times
  jobStartHour: any;
  jobStartMinute: any;
  jobStartTime: any;
  jobEndHour: any;
  jobEndMinute: any;
  jobEndTime: any;
  jobStartMeridiem: string;
  jobEndMeridiem: string;

  //shifts
  customShift: string;
  quickfillShifts: object[] = [];
  quickfillOrderByNext: 1;
  shiftHour: string;
  shiftMinute: string;
  selectedShift: any;
  tempCustomHour: string;
  tempCustomMinute: string;
  tempBreakHour: string;
  tempBreakMinute: string;
  customShiftSelected:boolean = false;
  
  //breaks
  customBreak: string = '-';
  breakHour: string;
  breakMinute: string;
  selectedBreak: any;
  //carryover summary text
  shiftSummary: string;
  serverShiftSummary: string;  //slightly different version for the server
  customBreakSelected:boolean = false;

  constructor(private storageService: StorageService,
    public dialog: MatDialog) { }

  @Input() update: number;
  @Input() error: string;

  ngOnInit() {
    this.job = this.storageService.get('job');
    this.timeValues = this.storageService.get('timeValues');

    if (this.job) {
      this.jobTitle = this.job['name'];
      if (this.job['quantity'] !== -1) {
        this.jobQuantity = this.storageService.get('jobQuantity') || this.job['quantity'];
        this.spotNumText = this.jobQuantity.toString();
      } else {
        this.jobQuantity = -1;
        this.spotNumText = ' an unlimited number of ';
      }
    } else {
      //create mode
      if (this.storageService.get('jobQuantity')) {
        if (this.storageService.get('jobQuantity') !== -1) {
          this.jobQuantity = this.storageService.get('jobQuantity');
          this.spotNumText = this.jobQuantity.toString();
        } else {
          //is unlimited
          this.jobQuantity = -1;
          this.spotNumText = ' an unlimited number of ';
        }
      } else {
        //nothing from local storage, reset to 1
        this.jobQuantity = 1;
        this.spotNumText = '1';
      }
      // this.jobQuantity = this.storageService.get('jobQuantity') || 1;
      // this.spotNumText = this.jobQuantity.toString() || '1';
    }

    //get start times
    if (this.timeValues) {
      this.jobStartHour = this.timeValues['starthour'];
      this.jobStartMinute = this.timeValues['startminute'];
    } else {
      if (this.job) {
        this.jobStartHour = this.job['starthour'];
        this.jobStartMinute = this.job['startminute'];
      }
    }
    this.jobStartTime = this.jobStartHour ? moment(this.jobStartHour + ':' + this.jobStartMinute, 'H:mm').format("h:mma"): '08:00am';
    // console.log('jobStartTime ', this.jobStartTime)

    // get end times
    if (this.timeValues) {
      this.jobEndHour = this.timeValues['endhour'];
      this.jobEndMinute = this.timeValues['endminute'];
    } else {
      if (this.job) {
        this.jobEndHour = this.job['endhour'];
        this.jobEndMinute = this.job['endminute'];
      }

    }
    this.jobEndTime = this.jobEndHour ? moment(this.jobEndHour + ':' + this.jobEndMinute, 'H:mm').format("h:mma"): '09:00am';
    // console.log('jobEndTime ', this.jobEndTime)

    //get initial time diff from shifts
    this.getTimeDiff();
    if (!this.timeError) {
      this.addInitialShift();
    }
    //initiate toggle form
    this.shiftBreakForm = new FormGroup({
      selectedShift: new FormControl(this.selectedShift),
      selectedBreak: new FormControl(this.selectedBreak)
    })
  }

  getTimeDiff() {
    let minsDiff,
      startTime = moment(this.jobStartTime, 'h:mma').format(''),
      endTime = moment(this.jobEndTime, 'h:mma').format('');

    if (moment(endTime).isBefore(moment(startTime))) {
      this.timeError = true;
      this.customShift = '-';
    } else {
      this.timeError = false;
      minsDiff = moment(endTime).diff(moment(startTime), 'minutes');
      if (!this.shiftHour) {
        //we dont care about the date so use an arbitrary date
        this.shiftHour = moment('1970-01-01').minutes(minsDiff).format('H');
        //save temp custom time in case custom is clicked again
        this.tempCustomHour = this.shiftHour;
      }
      if (!this.shiftMinute) {
        this.shiftMinute = moment('1970-01-01').minutes(minsDiff).format('m');
        this.tempCustomMinute = this.shiftMinute;
      }

      //update shift values
      if (this.tempCustomHour || this.tempCustomMinute) {
        if (this.shiftHour && (!this.shiftMinute || this.shiftMinute === '0')) {
          this.customShift = this.shiftHour + '<small>H</small>'
        } else if ((!this.shiftHour || this.shiftHour === '0') && this.shiftMinute) {
          this.customShift = this.shiftMinute + '<small>M</small>';
        } else if (this.shiftHour || this.shiftMinute) {
          this.customShift = this.shiftHour + '<small>H </small>' + this.shiftMinute + '<small>M</small>';
        } else {
          this.customShift = '-';
        }
      }
    }
  }

  addInitialShift() {
    this.quickfillShifts.push({
      fromTime: moment(this.jobStartTime, 'h:mma').format(),
      starthour: this.jobStartHour,
      startminute: this.jobStartMinute,
      toTime: moment(this.jobEndTime, 'h:mma').format(),
      endhour: this.jobEndHour,
      endminute: this.jobEndMinute
    })
    this.selectedShift = 'custom';
    this.selectedBreak = 'none';
    this.getShiftSummary();
  }

  getShiftSummary() {
    let shiftString, breakString;
    if (this.shiftHour || this.shiftMinute || this.breakHour || this.breakMinute) {
      //get shift string
      if (this.shiftHour || this.shiftMinute) {
        if (this.shiftHour !== '0' && (this.shiftMinute == '0' || !this.shiftMinute)) {
          shiftString = this.shiftHour + ' hour ';
        } else if ((!this.shiftHour || this.shiftHour == '0') && this.shiftMinute !== '0') {
          shiftString = this.shiftMinute + ' minute ';
        } else if (this.shiftHour !== '0' && this.shiftMinute !== '0') {
          shiftString = this.shiftHour + ' hour ' + this.shiftMinute + ' minute ';
        }
      } else {
        //shift is not set
        shiftString = '';
      }
      //get break string
      if (this.breakHour || this.breakMinute) {
        if (this.breakHour !== '0' && (this.breakMinute == '0' || !this.breakMinute)) {
          breakString = 'with ' + this.breakHour + ' hour breaks ';
        } else if ((!this.breakHour || this.breakHour == '0') && this.breakMinute !== '0') {
          breakString = 'with ' + this.breakMinute + ' minute breaks ';
        } else if (this.breakHour !== '0' && this.breakMinute !== '0') {
          breakString = 'with ' + this.breakHour + ' hour ' + this.breakMinute + ' minute breaks ';
        } else {
          //break is not available
          breakString = '';
        }
      } else {
        //break is not set
        breakString = '';
      }
    }

    this.shiftSummary = '(' + this.quickfillShifts.length + ') ' + shiftString + 'shifts ' + breakString + 'between ' + this.jobStartTime + ' and ' + this.jobEndTime;
    // console.log('this.shiftSummary ', this.shiftSummary)
  }

  openDialog(type) {
    let title: string, dialogRef, shiftHour, shiftMinute;
    if (type === 'shift') {
      title = 'Custom Shift Duration';
    } else if (type === 'break') {
      title = 'Custom Breaks Between Shifts';
    } else {
      title = '';
    }
    if (type === 'time') {
      dialogRef = this.dialog.open(EditShiftTimeDialogComponent, {
        width: '80%',
        hasBackdrop: true,
        autoFocus: false,
        data: {
          title: title,
          type: type,
          starthour: this.jobStartHour,
          startminute: this.jobStartMinute,
          endhour: this.jobEndHour,
          endminute: this.jobEndMinute
        }

      });
    } else {
      //type is shift or break
      //special cases
      shiftHour = this.shiftMinute == '60' ? '1' : this.shiftHour;
      shiftMinute = this.shiftMinute == '60' ? '0' : this.shiftMinute;
      dialogRef = this.dialog.open(EditShiftTimeDialogComponent, {
        width: '80%',
        hasBackdrop: true,
        autoFocus: false,
        data: {
          title: title,
          type: type,
          shifthour: this.tempCustomHour || shiftHour,
          shiftminute: this.tempCustomMinute || shiftMinute,
          breakhour: this.tempBreakHour || this.breakHour,
          breakminute: this.tempBreakMinute || this.breakMinute
        }
      });
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(result);
        if (type === 'time') {
          //change start times to moment
          this.jobStartTime = moment(result.startHour + ":" + result.startMinute + result.startMeridiem, 'h:mmA').format('h:mmA');
          this.jobStartHour = moment(this.jobStartTime, 'h:mmA').format('H');
          this.jobStartMinute = moment(this.jobStartTime, 'h:mmA').format('mm');
          //change end times to moment
          this.jobEndTime = moment(result.endHour + ":" + result.endMinute + result.endMeridiem, 'h:mmA').format('h:mmA');
          this.jobEndHour = moment(this.jobEndTime, 'h:mmA').format('H');
          this.jobEndMinute = moment(this.jobEndTime, 'h:mmA').format('mm');
          if (!this.customShiftSelected) {
              //no shift, just show initial shift time
              this.getTimeDiff();
          }
          if (this.shiftHour || this.shiftMinute || this.breakHour || this.breakMinute) {
            //either shift or break is given so run update shift duration
            this.updateShiftDuration();
          } 
          this.timeValues = {
            starthour: this.jobStartHour,
            startminute: this.jobStartMinute,
            endhour: this.jobEndHour,
            endminute: this.jobEndMinute
          }
        } else {
          //shift or break was changed
          if (result.customHour !== null || result.customMinute !== null) {
            //if both values are not null
            if (type === 'shift') {
              this.shiftHour = result.customHour,
                this.shiftMinute = result.customMinute;
              //update shift values
              if (this.shiftHour && !this.shiftMinute) {
                this.customShift = this.shiftHour + '<small>H</small>'
              } else if (!this.shiftHour && this.shiftMinute) {
                this.customShift = this.shiftMinute + '<small>M</small>';
              } else {
                this.customShift = this.shiftHour + '<small>H </small>' + this.shiftMinute + '<small>M</small>';
              }
              //set temp custom time to current shift time
              this.tempCustomHour = this.shiftHour;
              this.tempCustomMinute = this.shiftMinute;
            } else {
              //update values on shift break
              this.breakHour = result.customHour,
                this.breakMinute = result.customMinute;
              if (this.breakHour && !this.breakMinute) {
                this.customBreak = this.breakHour + '<small>H</small>'
              } else if (!this.breakHour && this.breakMinute) {
                this.customBreak = this.breakMinute + '<small>M</small>';
              } else {
                this.customBreak = this.breakHour + '<small>H </small>' + this.breakMinute + '<small>M</small>';
              }
              //set break custom time to current break time
              this.tempBreakHour = this.breakHour;
              this.tempBreakMinute = this.breakMinute;
            }
            this.updateShiftDuration();
          } else {
            //both values are null, reset
            this.quickfillShifts = [];
            if (type === 'shift') {
              this.customShift = '-';
              this.shiftHour = null;
              this.shiftMinute = null;

            } else {
              this.customBreak = '-';
              this.breakHour = null;
              this.breakMinute = null;
            }
          }
        }
        //keep track of changes
        this.changedVal = true;
      } else {
        //no result was returned, revert to previous
        if (type === 'shift' && this.customShiftSelected) {
          this.selectedShift = this.shiftMinute;
          this.shiftBreakForm.patchValue({selectedShift: this.shiftMinute})
        }
        if (type === 'break' && this.customBreakSelected) {
          this.selectedBreak = this.breakMinute;
          this.shiftBreakForm.patchValue({selectedBreak: this.breakMinute})
        }
      }
    })
  }

  updateShiftDuration() {
    let startTime = moment(this.jobStartTime, 'h:mma').format(),
      endTime = moment(this.jobEndTime, 'h:mma').format(),
      shiftStart,
      shiftEnd,
      shiftStartHour: string,
      shiftStartMinute: string,
      shiftEndHour: string,
      shiftEndMinute: string,
      diff: boolean,
      shiftArr = [],
      breakString: string,
      shiftDuration: string,
      breakDuration: string;

    shiftStart = startTime;

    if (this.shiftHour || this.shiftMinute || this.breakHour || this.breakMinute) {
      //default to zero if no value
      shiftDuration = (this.shiftHour || '00') + ":" + (this.shiftMinute || '00'),
        breakDuration = (this.breakHour || '00') + ":" + (this.breakMinute || '00');
      do {
        shiftEnd = moment(shiftStart).add(moment.duration(shiftDuration)).format();
        shiftStartHour = moment(shiftStart).format('HH');
        shiftStartMinute = moment(shiftStart).format('mm');
        shiftEndHour = moment(shiftEnd).format('HH');
        shiftEndMinute = moment(shiftEnd).format('mm');
        diff = moment(shiftEnd).isSameOrBefore(moment(endTime));

        if (diff) {
          shiftArr.push({
            fromTime: moment.utc(shiftStart).format(),
            starthour: shiftStartHour,
            startminute: shiftStartMinute,
            toTime: moment.utc(shiftEnd).format(),
            endhour: shiftEndHour,
            endminute: shiftEndMinute
          })
        }
        shiftStart = moment(shiftEnd).add(moment.duration(breakDuration)).format();
      } while (diff);
      this.quickfillShifts = [];
      this.quickfillShifts = shiftArr;
      //keep track of changes
      this.changedVal = true;
      this.getShiftSummary();
    } else {
      this.quickfillShifts = []
    }
  }

  deleteShift(idx) {
    this.quickfillShifts.splice(idx, 1);
    this.getShiftSummary();
  }

  setShiftDuration(val) {
    this.shiftHour = '0';
    this.shiftMinute = val;
    this.selectedShift = val;
    if (!this.timeError) {
      this.customShiftSelected = true;
      this.customShift = '-';
      this.tempCustomHour = null;
      this.tempCustomMinute = null;
      this.updateShiftDuration();
    }
  }

  setShiftBreak(val) {
    if (val === '0' || val === 'none') {
      //clear breaks
      this.breakHour = null;
      this.breakMinute = null;
    } else {
      this.breakHour = '0';
      this.breakMinute = val;
      this.selectedBreak = val;
      if (!this.shiftHour && !this.shiftMinute) {
      //remove shift rows if shift time is not specified
      this.quickfillShifts = [];
      }
      this.customBreakSelected = true;
      this.customBreak = '-';
    }
    if (!this.timeError && (this.shiftHour || this.shiftMinute)) {
      this.updateShiftDuration();
    }
  }

  onUpdate(event) {
    if (typeof event == 'object' && event['isUnlimited'] === true) {
      this.spotNumText = ' an unlimited number of ';
      this.jobQuantity = -1;
    } else {
      this.spotNumText = event;
      this.jobQuantity = event;
    }
    //keep track of changes
    this.changedVal = true;
  }

  onError(event) {
    this.showError = event['error'];
  }

  onFormSubmit() {
    //update local storage
    this.storageService.set('timeValues', {
      starthour: parseInt(this.jobStartHour),
      startminute: parseInt(this.jobStartMinute),
      endhour: parseInt(this.jobEndHour),
      endminute: parseInt(this.jobEndMinute)
    });
    this.storageService.set('quickfillShifts', this.quickfillShifts);
    this.storageService.set('shiftQty', this.quickfillShifts.length);
    this.storageService.set('shiftSummary', this.shiftSummary, null);
    this.storageService.set('timeInterval', 'from');
    this.storageService.set('timeValues', this.timeValues);
    this.storageService.set('jobQuantity', this.jobQuantity);
    this.storageService.set('job', this.job, function () {
      window.history.back();
    });
  }

  cancelChanges() {
    window.history.back();
  }

}
