<div class="edit-participants-page">
  <!-- share icons -->
  <div class="edit-share-icons">
      <ul>
      <li><a routerLink = "/invite"><i class="material-icons">share</i>share</a></li>
      <li><a (click) = "generateLink()"><i class="material-icons material-icons-outline">visibility</i>preview</a></li>
      <li><a routerLink="/messages"><i class="material-icons">chat_bubble_outline</i>message</a></li>
      </ul>
    </div>
  <div class="filter-page">
    <!-- filter header -->
    <div class="filter-header">
      <div class="input-group search-bar">
        <input [(ngModel)]="inputSearch" class="form-control" type="search"
          placeholder="Search by name, email or spot name" id="example-search-input">
        <span class="input-group-append">
          <div class="input-group-text"><i class="material-icons search-icon">search</i></div>
        </span>
      </div>
    </div>

    <section class="participant-section">
      <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
      <ng-container *ngIf="participants$  | async as participants">
        <div *ngFor="let participant of participants | filter:inputSearch" class="spots-section">
          <div class="participant-row" (click)="goToParticipantDetail(participant)">
            <div class="participant-info truncate">
              <!-- participant info -->
              <div class="avatar-inline" *ngIf="participant.contactname">
                <ngx-avatars name="{{participant.contactname}}"></ngx-avatars>
              </div>
              <div class="avatar-inline" *ngIf="!participant.contactname">
                <ngx-avatars name="{{participant.email}}"></ngx-avatars>
              </div>
              <div class="participant-name-row truncate">
                <div [ngStyle]="{'margin-top': participant.jobassignments.length ? '0' : '.7rem'}"
                  class="participant-name truncate">
                  <span *ngIf="participant.contactname">{{participant.contactname}}</span>
                  <span *ngIf="!participant.contactname">{{participant.email}}</span>
                </div>
                <div *ngIf="participant.jobassignments.length" class="participant-spot truncate">
                  {{participant.jobassignments[0].job_name}}
                  <span *ngIf="participant.numassignments > 1"> + {{participant.numassignments - 1}} more</span>
                </div>
              </div>
            </div>
            <div class="right-angle"><i class="material-icons">keyboard_arrow_right</i></div>
          </div>
        </div>
      </ng-container>
    </section>
  </div>
</div>