<div class="alert-dialog">
  <div>
    <form [formGroup]="editShiftDialogForm" (ngSubmit)="saveForm()">
      <!-- time dialog -->
      <div *ngIf="type === 'time'" class="time-section">
        <span class="mb-3">
          <!-- start time -->
          <h5>First Shift Start</h5>
          <span class="time-wrapper">
            <span>
              <select class="form-control" formControlName="startHour" (change) = "setTimeValues('startHour', $event.target.value)">
                <option *ngFor="let item of hourArr" [value]="item">{{item}}</option>
              </select>
            </span>
            <span class="divider"> : </span>
            <span>
              <select class="form-control" formControlName="startMinute"  (change) = "setTimeValues('startMinute', $event.target.value)">
                <option *ngFor="let item of minuteArr" [value]="item">{{item}}</option>
              </select>
            </span>
            <span class="time-button">
              <button class="btn btn-blue" type="button" (click)="toggleStartMeridiem()">{{jobStartMeridiem}}</button>
            </span>
          </span>
        </span>
        <!-- end time -->
        <span>
          <h5>Last Shift End</h5>
          <span class="time-wrapper">
            <span>
              <select class="form-control" formControlName="endHour" (change) = "setTimeValues('endHour', $event.target.value)">
                <option *ngFor="let item of hourArr" [value]="item">{{item}}</option>
              </select>
            </span>
            <span class="divider"> : </span>
            <span>
              <select class="form-control" formControlName="endMinute" (change) = "setTimeValues('endMinute', $event.target.value)">
                <option *ngFor="let item of minuteArr" [value]="item">{{item}}</option>
              </select>
            </span>
            <span class="time-button">
              <button class="btn btn-blue" type="button" (click)="toggleEndMeridiem()">{{jobEndMeridiem}}</button>
            </span>
          </span>
        </span>
        <span *ngIf="timeError" class="error">{{'_JobShiftTimeWarning_' | translate}}</span>
      </div>

      <!-- shift and repeat dialog -->
      <div *ngIf="type === 'shift' || type === 'break'" class="time-section" id="shift-section">
        <span class="mb-3">
          <h5>{{title}}</h5>
          <span class="time-wrapper">
            <!-- hour dropdown -->
            <span class="dropdown-span mb-2">
              <span class="mr-1">
                <select class="form-control" formControlName="customHour"  (change) = "setShiftTime('customHour', $event.target.value)">
                  <option *ngFor="let item of hourArr" [value]="item">{{item}}</option>
                </select>
              </span>
              <span>hours</span>
            </span>

            <!-- minute dropdown -->
            <span class="dropdown-span">
              <span class="mr-1">
                <select class="form-control" formControlName="customMinute"  (change) = "setShiftTime('customMinute', $event.target.value)">
                  <option *ngFor="let item of minuteArr" [value]="item">{{item}}</option>
                </select>
              </span>
              <span>minutes</span>
            </span>
          </span>
        </span>
      </div>
    </form>
    <div *ngIf="shiftTimeError" class="error-text">Please enter a valid time</div>
    <mat-dialog-actions align="end">
      <div class="bottom-buttons">
        <button (click)="closeOrCancel()" class="cancel-btn">{{'_Cancel_' | translate}}</button>
        <button [disabled]="timeError" class="btn btn-blue" (click)="saveForm()">{{'_Ok_' | translate}}</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>