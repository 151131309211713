<app-header icon="back" title="_AssignParticipant_" backText="_ViewSpot_" showNav="false"></app-header>

<div class="edit-select-participants-page">
  <!-- job details -->
  <div class="job-details">
    <span>{{jobName}} | {{jobOpenText}}</span>
  </div>
  <p class="help-text">Select a name below or click on the Add New Participant button.</p>
  <div class="filter-page">
    <!-- filter header -->
    <div class="filter-header">
      <div class="input-group search-bar">
        <input [(ngModel)]="inputSearch" class="form-control" type="search"
          placeholder="Search by name or email" id="example-search-input">
        <span class="input-group-append">
          <div class="input-group-text"><i class="material-icons right-arrow">keyboard_arrow_right</i></div>
        </span>
      </div>
    </div>

    <section class="participant-section"  *ngIf="!emptyList">
      <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
      <ng-container *ngIf="participantsArr.length">
        <div *ngFor="let participant of participantsArr | filter:inputSearch" class="spots-section">
          <div class="participant-row">
            <div class="participant-info truncate">
              <!-- participant info -->
              <div class="avatar-inline" *ngIf="participant.contactname">
                <ngx-avatar name="{{participant.contactname}}"></ngx-avatar>
              </div>
              <div class="avatar-inline" *ngIf="!participant.contactname">
                <ngx-avatar name="{{participant.email}}"></ngx-avatar>
              </div>
              <div class="participant-name-row truncate" (click)="submitted ? $event.preventDefault() : addSelectedParticipant(participant)">
                <div [ngStyle]="{'margin-top': participant.contactname && participant.email ? '0' : '.7rem'}"
                  class="participant-name truncate">
                  <span *ngIf="participant.contactname">{{participant.contactname}}</span>
                  <span *ngIf="!participant.contactname">{{participant.email}}</span>
                </div>
                <div *ngIf="participant.contactname" class="participant-spot truncate">
                    {{participant.email}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </section>

    <ng-container *ngIf="emptyList">
        <!-- if participants list is empty -->
        <p class="list-empty">There are no Participants to add. Click the button below to add a new participant.</p>
      </ng-container>
  </div>

  <section class="footer">
    <!-- fixed footer at bottom -->

    <div class="add-new-plus-icon">
        <span (click)="addNewParticipant()"><i class="material-icons">add</i>
          <span>{{'_AddNewParticipant_' | translate}}</span>
        </span>
        
      </div>
  </section>
</div>