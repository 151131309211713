<app-header icon="back" title="_MessageParticipants_" showNav="false" backText="_MessageParticipants_">
</app-header>

<div class="recipients-filter-page">
  <!-- accordion for recipients selected -->
  <section class="top-section" *ngIf="this.selected.length">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <span>
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? masterToggle(selection) : null"
              [checked]="selection.hasValue() && isAllSelected()">
            </mat-checkbox>
          </span>
          <span>{{selection.selected.length}} Selected</span>
          <!-- <span>10 Participants</span> -->

        </mat-expansion-panel-header>
        <div class="filter-page">
          <div class="filter-header">
            <div class="input-group search-bar">
              <input class="form-control" (keyup)="applyFilter($event)" placeholder="Search by name or email">
              <span class="input-group-append">
                <div class="input-group-text"><i class=" search-icon material-icons">search</i></div>
              </span>
            </div>
          </div>

          <div class="table-section">
            <table mat-table [dataSource]="recipientDataSource" class="mat-table">
              <!-- Checkbox Column -->
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation(); showSelection(row)"
                    (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                  </mat-checkbox>
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="contactname">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                  <span class="name-section">
                    <span class="participant-name">{{element.contactname || element.who}} </span>
                    <span *ngIf="element.email"
                      [ngClass]="!element.contactname && !element.who ? 'participant-name' : participant-email">{{element.email}}</span>
                  </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns; let last = last;" [ngClass]="{'remove-border': last}" (click)="selection.toggle(row)">
              </tr>
            </table>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>

  <section class="bottom-section">
    <h6>Choose Recipients By:</h6>
    <!-- status -->
    <a routerLink="/recipient-filter-by-status" class="filter-div default-blue-bg">
      <span>
        <span class="pr-1"><i class="material-icons small-icon">timelapse</i></span>
        <span>{{'_Status_' | translate}}</span>
      </span>
      <span><i class="material-icons large-icon">keyboard_arrow_right</i></span>
    </a>

    <!-- date -->
    <a *ngIf="dated" routerLink="/recipient-filter-by-date" class="filter-div light-green-bg">
      <span>
        <span class="pr-1"><i class="material-icons small-icon">insert_invitation</i></span>
        <span>{{'_AddByDateScheduled_' | translate}}</span>
      </span>
      <span><i class="material-icons large-icon">keyboard_arrow_right</i></span>
    </a>

    <!-- spot -->
    <a routerLink="/recipient-filter-by-spot" class="filter-div orange-bg">
      <span>
        <span class="pr-1"><i class="material-icons small-icon">work</i></span>
        <span>{{'_Spot_' | translate}}</span>
      </span>
      <span><i class="material-icons large-icon">keyboard_arrow_right</i></span>
    </a>

    <!-- name -->
    <a routerLink="/recipient-filter-by-name" class="filter-div dark-blue-bg">
      <span>
        <span class="pr-1"><i class="material-icons small-icon">person</i></span>
        <span>{{'_Name_' | translate}}</span>
      </span>
      <span><i class="material-icons large-icon">keyboard_arrow_right</i></span>
    </a>
  </section>
  <!-- footer -->
  <section class="footer">
    <span (click)="goNext()">Next</span>
  </section>
</div>