<app-header icon="back" backText="{{backText}}" title="_ParticipantQuickSearch_" showNav="false"></app-header>
<div class="quicksearch-page body-container">
  <div class="intro">
    <p>Find Participant Assignments/Spots</p>
  </div>
  <form [formGroup]="quickSearchForm">
      <div class="input-container">
        <input type="search" class="form-control" formControlName="userEmail" maxLength="40" [matAutocomplete]="title"
          matInput placeholder="participant name or email">
        <mat-autocomplete (optionSelected)='selectEmail($event.option.value)'
          #title="matAutocomplete">
          <mat-option *ngFor="let item of filteredOptions | async" [value]="item.email">
            <span *ngIf="item.name">{{item.email}} <{{item.name}}></span>
            <span *ngIf="!item.name">{{item.email}}</span>
          </mat-option>
        </mat-autocomplete>
        <i class="material-icons">search</i>
      </div>
  </form>

  <section class="signup-section">
    <div class="section-title">
      <span class="header-title">SignUp Title</span>
      <span class="header-organizer">Organizer</span>
    </div>
    <div class="section-info" *ngFor="let participant of participants" (click) = "goToDetail(participant)">
      <span class="signup-info">
        {{participant.title}}
      </span>
        <span class="signup-organizer">{{participant.organizer_name}}</span>
      <span class="list-icon">
        <i class="material-icons">keyboard_arrow_right</i>
      </span>
    </div>
  </section>
  <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
</div>