import { Component, OnInit, ElementRef, EventEmitter } from '@angular/core';
import { GoogleService } from './../organizer/register/google.service';
import { FacebookService } from './../organizer/register/facebook.service';
import { AppleService } from './../organizer/register/apple.service';
import { GoogleAnalyticsService } from "./../../shared-services/google-analytics.service";
import { Router } from "@angular/router";
import { StorageService } from './../../shared-services/storage.service';
import { HttpClient } from '@angular/common/http';
import { getPlatforms, isPlatform } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

//desktop social sign in
import { SocialUser } from '@abacritt/angularx-social-login';
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "@abacritt/angularx-social-login";

//capacitor social sign in
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin, FacebookLoginPlugin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in'; //for capacitor

@Component({
  selector: 'app-organizer',
  templateUrl: './organizer.component.html',
  styleUrls: ['./organizer.component.scss']
})

export class OrganizerComponent implements OnInit {

  errorMsg: string;
  errParam: {};
  showPswd = false;
  respStatus: boolean;
  showBtns: boolean = true;
  isCordovaApp: boolean = true;
  fbUserData: {};
  webClientId: string;
  loading: boolean = false;
  iosApp: boolean = false;
  loggedInUser = null;
  fbLogin: FacebookLoginPlugin;
  token = null;


  constructor(
    private storageService: StorageService,
    private router: Router,
    private googleService: GoogleService,
    private facebookService: FacebookService,
    private appleService: AppleService,
    private authService: SocialAuthService,
    private user: SocialUser,
    private googleAnalyticsService: GoogleAnalyticsService,
    public platform: Platform,
    private http: HttpClient
  ) { }


  ngOnInit() {
    this.user = null;
    this.resetForm();

    //check if app is run in browser or the mobile app
    if (isPlatform('capacitor')) {
      FacebookLogin.initialize({ appId: '105890006170720' });

      this.isCordovaApp = true;
      this.storageService.set('isCordovaApp', true);
      GoogleAuth.initialize();
      //initialize google login for app
      GoogleAuth.initialize({
        clientId: '408130889961-0uvgc4s8daspbmvigovmdljcin19pue4.apps.googleusercontent.com',
        scopes: ['profile', 'email'],
        grantOfflineAccess: true,
      });
      var det = getPlatforms();
      if (isPlatform('ios')) {
        //show apple login on ios devices
        this.iosApp = true;
      }
    } else {
      // console.log('not capacitor');
    }
  }

  onActivate(ElementRef) {
    ElementRef.errMessage.subscribe(data => {
      this.errorMsg = data;
      // console.log(this.errorMsg)
    });
    ElementRef.errParam.subscribe(data => {
      if (data) {
        this.errParam = { '0': data };
        // console.log(this.errParam)
      }
    })
    this.showBtns = false;
  }

  onDeactivate(event) {
    this.showBtns = true;
    this.resetForm();
  }

  resetForm() {
    this.errorMsg = null;
    this.showPswd = false;
  };

  goToRegister() {
    this.resetForm();
    this.showBtns = false;
    this.router.navigateByUrl('organizer/register');
  }

  goToLogin() {
    this.resetForm();
    this.showBtns = false;
    this.router.navigateByUrl('organizer/login');
  }

  getWebClientId = function () {
    let deviceClientId = '408130889961-0uvgc4s8daspbmvigovmdljcin19pue4.apps.googleusercontent.com'
    return deviceClientId;
  };

  // social login
  async signInWithGoogle() {
    this.googleAnalyticsService.emitEvent('button', 'click', 'm/google_signup_btn');
      this.loading = true;
      this.googleAppSignIn();
  }

  async signInWithFB() {
    console.log('fb sign in')
    this.googleAnalyticsService.emitEvent('button', 'click', 'm/fb_signup_btn');
    if (this.isCordovaApp) {
      const FACEBOOK_PERMISSIONS = [
        'email',
        'public_profile'
      ];
      const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS }) as FacebookLoginResponse;
      const token = await FacebookLogin.getCurrentAccessToken() as FacebookLoginResponse;
      const userEmail = await FacebookLogin.getProfile<{
        email: string;
      }>({ fields: ['email'] });
      const userName = await FacebookLogin.getProfile<{
        name: string;
      }>({ fields: ['name'] });
      if (result.accessToken) {
        // Login successful.
        // console.log('result', result);
        // console.log('token', token);
        // console.log('email', userEmail);
        // console.log('name', userName);
        let userData = {
          id: token['accessToken']['userId'],
          authToken: token['accessToken']['token'],
          name: userName['name'],
          email: userEmail['email']
        }
        this.verifyFbLogin(userData);
      } else {
        //error here
      }  
    } else {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
        (userData) => {
          this.loading = true;
          this.verifyFbLogin({
            id: userData['id'],
            authToken: userData['idToken']
          });
          this.loading = true;
        },
        error => {
          this.loading = false;
        }
      )
    }
  }

  async fbLogout() {
    await this.fbLogin.logout();
    this.user = null;
    this.token = null;
  }

  appleSignIn() {
    let options: SignInWithAppleOptions = {
      clientId: 'com.volunteerspot.mobile',
      redirectURI: 'https://smug.signup.dev/login',
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce',
    };

    SignInWithApple.authorize(options)
      .then((res: SignInWithAppleResponse) => {
        this.loading = true;
        // console.log('response from apple signin', res['response']);
        this.verifyAppleLogin(res.response);
      })
      .catch(error => {
        console.error(error);
        this.loading = false;
      });
  }

  async googleAppSignIn() {
    let googleUser = await GoogleAuth.signIn();
    // console.log('googleUser', googleUser);
    this.verifyGoogleLogin({
      id: googleUser['id'],
      idToken: googleUser['authentication']['idToken']
    });
  }

  async refresh() {
    let googleUser = await GoogleAuth.signIn();
    const authCode = await GoogleAuth.refresh;
    // console.log('refresh', authCode);
  }

  async signOut() {
    await GoogleAuth.signOut();
    this.user = null;
    // console.log('signed out', this.user);
  }

  verifyGoogleLogin(userData) {
    var data = {
      uid: userData.id,
      token: userData.idToken,
      source: 'organizer_path'
    };
    this.googleService.googleLogin(data).subscribe(
      response => {
        this.loading = false;
        this.storageService.set('google_user', true);
        this.processServerResponse(response);
      },
      err => {
        this.loading = false;
      }
    )
  };

  verifyFbLogin(userData) {
    var data = {
      authResponse: {
        accessToken: userData.authToken,
        userID: userData.id,
        email: userData.email,
        name: userData.name,
      },
      userResponse: {
        email: userData.email,
        name: userData.name,
        id: userData.id
      },
      source: 'organizer_path'
    };
    this.facebookService.fbLogin(data).subscribe(
      response => {
        this.storageService.set('fb_user', true);
        this.processServerResponse(response);
      },
      err => {
        // console.log('error');
        this.loading = false;
      }
    )
  };

  verifyAppleLogin(userData) {
    var userInfo, data;
    if (userData) {
      //send user name
      userInfo = {
        name: {
          givenName: userData['givenName'],
          familyName: userData['familyName']
        }
      }
    }
    if (userData['email']) {
      //if present, send email also
      userInfo['email'] = userData['email'];
    }
    data = {
      user: userInfo,
      state: userData['state'],
      id_token: userData['identityToken'],
    };
    console.log(data)
    this.appleService.appleLogin(data).subscribe(
      response => {
        if (response && response['status']) {
          this.storageService.set('apple_user', true);
          this.storageService.set('code', response['code']);
          this.processServerResponse(response);
        } else {
          this.loading = false;
          this.errorMsg = response['message'];
        }
      },
      err => {
        this.loading = false;
      }
    )
  };

  processServerResponse(res) {
    var userData;
    this.respStatus = res.body && res.body['status'] !== null ? res.body['status'] : res.status;
    if (this.respStatus) {
      let route = this.router;
      //status is true, proceed
      userData = res['user'] || res.body['user'];
      //send event tracking
      this.storageService.set('full_login', true);
      this.storageService.process('role', 'organizer');
      this.storageService.set('newuser', true);
      this.storageService.process('user', userData, function () {
        route.navigateByUrl('dashboard');
      });
    } else {
      //status is false, emit error to main app component
      this.errorMsg = res.body && res.body['code'] ? res.body['code'] : res.code;
      this.loading = false;
    }
  };
}