<app-header icon="back" title="_Spot_" showNav="false" backText="_MessageParticipants_">
</app-header>
<div class="filter-by-spot-page message-filter-pages orange-accordion">
  <div class="message-header">{{'_RecipientFilterSpot_' | translate}}</div>
  <div class="select-header">
    <span (click) = "selectAllCheckboxes()">{{'_SelectAll_'| translate}}</span>
    <span (click) = "clearAllCheckboxes()">{{'_ClearAll_'| translate}}</span>
  </div>
  <div class="container">
    <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
    <mat-accordion>
      <mat-expansion-panel class="mat-top-panel" *ngFor="let key of jobKeys; first as isFirst;" [expanded]="isFirst">
        <mat-expansion-panel-header class="mat-top-header">
          <div class="panel-top-header">
            <span class="d-flex">
              <mat-checkbox [(ngModel)] ="key.checked" (change)="selectAll($event.checked, key)" (click)="stopPropagation($event)"> </mat-checkbox>
              <!-- <span>{{key.spot}} ({{getTotal(key.spot)}})</span> -->
              <span>{{key.spot}} ({{key.length}})</span>
            </span>
          </div>
        </mat-expansion-panel-header>
        <span class="spot-name-row" *ngFor="let item of groupedParticipants[key.spot]">
          <mat-checkbox [(ngModel)]="item.checked" (change)="select($event.checked, item, key)">
            <label>
              <span class="flex-column truncate">
                <span class="participant-name">{{item.contactname}}</span>
                <span *ngIf="item.email" [ngClass]="!item.contactname ? 'participant-name' : participant-email">{{item.email}}</span>
              </span>
            </label>
          </mat-checkbox>
        </span>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <section class="footer">
    <span (click)="goNext()">Next</span>
  </section>
</div>