import { Component, OnInit,  Input } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { StorageService } from '../../shared-services/storage.service';

@Component({
  selector: 'app-add-new-spot',
  templateUrl: './add-new-spot.component.html',
  styleUrls: ['./add-new-spot.component.scss']
})
export class AddNewSpotComponent implements OnInit {

backText: string;
backToPage: string;
  
  changedVal: boolean = false;
  mode: string;

  constructor(private activatedRoute: ActivatedRoute,
             private storageService: StorageService) { }

  ngOnInit() {
    //get nav query params
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params['backToPage'] === 'create') {
        this.backToPage = "create";
        this.backText = '_CreateWizard_';
        this.storageService.set('jobPageVisited', true);
      } else {
        this.backText = '_ViewSpot_';
      }
    })
  }

  isChanged(event) {
    if (event === true) {
      // console.log('changed');
      this.changedVal = true;
    }
  }
}
