import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { StorageService } from '../../shared-services/storage.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  isExit: boolean = false;
  isContinue: boolean = false;
  isWarning: boolean = false;
  reverseBtns: boolean = false;
  buttonText: string;
  buttonCancelText: string;
  type: string;
  buttonType: string;
  params: {};
  warningParam: {};
  paramTitle: {};
  msgParam: {};

  constructor(
    private storageService: StorageService,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {
  }

  ngOnInit() {
    this.title = this.data.title ? this.data.title : null;
    this.message = this.data.message ? this.data.message : null;
    this.isExit = this.data.isExit ? this.data.isExit : null;
    this.isContinue = this.data.isContinue ? this.data.isContinue : null;
    this.reverseBtns = this.data.reverseBtns ? this.data.reverseBtns : false;
    this.isWarning = this.data.isWarning ? this.data.isWarning : null;
    this.buttonText = this.data.buttonText ? this.data.buttonText : null;
    this.buttonCancelText = this.data.buttonCancelText ? this.data.buttonCancelText : null;
    this.type = this.data.type ? this.data.type : null;
    this.params = this.data.params ? this.data.params : null;
    this.warningParam = this.data.warningParam ? this.data.warningParam : null;
    this.buttonType = this.data.buttonType ? this.data.buttonType : null;

    //saved local storage values that need to be cleared
    if(this.storageService.get('timeInterval') !== null) {
      this.storageService.remove('timeInterval', null);
    }
    if (this.params) {
      //use blank string if any parameter is not given
      let name, email, quantity;

      if (this.type === 'shift' || ((this.type === "repeat") && !this.storageService.get('repeatAdded'))) {
        quantity = this.params['quantity'] ? this.params['quantity']  : '';
        this.msgParam = {
          '0': quantity
        }
      } else {
        name = this.params['name'] ? this.params['name'] : '';
        email = this.params['email'] ? "(" + this.params['email'] + ")" : '';
        this.msgParam = {
          '0': name,
          '1': email
        }
      }
    }

    if (this.warningParam) {
      // let title = this.warningParam['title'];
      this.msgParam = {
        '0': this.warningParam
      }
    }
  }

  continue() {
    this.dialogRef.close('continue');
  }
  
  goBack() {
    window.history.back();
  }
  
  closeOrCancel() {
    this.dialogRef.close();
  }

}
