<app-header icon="back" title="_TimeZoneSupport_" showNav="false" backText="_Details_" changed="{{changedVal}}"></app-header>
<div class="edit-notification-settings body-container">
  <form [formGroup]="editTimeZoneForm" (ngSubmit)="onSubmit()">
    <div class="container">
      <p class="timezone-intro">{{'_TimeZoneIntro_' | translate}}</p>
      <div class="form-group">
        <mat-checkbox formControlName="allowTimeZones" (change)="onChange($event)">
          <label>{{'_EnableTimeZone_' | translate}}</label>
        </mat-checkbox>
      </div>
      <div class="form-group">
        <!-- notify change-->
        <label class="edit-label-name">{{'_SelectTimeZone_'| translate}}</label>
        <select class="form-control" formControlName="selectTimeZone">
          <option *ngFor="let item of timeZoneValues | keyvalue: unsorted" [value]="item.key">{{item.value}} - {{item.key}}</option>
        </select>
      </div>
    </div>

    <div class="button-bottom"> 
      <button type="submit" class="btn btn-blue edit-update-btn">{{'_Update_' | translate}}</button>
      <div class="edit-cancel-btn" (click)=cancelChanges()>{{'_Cancel_' | translate}}</div>
    </div>
  </form>
</div>