<div class="container">
  <div class="info-container">
    <div class="avatar-container">
      <ngx-avatars *ngIf="participantName" name="{{participantName}}"></ngx-avatars>
      <ngx-avatars *ngIf="!participantName && hasEmail" name="{{participantEmail}}"></ngx-avatars>
    </div>
    <div *ngIf="participantName" class="info-name truncate">{{participantName}}</div>
    <div *ngIf="hasEmail" [ngClass]="{'info-name':!participantName, 'info-email':participantName}"><a
        class="email-link truncate" href="mailto:{{participantEmail}}">{{participantEmail}}</a></div>
    <div class="info-phone"><a href="tel:{{participantPhone}}">{{participantPhone}}</a></div>
  </div>

  <div class="icon-container">
    <!-- email participant -->
    <span class="icon-row">
      <span *ngIf="hasEmail" class="icon-col">
        <span><a href="mailto:{{participantEmail}}"><i class="material-icons">email</i></a></span>
        <span class="icon-text">email</span>
        <!-- </span> -->
      </span>
      <span *ngIf="participantPhone" class="icon-col">
        <span><a href="tel:{{participantPhone}}"><i class="material-icons">call</i></a></span>
        <span class="icon-text">call</span>
      </span>
      <span *ngIf="participantPhone" class="icon-col">
        <!-- angular marks sms as unsafe, so sanitize -->
        <span><a [href]="sanitizeSms('sms:' + participantPhone)"><i class="material-icons">chat</i></a></span>
        <span class="icon-text">chat</span>
      </span>
      <span *ngIf="!quickSearch" class="icon-col">
        <span (click)="editParticipant()"><i class="material-icons">edit</i></span>
        <span class="icon-text" (click)="editParticipant()">edit</span>
      </span>
      <span *ngIf="quickSearch" class="icon-col">
        <span (click)="sendLinks()"><i class="material-icons">unarchive</i></span>
        <span class="icon-text" (click)="sendLinks()">send links</span>
      </span>
    </span>
  </div>

  <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>

  <!-- spot section -->
  <div class="spot-section">
    <div *ngIf="hasJob" class="list-header">Spots</div>
    <ul *ngIf="jobs" class="ul-list">
      <li *ngFor="let job of jobs; index as i" (click)="goToSignup(job)">
        <a class="c-pointer row-list">
          <span class="spot-list-main">
            <!-- spot title -->
            <span class="list-title">
              {{job.job_name}}
            </span>
            <!-- spot activity name under title -->
            <span class="spot-activity-title">
              {{job.quantity || job.jobass_quantity}}
              <!-- spot or spots -->
              <ng-container *ngIf="job.quantity == 1 || job.jobass_quantity == 1; else multipleSpots">
                Spot</ng-container>
              <ng-template #multipleSpots> Spots</ng-template>
            </span>
          </span>
          <!-- date and time -->
          <span class="text-right date-time-list">
            <span *ngIf="job.date && job.date !== '2038/01/01'" class="list-date">
              {{job.date | date: 'MMM d, yyyy'}}
            </span>
            <span *ngIf="job.starttime && job.starttime !== '1:01am'" class="list-date">{{job.starttime}}</span>
            <!-- right angle icon -->
            <span *ngIf="!quickSearch" class="list-icon" [ngStyle]="{'padding-top': job.starttime ? '.8rem' : '0'}">
              <i class="material-icons">keyboard_arrow_right</i>
            </span>
          </span>
        </a>
      </li>
    </ul>
    <div *ngIf="!hasJob && quickSearch" class="text-center">
      <p>No upcoming assignments.</p>
    </div>
  </div>
</div>