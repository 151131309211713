<app-header icon="back" title="_OrganizeSignup_" showNav="true"></app-header>

<div class="forgot-pswd-page">
  <!-- error div -->
  <div *ngIf="showError" class="error-div">{{errorMsg | translate:errorParams}}</div>
  <div class="side-padding ios-padding-top">
    <div *ngIf='!resetComplete'>
      <h4 class="text-center default-blue">{{'_ForgotPasswordHeader_'| translate}}</h4>
      <p>{{'_ForgotPasswordHelpText_' | translate}}
      </p>
      <form #resetForm="ngForm" (ngSubmit)="onResetSubmit(resetForm)">
        <div class="form-group">
          <input type="email" class="form-control" name="email" [(ngModel)]="resetForm.email" #email="ngModel" required
            placeholder="you@email.com" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$">
          <div *ngIf="formSubmitted && email.errors?.required" class="error"> <small><span
                translate>_VALIDATION_REQUIRED_ERROR_</span></small></div>
          <div *ngIf="formSubmitted && email.errors?.pattern" class="error"> <small><span
                translate>_VALIDATION_EMAIL_ERROR_</span></small></div>
        </div>
        <button type="submit" class="btn btn-blue btn-block mb-4">{{'_Send_'| translate}}</button>
      </form>
    </div>

    <div *ngIf='resetComplete'>
      <h5 class="default-blue">{{'_ChangePasswordHeader_'| translate}}</h5>
      <p class="text-center mt-3">{{'_ResetPasswordHelpText_'| translate}}</p>
      <a routerLink="/organizer/login" class="btn btn-blue btn-block mb-4">{{'_Ok_'| translate}}</a>
    </div>
  </div>
</div>
