import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../shared-services/storage.service';
import { VolunteerService } from '../../shared-services/volunteer.service';
import { JobService } from '../../shared-services/job.service';
import { UserService } from '../../shared-services/user.service';
import { Router} from "@angular/router";
import { map, filter } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { AvatarModule } from 'ngx-avatars';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DomsanitizerPipe } from '../../pipes/domsanitizer.pipe';
import { AlertDialogComponent } from './../../shared/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';

import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'app-add-edit-participant-detail',
  templateUrl: './add-edit-participant-detail.component.html',
  styleUrls: ['./add-edit-participant-detail.component.scss'],
})
export class AddEditParticipantDetailComponent implements OnInit {

  participant: any;
  activity: {};
  participantName: string;
  participantFirstName: string;
  participantEmail: string;
  participantPhone: string;
  participantId: string;
  // jobs$: any;
  jobs: [];
  job: {};
  hasJob: boolean;
  hasEmail: boolean;
  loading: boolean = false;

  //editor
  editorForm: FormGroup;
  editorContent: string;
  editorMaxLength: number = 10000;
  subjectParams = null;
  quickSearch: boolean = false;


  constructor(private storageService: StorageService,
    private volunteerService: VolunteerService,
    private userService: UserService,
    private jobService: JobService,
    private translate: TranslateService,
    private router: Router,
    public dialog: MatDialog,
    private sanitize: DomsanitizerPipe) { }

  ngOnInit() {
    var jobs, activityId;
    this.activity = this.storageService.get('activity');
    this.job = this.storageService.get('job');
    if (window.location.href.indexOf('quicksearch-detail') > -1) {
      //quick search view
      this.quickSearch = true;
      if (this.storageService.get('quicksearchVol')) {
        this.participant = this.storageService.get('quicksearchVol');
        this.participantName = this.participant.contactname || null;
        this.participantEmail = this.participant.email.toLowerCase();
        this.participantPhone = this.participant.contactphone;
        activityId = this.participant['activity_id'];
      }
      if (this.storageService.get('quicksearch-detail')) {
        jobs = this.storageService.get('quicksearch-detail')['jobassignment_details'];
        this.jobData(jobs);
      }
    } else {
      // participant view
      this.quickSearch = false;
      this.participant = this.storageService.get('participant');
      this.participantName = this.participant.name;
      this.participantFirstName = this.participant.contact_first_name || this.participant.contactname || null;
      this.participantEmail = this.participant.email ? this.participant.email.toLowerCase() : null;
      this.participantPhone = this.participant.phone;
      this.participantId = this.participant.id;
  
      this.volunteerService.query(this.activity['id'], this.participantId).subscribe(result => {
        if (result.status) {
          this.loading = false;
          this.storageService.process('participant', result.body['volunteer']);
          jobs = result.body['volunteer']['jobassignment_details'];
          this.jobData(jobs);
        }
      })
    }
    this.hasEmail= this.participantEmail ? true : false;
  }

  jobData(jobs) {
    this.jobs = jobs;
    jobs.forEach(item => {
      if(item.starthour) {
        item.starttime = moment(item.starthour, "h").format("h") + ':' + moment(item.startminute, "mm").format("mm") + moment(item.starthour, "h mm a").format("a");
      } else if (item.job_starttime && item.job_starttime.indexOf('-1') == -1) {
        //sometimes the start time shows as -1 if there is no start time
        item.starttime = item.job_starttime;
      }
      if (item.event_date) {
        item.date = item.event_date;
      }
    })
    if (jobs.length) {
      this.hasJob = true;
    } else {
      this.hasJob = false;
    }
    return jobs;
  }

  editParticipant() {
    this.router.navigate(['edit_assign_spot/' + this.participantId], { queryParams: { editVol: true } });
    this.storageService.set('editingParticipant', false);
  }
  
  goToSignup(job) {
    if (!this.quickSearch) {
      this.jobService.get(job.job_id, this.activity['id']).subscribe(
        response => {
          if (response) {
            let route = this.router;
            this.storageService.process('job', response.body['data'], function() {
              route.navigateByUrl('edit_spots_detail/' + job['job_id']);  
            });
          }
        }
      )
    }
  }

  sendLinks() {
    this.loading = true;
    this.userService.getInvitationSummary({
      email: this.participantEmail
    }).subscribe(response => {
      this.loading = false;
      if (response) {
        if (response.body['status']) {
          this.showAlert(true);
        } else {
          //no email was sent
          this.showAlert(false);
        }
      }
    })
  }

  getMaxLength(e) {
    if (e.editor.getLength() > this.editorMaxLength) {
      e.editor.deleteText(this.editorMaxLength, e.editor.getLength());
    }
  }

  showAlert(status) {
    let msg, title;
    if (status) {
      this.translate.get('_EmailSentConfirm_').subscribe((text) => {
        msg = text;
        title = 'Message Sent'
      })
    } else {
      //different header
      this.translate.get('_NoEmailSentHeader_').subscribe((text) => {
        title = text;
      })
      if (this.participantName) {
        //alert message shows name
        this.translate.get('_NoEmailSentWithName_', {
          '0': this.participantName.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase()),
          '1': this.participantEmail
        }).subscribe(
          response => {
            msg = response.replace('&lt;','<').replace('&gt;','>');
          }
        )
      } else {
        //alert message only has email
        this.translate.get('_NoEmailSent_', {
          '0': this.participantEmail
        }).subscribe(
          response => {
            msg = response;
          }
        )
      }

    }
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      data: {
        isSuccess: true,    //success dialog
        title: title, 
        message: msg
      }
    });
  }

  sanitizeSms(phone) {
    //angular marks sms numbers as unsafe, so you need to sanitize first
    return this.sanitize.transform(phone)
  }
}
