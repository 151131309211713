<app-header icon="home" title="SignUp Created" showNav="false"></app-header>
<div class="done-page body-container">
  <div class="done-header blue-gradient-bg">{{'_DoneSuccessMessage_' |translate}}
  </div>
  <div class="container">
    <div class="mb-3">
      <p class="capitalize">{{activityTitle}}</p>
      <a class="btn default-blue-border btn-block" (click) ="generateLink()">
        <span class="float-left"><i class="material-icons">remove_red_eye</i></span>
        <span class="text-link">{{'_PreviewSignup_' | translate}}</span></a>
        <a class="btn default-blue-border btn-block"  [routerLink]="['/edit/detail/', activityId]">
          <span class="float-left"><i class="material-icons">edit</i></span>{{'_EditSignUp_' | translate}}</a>
    </div>
    <div>
      <p>{{'_ShareInvitationFBShare_' | translate}}</p>
      <app-share></app-share>
    </div>
    <div><small>* Please visit our desktop version to send private individual invitations or to get a html SignUp button for your website or blog.</small></div>
  </div>
</div>