import { BrowserModule } from '@angular/platform-browser';
// import { HttpClient, HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from './shared-services/HttpRequestInterceptor';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import {TranslateLoader, TranslateModule, TranslateParser, TranslateDefaultParser} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
// import { GooglePlus } from '@ionic-native/google-plus/ngx';
// import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from '@abacritt/angularx-social-login';


// services
import { StorageService } from './shared-services/storage.service';
import { GoogleAnalyticsService } from "./shared-services/google-analytics.service";
import { ActivityService } from './shared-services/activity.service';
import { CommentService } from './shared-services/comment.service';
import { EventService } from './shared-services/event.service';
import { InvitationService } from './shared-services/invitation.service';
import { JobService } from './shared-services/job.service';
import { UserService } from './shared-services/user.service';
import { VolunteerService } from './shared-services/volunteer.service';
import { ErrorHandlerService } from './shared-services/error-handler.service';
import { MessagesService } from './shared-services/messages.service';
import { ReportingService } from './shared-services/reporting.service';
import { environment } from './../environments/environment';

// components
import { AppComponent } from './app.component';

//modules
// import { QuillModule } from 'ngx-quill';
import { HomepageModule } from './homepage/homepage.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AvatarModule } from 'ngx-avatars';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { EditEventsModule } from './edit-events/edit-events.module';
import { CreateSignupModule } from './create-signup/create-signup.module';
import { isPlatform } from '@ionic/angular';

// Configs 
export class CustomParser extends TranslateDefaultParser {
  //change the parser to accept {0} as parameter instead of {{0}}
  templateMatcher: RegExp = /{\s?([^{}\s]*)\s?}/g;
};

//load language file using api call
export function createTranslateLoader(http: HttpClient) {
  // if (isPlatform('capacitor')) {
  if(window.origin.indexOf("localhost") > -1) {
    return new TranslateHttpLoader(http, 'assets/lang/', '.json');
  } else {
    return new TranslateHttpLoader(http, environment.apiUrl + '/api/languages/', '?format=map');
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    IonicModule.forRoot(),
    HttpClientModule,
    FontAwesomeModule,
    AvatarModule,
    SocialLoginModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      },
      parser: {
        provide: TranslateParser,
        useClass: CustomParser
      }
    }),
    HomepageModule,
    DashboardModule,
    AppRoutingModule,   //should be after feature modules
    SharedModule,
    EditEventsModule,
    CreateSignupModule,
    BrowserAnimationsModule,
    NgxTippyModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    {provide: 'BASE_API_URL', 
    useValue: environment.apiUrl},
    {provide: HTTP_INTERCEPTORS, 
    useClass: HttpRequestInterceptor, 
    multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '408130889961-0uvgc4s8daspbmvigovmdljcin19pue4.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1731142263830615')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    StorageService,
    GoogleAnalyticsService,
    ActivityService,
    UserService,
    CommentService,
    EventService,
    InvitationService,
    JobService,
    VolunteerService,
    ErrorHandlerService,
    MessagesService,
    ReportingService
    // Facebook,
    // GooglePlus,
    // SignInWithApple
    ],
    
  bootstrap: [AppComponent]
})
export class AppModule { }



