<div class="warning-dialog alert-dialog">
  <h2 mat-dialog-title>{{title}}</h2>
  <mat-dialog-content>
    <p>{{message}}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button (click)="cancel()" class="edit-cancel-btn">{{buttonCancelText | translate}}</button>
    <button class="btn continue-btn" (click)="continue()">{{buttonText | translate}}</button>
  </mat-dialog-actions>
</div>
