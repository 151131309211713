import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '../app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { QuillModule } from 'ngx-quill';
import { AvatarModule } from 'ngx-avatars';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {DragDropModule, CdkDragDrop} from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { Router, ActivatedRoute, RouterModule } from "@angular/router";
import { ClipboardModule } from 'ngx-clipboard';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { DomsanitizerPipe } from '../pipes/domsanitizer.pipe';
import { HeaderComponent } from './header/header.component';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { FilterPipe } from '../pipes/filter.pipe';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { EditConfDialogComponent } from './edit-conf-dialog/edit-conf-dialog.component';
import { DeleteFutureDialogComponent } from './delete-future-dialog/delete-future-dialog.component';
import { InviteParticipantsComponent } from './invite-participants/invite-participants.component';
import { EditSpotsDialogComponent } from './edit-spots-detail-dialog/edit-spots-detail-dialog';
import { EditTimezoneComponent } from '../edit-events/edit-timezone/edit-timezone.component';
import { EditShiftTimeDialogComponent } from './edit-shift-time-dialog/edit-shift-time-dialog.component';
import { AddEditJobComponent } from './add-edit-job/add-edit-job.component';
import { AddEditSpotComponent } from './add-edit-spot/add-edit-spot.component';
import { ExtraSpecialFieldsComponent } from './extra-special-fields/extra-special-fields.component';
import { AddEditXtraFieldsComponent } from './add-edit-xtra-fields/add-edit-xtra-fields.component';
import { AddEditThemesComponent } from './add-edit-themes/add-edit-themes.component';
import { AddEditParticipantInfoComponent } from './add-edit-participant-info/add-edit-participant-info.component';
import { AddEditParticipantDetailComponent } from './add-edit-participant-detail/add-edit-participant-detail.component';
import { NumberSpinnerComponent } from './number-spinner/number-spinner.component';
import { MessagesComponent } from './messages/messages.component';
import { RecipientFilterComponent } from './messages/recipient-filter/recipient-filter.component';
import { RecipientFilterByStatusComponent } from './messages/recipient-filter-by-status/recipient-filter-by-status.component';
import { RecipientFilterByDateComponent } from './messages/recipient-filter-by-date/recipient-filter-by-date.component';
import { RecipientFilterBySpotComponent } from './messages/recipient-filter-by-spot/recipient-filter-by-spot.component';
import { RecipientFilterByNameComponent } from './messages/recipient-filter-by-name/recipient-filter-by-name.component';
import { ShareComponent } from './invite-participants/share/share.component';
import { QuicksearchComponent } from './quicksearch/quicksearch.component';
import { QuicksearchDetailComponent } from './quicksearch-detail/quicksearch-detail.component';
import { GooglePlacesAutocompleteComponent } from './google-places-autocomplete/google-places-autocomplete.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        AppRoutingModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgxTippyModule,
        QuillModule.forRoot(),
        AvatarModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatListModule,
        MatIconModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatBottomSheetModule,
        MatTableModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonToggleModule,
        MatRadioModule,
        MatTabsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
        DragDropModule,
        MatExpansionModule,
        ClipboardModule,
        AutocompleteLibModule,
        MatMomentDateModule

    ],
    declarations: [
        HeaderComponent,
        ProfileSettingsComponent,
        ProgressBarComponent,
        FilterPipe,
        DomsanitizerPipe,
        AlertDialogComponent,
        WarningDialogComponent,
        ConfirmDialogComponent,
        EditConfDialogComponent,
        DeleteFutureDialogComponent,
        InviteParticipantsComponent,
        EditSpotsDialogComponent,
        EditShiftTimeDialogComponent,
        AddEditJobComponent,
        AddEditSpotComponent,
        EditTimezoneComponent,
        ExtraSpecialFieldsComponent,
        AddEditXtraFieldsComponent,
        AddEditThemesComponent,
        AddEditParticipantInfoComponent,
        AddEditParticipantDetailComponent,
        NumberSpinnerComponent,
        MessagesComponent,
        RecipientFilterComponent,
        RecipientFilterByStatusComponent,
        RecipientFilterByDateComponent,
        RecipientFilterBySpotComponent,
        RecipientFilterByNameComponent,
        ShareComponent,
        QuicksearchComponent,
        QuicksearchDetailComponent,
        GooglePlacesAutocompleteComponent
    ],
    providers: [
        DomsanitizerPipe,
        MatDatepickerModule
    ],
    exports: [
        HeaderComponent,
        ProgressBarComponent,
        CommonModule,
        TranslateModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        QuillModule,
        AvatarModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatListModule,
        MatIconModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatBottomSheetModule,
        MatTableModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonToggleModule,
        NgxTippyModule,
        MatRadioModule,
        MatTabsModule,
        MatDialogModule,
        MatInputModule,
        DragDropModule,
        MatFormFieldModule,
        MatExpansionModule,
        ClipboardModule,
        FilterPipe,
        DomsanitizerPipe,
        AutocompleteLibModule,
        MatMomentDateModule,
        AddEditJobComponent,
        AddEditSpotComponent,
        EditTimezoneComponent,
        ExtraSpecialFieldsComponent,
        AddEditXtraFieldsComponent,
        AddEditThemesComponent,
        AddEditParticipantInfoComponent,
        AddEditParticipantDetailComponent,
        NumberSpinnerComponent,
        ShareComponent,
        QuicksearchComponent,
        QuicksearchDetailComponent,
        GooglePlacesAutocompleteComponent
    ]
})
export class SharedModule { }