import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "@abacritt/angularx-social-login";
import { SocialUser } from '@abacritt/angularx-social-login';

//components
import { HomepageComponent } from './homepage.component';
import { RegisterComponent } from './organizer/register/register.component';
import { LoginComponent } from './organizer/login/login.component';
import { ForgotPswdComponent } from './forgot-pswd/forgot-pswd.component';
import { OrganizerComponent } from './organizer/organizer.component';

//services
import { UserService } from '../shared-services/user.service';
import { GoogleService} from './organizer/register/google.service';

// Configs 
const fbLoginOptions = {
  scope: 'email',
  return_scopes: true
}; 
 
const googleLoginOptions = {
  scope: 'profile email'
};

@NgModule({
  imports: [
    CommonModule,
    SocialLoginModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    FormsModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    RegisterComponent,
    LoginComponent,
    ForgotPswdComponent,
    HomepageComponent,
    OrganizerComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    UserService,
    GoogleService,
    SocialUser,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '408130889961-0uvgc4s8daspbmvigovmdljcin19pue4.apps.googleusercontent.com',
               {
                oneTapEnabled: false,
              }
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1731142263830615')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LdJaFIUAAAAACTUyERnuZ4j6TIkzuJ_ft3uwTSD' } as RecaptchaSettings //TODO: use for production
      // useValue: { siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' } as RecaptchaSettings //dev

    }
  ],
  exports: [
    HomepageComponent,
    OrganizerComponent,
    RegisterComponent,
    LoginComponent,
    ForgotPswdComponent
  ]
})
export class HomepageModule { }
