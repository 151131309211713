<app-header icon="back" title="_Status_" showNav="false" backText="_MessageParticipants_">
</app-header>
<div class="filter-by-status message-filter-pages blue-accordion">
  <div class="message-header">{{'_RecipientFilterStatus_' | translate}}</div>
  <div class="select-header">
    <span (click) = "selectAllCheckboxes()">{{'_SelectAll_'| translate}}</span>
    <span (click) = "clearAllCheckboxes()">{{'_ClearAll_'| translate}}</span>
  </div>
  <div class="container">
    <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
    <mat-accordion #accordion="matAccordion" [multi]="false">
      <!-- viewed -->
      <mat-expansion-panel class="mat-top-panel" [expanded]="true">
        <mat-expansion-panel-header class="mat-top-header">
          <div class="panel-top-header">
            <span class="panel-top">
              <mat-checkbox [checked]="checkAllViewed" (change)="selectAll($event, 'viewed')" (click)="stopPropagation($event)"> </mat-checkbox>
              <span>{{'_Viewed_' | translate}} ({{viewed.length}})</span>
            </span>
          </div>
        </mat-expansion-panel-header>
        <div class="spot-name-row" *ngFor="let item of viewed">
          <mat-checkbox [checked]="item.checked" (change)="select($event, item, 'viewed')">
            <label>
              <span class="flex-column truncate">
                <span *ngIf="item.contactname" class="participant-name">{{item.contactname}}</span>
                <span *ngIf="item.email" [ngClass]="!item.contactname ? 'participant-name' : participant-email">{{item.email}}</span>
                <span *ngIf="item.jobassignments.length">{{item.jobassignments[0].job_name}}
                  <span *ngIf="item.jobassignments.length > 1"> + {{item.jobassignments.length - 1}} more</span>
                </span>
              </span>
            </label>
          </mat-checkbox>
        </div>
      </mat-expansion-panel>

      <!-- not viewed -->
      <mat-expansion-panel class="mat-top-panel">
        <mat-expansion-panel-header class="mat-top-header">
          <div class="panel-top-header">
            <span class="panel-top">
              <mat-checkbox [checked]="checkAllNotViewed" (change)="selectAll($event, 'notViewed')" (click)="stopPropagation($event)"></mat-checkbox>
              <span>{{'_NotViewed_' | translate}} ({{notViewed.length}})</span>
            </span>
          </div>
        </mat-expansion-panel-header>
        <div class="spot-name-row" *ngFor="let item of notViewed">
          <mat-checkbox [checked]="item.checked" (change)="select($event, item, 'notViewed')">
            <label>
              <span class="flex-column truncate">
                <span *ngIf="item.contactname" class="participant-name">{{item.contactname}}</span>
                <span *ngIf="item.email" [ngClass]="!item.contactname ? 'participant-name' : participant-email">{{item.email}}</span>
                <span *ngIf="item.jobassignments.length">{{item.jobassignments[0].job_name}}
                  <span *ngIf="item.jobassignments.length > 1"> + {{item.jobassignments.length - 1}} more</span>
                </span>
              </span>
            </label>
          </mat-checkbox>
        </div>
      </mat-expansion-panel>

      <!-- signed up -->
      <mat-expansion-panel class="mat-top-panel">
        <mat-expansion-panel-header class="mat-top-header">
          <div class="panel-top-header">
            <span class="panel-top">
              <mat-checkbox [checked]="checkAllSignedUp" (change)="selectAll($event, 'signedUp')" (click)="stopPropagation($event)"></mat-checkbox>
              <span class="">{{'_SignedUpMsg_' | translate}} ({{signedUp.length}})</span>
            </span>
          </div>
        </mat-expansion-panel-header>
        <div class="spot-name-row" *ngFor="let item of signedUp">
          <mat-checkbox [checked]="item.checked" (change)="select($event, item, 'signedUp')">
            <label>
              <span class="flex-column truncate">
                <span *ngIf="item.contactname" class="participant-name">{{item.contactname}}</span>
                <span *ngIf="item.email" [ngClass]="!item.contactname ? 'participant-name' : participant-email">{{item.email}}</span>
                <span *ngIf="item.jobassignments.length">{{item.jobassignments[0].job_name}}
                  <span *ngIf="item.jobassignments.length > 1"> + {{item.jobassignments.length - 1}} more</span>
                </span>
              </span>
            </label>
          </mat-checkbox>
        </div>
      </mat-expansion-panel>

      <!-- not signed up -->
      <mat-expansion-panel class="mat-top-panel">
        <mat-expansion-panel-header class="mat-top-header">
          <div class="panel-top-header">
            <span class="panel-top">
              <mat-checkbox [checked]="checkAllNotSignedUp" (change)="selectAll($event, 'notSignedUp')" (click)="stopPropagation($event)">
              </mat-checkbox>
              <span class="">{{'_NotSignedUp_' | translate}} ({{notSignedUp.length}})</span>
            </span>
          </div>
        </mat-expansion-panel-header>
        <div class="spot-name-row" *ngFor="let item of notSignedUp">
          <mat-checkbox [checked]="item.checked" (change)="select($event, item, 'notSignedUp')">
            <label>
              <span class="flex-column truncate">
                <span *ngIf="item.contactname" class="participant-name">{{item.contactname}}</span>
                <span *ngIf="item.email" [ngClass]="!item.contactname ? 'participant-name' : participant-email">{{item.email}}</span>
                <span *ngIf="item.jobassignments.length">{{item.jobassignments[0].job_name}}
                  <span *ngIf="item.jobassignments.length > 1"> + {{item.jobassignments.length - 1}} more</span>
                </span>
              </span>
            </label>
          </mat-checkbox>
        </div>
      </mat-expansion-panel>

      <!-- declined -->
      <mat-expansion-panel class="mat-top-panel">
        <mat-expansion-panel-header class="mat-top-header">
          <div class="panel-top-header">
            <span class="panel-top">
              <mat-checkbox [checked]="checkAllDeclined" (change)="selectAll($event, 'declined')" (click)="stopPropagation($event)"> </mat-checkbox>
              <span class="">{{'_Declined_' | translate}} ({{declined.length}})</span>
            </span>
          </div>
        </mat-expansion-panel-header>
        <div class="spot-name-row" *ngFor="let item of declined">
          <mat-checkbox [checked]="item.checked" (change)="select($event, item, declined)">
            <label>
              <span class="flex-column truncate">
                <span *ngIf="item.contactname" class="participant-name">{{item.contactname}}</span>
                <span *ngIf="item.email" [ngClass]="!item.contactname ? 'participant-name' : participant-email">{{item.email}}</span>
                <span *ngIf="item.jobassignments.length">{{item.jobassignments[0].job_name}}
                  <span *ngIf="item.jobassignments.length > 1"> + {{item.jobassignments.length - 1}} more</span>
                </span>
              </span>
            </label>
          </mat-checkbox>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <section class="footer">
    <span (click)="goNext()">Next</span>
  </section>
</div>