import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  loggedInStatus: boolean;

  constructor(private http: HttpClient,
    @Inject('BASE_API_URL') private apiURL:string) { }

  getProfile() {
    return this.http.get(this.apiURL + '/api/users/get_profile_info', {observe: 'response'});
  }

  setProfile(data) {
    return this.http.post(this.apiURL + '/api/users/put_profile_info', data, {observe: 'response'});                                                                            
  }

  orgLogin(data) {
    return this.http.post(this.apiURL + '/api/users/login', data, {observe: 'response'});
  }

  nativeOrgLogin = async (data) => {
    const options = {
      url: this.apiURL + '/api/users/login',
      headers: { 'content-type': 'application/json'},
      data,
    };
    const response: HttpResponse = await CapacitorHttp.post(options);
    return response;
  };
  

  orgRegister(data) {
    return this.http.post(this.apiURL + '/api/users', data, {observe: 'response'});
  }

  nativeOrgRegister = async (data) => {
    const options = {
      url: this.apiURL + '/api/users',
      headers: { 
        'content-type': 'application/json',
        origin: 'odaro'
      },
      data,
    };
  
    const response: HttpResponse = await CapacitorHttp.post(options);
    return response;
  };

  verifyRegistered(data) {
    return this.http.post(this.apiURL + '/api/users/verify_registered', data, {observe: 'response'});
  }

  verifyEmail(data) {
    return this.http.post(this.apiURL + '/api/users/verify_email', data, {observe: 'response'});
  }

  logout() {
    return this.http.get('/api/users/logout/', { responseType: 'blob' });
  }

  getInvitationSummary(data) {
    return this.http.post(this.apiURL + '/api/users/send_invitations_summary', data, {observe: 'response'});
  }
}
