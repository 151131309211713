<app-header icon="back" title="_AddByDateScheduled_" showNav="false" backText="_MessageParticipants_">
</app-header>
<div class="filter-by-date-page message-filter-pages green-accordion">
  <div class="message-header">{{'_RecipientFilterdDate_' | translate}}</div>
  <div class="select-header">
    <span (click) = "selectAllCheckboxes()">{{'_SelectAll_'| translate}}</span>
    <span (click) = "clearAllCheckboxes()">{{'_ClearAll_'| translate}}</span>
  </div>
  <div class="container">
    <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
    <mat-accordion #accordion="matAccordion" [multi]="false">
      <!-- outer panel for dated signups -->
      <mat-expansion-panel class="mat-top-panel"
        *ngFor="let key of monthKeys; index as i; first as isFirst;" [expanded]="isFirst">
        <mat-expansion-panel-header class="mat-top-header">
          <div class="panel-top-header">
            <span class="d-flex">
              <mat-checkbox [checked]="key.checked" (change)="selectAllEvents($event, key)" (click)="stopPropagation($event)"></mat-checkbox>
              <span>{{key.date | date: 'MMM, yyyy'}} ({{getTotal(key.date)}})</span>
            </span>
          </div>
        </mat-expansion-panel-header>
        <div>
          <mat-accordion class="inner-accordion" [multi]="false">
            <!-- second level panel for month and date -->
            <mat-expansion-panel *ngFor="let month of monthData[key.date]; index as j;">
              <mat-expansion-panel-header class="panel-second-header-top">
                <span class="panel-second-header" id="{{ 'spot-' + month.id }}">
                  <span>
                    <mat-checkbox [checked]="month.checked" (change)="selectAllMonths($event, month, key)" (click)="stopPropagation($event)"></mat-checkbox>
                    <span class="">{{month.edate | date: 'EEE, MMM d'}} ({{month.totalParticipants}})</span>
                  </span>
                </span>
              </mat-expansion-panel-header>
                  <span class="job-name" *ngFor="let assignment of month.assignments">
                  <mat-checkbox [checked]="assignment.checked" (change)="select($event, assignment, month, key)" (click)="stopPropagation($event)">
                    <label>
                      <span class="flex-column">
                        <span *ngIf="assignment.who" class="participant-name">{{assignment.who}}</span>
                        <span *ngIf="assignment.email" [ngClass]="!assignment.who ? 'participant-name' : participant-email">{{assignment.email}}</span>
                        <span>{{assignment.job_name}}</span>
                      </span>
                    </label>
                  </mat-checkbox>
                </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <section class="footer">
    <span (click)="goNext()">Next</span>
  </section>
</div>