import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UtilitiesService } from './../../../shared-services/utilities.service';
import { VolunteerService } from '../../../shared-services/volunteer.service';
import { StorageService } from '../../../shared-services/storage.service';

export interface User {
  name: string;
  email: string;
}

@Component({
  selector: 'app-recipient-filter-by-name',
  templateUrl: './recipient-filter-by-name.component.html',
  styleUrls: ['./recipient-filter-by-name.component.scss']
})
export class RecipientFilterByNameComponent implements OnInit {

  participants = [];
  volunteerId: number;
  activity: {};
  inputSearch;

  loading: boolean = true;
  selectedArr: string[] = [];
  filteredData = [];
  isFiltered: boolean = false;
  dataSource: MatTableDataSource<User>;
  selection = new SelectionModel<User>(true, []);
  displayedColumns: string[] = ['select', 'contactname'];

  constructor(private router: Router,
    private volunteerService: VolunteerService,
    private utilitiesService: UtilitiesService,
    private storageService: StorageService) { }

  ngOnInit() {
    let participantsList;
    this.activity = this.storageService.get('activity');
    this.selectedArr = this.storageService.get('selectedParticipants') || [];
    if(this.storageService.get('volunteerData')) {
      this.participants = this.storageService.get('volunteerData');
      this.dataSource = new MatTableDataSource<User>(this.participants);
      this.dataSource.data.forEach(row =>{
        if (this.selectedArr.some(x => (x['id'] || x['volunteer_id']) === row['id'])) {
          this.selection.select(row)
        }
      });
      this.loading = false;
    } else {
      //data is not in local storage, call from server
      this.volunteerService.get(this.activity['id']).subscribe(res => {
        if (res) {
          this.loading = false;
          participantsList = res.body['data'];
          participantsList.forEach(item => {
            if (item.email) {
              this.participants.push(item); 
            }
          })
          this.storageService.set('volunteerData', this.participants);
          this.dataSource = new MatTableDataSource<User>(this.participants);
          this.dataSource.data.forEach(row =>{
            if (this.selectedArr.some(x => (x['id'] || x['volunteer_id']) === row['id'])) {
              this.selection.select(row)
            }
          });
        }
      })
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    let numSelected = this.selection.selected.length;
    let numRows = this.dataSource.data.length;
    if (this.isFiltered && this.selection.selected.length && (this.filteredData.length === this.selection.selected.length)) {
      //data has been filtered and all filtered items were selected
      return true;
    } else {
      return numSelected === numRows;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(item) {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectedArr = [];
    } else {
      if (this.isFiltered) {
        //if data is filtered, only add filtered data
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
        this.filteredData = this.dataSource.filteredData;
        this.selectedArr = this.filteredData;
      } else {
        //add all items
        this.dataSource.data.forEach(row => this.selection.select(row));
        this.selectedArr = item.selected;
      }
    }
    this.saveSelection();
  }

  showSelection(item) {
    let idx = this.selectedArr.findIndex(elem => {
      return elem['id'] === item['id']
    });
    if (idx !== -1) {
      //remove item from list
      this.selectedArr.splice(idx, 1);
    } else {
      //add item to list
      this.selectedArr.push(item);
    }
    this.saveSelection();
  }

  applyFilter(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // console.log('this.dataSource.filter ', this.dataSource.filteredData)
    if (this.dataSource.filteredData.length < this.dataSource.data.length) {
      this.isFiltered = true;
    } else {
      this.isFiltered = false;
    }
    this.filteredData = this.dataSource.filteredData;
  }

  toggleSelect(event, participant) {
    let idx;
    if (event.checked) {
      this.selectedArr.push(participant);
    } else {
      idx = this.selectedArr.findIndex(item => {
        return participant['id'] === item['id']
      })
      if (idx !== null) {
        this.selectedArr.splice(idx, 1);
      }
    }
    this.saveSelection();
  }

  selectAll(event) {
    event.stopPropagation();
  }

  saveSelection() {
    this.storageService.set('selectedParticipants', this.selectedArr);
  }

  goNext() {
    //go back to the recipients filter page with the buttons
    this.storageService.set('selectedParticipants', this.selectedArr);
    window.history.back();
  }

}
