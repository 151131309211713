import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../../../shared-services/google-analytics.service";

import { UserService } from '../../../shared-services/user.service';
import { StorageService } from '../../../shared-services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  showBtns: boolean = false;
  formSubmitted: boolean = false;
  showPswd = false;
  respStatus: boolean;
  isCordovaApp: boolean = false;
  email: any;
  password: any;

  @Output() errMessage = new EventEmitter<Event>();
  @Output() errParam = new EventEmitter<Event>();

  constructor(private userService: UserService,
    private storageService: StorageService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    //check if app is run in browser or the mobile app
    if (this.storageService.get('isCordovaApp') && this.storageService.get('isCordovaApp') === true) {
      this.isCordovaApp = true;
      if (this.storageService.get('email') && this.storageService.get('password')) {
        //login automatically on app
        this.email = this.storageService.get('email');
        this.password = this.storageService.get('password');
        let loginForm = {
          email: this.email,
          password: this.password
        }
        this.onLoginSubmit(loginForm, true);
      }
    }
  }

  ngOnDestroy() {
    this.showBtns = true;
    this.resetForm();
  }

  setInputValue(input, form, event) {
    if (input === 'email') {
      form.controls['email'].setValue(event);
    } else if (input === 'password') {
      form.controls['password'].setValue(event);
    }
    // console.log('formVal', form.controls);
    // console.log('event', event)
  }

  showPassword() {
    //toggle show password
    this.showPswd = !this.showPswd;
  }

  resetForm() {
    this.formSubmitted = false;
    this.showPswd = false;
  };

  onLoginSubmit(loginForm, autocomplete?) {
    let formData, email, password;
    email = autocomplete ? loginForm.email : loginForm.value.email;
    password = autocomplete ? loginForm.password : loginForm.value.password;
    this.formSubmitted = true;
    formData = {
      email: email,
      password: password
    }
    if (loginForm.valid || autocomplete) {
      if (this.isCordovaApp) {
        //capacitor login
        this.nativeLogin(formData, email, password);
      } else {
        //mobileweb login
        this.userService.orgLogin(formData).subscribe(
          response => {
            if (response.body && response.body['status']) {
              this.processLogin(response);
            } else {
              this.onError(response);
            }
          }
        )
      }
    }
  }

  async nativeLogin(formData, email, password) {
    this.userService.nativeOrgLogin(formData).then((res: any) => {
      if (res && res.data['status']) {
        this.processLogin(res, email, password);
      } else {
        this.onError(res);
      }
    })
  }

  onError(response) {
    //status is false, emit error to main app component
    let errorCode;
    if (response.body) {
      errorCode = response.body['code'];
    } else {
      errorCode = response.data['code']
    }
    this.errMessage.emit(errorCode);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    //remove saved password
    if (this.storageService.get('password')) {
      this.storageService.remove('password');
    }
  }

  processLogin(response, email?, password?) {
    let userData, route = this.router;
    //status is true, proceed
    if (this.isCordovaApp) {
      //save login info in app
      this.storageService.set('email', email);
      this.storageService.set('password', password);
      userData = response.data['user'];
    } else {
      userData = response['user'] || response.body['user'];
    }
    
    //set tracking and storage values
    this.googleAnalyticsService.emitEvent('button', 'click', 'm/org_login');
    this.storageService.process('full_login', true);
    this.storageService.process('role', 'organizer');
    this.storageService.process('user', userData, function () {
      route.navigateByUrl('dashboard');
    });
  }
}
