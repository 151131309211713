<div class="delete-future-dialog alert-dialog">
  <h2 mat-dialog-title>{{'_DeleteJobModalTitle_' | translate}}</h2>

  <mat-dialog-content class="delete-text">
    <div *ngIf="jobAssignment && !force">
      <p>{{'_DeleteJobHasAssignment_' | translate}}</p>
      <p class="error">{{'_ConfirmDeleteJobWithAssignments_' | translate}}</p>
    </div>

    <div *ngIf="!jobAssignment && !force">
      <p>{{'_DeleteJobNoAssignmentsNoDateMobile_' | translate}}</p>
      <p class="error">{{'_ConfirmDeleteJob_' | translate}}</p>
    </div>
    <div *ngIf="force" class="force">
      <span class="error" [innerHTML] = "'_ORG_JOBS_DELETE_JOBS_' | translate:forceParam"></span>
      <div class="form-group mt-2 mb-0">
        <mat-checkbox (change)="toggleforceConfirm($event)">
          <label class="mb-0">{{'_DeleteJobModalForce_' | translate}}</label>
        </mat-checkbox>
      </div>
    </div>
    <p class="light-gray-text" *ngIf="repeating"> {{'_JobInRepeatingSchedule_' | translate}}</p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="alert-dialog-btn-wide">
      <button *ngIf="!repeating" class="btn delete-one" (click)="delete('norepeat')">{{'_Delete_' | translate}}</button>
      <button *ngIf="repeating" class="btn delete-one" (click)="delete('one')">{{'_DeleteOnlyThis_' | translate}}</button>
      <button *ngIf="repeating" class="btn delete-all" (click)="delete('future')">{{'_DeleteAllFutureSpots_' | translate}}</button>

      <a (click)="closeOrCancel()" class="cancel">{{'_Cancel_' | translate}}</a>
    </div>
  
  </mat-dialog-actions>
</div>
