import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../shared-services/storage.service';
import { VolunteerService } from '../../shared-services/volunteer.service';
import { EditEventsService } from '../edit-events.service';
import { JobService } from '../../shared-services/job.service';
import { Router} from "@angular/router";
import { map, filter } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { AvatarModule } from 'ngx-avatars';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DomsanitizerPipe } from '../../pipes/domsanitizer.pipe';
import { AlertDialogComponent } from './../../shared/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';

import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'app-edit-participant-detail',
  templateUrl: './edit-participant-detail.component.html',
  styleUrls: ['./edit-participant-detail.component.scss']
})
export class EditParticipantDetailComponent implements OnInit {

  participant: any;
  activity: {};
  participantName: string;
  participantFirstName: string;
  participantEmail: string;
  participantPhone: string;
  participantId: string;
  jobs$: any;
  job: {};
  hasJob: boolean;
  hasEmail: boolean;
  loading: boolean = true;

  //editor
  editorForm: FormGroup;
  editorContent: string;
  editorMaxLength: number = 10000;
  subjectParams = null;

  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', {'align': []}, 'link'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }]
    ]
  }

  constructor(private storageService: StorageService,
              private volunteerService: VolunteerService,
              private editEventsService: EditEventsService,
              private jobService: JobService,
              private translate: TranslateService,
              private router: Router,
              public dialog: MatDialog,
              private sanitize: DomsanitizerPipe) { }

  ngOnInit() {
    this.activity = this.storageService.get('activity');
    this.job = this.storageService.get('job');
    this.participant = this.storageService.get('participant');
    this.participantName = this.participant.name;
    this.participantFirstName = this.participant.contact_first_name || this.participant.contactname;
    this.participantEmail = this.participant.email;
    this.participantPhone = this.participant.phone;
    this.participantId = this.participant.id;

    // initialize form
    this.editorForm = new FormGroup({
      editor: new  FormControl('', [Validators.required]),
      editorSubject: new  FormControl('', [Validators.required])
    })


    //subject string
    this.subjectParams = {
      '0': this.activity["title"],
      '1': this.activity["orgname"]
    }

   this.translate.get('_DefaultMessageSubject_', this.subjectParams).subscribe((text) => {
      this.editorForm.patchValue({editorSubject: text});
    })
    this.hasEmail= this.participantEmail ? true : false;

    // this.jobs$ = this.volunteerService.query(this.activity['id'], this.participantId)
    // .pipe(map(res => {
    //   this.loading = false;
    //   this.storageService.process('participant', res.body['volunteer']);
    //   var jobAssignment = res.body['volunteer']['jobassignment_details'];
    //   var starttime;
    //   jobAssignment.forEach(item => {
    //     if(item.starthour) {
    //       item.starttime = moment(item.starthour, "h").format("h") + ':' + moment(item.startminute, "mm").format("mm") + moment(item.starthour, "h mm a").format("a");
    //     }
    //   })
    //   if (jobAssignment.length) {
    //     this.hasJob = true;
    //   } else {
    //     this.hasJob = false;
    //   }
    //   return jobAssignment;
    // }))
  }

  // goToSignup(job) {
  //   this.jobService.get(job.job_id, this.activity['id']).subscribe(
  //     response => {
  //       if (response) {
  //         let route = this.router;
  //         this.storageService.process('job', response.body['data'], function() {
  //           route.navigateByUrl('edit_spots_detail/' + job['job_id']);  
  //         });
  //       }
  //     }
  //   )
  // }

  // sanitizeSms(phone) {
  //   //angular marks sms numbers as unsafe, so you need to sanitize first
  //   return this.sanitize.transform(phone)
  // }
  editParticipant() {
    // this.router.navigateByUrl('edit_assign_spot/' + this.participantId);
    this.router.navigate(['edit_assign_spot/' + this.participantId], { queryParams: { editVol: true } });
    this.storageService.set('editingParticipant', false);
  }


  deleteParticipant() {
    //show alert
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      autoFocus: false,
      data: {
        isConfirm: true,  //confirm dialog
        isContinue: true,
        buttonText: "_Delete_",
        buttonCancelText: "_Cancel_",
        title: "_GenericDeleteHeader_",
        message: "_DeleteParticipantBody_",
        params: {
          email: this.participantEmail || '',
          name: this.participantFirstName || ''
        }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'continue') {
        this.volunteerService.deleteVolunteer(this.participantId, this.activity['id']).subscribe(
          response => {
            if (response.status) {
              // go back
              this.router.navigateByUrl('edit_spots_detail/' + this.job['id']);
            }
          }
        )
      }
    }) 
  }

  onEditorSubmit() {
    this.editorContent = this.editorForm.get('editor').value;
    var subject = this.editorForm.get('editorSubject').value;
    this.editEventsService.sendMessage(this.participantEmail, this.editorContent, this.participantName, this.participantId, this.activity['id'], subject).subscribe(
      response => {
        if (response.status) {
          this.showAlert();
        }
      },
      error => {
        // console.log('error ', error)
      }
    );
  }

  getMaxLength(e) {
    if (e.editor.getLength() > this.editorMaxLength) {
      e.editor.deleteText(this.editorMaxLength, e.editor.getLength());
    }
  }

  showAlert() {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      data: {
        isSuccess: true,    //success dialog
        title: 'Message Sent', 
        message: this.participantFirstName + ' will get your message through email.'
      }
    });
  }
}
