import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private router:Router) { }

  process(key:string, value, callbackFn?) {
    //job, activity & participant needs to be processed differently before the values are stored. This is because of all the 'standardizing' done in the current mobile client. If I dont do this, things will get funky when trying to go to the current mobile pages. 
    let callback = callbackFn ? callbackFn : null;
     if (key === 'participant') {
        value.name = value.name || value.contactname;
        value.phone = value.phone || value.phonenumber || value.contactphone;
        this.set(key, value, callback);
     } else if (key === 'activity') {
        value.id = value.id || value.activity_id;
        value.title = value.title || value.activity_title;
        value.badge = value.badge || value.activity_badge;
        value.orgemail = value.orgemail || value.org_email;
        value.waiver = value.activity_waiver || value.waiver;
        this.set(key, value, callback);
     } else if (key === 'job') {
        value.id = value.id || value.jobid || value.job_id;
        value.starthour = value.starthour || value.jobstarthour || 0;
        value.startminute = value.startminute || value.jobstartminute || 0;
        value.endhour = value.endhour || value.jobendhour || 0;
        value.endminute = value.endminute || value.jobendminute || 0;
        value.date = value.job_date || value.edate || value.date;
        value.name = value.name || value.jobname || value.job_name;
        value.quantity = value.quantity;
        value.description = value.desc || value.jobdesc || value.job_desc || ''; // '' || undefined => undefined, but '' || undefined || '' => '', so...
        value.jobassignments = value.jobassignments;
        value.jobassignmentid = value.jassid;
        value.jobassignmentquantity = value.jassquantity; // how many spots is the owner signed up for; used for My Spots
        value.jobtype = value.jobtype;
        value.activity_id = value.activity_id;
        value.repeating = value.repeating;
        value.parent_job_id = value.parent_job_id;
        value. repeating_json = value.repeating_json;
        this.set(key, value, callback);
     } else {
      this.set(key, value, callback);
     }
  }

  set(key, value, callback?) {
    //for now, this just puts the user data in local storage so the old app can retrieve the user details and actually process the login on that side

    window.localStorage.setItem('vs_' + key, JSON.stringify(value));
    if(callback) {
      //callback function
      callback();
      // this.router.navigateByUrl(callback);
    }
  }

  get(item) {
    if(window.localStorage.getItem('vs_' + item) !== 'undefined') {
      return JSON.parse(window.localStorage.getItem('vs_' + item));
    } 
  }

  remove(item, callback?) {
    window.localStorage.removeItem('vs_' + item);
    if (callback) {
      callback();
    }
  }

  removeMultiple(itemsArr, callback?) {
    itemsArr.forEach(item => {
      window.localStorage.removeItem('vs_' + item);
    })
    if (callback) {
      callback();
    }
  }

  reset() {
    window.localStorage.clear();
  }
}
