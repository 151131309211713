import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { StorageService } from '../../shared-services/storage.service';
import { EventService } from '../../shared-services/event.service';
import { UtilitiesService } from '../../shared-services/utilities.service';
import { ActivityService } from '../../shared-services/activity.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";
import { forkJoin } from 'rxjs';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { DomSanitizer } from '@angular/platform-browser';
import { Browser } from '@capacitor/browser';

import * as moment_ from 'moment';
const moment = moment_;
// declare var cordova: any;

@Component({
  selector: 'app-edit-spots',
  templateUrl: './edit-spots.component.html',
  styleUrls: ['./edit-spots.component.scss']
})
export class EditSpotComponent implements OnInit {

  constructor(private router: Router,
    private storageService: StorageService,
    private eventService: EventService,
    private utilitiesService: UtilitiesService,
    private activityService: ActivityService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer) { }

  activity: {};
  volunteerId: {};
  activityThemeFolder: string;
  title: string;
  calcWidth: number;
  isUnlimited: boolean = false;
  totalAssignments: number = 0;
  activityTotalNum: number = 0;
  activityTotal: any;
  showMonth: boolean = false;
  changedVal: boolean = false;
  loading: boolean = true;
  startDateRange: string;
  endDateRange: string;
  datedSignup: boolean = true;
  isCordovaApp:boolean = false;
  urlLocation: string;
  environment: string;

  @ViewChildren('panel', { read: ElementRef }) panels;

  ngOnInit() {
    //check if app is run in browser or the mobile app
    if (this.storageService.get('isCordovaApp') && this.storageService.get('isCordovaApp') === true) {
      this.isCordovaApp = true;
    }
    //set window location
    this.environment = this.storageService.get('environment');
    if (this.isCordovaApp) {
      if (this.environment) {
        this.urlLocation = this.environment;
      } else {
        this.urlLocation = 'https://signup.com';
      }
    } else {
      //mobile web
      this.urlLocation = window.location.origin;
    }
        
    this.activity = this.storageService.get('activity');
    // this.hasSpots = this.storageService.get('hasSpots');
    this.activityThemeFolder = this.activity['theme']['folder'];
    this.datedSignup = this.activity['kind'] === 'nodate' ? false : true;
    if (this.activity['startdate'] !== this.activity['enddate']) {
      this.showMonth = true;
    } else {
      this.showMonth = false;
    }

    this.title = this.activity['title'];
    //remove unnecessary local storage settings that might bleed through
    this.storageService.removeMultiple(['jobQuantity', 'selectedParticipants', 'formMessage', 'editorCount', 'irregularDaysSelected', 'originalRepeat', 'timeValues', 'jobName', 'jobType', 'jobDesc']);
  }

  trackByFn(index, item) {
    //set trackby so angular doesnt remove all elements from the dom if still valid
    return index;
  }

  onUpdate(event){
    if (event === 'disable-spinner') {
      this.loading = false;
    } else if (event === 'enable-spinner') {
      this.loading = true;
    }
  }

  ondateChange(event) {
    // console.log(event)
    this.setDates(event);
  }

  setDates(date) {
    //date range in header
    let startDate = date.startDate, endDate = date.endDate;
    if (startDate!== "2038/01/01" && startDate!==  "2038-01-01") {
      //it is a dated signup
      if (startDate !== null) {
        if (moment(startDate).format("YYYY") === (endDate && moment(endDate).format("YYYY"))) {
          //start and end year are the same, so do not show year for the start date
          this.startDateRange = moment(startDate).format("MMM D");
        } else {
          //if different, show year for both
          this.startDateRange = moment(startDate).format("MMM D, YYYY");
        }
        if (endDate) {
          this.endDateRange = moment(endDate).format("MMM D, YYYY");
        }
      } else {
        //its dated but no start date, that means its incomplete
        this.startDateRange = null;
      }
    }

  }

  generateLink() {
    var link;
    this.utilitiesService.generateLink(this.activity['id']).subscribe(
      response => {
        //save current user object because it changes when you to participant view
        this.storageService.set('userObject',this.storageService.get('user'));
        link = response.body['longlink'] + '&amp;popup=true&amp;preview=true';
        if (this.isCordovaApp) {
          // cordova.InAppBrowser.open(link, '_system', 'location=yes');
          Browser.open({ url: link });
        } else {
          window.location.href = link;
        }
      }
    )
  }

  isChanged(event) {
    if (event === true) {
      // console.log('changed');
      this.changedVal = true;
    }
  }

}
