import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, forwardRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { QuillModule } from 'ngx-quill';
import { MatMenuModule } from '@angular/material/menu';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';
import { EditConfDialogComponent } from './../../shared/edit-conf-dialog/edit-conf-dialog.component';
import { AlertDialogComponent } from './../alert-dialog/alert-dialog.component';
import { StorageService } from '../../shared-services/storage.service';
import { ActivityService } from '../../shared-services/activity.service';
import { JobService } from '../../shared-services/job.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { TranslateService } from '@ngx-translate/core';

import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'app-add-edit-job',
  templateUrl: './add-edit-job.component.html',
  styleUrls: ['./add-edit-job.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddEditJobComponent),
      multi: true
    },
  ]
})
export class AddEditJobComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Output() changed = new EventEmitter();
  @Output() newSpotCreated = new EventEmitter();

  editJobForm: FormGroup;
  subscriptions: Subscription[] = [];

  activity: {};
  job: any;
  jobDesc: string;
  jobType: number;
  jobTitleSuggestions: string[] = [];
  jobQuantity: number;
  totalJobAssigned: number;
  hourArr: string[] = [];
  minuteArr: string[] = [];
  isUnlimited: boolean = false;
  repeatEnabled: boolean = false;   //disable for undated signups
  isRepeat: boolean = false;  //does it already have repeat enabled
  timeError: boolean = false;
  numError: boolean = false;
  showError: boolean = false;
  undated: boolean = false;
  shiftSummary: string;
  repeatSummary: string;
  timeFieldSetting: string = 'e';   //needed by the desktop
  shiftQty: number;
  repeatingJSONData: {};
  defaultServerValues: {};
  originalRepeat: boolean = false;  //was job originally repeating and we removed the repeating sequence?
  hasRepeatingData: boolean = false;
  shiftData: object[] = [];
  serverSummary: string;
  repeatingJobs: object[] = [];
  dates: {};
  dayOfMonth: {};
  dayOfWeek: {};
  showCloseBtn: boolean = false;
  confMessage: string;
  confMessageParam: {};
  errorMessage: string;
  errorMessageParam: {};
  jobSaveQueue: {}; //used to save the data in case we need to force change
  repeatingScheduleQueue: {}; //used to save the data in case we need to force change
  showDateError: boolean = false;
  showNameError: boolean = false;
  creating: boolean = false;
  editing: boolean = false;
  goToCreatePage: boolean = false;  //editing in create mode, will need to go back to create wizard
  submitted: boolean = false;
  loading: boolean = false;
  spotDateTooltip: string = "You can choose specific calendar dates, or no date at all by leaving the field blank. Spots dates can be one individual day, or a span of multiple days if you set a spot to repeat.";
  //date
  jobDate: any;
  minDate: any;
  noTime: boolean = false;

  timeInterval: string;
  //start times
  jobStartHour: any;
  jobStartMinute: any;
  jobStartMeridiem: string;
  prevJobStartHour: any;
  prevJobStartMinute: any;
  prevStartMeridiem: string;

  //end times
  jobEndHour: any;
  jobEndMinute: any;
  jobEndMeridiem: string;
  prevJobEndHour: any;
  prevJobEndMinute: any;
  prevEndMeridiem: string;

  //editor
  editorMaxLength: number = 2560;
  editorCount: number;

  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', { 'align': [] }, 'link'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ]
  };
  editorPlaceholder: '';

  // set tooltip defaults
  nagTooltip: string = '';

  tippyProps: NgxTippyProps = {
    allowHTML: true,
    trigger: 'click',
    arrow: true,
    placement: 'auto',
    maxWidth: 230,
  };

  constructor(private storageService: StorageService,
    private activityService: ActivityService,
    private jobService: JobService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private tippyService: NgxTippyService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef) {}

  @Input() update: number;
  @Input() error: string;
  @Input() mode: string;

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit() {
    this.setContentForTooltip();
    this.cdr.detectChanges();
  } 

  setContentForTooltip() {
    if (this.storageService.get('job')) {
      this.job = this.storageService.get('job');
    } 
    // if (this.activity['kind']) {
      // if (this.job['date'] !== '2038/01/01') {
        // this.translate.get('_SpotDateInfo_').subscribe((text) => {
        //   // this.tippyService.setContent('spotDateTooltip', text);
        //   this.spotDateTooltip = text;
        // })
      // }
    // }
    this.translate.get('_SpotTimeInfo_').subscribe((text) => {
      this.tippyService.setContent('spotTimeTooltip', text);
    })
    this.translate.get('_SpotExtraDetailsHeaderInfo_').subscribe((text) => {
      this.tippyService.setContent('spotDetailHeaderTooltip', text);
    })
    this.translate.get('_SpotExtraDetailsInfo_').subscribe((text) => {
      this.tippyService.setContent('spotDetailInfoTooltip', text);
    })
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this.editJobForm.value;
  }

  set value(value) {
    this.editJobForm.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
    if (value === null) {
      this.editJobForm.reset();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit() {
    //scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    let jobName = this.storageService.get('jobName'),
      jobType = this.storageService.get('jobType'),
      jobQuantity = this.storageService.get('jobQuantity'),
      jobDesc = this.storageService.get('jobDesc');

    //creating or editing
    if (this.mode === 'create') {
      this.creating = true;
      this.storageService.remove('job');
      this.storageService.set('creating', true);
    } else if (this.mode === 'edit') {
      this.editing = true;
      this.storageService.set('creating', false);
    }

    //get nav query params
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && ((params['backToPage'] === 'create') || params['mode'] === 'create')) {
        this.goToCreatePage = true;
      }
    })

    this.activity = this.storageService.get('activity');
    //get previously saved values from previous ui
    if (this.storageService.get('job')) {
      this.job = this.storageService.get('job');
    } 
    //get storage values
    this.timeInterval = this.storageService.get('timeInterval');
    this.timeFieldSetting = this.storageService.get('timeFieldSetting');
    this.shiftSummary = this.storageService.get('shiftSummary');
    this.shiftQty = this.storageService.get('shiftQty');

    if (this.job) {
      this.totalJobAssigned = this.activity['bringstotalassignments'] + this.activity['jobstotalassignments'];
      this.jobType = jobType || this.job['jobtype'];
      jobName = jobName || this.job['name'];
      this.jobDesc = jobDesc || this.job['desc'];

      this.noTime = this.job['startHour'] === -1 ? true : false;
      this.isRepeat = this.job && this.job['repeating'] && this.job['repeating_jobs'] && this.job['repeating_jobs'].length > 1;
      this.hasRepeatingData = this.job['repeating'] && this.job['repeating_json'] || this.storageService.get('repeatingJSONData');
      this.jobQuantity = jobQuantity || this.job['quantity'];

      if (this.hasRepeatingData) {
        //get previously saved local storage values
        this.repeatingJSONData = this.storageService.get('repeatingJSONData') || JSON.parse(this.job['repeating_json']);
        this.serverSummary = this.storageService.get('serverSummary') || this.job['repeating_description'];
      }
      // dated or undated?
      if (this.activity['kind'] === 'dated' && this.job['date'] !== '2038/01/01') {
        this.minDate = moment().toDate();
        this.repeatEnabled = true;
        this.undated = false;
      } else {
        this.undated = true;
        this.repeatEnabled = false;
      }
      this.jobDate = moment(this.job['date']).format('YYYY-MM-DD');

    } else {
      //set defaults when creating
      if (this.activity) {
        if (this.activity['kind'] === 'dated') {
          this.undated = false;
        } else {
          this.undated = true;
        }
      }
      this.jobType = jobType || 0;
      this.jobQuantity = jobQuantity || 1;
      this.hasRepeatingData = this.storageService.get('repeatingJSONData');
      if (this.hasRepeatingData) {
        //get previously saved local storage values
        this.repeatingJSONData = this.storageService.get('repeatingJSONData');
        this.serverSummary = this.storageService.get('serverSummary');
      }
    }

    this.isUnlimited = this.jobQuantity === -1;
    this.initTime();
    //initialize form
    this.editJobForm = this.formBuilder.group({
      jobname: [jobName || '', Validators.required],
      editor: [this.jobDesc || ''],
      timeInterval: [this.timeInterval],
      spotDate: [this.jobDate || ''],
      startHour: [this.jobStartHour],
      startMinute: [this.jobStartMinute],
      endHour: [this.jobEndHour],
      endMinute: [this.jobEndMinute]
    });




    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.editJobForm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    if (this.storageService.get('quickfillShifts')) {
      this.shiftData = this.storageService.get('quickfillShifts');
    }

    if (this.storageService.get('repeatSummary') && this.storageService.get('repeatingJobs').length > 1) {
      //repeat was added from repeating job ui
      this.showDateError = false;
      this.isRepeat = true;
    }
    //get job title suggestions
    if (this.activity) {
      this.activityService.orgJobTitles(this.activity['activitytype'], this.jobType).subscribe(result => {
        if (result.status) {
          this.jobTitleSuggestions = result.body['job_titles'];
        }
      })
    }

    //check for changes
    this.formValueChanged();

  }

  initTime() {
    let timeValues = this.storageService.get('timeValues');
    if (!this.timeInterval) {
      //time interval was not previously set
      if (this.job && (this.job['starthour'] !== -1 && this.job['endhour'] === -1)) {
        //start time only
        this.timeInterval = 'at';
        this.timeFieldSetting = 's';
      } else if (this.job && (this.job['starthour'] === -1 && this.job['endhour'] === -1)) {
        //anytime
        this.timeInterval = 'anytime';
        this.timeFieldSetting = 'a';
      } else {
        //start and end time
        this.timeInterval = 'from';
        this.timeFieldSetting = 'e';
      }
    }

    //get start times
    if (timeValues) {
      //values were previously set before going to spot shift screen
      this.jobStartHour = moment(timeValues['starthour'], "H").format("hh");
      this.jobStartMinute = moment(timeValues['startminute'], "mm").format("mm");
      this.jobStartMeridiem = moment(timeValues['starthour'], "h mm a").format("A");
      if (timeValues['endhour'] !== -1) {
        this.jobEndHour = moment(timeValues['endhour'], "H").format("hh");
        this.jobEndMinute = moment(timeValues['endminute'], "mm").format("mm");
        this.jobEndMeridiem = moment(timeValues['endhour'], "h mm a").format("A");
      } else {
        // no end time, add an hour from start time
        this.jobEndHour = moment(timeValues['starthour'], "hh").add(1, 'hours').format('HH');
        this.jobEndMinute = '00';
        this.jobEndMeridiem = this.jobStartMeridiem;

      }
    } else {
      if (this.job) {
        if (this.job['starthour'] !== -1) {
          this.jobStartHour = moment(this.job['starthour'], "H").format("hh");
          this.jobStartMinute = moment(this.job['startminute'], "mm").format("mm");
          this.jobStartMeridiem = moment(this.job['starthour'], "h mm a").format("A");
        } else {
          this.jobStartHour = '08';
          this.jobStartMinute = '00';
          this.jobStartMeridiem = 'AM';
        }
        if (this.job['endhour'] !== -1) {
          this.jobEndHour = moment(this.job['endhour'], "H").format("hh");
          this.jobEndMinute = moment(this.job['endminute'], "mm").format("mm");
          this.jobEndMeridiem = moment(this.job['endhour'], "h mm a").format("A") || this.jobStartMeridiem;
        } else {
          this.jobEndHour = this.jobStartHour ? moment(this.jobStartHour, "hh").add(1, 'hours').format('HH') : '09';
          this.jobEndMinute = this.jobStartMinute ? this.jobStartMinute : '00';
          this.jobEndMeridiem = this.jobStartMeridiem;
        }
      } else {
        //set default start times for creating
        this.jobStartHour = '08';
        this.jobStartMinute = '00';
        this.jobEndHour = '09';
        this.jobEndMinute = '00';
        this.jobStartMeridiem = 'AM';
        this.jobEndMeridiem = 'AM';
      }
    }
    this.setInitValues();
    this.processOrigRepeatingDates();
    // }

    if (this.timeFieldSetting === 'e') {
      this.checkTime();
    }
    this.setTimeArray();
  }

  setInitValues() {
    //set initial values to be sent back to server
    this.dayOfMonth = {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      11: false,
      12: false,
      13: false,
      14: false,
      15: false,
      16: false,
      17: false,
      18: false,
      19: false,
      20: false,
      21: false,
      22: false,
      23: false,
      24: false,
      25: false,
      26: false,
      27: false,
      28: false,
      29: false,
      30: false,
      31: false
    }

    this.dayOfWeek = {
      "sunday": false,
      "monday": false,
      "tuesday": false,
      "wednesday": false,
      "thursday": false,
      "friday": false,
      "saturday": false
    }

    if (!this.repeatingJSONData) {
      //if there's no repeating data, use defaults
      this.repeatingJSONData = {
        interval: 'irregular',
        intervalShowing: false,
        interval_unit: 1,
        enddate: moment(this.jobDate).format('YYYY-MM-DD'),
        dayOfWeek: this.dayOfWeek,
        dayOfWeekShowing: 'false',
        dayOfMonth: this.dayOfMonth,
        monthly: {
          type: 'each',
          dayOfMonth: "first",
          dayOfMonthShowing: false,
          dayOfWeek: "day",
          dayOfWeekShowing: false
        },
        visibleEndDate: moment(this.jobDate).toISOString(),
        visibleEndDateUpdated: false,
        startdate: moment(this.jobDate).toISOString()
      }
    }

    this.storageService.set('dayOfMonth', this.dayOfMonth);
    this.storageService.set('dayOfWeek', this.dayOfWeek);
  }


  processOrigRepeatingDates() {
    let tmpArr;
    this.repeatingJobs = [];
    //the desktop uses this data to display the dates in its calendar
    let datesObj = {}, jobDate, tmpRepeatingArr, enddate;
    if (this.storageService.get('repeatingJobs') && this.storageService.get('repeatingJobs').length) {
      //we have data from the repeating shift ui
      tmpArr = this.storageService.get('repeatingJobs');
      tmpArr.forEach(item => {
        this.repeatingJobs.push({ date: item });
      })
      this.jobDate = this.repeatingJobs[0]['date'];
    } else {
      //we have to get it from the server and process it
      if (this.job && (this.job['repeating_jobs'] && this.job['repeating_jobs'].length)) {
        this.job['repeating_jobs'].forEach(dateItem => {
          this.repeatingJobs.push({ date: dateItem });
        })
      }
    }
    //process dates object for server
    jobDate = moment(this.jobDate).format('YYYY/MM/DD');
    if (this.job && (this.job['repeating_jobs'])) {
      tmpRepeatingArr = this.job['repeating_jobs'];
      tmpRepeatingArr.forEach(item => {
        //some manipulation to make sure it's in the right format
        datesObj[moment(item).toISOString().slice(0, -5) + "Z"] = {
          date: moment(item).toISOString(),
          dateString: moment(item).toISOString().slice(0, -5) + "Z",
          selected: true
        }
        if (item === jobDate) {
          datesObj[moment(item).toISOString().slice(0, -5) + "Z"].disabled = true;
        }
      })
    } else {
      //no repeating job from server
      jobDate = moment().format('YYYY-MM-DD');
      datesObj[moment(jobDate).toISOString().slice(0, -5) + "Z"] = {
        date: moment(jobDate).toISOString(),
        dateString: moment(jobDate).toISOString().slice(0, -5) + "Z",
        selected: false
      }
      if (this.repeatingJSONData && this.repeatingJSONData['enddate']) {
        enddate = this.repeatingJSONData['enddate'];
        datesObj[moment(enddate).toISOString().slice(0, -5) + "Z"] = {
          date: moment(enddate).toISOString(),
          dateString: moment(enddate).toISOString().slice(0, -5) + "Z",
          selected: true,
          disabled: true
        }
      }
    }
    this.dates = datesObj;
    this.storageService.set('dates', this.dates);
    if (!this.storageService.get('originalRepeat')) {
      //was the repeating sequence previously removed?
      if (this.repeatingJobs) {
        if (this.creating && this.repeatingJobs.length === 1) {
          //when creating, show job date if its only one date
          this.repeatSummary = moment(this.repeatingJobs[0]['date']).format('MM-DD-YYYY');
        } else if (this.repeatingJobs.length > 1) {
          if (this.storageService.get('repeatSummary') !== null) {
            this.repeatSummary = this.storageService.get('repeatSummary');
          } else {
            this.repeatSummary = this.getSummaryText();
          }
        }
      }
    }
  }

  getSummaryText() {
    let jsonData = this.repeatingJSONData,
      repeatIntervalUnit = jsonData['interval_unit'],
      startDate: string,
      endDate: string,
      repeatInterval = jsonData['interval'],
      repeatDayOfWeek = jsonData['dayOfWeek'],
      repeatMonth = jsonData['monthly'],
      repeatDayOfWeekArr = [],  //making a copy cos we dont want to change the array
      repeatType: string,
      repeatSummary: string,
      repeatIntervalString: string,
      weeklyDates: string,
      weekOfMonth: string,
      weekDay: string,
      lastDay: string,
      endDateString: string,
      endString: string,
      tmpString: string = '';

    if (this.repeatingJobs.length) {
      startDate = this.repeatingJobs.length ? moment(this.repeatingJobs[0]['date']).format('MMM DD, YYYY') : null;
      endDate = this.repeatingJobs.length ? moment(this.repeatingJobs[this.repeatingJobs.length - 1]['date']).format('MMM DD, YYYY') : null;
    }

    for (var key in repeatDayOfWeek) {
      if (repeatDayOfWeek[key] === true) {
        //capitalize first letter and add selected days to array
        key = key.charAt(0).toUpperCase() + key.substr(1);
        repeatDayOfWeekArr.push(key);
      }
    }
    if (repeatInterval !== 'irregular') {
      //irregular repeat
      endDateString = ' from ' + startDate + ' until ' + endDate + '.';
      if (repeatIntervalUnit) {
        //no events will be generated since the interval is 0
        switch (repeatInterval) {
          case 'weekly':
            repeatType = ' weeks ';
            break;
          case 'monthly':
            repeatType = ' months ';
            break;
          case 'daily':
            repeatType = ' days ';
            break;
        }
        if (repeatIntervalUnit === 1) {
          repeatIntervalString = repeatInterval;
        } else {
          repeatIntervalString = 'every ' + repeatIntervalUnit + repeatType;
        }
        if (repeatInterval === 'weekly') {
          //weekly text addition
          if (repeatDayOfWeekArr.length) {
            if (repeatDayOfWeekArr.length > 1) {
              lastDay = repeatDayOfWeekArr.pop();
              weeklyDates = repeatDayOfWeekArr.join(', ') + ' and ' + lastDay;
            } else {
              weeklyDates = repeatDayOfWeekArr.join('');
            }
            tmpString = ' on ' + weeklyDates;
          }
        } else if (repeatInterval === 'monthly') {
          if (repeatMonth['type'] === 'on') {
            //text includes week of month, eg 1st Saturday, 2nd Sunday, etc
            weekOfMonth = repeatMonth['dayOfMonth'];
            weekDay = repeatMonth['dayOfMonth'];
            tmpString = ' on the ' + weekOfMonth + ' ' + weekDay + ' of the month ';
          } else {
            //text is on exact month date
            tmpString = '';
          }
        }
        endString = repeatIntervalString + tmpString + endDateString;
        repeatSummary = 'Repeats ' + endString;
      }
    } else {
      //irregular repeat
      if (this.job && (this.job['repeating_jobs'])) {
        repeatSummary = 'Repeats from a group of ' + this.job['repeating_jobs'].length + ' days';
      } else {
        repeatSummary = '';
      }
    }
    return repeatSummary;
  }


  defaultServerParams(data?) {
    let startTime, endTime;
    startTime = moment(this.jobStartHour + ":" + this.jobStartMinute + this.jobStartMeridiem, 'h:mma').toISOString();
    endTime = moment(this.jobEndHour + ":" + this.jobEndMinute + this.jobEndMeridiem, 'h:mma').toISOString();
    this.defaultServerValues = {
      selected_activity: this.activity['id'],
      date: this.undated ? '2038-01-01' : moment(this.jobDate).format('YYYY-MM-DD'),
      dates: this.dates,
      /*
      * timeFieldSetting:
      * What to display to the user:
      *  'a' = Anytime, so not displaying the time fields
      *  's' = Start Time Only, so only display the start time
      *  'e' = End Time Also, so display both start and end times
      */
      starthour: this.jobStartHour,
      startminute: this.jobStartMinute,
      endhour: this.jobEndHour,
      endminute: this.jobEndMinute,
      shifts: [],
      name: this.editJobForm.get('jobname').value,
      quantity: this.jobQuantity,
      jobtype: this.jobType,
      desc: this.editJobForm.get('editor').value,
      repeating: this.isRepeat ? true : false,
      repeatingError: null,
      repeating_description: this.serverSummary || '.',
      repeating_json: JSON.stringify(this.repeatingJSONData),
      include_assignments: false,
      currentShift: {},
      autofillShifts: [],
      repeatingShifts: [],
      visibleQuantity: this.jobQuantity,
      visibleDate: this.undated ? '2038-01-01' : moment(this.jobDate).toISOString(),
      fromTime: this.timeFieldSetting === 'e' || this.timeFieldSetting === 's' ? startTime : -1,
      toTime: this.timeFieldSetting === 'e' ? endTime : -1,
      timeFieldSetting: this.shiftData && this.shiftData.length ? 'e' : this.timeFieldSetting
    }
    if (this.job && this.job['id']) {
      this.defaultServerValues['id'] = this.job['id'];
    }
    if (data) {
      this.defaultServerValues['starthour'] = data['starthour'];
      this.defaultServerValues['startminute'] = data['startminute'];
      this.defaultServerValues['endhour'] = data['endhour'];
      this.defaultServerValues['endminute'] = data['endminute'];
    }
  }

  onUpdate(event) {
    this.numError = false;
    this.isChanged(true);
    if (typeof event == 'object' && event['isUnlimited'] === true) {
      //job quantity has been changed to unlimited
      this.jobQuantity = -1;
    } else {
      this.jobQuantity = event;
    }
    this.storageService.set('jobQuantity', this.jobQuantity);
  }

  onError(event) {
    this.numError = true;
  }

  setTimeArray() {
    //set hour array
    for (let i = 0; i <= 12; i++) {
      let newVal: string;
      if (i.toString().length === 1) {
        newVal = i.toString().padStart(2, "0");
        this.hourArr.push(newVal);
      } else {
        this.hourArr.push(i.toString());
      }
    }
    //set minute array
    for (let i = 0; i < 60; i += 5) {
      let newVal: string;
      if (i.toString().length === 1) {
        newVal = i.toString().padStart(2, "0");
        this.minuteArr.push(newVal);
      } else {
        this.minuteArr.push(i.toString());
      }
    }
  }

  formValueChanged() {
    //check for value changes
    this.editJobForm.valueChanges.subscribe(val => {
      this.isChanged(true);
      if (this.editJobForm.get('jobname').value) {
        this.showNameError = false;
      }
    })
  }

  isChanged(changed) {
    if (changed === true) {
      //set emitted value for the header when back icon is pressed
      this.changed.emit(true);
    }
  }

  getMaxLength(e) {
    if (e.editor.root.innerText.length > this.editorMaxLength) {
      e.editor.deleteText(this.editorMaxLength, e.editor.root.innerText.length);
      this.editorCount = this.editorMaxLength;
    } else {
      this.editorCount = e.editor.root.innerText.length;
    }
  }

  editorCreated(editor) {
    this.editorCount = editor.root.innerText.length;

  }

  setRepeat() {
    // save values in local storage so we dont lose our place
    this.saveValues();
    if (this.repeatEnabled) {
      this.storageService.set('originalRepeat', this.originalRepeat);  //we need to remember that the repeat sequence was removed.
    }
    this.router.navigateByUrl('edit_job_set_repeating');
  }

  saveValues() {
    let startHour, startMinute, endHour, endMinute, data;

    if (this.timeFieldSetting === 'a') {
      //use previously saved values from AT or FROM
      if (this.prevJobStartHour) {
        this.jobStartHour = this.prevJobStartHour;
        this.jobStartMinute = this.prevJobStartMinute;
        this.jobStartMeridiem = this.prevStartMeridiem;
        if (this.prevJobEndHour) {
          this.jobEndHour = this.prevJobEndHour;
          this.jobEndMinute = this.prevJobEndMinute;
          this.jobEndMeridiem = this.prevEndMeridiem;
        }
      } else {
        //use default values
        this.jobStartHour = this.jobStartHour === -1 ? '08' : this.jobStartHour;
        this.jobStartMinute = this.jobStartMinute === -1 ? '00' : this.jobStartMinute;
      }
      startHour = moment(this.jobStartHour + ":" + this.jobStartMinute + this.jobStartMeridiem, 'h:mma').format('HH');
      startMinute = this.jobStartMinute;
      endHour = moment(startHour, "hh").add(1, 'hours').format('HH') || '09';
      endMinute = startMinute || '00';
    } else if (this.timeFieldSetting === 's') {
      startHour = moment(this.jobStartHour + ":" + this.jobStartMinute + this.jobStartMeridiem, 'h:mma').format('HH');
      startMinute = this.jobStartMinute;
      endHour = moment(startHour, "hh").add(1, 'hours').format('HH') || '09';
      endMinute = startMinute || '00';
    } else {
      //time field setting is E
      startHour = moment(this.jobStartHour + ":" + this.jobStartMinute + this.jobStartMeridiem, 'h:mma').format('HH');
      startMinute = this.jobStartMinute;
      endHour = moment(this.jobEndHour + ":" + this.jobEndMinute + this.jobEndMeridiem, 'h:mma').format('HH');
      endMinute = this.jobEndMinute;
    }

    data = {
      starthour: startHour,
      startminute: startMinute,
      endhour: endHour,
      endminute: endMinute
    }

    this.storageService.set('jobName', this.editJobForm.get('jobname').value);
    this.storageService.set('jobDesc', this.editJobForm.get('editor').value);
    this.storageService.set('timeInterval', this.timeInterval, null);
    this.storageService.set('timeFieldSetting', this.timeFieldSetting, null);
    this.storageService.set('jobType', this.jobType);
    this.storageService.set('timeFieldSetting', this.timeFieldSetting);
    this.storageService.set('timeValues', {
      starthour: data.starthour,
      startminute: data.startminute,
      endhour: data.endhour,
      endminute: data.endminute
    })
  }

  toggleStartMeridiem() {
    if (this.jobStartMeridiem === 'AM') {
      this.jobStartMeridiem = 'PM';
      this.jobEndMeridiem = 'PM';
    } else {
      this.jobStartMeridiem = 'AM';
      this.jobEndMeridiem = 'AM'
    }
    if (this.timeFieldSetting === 'e') {
      this.checkTime();
    }
  }

  toggleEndMeridiem() {
    if (this.jobEndMeridiem === 'AM') {
      this.jobEndMeridiem = 'PM'
    } else {
      this.jobEndMeridiem = 'AM'
    }
    if (this.timeFieldSetting === 'e') {
      this.checkTime();
    }
  }

  setJobType(val) {
    this.isChanged(true);
    this.jobType = val;
  }

  checkTime() {
    let startTime = moment(this.jobStartHour + ":" + this.jobStartMinute + this.jobStartMeridiem, 'h:mma').format(),
      endTime = this.jobEndHour !== -1 ? moment(this.jobEndHour + ":" + this.jobEndMinute + this.jobEndMeridiem, 'h:mma').format() : null;
    if (endTime && moment(endTime).isBefore(moment(startTime))) {
      this.timeError = true;
    } else {
      this.timeError = false;
    }
  }

  setTimeInterval(val) {
    this.savePrevValues();
    if (val === 'at') {
      this.timeFieldSetting = 's';
      if (this.prevJobStartHour) {
        this.jobStartHour = this.prevJobStartHour;
        this.jobStartMinute = this.prevJobStartMinute;
        this.jobStartMeridiem = this.prevStartMeridiem;
      } else {
        this.jobStartHour = this.jobStartHour === -1 ? '08' : this.jobStartHour;
        this.jobStartMinute = this.jobStartMinute === -1 ? '00' : this.jobStartMinute;
        this.jobStartMeridiem = this.jobStartMeridiem || 'AM';
      }
      this.jobEndHour = -1;
      this.jobEndMinute = -1;
      this.timeError = false;
    } else if (val === 'anytime') {
      this.timeFieldSetting = 'a';

      // reset values
      this.jobStartHour = -1;
      this.jobStartMinute = -1;
      this.jobEndHour = -1;
      this.jobEndMinute = -1;
      this.jobStartMeridiem = 'AM';
      this.jobEndMeridiem = 'AM';
      this.timeError = false;
    } else {
      //val is from
      this.timeFieldSetting = 'e';
      if (this.prevJobStartHour) {
        this.jobStartHour = this.prevJobStartHour;
        this.jobStartMinute = this.prevJobStartMinute;
        this.jobStartMeridiem = this.prevStartMeridiem;
        if (this.prevJobEndHour) {
          this.jobEndHour = this.prevJobEndHour;
          this.jobEndMinute = this.prevJobEndMinute;
          this.jobEndMeridiem = this.prevEndMeridiem;
        } else {
          //you previously had start time but no end time
          this.jobEndHour = moment(this.jobStartHour, 'hh').add(1, 'hours').format('hh');
          this.jobEndMinute = this.jobStartMinute;
          this.jobEndMeridiem = this.jobStartMeridiem;
        }
      } else {
        this.jobStartHour = this.jobStartHour === -1 ? '08' : this.jobStartHour;
        this.jobStartMinute = this.jobStartMinute === -1 ? '00' : this.jobStartMinute;
        this.jobStartMeridiem = this.jobStartMeridiem || 'AM';
        this.jobEndHour = this.jobEndHour === -1 ? moment(this.jobStartHour, 'hh').add(1, 'hours').format('hh') : this.jobEndHour;
        this.jobEndMinute = this.jobEndMinute === -1 ? this.jobStartMinute : this.jobEndMinute;
        this.jobEndMeridiem = this.jobEndMeridiem || this.jobStartMeridiem;
        //special case
        if (this.jobStartHour === '11' && this.jobStartMeridiem === 'AM') {
          this.jobEndMeridiem = 'PM';
        }
      }

      this.checkTime();
    }
    this.editJobForm.patchValue({
      startHour: this.jobStartHour,
      startMinute: this.jobStartMinute,
      endHour: this.jobEndHour,
      endMinute: this.jobEndMinute
    })
    this.timeInterval = val;
    this.isChanged(true);
  }

  savePrevValues() {
    if (this.jobStartHour !== -1) {
      this.prevJobStartHour = this.jobStartHour;
      this.prevJobStartMinute = this.jobStartMinute;
      this.prevStartMeridiem = this.jobStartMeridiem;
    }
    if (this.jobEndHour !== -1) {
      this.prevJobEndHour = this.jobEndHour;
      this.prevJobEndMinute = this.jobEndMinute;
      this.prevEndMeridiem = this.jobEndMeridiem;
    }
  }

  setTimeValues(shift, val) {
    if (shift === 'startHour') {
      this.jobStartHour = val;
      if (this.timeFieldSetting === 'e') {
        this.jobEndHour = moment(this.jobStartHour, 'hh').add(1, 'hours').format('hh')
        this.jobEndMeridiem = this.jobStartMeridiem;
        if (this.jobStartHour === '11' && this.jobStartMeridiem === 'AM') {
          this.jobEndMeridiem = 'PM';
        }
        this.editJobForm.patchValue({ endHour: this.jobEndHour });

      }
    } else if (shift === 'startMinute') {
      this.jobStartMinute = val;
    } else if (shift === 'endHour') {
      this.jobEndHour = val;
    } else {
      //shift is end minute
      this.jobEndMinute = val;
    }
    if (this.timeFieldSetting === 'e') {
      this.checkTime();
    }
    //remove previous saved values
    this.prevJobStartHour = null;
    this.prevJobStartMinute = null;
    this.prevStartMeridiem = null;
    this.prevJobEndHour = null;
    this.prevJobEndMinute = null;
    this.prevEndMeridiem = null;
  }

  setShifts() {
    this.saveValues();
    this.router.navigateByUrl('edit_spot_shifts');
  }

  removeSummary(type) {
    let msg, qty,
      repeatAdded = this.storageService.get('repeatAdded');
    if (type === "shift") {
      msg = "_DeleteJobsModalBody_";
      qty = this.shiftQty;
    } else {
      if (repeatAdded) {
        msg = "_DeleteRepeatingModalBody_";
      } else {
        msg = "_DeleteJobsModalBody_";
        qty = this.repeatingJobs.length;
        ;
      }
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      autoFocus: false,
      data: {
        isConfirm: true,  //confirm dialog
        isContinue: true,
        buttonText: "_Yes_",
        buttonCancelText: "_No_",
        title: "_DeleteJobsModalTitle_",
        message: msg,
        type: type,
        params: {
          quantity: qty
        }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == "continue") {
        this.isChanged(true);
        //remove quick shift data
        this.storageService.remove('quickfillShifts');
        this.shiftData = [];
        if (type === 'repeat') {
          this.storageService.set('repeatSummary', null, null);
          this.repeatSummary = null;
          this.originalRepeat = true;
          if (this.creating) {
            this.jobDate = null;
          }
          //remove local storage repeating values
          this.storageService.removeMultiple(['irregularDaysSelected', 'repeatingJobs', 'repeatingJSONData', 'originalRepeat']);
        } else {
          //type is shift
          this.storageService.set('shiftSummary', null, null);
          this.shiftSummary = null;
          // initialize time form
          this.initTime();
        }
      }
    })
  }

  onSubmit(forceChange?) {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!this.editJobForm.get('jobname').value) {
      //no job title, show warning
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.showNameError = true;
    } else if (this.activity['kind'] === 'dated' && !this.jobDate && !this.repeatSummary) {
      //no job date, show warning
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.showDateError = true;
    } else {
      this.showDateError = false;
      this.showNameError = false;
      this.submitted = true;
      this.loading = true;
      if (this.job) {
        if (this.job['repeating'] && this.job['repeating_jobs'] && this.job['repeating_jobs'].length > 1) {
          //show prompt if original job is repeated
          const dialogRef = this.dialog.open(EditConfDialogComponent, {
            width: '80%',
            hasBackdrop: true,
            autoFocus: false,
            data: {
              type: "edit",
              force: forceChange || null
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              if (forceChange) {
                //user forced changes, call api calls again
                let newData = { ...{ force: true }, ...this.jobSaveQueue }
                this.updateJob(newData, result['saveInstance']);
              } else {
                this.processSave(result['saveInstance']);
              }
            }
          })
        } else {
          //just save
          this.processSave();
        }
      } else {
        //create a new job
        this.processSave();
      }
    }
  }

  processSave(instance?) {
    let data, lastIdx: boolean = false;
    //set time values for 'from' and 'at'
    if (this.timeFieldSetting === 'a') {
      this.jobStartHour = -1;
      this.jobStartMinute = -1;
      this.jobEndHour = -1;
      this.jobEndMinute = -1;
    } else if (this.timeFieldSetting === 's') {
      this.jobEndHour = -1;
      this.jobEndMinute = -1;
    }
    //make sure start and end hours are in 24hr format

    if (this.jobStartHour !== -1) {
      this.jobStartHour = moment(this.jobStartHour + ":" + this.jobStartMinute + this.jobStartMeridiem, 'h:mma').format('HH');
    }
    if (this.jobEndHour !== -1) {
      this.jobEndHour = moment(this.jobEndHour + ":" + this.jobEndMinute + this.jobEndMeridiem, 'h:mma').format('HH');
    }

    this.defaultServerParams({
      starthour: this.jobStartHour,
      startminute: this.jobStartMinute,
      endhour: this.jobEndHour,
      endminute: this.jobEndMinute
    });
    data = this.defaultServerValues;
    if (this.isUnlimited) {
      data['noDate'] = true;
    }
    if (this.isRepeat) {
      //add these params to repeating jobs
      data['shifts'] = this.repeatingJobs;
      data['update_future'] = instance && instance === 'future' ? true : false;
      data['update_all'] = false;
      if (this.repeatingJSONData && this.repeatingJSONData['interval'] !== 'irregular') {
        data['repeatingShifts'] = this.repeatingJobs;
      }
      if (this.job && this.job['repeating']) {
        //job already has repeating data from server
        if (this.originalRepeat) {
          //was originally repeat but repeat data has been removed
          let parsedData = JSON.parse(data['repeating_json']);
          data['update_future'] = false;
          data['update_all'] = instance && instance === 'future' ? true : false;
          data['repeating'] = false;
          parsedData['enddate'] = moment(this.jobDate).format('YYYY-MM-DD'); //change the repeating json end date
          parsedData['visibleEndDate'] = moment(this.jobDate).toISOString();
          data['visibleDate'] = parsedData['visibleEndDate'];
          //stringify data
          this.repeatingJSONData = JSON.stringify(parsedData);
          data['repeating_json'] = this.repeatingJSONData;
          // replace shift data with just the job date since repeating data has been removed
          data['shifts'] = [];
          data['shifts'].push({ date: moment(this.jobDate).format('YYYY/MM/DD') });
          this.updateJob(data, instance);
        } else {
          //data is repeating, proceed as usual
          this.updateJob(data, instance);
        }
      } else {
        //we are adding a fresh repeating sequence
        if (this.shiftData.length) {
          //we are adding a repeat and a shift
          this.shiftData.forEach((item, idx, arr) => {
            this.defaultServerParams(item);
            data = this.defaultServerValues;
            data['shifts'] = this.repeatingJobs;
            data['repeatingShifts'] = this.repeatingJobs;
            data['update_future'] = false;
            data['update_all'] = false;
            delete data['id'];  //use existing id instead
            if (this.job && this.job['id']) {
              data['existing_job_id'] = this.job['id'];
            }
            if (idx === arr.length - 1) {
              //last element in arr
              lastIdx = true;
            }
            this.saveJob(data, lastIdx);
          })
        } else {
          //no shifts, but adding repeat
          delete data['id'];  //use existing id instead
          if (this.job && this.job['id']) {
            data['existing_job_id'] = this.job['id'];
          }
          this.saveJob(data, true);
        }
      }
    } else {
      //job is not repeating, shift parameter is different
      this.processShiftParam(data);
      if (this.shiftData.length) {
        //we are adding a shift
        this.processShifts(data, instance);
      } else {
        //no shift or repeat, but job got updated
        data['quickfillShifts'] = data['shifts'];
        data['repeatingPattern'] = this.repeatingJSONData;
        if (this.job) {
          this.updateJob(data);
        } else {
          //we are creating
          this.saveJob(data, true);
        }
      }
    }
  }

  processShifts(data, instance) {
    //process first shift separately because the job pattern is updated
    let shiftData, repeatingJSONData, tempShiftArray = [], firstShift, order = 2,
      startTime = moment(this.jobStartHour + ":" + this.jobStartMinute + this.jobStartMeridiem, 'h:mma').toISOString(),
      endTime = moment(this.jobEndHour + ":" + this.jobEndMinute + this.jobEndMeridiem, 'h:mma').toISOString();
    firstShift = this.shiftData[0];
    data['starthour'] = firstShift['starthour'];
    data['startminute'] = firstShift['startminute'];
    data['endhour'] = firstShift['endhour'];
    data['endminute'] = firstShift['endminute'];
    data['repeatingPattern'] = this.repeatingJSONData;
    data['quickfillShifts'] = [];
    this.shiftData.forEach((shift, idx) => {
      tempShiftArray.push({
        name: this.editJobForm.get('jobname').value,
        jobtype: this.jobType,
        quantity: this.jobQuantity,
        visibleQuantity: this.jobQuantity,
        desc: this.editJobForm.get('editor').value,
        timeFieldSetting: "e",
        fromTime: startTime,
        starthour: shift['starthour'],
        startminute: shift['startminute'],
        toTime: endTime,
        endhour: shift['endhour'],
        endminute: shift['endminute'],
        count: this.shiftData.length,
        isLast: idx === this.shiftData.length - 1 ? true : false,
        shiftGroupId: 1,
        orderBy: order++  //increment by 1 (for some reason, it starts from 2 on desktop, so we'll go with that so as not to cause conflicts)
      })
      // console.log('tempShiftArray ', tempShiftArray)
    })
    data['quickfillShifts'] = tempShiftArray;

    //process shift param
    // this.processShiftParam(data);
    shiftData = this.shiftData;
    repeatingJSONData = this.repeatingJSONData;
    if (this.creating) {
      this.createNewShifts(data);
    } else {
      //we are editing
      this.updateJob(data, instance, () => {
        this.createNewShifts(data, instance);
      });
    }
  }

  createNewShifts(data, instance?) {
    let lastIdx: boolean = false;
    if (this.editing) {
      //remove first element when editing
      this.shiftData.shift();
    }
    //remove id
    delete data['id'];
    //process the rest of the shifts
    this.shiftData.forEach((item, idx, arr) => {
      data['starthour'] = item['starthour'];
      data['startminute'] = item['startminute'];
      data['endhour'] = item['endhour'];
      data['endminute'] = item['endminute'];
      data['repeating_json'] = this.repeatingJSONData;
      this.processShiftParam(data);
      if (idx === arr.length - 1) {
        //last element in arr
        lastIdx = true;
      }
      this.saveJob(data, lastIdx);
    })
  }

  processShiftParam(data) {
    let tmpShift = {
      name: this.editJobForm.get('jobname').value,
      jobtype: this.jobType,
      quantity: this.jobQuantity,
      visibleQuantity: this.jobQuantity,
      starthour: data['starthour'],
      startminute: data['startminute'],
      endhour: data['endhour'],
      endminute: data['endminute']
    }
    data['shifts'] = [];
    data['shifts'].push(tmpShift);
  }

  saveJob(data, last) {
    this.jobService.saveJob(data).subscribe(result => {
      if (result.body['status'] && !this.showError) {
        // only proceed if there's no error
        this.storageService.set('jobToExpand', result.body['job_id'], null);
        if (last) {
          //last item in array, clean local storage and go to specified page
          this.cleanUpLocalStorage();
        }
      }
    })
  }

  updateJob(data, instance?, callback?) {
    this.jobSaveQueue = this.jobSaveQueue || data;
    this.jobService.updateJob(this.job['id'], data).subscribe(result => {
      if (result.body['status']) {
        if (instance) {
          if (instance === 'one') {
            //cleanup and go back
            this.cleanUpLocalStorage();
          } else if (instance === 'future') {
            if (this.storageService.get('repeatingJSONData') || this.originalRepeat) {
              //we changed or removed the repeat pattern, call update repeating api
              this.updateRepeatingJob(data);
            } else {
              if (result.body['show_message']) {
                //job was force changed, show conf message to user
                this.showCloseBtn = true;
                this.confMessage = result.body['code'];
                this.confMessageParam = { '0': result.body['show_params'][0] };
              } else {
                //clean local storage and go back
                this.cleanUpLocalStorage();
              }
            }
          }
        } else {
          //clean local storage and go back
          this.cleanUpLocalStorage();
        }
        this.showError = false;
      } else if (result.body['force_required']) {
        //error code, save data first
        if (result['code'] === "_ORG_JOBS_UPDATE_JOBS_") {
          //job assignment will be updated, show alert
          this.onSubmit('updateJob');
        }
      } else if (result.body['code'] === "_ORG_JOBS_ID_INVALID_") {
        // //job was not found, maybe deleted on desktop. Go to job list page
        this.router.navigateByUrl('edit/spot/' + this.activity['id']);
      } else if (result.body['code'] === "_ORG_JOBS_ALREADY_SIGNED_UP_" || "_ORG_REPEATING_JOBS_ALREADY_SIGNED_UP_") {
        //you cannot enter a value for qty less than num of spots already assigned, resubmit if user changes qty
        this.showError = true;
        this.loading = false;
        this.submitted = false;
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        this.errorMessage = result.body['code'];
      }
      if (callback && !this.showError) {
        //process the rest of the shifts
        callback();
      }
    })
  }

  updateRepeatingJob(data) {
    let repeatingScheduleData;
    if (this.repeatingScheduleQueue) {
      //we previously saved the value because of a force change
      repeatingScheduleData = { ...{ force: true }, ...this.repeatingScheduleQueue }
    } else {
      repeatingScheduleData = {
        selected_activity: this.activity['id'],
        shifts: data['shifts'],
        repeating_description: this.serverSummary,
        repeating_json: JSON.stringify(this.repeatingJSONData),
        id: this.job['id']
      }
    }

    this.jobService.saveRepeatingSchedule(repeatingScheduleData).subscribe(result => {
      if (result) {
        if (result.body['status']) {
          this.showCloseBtn = true;
          if (result.body['show_message']) {
            //job was force changed, show confirmation message to user
            this.confMessage = result.body['code'];
            this.confMessageParam = { '0': result.body['show_params'][0] };

          } else {
            //clean up local storage and go back
            this.cleanUpLocalStorage();
          }
        } else {
          //save data for later
          this.repeatingScheduleQueue = repeatingScheduleData;
          // force change, open modal again
          if (result.body['force_required']) {
            //show alert again
            this.onSubmit('updateRepeating');
          }
        }
      }
    })
  }

  repeatPatternConf() {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      data: {
        isSuccess: true,    //success dialog
        title: 'Notice',
        message: 'SaveOnlyDoesntUpdateRepeating'
      }
    });
  }

  cancelChanges() {
    this.cleanUpLocalStorage();
  }

  cleanUpLocalStorage() {
    let itemsArr = ['timeValues', 'quickfillShifts', 'repeatingJobs', 'origRepeatingJobs', 'timeInterval', 'irregularDaysSelected', 'jobQuantity', 'originalRepeat', 'repeatingJSONData', 'repeatAdded', 'shiftQty'],
      route = this.router, activity = this.activity, job = this.job;
    this.storageService.removeMultiple(itemsArr, () => {
      //go back to list of jobs
      if (this.goToCreatePage) {
        this.storageService.set('jobPageVisited', true);
        route.navigateByUrl('create');
      } else if (job) {
        route.navigateByUrl('edit/spot/' + activity['id']);
      } else {
        window.history.back();
      }
    })
  }
}
