import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../../shared-services/event.service';
import { StorageService } from '../../../shared-services/storage.service';
import { VolunteerService } from '../../../shared-services/volunteer.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

export interface User {
  name: string;
  email: string;
}


@Component({
  selector: 'app-recipient-filter',
  templateUrl: './recipient-filter.component.html',
  styleUrls: ['./recipient-filter.component.scss']
})
export class RecipientFilterComponent implements OnInit {

  activity: {};
  eventData: {};
  participants = [];
  volunteerData: {};
  dated:boolean = true;
  selected: any[] = [];
  selectedArr: string[] = [];
  filteredData = [];
  isFiltered: boolean = false;
  recipientDataSource: MatTableDataSource<User>;
  selection = new SelectionModel<User>(true, []);
  displayedColumns: string[] = ['select', 'contactname'];

  constructor(private router: Router,
    private volunteerService: VolunteerService,
    private storageService: StorageService,
    private eventService: EventService) { }

  ngOnInit() {
    this.activity = this.storageService.get('activity');
    this.dated = this.activity['kind'] === 'dated'? true : false;

    if(this.storageService.get('eventData')) {
      this.eventData = this.storageService.get('eventData');
    } 
    if(this.storageService.get('volunteerData')) {
      this.participants = this.storageService.get('volunteerData');
    }

    if (this.storageService.get('selectedParticipants')) {
      this.selected = this.storageService.get('selectedParticipants');
      this.recipientDataSource = new MatTableDataSource<User>(this.selected);
      this.masterToggle(this.selection);
    }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    let numSelected = this.selection.selected.length;
    let numRows = this.recipientDataSource.data.length;
    if (this.isFiltered && this.selection.selected.length && (this.filteredData.length === this.selection.selected.length)) {
      //data has been filtered and all filtered items were selected
      return true;
    } else {
      return numSelected === numRows;
    }
  }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(item) {
      if (this.isAllSelected()) {
        this.selection.clear();
        this.selectedArr = [];
      } else {
        if (this.isFiltered) {
          //if data is filtered, only add filtered data
          this.recipientDataSource.filteredData.forEach(row => this.selection.select(row));
          this.filteredData = this.recipientDataSource.filteredData;
          this.selectedArr = this.filteredData;
        } else {
          //add all items
          this.recipientDataSource.data.forEach(row => this.selection.select(row));
          this.selectedArr = item.selected;
        }
      }
      this.saveSelection();
    }
  
    showSelection(item) {
      let idx = this.selectedArr.findIndex(elem => {
        return elem['id'] === item['id']
      });
      if (idx !== -1) {
        //remove item from list
        this.selectedArr.splice(idx, 1);
      } else {
        //add item to list
        this.selectedArr.push(item);
      }
      this.saveSelection();
    }

  applyFilter(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    this.recipientDataSource.filter = filterValue.trim().toLowerCase();
    if (this.recipientDataSource.filteredData.length < this.recipientDataSource.data.length) {
      this.isFiltered = true;
    } else {
      this.isFiltered = false;
    }
    this.filteredData = this.recipientDataSource.filteredData;
  }

  toggleSelect(event, participant) {
    let idx;
    if (event.checked) {
      this.selectedArr.push(participant);
    } else {
      idx = this.selectedArr.findIndex(item => {
        return participant['id'] === item['id']
      })
      if (idx !== null) {
        this.selectedArr.splice(idx, 1);
      }
    }
    this.saveSelection();
  }

  selectAll(event) {
    event.stopPropagation();
  }

  saveSelection() {
    this.storageService.set('selectedParticipants', this.selectedArr);
  }

  goNext() {
    window.history.back();

    // this.router.navigateByUrl('messages');
  }
}
