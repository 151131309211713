import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EditEventsService {

  constructor(private http: HttpClient,
    @Inject('BASE_API_URL') private apiURL:string) { }

  sendMessage(inviteList, message, name, recipientIds, activityId, subject) {
    var data = {
      'inviteList': inviteList,
      'message': message,
      'messagetype': 0, //for msg form
      'name': name,
      'recipients': [recipientIds],
      'selected_activity': activityId,
      'subject': subject
   }
   return this.http.post(this.apiURL + '/api/messages/sendmessage', data, {observe: 'response'});
  }
}