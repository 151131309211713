import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../shared-services/storage.service';
import { EventService } from '../../../shared-services/event.service';
import { VolunteerService } from '../../../shared-services/volunteer.service';
import { Router } from "@angular/router";
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
// import { iterateListLike } from '@angular/core/src/change_detection/change_detection_util';
// import { IterableChangeRecord_ } from '@angular/core/src/change_detection/differs/default_iterable_differ';

@Component({
  selector: 'app-recipient-filter-by-date',
  templateUrl: './recipient-filter-by-date.component.html',
  styleUrls: ['./recipient-filter-by-date.component.scss']
})
export class RecipientFilterByDateComponent implements OnInit {

  constructor(private router: Router,
    private volunteerService: VolunteerService,
    private storageService: StorageService,
    private eventService: EventService) { }

  activity: {};
  volunteerId: {};
  participants: any[] = [];
  title: string;
  events: {};
  monthData: {};
  monthKeys: any[] = [];
  loading: boolean = true;
  selectedArr: any[] = [];

  ngOnInit() {
    this.activity = this.storageService.get('activity');
    this.title = this.activity['title'];
    this.selectedArr = this.storageService.get('selectedParticipants') || [];
    let participantsList;

    if (this.storageService.get('volunteerData')) {
      this.participants = this.storageService.get('volunteerData');
      this.storageService.set('volunteerData', this.participants);
      this.getEventData();
    } else {
      //data is not in local storage, call from server
      this.volunteerService.get(this.activity['id']).subscribe(res => {
        if (res) {
          participantsList = res.body['data'];
          participantsList.forEach(item => {
            if (item.email) {
              this.participants.push(item); 
            }
          })
          this.getEventData();
        }
      })
    }
  }

  getEventData() {
    if (this.storageService.get('eventData')) {
      this.events = this.storageService.get('eventData');
      this.processEventData(this.events);
    } else {
      this.eventService.get(this.activity['id'], true, 'only_assigned').subscribe(
        response => {
          this.events = response['body']['data'];
          this.storageService.set('eventData', this.events);
          this.processEventData(this.events);
        }
      )
    }
  }

  processEventData(events) {
    let participantCount = 0, monthKeysArr = [], assignments = [],idx, volData;
    this.monthData = events;
    monthKeysArr = Object.keys(events);
    monthKeysArr.forEach(item => {
      this.monthData[item].forEach(month => {
        month['jobs'].forEach(job => {
          job['jobassignments'].forEach(participant => {
            //check items already in selected array
            if (this.selectedArr.some(x =>(x.id || x.volunteer_id) === participant.volunteer_id)) {
              participant.checked = true;
            }
            //match participants with emails
            idx = assignments.findIndex(x => x.volunteer_id === participant.volunteer_id);
            volData = this.participants.find(x => x.id === participant.volunteer_id);
              if (idx === -1 && volData) {
              assignments.push ({
                volunteer_id: participant['volunteer_id'], 
                who: participant['who'],
                email: volData['email'],
                job_name: job['name'],
                checked: participant['checked'] == true ? true : false
              })
            }
          })
          month.assignments = assignments;
          participantCount += assignments.length;
          month.totalParticipants = participantCount;
          participantCount = 0;

         })
        //  reset
        assignments = [];
      })
      this.monthKeys.push({
        date: item,
        checked: false
      })
    })
    this.loading = false;
  }

  getTotal(key) {
    let totalCount = 0;
    this.monthData[key].forEach(month => {
        totalCount += month.totalParticipants;
    })
    return totalCount;
  }

  selectAllCheckboxes() {
    this.monthKeys.forEach(key => {
      key.checked = true;
      this.checkAllEvents(key);
    })
    this.saveSelection();
  }

  clearAllCheckboxes() {
    this.monthKeys.forEach(key => {
      key.checked = false;
      this.unCheckAllEvents(key);
    })
    this.saveSelection();
  }

  selectAllEvents(event, key) {
    if (event.checked) {
      this.checkAllEvents(key);
    } else {
      this.unCheckAllEvents(key)
    }
  }

  checkAllEvents(key) {
    key.checked = true;
    this.monthData[key.date].forEach(month => {
      month.checked = true;
        month['assignments'].forEach(item => {
          item.checked = true;
          if (this.selectedArr.length) {
            if (!this.selectedArr.some(x => (x.id || x.volunteer_id) === item.volunteer_id)) {
              this.selectedArr.push(item);
            }
          } else {
            this.selectedArr.push(item);
          }
        })
    })
  }

  unCheckAllEvents(key) {
    let idx;
    key.checked = false;
    this.monthData[key.date].forEach(month => {
      month.checked = false;
        month['assignments'].forEach(item => {
          item.checked = false;
          idx = this.selectedArr.findIndex(x => (x.id || x.volunteer_id) === item.volunteer_id);
          if (idx != -1) {
            //found the index, now remove from array
            this.selectedArr.splice(idx, 1);
          }
        })
    })
  }

  selectAllMonths(event, month, key) {
    if (event.checked) {
      this.checkMonth(month, key);
    } else {
      this.unCheckMonth(month, key);
    }
    this.isParticipantChecked(event);
    this.areAllMonthsChecked(key);
  }

  checkMonth(month, key) {
    let idx;
    month.checked = true;
    key.checked = true;
      month['assignments'].forEach(item => {
        item.checked = true;
        idx = this.selectedArr.findIndex(x => (x.id || x.volunteer_id) === item.volunteer_id);
        if (idx === -1) {
          this.selectedArr.push(item);
        }
      })
      // this.areAllMonthsChecked(key);
  }

  unCheckMonth(month, key) {
    let idx;
    month.checked = false;
    key.checked = false;
    month['assignments'].forEach(item => {
      item.checked = false;
      idx = this.selectedArr.findIndex(x => (x.id || x.volunteer_id) === item.volunteer_id);
      if (idx != -1) {
        //found the index, now remove from array
        this.selectedArr.splice(idx, 1);
      }
    })
  }

  select(event, job, month, key) {
    let idx;
    if (this.selectedArr.length) {
      idx = this.selectedArr.findIndex(x => (x.id || x.volunteer_id) === job.volunteer_id);
      if (event.checked) {
        if (idx === -1) {
          job.checked = true;
          this.selectedArr.push(job);
        }
      } else {
        if (idx != -1) {
          //found the index, remove from array
          this.selectedArr.splice(idx, 1);
          job.checked = false;
          month.checked = false;
          key.checked = false;
        }
      }
    } else {
      // the selected array is empty, no need to iterate
      if (event.checked) {
        job.checked = true;
        this.selectedArr.push(job);
      }
    }
    this.isParticipantChecked(event, job);
    this.areAllJobsChecked(month, key);
  }

  areAllMonthsChecked(key) {
    let allSelected = this.monthData[key.date].every(x=> x.checked);

      if (allSelected) {
        key.checked = true;
      } else {
        key.checked = false;
      }
  }

  areAllJobsChecked(month, key) {
    let allSelected = month['assignments'].every(x=> x.checked);
    if (allSelected) {
      month.checked = true;
    } else {
      month.checked = false;
    }
    this.areAllMonthsChecked(key);
  }

  isParticipantChecked(event, vol?) {
    let idx;
    Object.keys(this.monthData).forEach(item => {
      this.monthData[item].forEach(month => {
        // month['jobs'].forEach(job => {
          month['assignments'].forEach(item => {
            if (vol) {
              if (item.volunteer_id === vol.volunteer_id) {
                if (event.checked === true) {
                  //uncheck other instances
                  item.checked = true;
                } else {
                  //check other instances
                  item.checked = false;
                }
              }
            } else {
              idx = this.selectedArr.findIndex(x => (x.id || x.volunteer_id) === item.volunteer_id);
              if (idx != -1) {
                // found the index
                if (event.checked === true) {
                  //uncheck other instances
                  item.checked = true;
                } else {
                  //check other instances
                  item.checked = false;
                }
              }
            }
          })
      })
    })
    this.saveSelection();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  saveSelection() {
    this.storageService.set('selectedParticipants', this.selectedArr);
  }

  goNext() {
    //go back to the recipients filter page with the buttons
    // this.router.navigateByUrl('recipient-filter');
    this.storageService.set('selectedParticipants', this.selectedArr);
    window.history.back();
  }
}
