import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AlertDialogComponent } from './../../shared/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { StorageService } from '../../shared-services/storage.service';
import { ActivityService } from './../../shared-services/activity.service';
import { forkJoin } from 'rxjs';
import { Router } from "@angular/router";

@Component({
  selector: 'app-edit-organizer-info',
  templateUrl: './edit-organizer-info.component.html',
  styleUrls: ['./edit-organizer-info.component.scss']
})
export class EditOrganizerInfoComponent implements OnInit {

  editOrganizerForm: FormGroup;
  changedVal: boolean = false;

  activity: {};
  user: {};
  orgEmail: string;
  orgName: string;
  primaryOrg: {};
  pryOrgChanged: boolean = false;
  primaryOrgEmail: string;
  primaryOrgName: string;
  maxOrganizer: number;   //number of assistant organizers allowed
  primaryOrgArr: string[] = [];  //array to hold all organizers
  newAsstOrgArr: string[] = [];  //array to hold newly added assistant organizers
  saveQueue: string[] = [];
  isPrimary: boolean = false;  //is logged in user the primary organizer?
  orderByCount = 1;
  orgPhone: string;
  orgTitle: string;
  removeMyself: boolean = false;   //flag to check if currently signed in user removed themselves

  constructor(private storageService: StorageService,
    private activityService: ActivityService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router:Router) { }

  ngOnInit() {
    //get user
    this.user = this.storageService.get('user');
    //get number of assistant organizers allowed
    if (this.user) {
      this.maxOrganizer = this.user['premium_data'] ? this.user['premium_data'].features[2].count + 1 : null;
      //add 1 because I am adding the primary organizer here
    }

    //get primary organizer
    this.activity = this.storageService.get('activity');
    this.primaryOrgArr = this.storageService.get('organizersArr');
    this.primaryOrgArr.map(item => {
      if (item['primary'] == true) {
        this.primaryOrg = item;
      }
    }) 

    this.orgName = this.activity['orgname'];
    this.orgEmail = this.activity['org_email'];
    this.orgPhone = this.activity['orgphonenumber'];
    this.orgTitle = this.activity['orgtitle'];

    this.editOrganizerForm = this.formBuilder.group({
      primaryOrganizer: new FormControl(this.primaryOrg['id'])
    })

    // disable dropdown if primary organizer is not logged in user
    if (this.user['id'] === this.primaryOrg['user_id']) {
      this.isPrimary = true;
      this.enableOrDisableDropdown();
    } else {
      this.isPrimary = false;
      this.editOrganizerForm.controls['primaryOrganizer'].disable();
    }
    this.formValueChanged();
  }

  enableOrDisableDropdown() {
    if (this.primaryOrgArr.length > 1 || this.newAsstOrgArr.length) {
      //if there is only one primary organizer, disable select option
      this.editOrganizerForm.controls['primaryOrganizer'].enable();
    } else {
      this.editOrganizerForm.controls['primaryOrganizer'].disable();
    }
  }

  openFieldDialog( whichArr:string, item?:{}, idx?:number) {
    //open dialog box to add or edit assistant organizers
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      autoFocus: false,
      panelClass: 'orgform-dialog-container',
      data: {
        isOrgForm: true,  //organizer form dialog
        isEdit: whichArr === "pryOrgArray" || whichArr === "currentPryOrg" ? true : false,
        org_email: item && item['email'] ? item['email'] : null,
        org_name: item && item['name'] ? item['name'] : null,
        org_phone: item && item['phone'] ? item['phone'] : null,
        org_title: item && item['title'] ? item['title'] : null
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'closed') {
        let itemIdx;
        this.changedVal = true;   //value has been changed
        if (whichArr === 'currentPryOrg') {
          itemIdx = this.primaryOrgArr.findIndex(obj => {
            return obj['email'] === result.email;
          })
        } else {
          itemIdx = idx;
        }
        this.processChanges(whichArr, result, itemIdx);
      }
    });
  }

  processChanges(whichArr, result, idx?) {
    let changedArr:string[] = [], 
        editedItem,
        newItem,
        isPryOrganizer: boolean = false,
        newOrg: boolean = false;
      if (whichArr === 'currentPryOrg') {
        changedArr = this.primaryOrgArr;
        isPryOrganizer = true;
      } else if (whichArr === 'pryOrgArray') {
        changedArr = this.primaryOrgArr;
      } else if (whichArr === 'asstOrgArray') {
        changedArr = this.newAsstOrgArr;
      } else {
        newOrg = true;
      }

      if (!newOrg) {
        // we are editing a current organizer
        editedItem = changedArr[idx];
        //add updated values
        editedItem['email'] = result.email;
        editedItem['name'] = result.name;
        editedItem['phone'] = result.phone;
        editedItem['title'] = result.title;
        editedItem['changed'] = true;
        changedArr.splice(idx, 1, editedItem);
        if (isPryOrganizer) {
          this.primaryOrg = editedItem;
        }
        //item was edited, add to save queue
        this.saveQueue.push(editedItem);
      } else {
        //this is a newly added organizer
        newItem = {
          'email': result.email.toLowerCase(),
          'name': result.name,
          'phone': result.phone,
          'primary': false,
          'selected_activity': this.activity['id'],
          'title': result.title,
          'validated': false
        }
        this.newAsstOrgArr.push(newItem);
        this.enableOrDisableDropdown();
      }
  }

  removeOrganizer(item, idx) {
    if (item['id']) {
      //this is a previously saved organizer, show alert
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '80%',
        hasBackdrop: true,
        autoFocus: false,
        data: {
          isConfirm: true,  //confirm dialog
          isContinue: true,
          buttonText: "_Yes_",
          buttonCancelText: "_No_",
          title: "_RemoveOrganizerHeader_",
          message: "_RemoveOrganizerBody_",
          params: {
            email: item['email'],
            name: item['name']
          }
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result === 'continue') {
          this.activityService.deleteOrganizer(item['org_id'], this.activity['id']).subscribe(
            response => {
              if (response.status) {
                this.primaryOrgArr.splice(idx, 1);
                // remove from save queue
                let itemIdx = this.saveQueue.findIndex(obj => {
                  return item['org_id'] === obj['org_id']
                })
                if (itemIdx !== null) {
                  this.saveQueue.splice(itemIdx, 1);
                }
              }
            }
          )
        }
      }) 
      if (this.user['id'] === item['user_id']) {
      //check if current signed in user removed themselves from list
        this.removeMyself = true;
      } else {
        this.removeMyself = false;
      }
    } else {
      // its not a previously save organizer,just remove from array
      this.newAsstOrgArr.splice(idx, 1);
    }
    this.enableOrDisableDropdown();
  }

  setPrimaryOrg(event) {
    let currentPryOrg, thisIdx;
    currentPryOrg = this.primaryOrg;
    this.pryOrgChanged = true;
    thisIdx = this.primaryOrgArr.findIndex(item => {
        //already existing assistant organizer saved previously
        return item['id'] === parseInt(event.target.value);
    })

    this.primaryOrg = this.primaryOrgArr[thisIdx];
  }

  cancelChanges() {
    window.history.back();
  }

  formValueChanged() {
    //check for value changes
    this.editOrganizerForm.valueChanges.subscribe(val => {
      this.changedVal = true;
    })
  }

  onOrgSubmit() {
    let saveAll = [];
    if (this.saveQueue.length) {
      //organizers have been edited
      this.saveQueue.forEach(item => {
        item['selected_activity'] = this.activity['id'];
        saveAll.push(this.activityService.updateOrganizer(item));
      })
    }
    if (this.pryOrgChanged) {
      //primary organizer was changed
      saveAll.push(this.activityService.makePrimaryOrganizer(this.primaryOrg['org_id'], this.activity['id']))
    }

    if (this.newAsstOrgArr) {
      //new organizers have been added
      this.newAsstOrgArr.forEach(item => {
        saveAll.push(this.activityService.addNewOrganizer(item));
      })
    }

    if (saveAll.length) {
      forkJoin(saveAll).subscribe(
        response => {
          if (response) {
            this.changedVal = false;   //reset
            //if I removed myself, go to dashboard
            if (this.removeMyself) {
              this.router.navigateByUrl('dashboard');
            } else {
              //just go back
              window.history.back();
            }
          }
        }
      )
    } else {
      //still check if I removed myself as it will not be in queue
      if (this.removeMyself) {
        this.router.navigateByUrl('dashboard');
      } else {
        //just go back
        window.history.back();
      }
    }
  }
}
