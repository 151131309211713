<div class="show-preview-page">
  <span class="preview-header">
    <span><h5>Preview Signup</h5></span>
    <span><a (click)="closePreview()" class="c-pointer"><img src="assets/images/close-btn.png" alt=""></a></span>
  </span>
  <div class="preview-theme-title"
  [ngStyle]="{'background-image': 'url(' + urlLocation + '/client/themes/' + activityThemeFolder + '/imgs/mobile_background.jpg)'}">
  <div class="theme-title-inner">
    <span class="theme-badge" *ngIf = "badgeUrl"> 
      <span class="theme-badge-img"><img [src]="badgeUrl" [alt]="badgeName"></span>
      </span>
    <span class="theme-title-innertext">
      <span class="truncate">{{title}}</span> 
      <!-- <span *ngIf="startDateRange">
        <span class="small">{{startDateRange}}</span> <span *ngIf="endDateRange"> - {{endDateRange}}</span>
      </span> -->
    </span>
  </div>
</div>
</div>