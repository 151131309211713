import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient,
    @Inject('BASE_API_URL') private apiURL:string) {}

  get(activityId, groupbyMonth, filter?, otherParams?) {
    var data,
      tmpData = {
      'selected_activity': activityId,
      'activity_id': activityId,
      'include_comments': 'false',
      'include_jobassignments': 'true',
      'include_jobs': 'true',
      'my_jobs': 'false'
    }
    if(otherParams) {
      data = tmpData;
      data['group_by_month'] = '1';
    } else {
      data = {
        'selected_activity': activityId,
      }
    }
    if (filter) {
      data[filter]= 'true'
    }
    if(groupbyMonth) {
      data['group_by_month'] = '1';
    }
    return this.http.get(this.apiURL + '/api/org_events/', { params:data, observe: 'response'});
  }

  getParticipantEvent(activityId, accessKey, startDate, endDate) {
    var data = {
      'selected_activity': activityId,
      'activity_id': activityId,
      'accesskey': accessKey,
      'startdate': startDate,
      'enddate': endDate,
      'include_comments': 'false',
      'include_jobassignments': 'true',
      'include_jobs': 'true',
      'my_jobs': 'false'
    }
    return this.http.get(this.apiURL + '/api/events/', { params:data, observe: 'response'});
  }
}
