<app-header icon="home" title="showLogo" showNav="true"></app-header>
<div class="edit-page">
   <div id="header-container">
     <div class="header">
       <ul>
         <li><a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
             [routerLink]="['spot/', activityId]">Spots</a></li>
         <li><a routerLinkActive="active" [routerLink]="['participant/', activityId]">Participants</a></li>
         <li><a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
             [routerLink]="['detail/', activityId]">Details</a></li>
       </ul>
     </div>
   </div>

   <router-outlet></router-outlet>
</div>