import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ForgotPswdService } from './forgot-pswd.service';

@Component({
  selector: 'app-forgot-pswd',
  templateUrl: './forgot-pswd.component.html',
  styleUrls: ['./forgot-pswd.component.scss']
})
export class ForgotPswdComponent implements OnInit {

  constructor(private forgotPswdService: ForgotPswdService) { }

  ngOnInit() {
  }

  formSubmitted: boolean = false;
  resetComplete: boolean = false;
  showError: boolean = false;
  respStatus: boolean;
  errorMsg: string;
  errorParams: {};
  serverError: string;

  @Output() errMessage = new EventEmitter<Event>();
  onResetSubmit(resetForm: NgForm) {
    this.formSubmitted = true;
    this.showError = false;
    if (resetForm.valid) {
      this.forgotPswdService.orgForgotPswd(resetForm.value).subscribe (
        response => {
          if (response.body) {
            this.respStatus = response.body['status'];  
            if (this.respStatus) {
              //status is true, proceed
                this.resetComplete = true;
            } else {
              //status is false, show error
              if (response.body['code'].indexOf('|') > -1) {
                //server returned error param
                this.serverError = response.body['code'].split('|');
                this.errorMsg = this.serverError[0];
                this.errorParams = {'0': this.serverError[1]};
              } else {
                this.errorMsg = response.body['code'];
              }
              this.showError = true
            }         
          }
        }
      )
    }
  };
}
