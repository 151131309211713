<div class="edit-details-page">
  <div class="edit-share-icons">
    <ul>
      <li><a routerLink="/invite"><i class="material-icons">share</i>share</a></li>
      <li><a (click)="generateLink()"><i class="material-icons material-icons-outline">visibility</i>preview</a></li>
      <li><a routerLink="/messages"><i class="material-icons">chat_bubble_outline</i>message</a></li>
    </ul>
  </div>
  <mat-accordion>
    <mat-expansion-panel>
      <!-- Reports panel -->
      <mat-expansion-panel-header>
        <div class="panel-header">{{'_Reports_' | translate}}</div>
      </mat-expansion-panel-header>
      <div>
        <form [formGroup]="reportForm" (ngSubmit)="onReportSubmit()">
          <div *ngIf="hasSpots" class="panel-body report-panel" [ngClass]="{'focus-div-blue': whosComing }"
            (click)=selectWhosComing()>
            <!-- who's coming -->
            <div class="whos-coming">
              <h5 [innerHtml]="'_ReportSummary_' | translate"></h5>
              <div [innerHtml]="'_ReportSummaryDescription_' | translate"></div>
              <div class="form-group" *ngIf="whosComing">
                <mat-checkbox formControlName="whosComingComments">
                  <label [innerHtml]="'_CommentsDescriptionMobile_' | translate"></label>
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div *ngIf="hasSpots" class="panel-body report-panel" [ngClass]="{'focus-div-blue': signUpSheet }"
            (click)=selectSignUpSheet()>
            <!-- sign up sheet -->
            <div class="signup-sheet">
              <h5 [innerHtml]="'_ReportSignup_' | translate"></h5>
              <div [innerHtml]="'_ReportSignupDescription_' | translate"></div>
              <div *ngIf="signUpSheet">
                <div class="form-group mb-0">
                  <mat-checkbox formControlName="customFields">
                    <label [innerHtml]="'_ExtraFields_' | translate"></label>
                  </mat-checkbox>
                </div>
                <div class="form-group mb-0">
                  <mat-checkbox formControlName="checkInSheet">
                    <label [innerHtml]="'_HideEmptySpots_' | translate"></label>
                  </mat-checkbox>
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="participantDetails">
                    <label class="mt-0" [innerHtml]="'_HideParticipantDetails_' | translate"></label>
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="panel-body report-panel" [ngClass]="{'focus-div-blue': tracking }" (click)=selectTracking()>
            <!-- roster and hours tracking -->
            <div class="whos-coming">
              <h5 [innerHtml]="'_ReportRoster_' | translate"></h5>
              <div [innerHtml]="'_ReportRosterDescription_' | translate"></div>
              <div *ngIf="tracking">
                <div class="form-group mb-0">
                  <mat-checkbox formControlName="customFieldsTracking">
                    <label [innerHtml]="'_ExtraFields_' | translate"></label>
                  </mat-checkbox>
                </div>
                <div class="form-group" *ngIf="userIsPremium">
                  <mat-checkbox formControlName="participantHours">
                    <label class="mt-0" [innerHtml]="">Include Participant Hours</label>
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="panel-body report-panel" [ngClass]="{'focus-div-blue': export }" (click)=selectExport()>
            <!-- export -->
            <div class="export">
              <h5>{{'_Export_' | translate}}</h5>
              <div [innerHtml]="'_ExportHelpText_' | translate"></div>
            </div>
          </div>
        </form>
      </div>
      <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
      <button class="btn btn-blue wide-btn" type="submit" (click)="onReportSubmit()"
        [disabled]="!formIsValid">{{'_EmailReport_' | translate}}</button>
    </mat-expansion-panel>


    <mat-expansion-panel>
      <!-- basic info panel -->
      <mat-expansion-panel-header>
        <div class="panel-header">{{'_BasicInfoHeader_' | translate}}</div>
      </mat-expansion-panel-header>
      <div class="panel-title">{{'_Name_' | translate}}</div>
      <div class="edit-icon" (click)="editSignupInfo()"><i class="material-icons">edit</i></div>
      <div class="panel-body">{{title}}</div>
      <div class="panel-title">{{'_ActivityCategory_' | translate}}</div>
      <div class="panel-body">{{category}}</div>
      <div class="panel-title">{{'_WelcomeField_' | translate}}</div>
      <div class="panel-body">
        <span [ngClass]="{'multiline' : description && showLess}">{{description}}</span>
        <span class="default-blue d-inline-block" *ngIf="description && showLess" (click)=toggleDesc(false)>Show
          More</span>
        <span class="default-blue d-block" *ngIf="description && !showLess" (click)=toggleDesc(true)>Show Less</span>
      </div>
      <div class="panel-title">{{'_Location_' | translate}}</div>
      <div class="panel-body">{{location}}</div>
      <div class="panel-title">{{'_Options_' | translate}}</div>
      <div class="panel-body">

        <!-- template for on and off toggle -->
        <ng-template #toggleOff>
          <span class="toggle-btn-off float-left">Off</span>
        </ng-template>
        <ng-template #toggleOn>
          <span class="toggle-btn-on float-left">On</span>
        </ng-template>

        <!-- allow share -->
        <div class="info-icon" *ngIf="share; then toggleOn; else toggleOff"></div>
        <div class="other-info">
          {{'_ShareInviteWithOthers_' | translate}}</div>
        <!-- allow show names -->
        <div class="info-icon" *ngIf="showNames; then toggleOn; else toggleOff"></div>
        <div class="other-info">
          {{'_SeeWhoResponded_' | translate}}</div>
        <!-- allow swap -->
        <div class="info-icon" *ngIf="allowSwap; then toggleOn; else toggleOff"></div>
        <div class="other-info">{{'_AllowSwaps_' | translate}}
        </div>
        <!-- allow signups -->
        <div class="info-icon" *ngIf="signupOthers; then toggleOn; else toggleOff"></div>
        <div class="other-info">
          {{'_AllowSignUpOthers_' | translate}}</div>

        <!-- hide comments -->
        <div class="info-icon" *ngIf="hideComment; then toggleOn; else toggleOff"></div>
        <div class="other-info">
          {{'_HideComments_' | translate}}</div>

        <!-- time 24hr -->
        <div class="info-icon" *ngIf="time24hr; then toggleOn; else toggleOff"></div>
        <div class="other-info">
          {{'_24HrTime_' | translate}}</div>

        <!-- nag -->
        <div class="info-icon" *ngIf="nag; then toggleOn; else toggleOff"></div>
        <div class="other-info">
          {{'_nag_' | translate}}</div>


        <!-- allow limit spots -->
        <ng-container *ngIf="userIsPremium">
          <div class="info-icon" *ngIf="limitSpotsActive; then toggleOn; else toggleOff"></div>
          <div class="other-info" *ngIf="userIsPremium">
            {{'_LimitSpotsInfo_' | translate}}</div>
        </ng-container>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <!-- participant info panel -->
      <mat-expansion-panel-header>
        <div class="panel-header">{{'_ParticipantInfoShort_' | translate}}</div>
      </mat-expansion-panel-header>
      <div>
        <div class="panel-title">{{'_ParticipantInfo_' | translate}}</div>
        <div class="edit-icon" (click)=editParticipantInfo()><i class="material-icons">edit</i></div>
        <div class="panel-body">
          <div class="other-info"><span class="asterisk">* </span>{{'_FullName_' | translate}} ({{'_Required_' |
            translate}})</div>
          <div class="other-info"><span class="asterisk">* </span>{{'_Email_' | translate}} ({{'_Required_' |
            translate}})</div>
          <div class="other-info">
            <span *ngIf="requirePhone"><span class="asterisk">* </span></span>
            <span *ngIf="!requirePhone" [ngStyle]="{'margin-left': '0.2rem'}">&nbsp;</span>
            {{'_PhoneNumber_' | translate}}
            <span *ngIf="requirePhone">({{'_Required_' | translate}})</span>
          </div>
        </div>
        <div class="panel-title">{{'_ParticipantCustomQuestions_' | translate}}</div>
        <div *ngFor="let item of customField">
          <div class="clearfix">
            <div class="other-info truncate">
              <span *ngIf="item.required"><span class="asterisk">* </span></span>
              <span *ngIf="!item.required" [ngStyle]="{'margin-left': '0.2rem'}">&nbsp;</span>{{item.title}}
              <span *ngIf="item.required">({{'_Required_' | translate}})</span>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <!-- Organizer info panel -->
      <mat-expansion-panel-header>
        <div class="panel-header">{{'_OrganizerInfoShort_' | translate}}</div>
      </mat-expansion-panel-header>
      <div>

        <div class="edit-icon" (click)=editOrganizerInfo()><i class="material-icons">edit</i></div>
        <div *ngIf="primaryName" class="panel-body font-weight-bold org-panel truncate">{{primaryName}},
          {{primaryEmail}} (primary)</div>

        <ng-container *ngIf="assistOrganizers">
          <div *ngFor="let org of assistOrganizers">
            <div class="panel-body org-panel truncate">{{org.name}}, {{org.email}}</div>
          </div>
        </ng-container>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <!-- Notification settings panel -->
      <mat-expansion-panel-header>
        <div class="panel-header">{{'_EditNotifSettingHeader_' | translate}}</div>
      </mat-expansion-panel-header>
      <div>
        <div class="position-relative" *ngIf="notifSpinner">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div *ngIf="!notifSpinner">
          <div class="panel-title">{{'_NotifyReminder_' | translate}}</div>
          <div class="edit-icon" (click)=editSettings()><i class="material-icons">edit</i></div>
          <div class="panel-body">{{sendReminders}}</div>
          <div class="panel-title">{{'_NotifyOnChange_' | translate}}</div>
          <div class="panel-body">{{notifySpotsChanged}}</div>
          <div class="panel-title">{{'_NotifySummary_' | translate}}</div>
          <div class="panel-body">{{commentsSummary}}</div>
          <div class="panel-title">{{'_NotifyOnSignup_' | translate}}</div>
          <div class="panel-body">{{notifySignUp}}</div>
          <div class="panel-title">{{'_NotifyOnCancel_' | translate}}</div>
          <div class="panel-body">{{notifyCancel}}</div>
          <div class="panel-title">{{'_NotifyOnFull_' | translate}}</div>
          <div class="panel-body">{{notifyFullSignup}}</div>
          <div class="panel-title">{{'_AddExtraReminders_' | translate}}</div>
          <div class="panel-body">{{addExtraDetails}}</div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <!-- Image and Theme panel -->
      <mat-expansion-panel-header>
        <div class="panel-header">{{'_ImageAndTheme_' | translate}}</div>
      </mat-expansion-panel-header>
      <div>
        <div class="panel-title">{{'_Edit_' | translate}}</div>
        <div class="edit-icon" (click)=editThemeInfo()><i class="material-icons">edit</i></div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="datedSignup">
      <!-- Time zone panel -->
      <mat-expansion-panel-header>
        <div class="panel-header">{{'_TimeZoneSupport_' | translate}}</div>
      </mat-expansion-panel-header>
      <div>
        <div class="panel-title">
          <span *ngIf="timeZone">{{timeZone}}</span>
          <span *ngIf="!timeZone">Off</span>
        </div>
        <div class="edit-icon" (click)=editTimeZone()><i class="material-icons">edit</i></div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>