<div class="register-div">
  <p class="separator-text"> {{'_Or_'| translate}}</p>
  <p class="text-center">{{'_CreateWithEmail_'| translate}}</p>
  <form #registerForm="ngForm" (ngSubmit)="onRegisterSubmit(registerForm)">
    <div class="form-group">
      <label for="name"><small>{{'_Name_'| translate}}</small></label>
      <input type="text" class="form-control" name="name" [(ngModel)]="registerForm.name" #name="ngModel" required>
      <div *ngIf="formSubmitted && name.errors?.required" class="error"> <small><span
            translate>_VALIDATION_REQUIRED_ERROR_ </span></small></div>
    </div>
    <div class="form-group">
      <label for="email"><small>{{'_EmailAddress_'| translate}}</small></label>
      <input type="email" class="form-control" name="email" [(ngModel)]="registerForm.email" #email="ngModel" required
        pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$">
      <div *ngIf="formSubmitted && email.errors?.required" class="error"> <small><span
            translate>_VALIDATION_REQUIRED_ERROR_</span></small></div>
      <div *ngIf="formSubmitted && email.errors?.pattern" class="error"> <small><span
            translate>_VALIDATION_EMAIL_ERROR_</span></small></div>
    </div>
    <div class="form-group pswd-input">
      <label for="password"><small>{{'_Password_'| translate}}</small></label>
      <input [type]="showPswd ? 'text' : 'password'" class="form-control" name="password"
        [(ngModel)]="registerForm.password" #password="ngModel" required minlength="6">
      <a *ngIf="!this.showPswd" class="pswd-btn" (click)="showPassword()">{{'Show' | translate}}</a>
      <a *ngIf="this.showPswd" class="pswd-btn" (click)="showPassword()">{{'Hide' | translate}}</a>
      <div *ngIf="formSubmitted && password.errors?.required" class="error"> <small><span
            translate>_VALIDATION_REQUIRED_ERROR_ </span> </small></div>
      <div *ngIf="formSubmitted && password.errors?.minlength" class="error"> <small
          translate>_PASSWORD_MALFORMED_</small></div>
    </div>
    <div class="form-group" *ngIf="!isCordovaApp">
      <re-captcha (resolved)="resolved($event)" required></re-captcha>
      <div *ngIf="captchaError" class="error"> <small>{{'_CAPTCHA_ERROR_'| translate}}</small></div>
    </div>
    <button type="submit" class="btn btn-blue btn-block mb-4">{{'_CreateAccount_'| translate}}</button>
  </form>
</div>
<a class="btn btn-gray btn-block" routerLink="/organizer/login">
  {{'_AlreadyRegistered_'| translate}}
</a>
<router-outlet></router-outlet>
<div class="p-4 terms">
  <p><small>By creating an account, you are consenting to our <a href="https://signup.com/Privacy">Privacy Policy</a> & <a
        href="https://signup.com/Terms">Terms of Use</a>.</small></p>
</div>
