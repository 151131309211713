import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { UserService } from './../../shared-services/user.service';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  title: string;
  message: string;

  errorMsg: string;
  errorParams: {};
  showError: boolean = false;

  //type of dialog to show
  isSuccess: boolean = false;
  // isConfirm: boolean = false;
  isExtraFieldForm: boolean = false;
  isOrgForm: boolean = false;

  //form
  dialogForm: FormGroup;
  inputLabel: string;
  formInput: string;
  showRequired: boolean = false;
  required: boolean = false;
  chkboxLabel: string;
  fieldTitle:string;
  suggestions: string[] = [];
  formIsValid: boolean = false;

  //org form
  orgEmail: string;
  orgEmailControl: string;  //used for validation
  orgName: string;
  orgPhone: string;
  orgTitle: string;

  //edit mode?
  isEdit: boolean = false;

  // params: {};
  // msgParam: {};

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {
    }

  ngOnInit() {
      this.title = this.data.title ? this.data.title : null;
      this.message = this.data.message ? this.data.message : null;
      this.isExtraFieldForm = this.data.isExtraFieldForm ? true : false;
      this.isOrgForm = this.data.isOrgForm ? true : false;
      this.isSuccess = this.data.isSuccess ? true : false;
      
      // initialize extra field form only if present
      if(this.isExtraFieldForm) {
        this.formInput = this.data.formInput ? this.data.formInput : null;
        this.showRequired = this.data.showChkBox ?true : false;
        this.chkboxLabel = this.data.chkboxLabel ? this.data.chkboxLabel : null;
        this.required = this.data.required ? this.data.required : null;
        this.suggestions = this.data.suggestions ? this.data.suggestions : null;
        this.isEdit = this.data.isEdit === true ? true : false;

        if (this.isEdit) {
          this.fieldTitle = "_EditAdditionalField_";
        } else {
          this.fieldTitle = "_AdditionalField_";
        }
        this.dialogForm = new FormGroup ({
          formInput: new FormControl(this.formInput, [Validators.required]),
          isRequired: new FormControl(this.required)
        })
        this.formValueChanged();

      }

      // initialize organizer form only if present
      if(this.isOrgForm) {
      this.orgEmail = this.data.org_email || null;
      this.orgName = this.data.org_name || null;
      this.orgPhone = this.data.org_phone || null;
      this.orgTitle = this.data.org_title || null;
      this.isEdit = this.data.isEdit === true ? true : false;

      if (this.isEdit) {
        this.fieldTitle = "_EditOrganizer_";
        //form is valid when editing
        this.formIsValid = true;
      } else {
        this.fieldTitle = "_AddOrganizer_";
        this.formIsValid = false;
      }

      this.dialogForm = new FormGroup ({
        email: new FormControl(this.orgEmail, [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")]),
        name:  new FormControl(this.orgName, [Validators.required]),
        phone: new FormControl(this.orgPhone),
        title: new FormControl(this.orgTitle)
      })

        this.orgEmailControl = this.dialogForm.controls['email'].value;
        if (this.orgEmailControl) {
          // disable email if filled as it cannot be changed
          this.dialogForm.controls['email'].disable();
        } else if (this.orgEmailControl === null) {
            // disable other controls if email is blank when adding
            this.disableOrgControls();
          // }
        } 
        this.formValueChanged();
    }
  }

  verifyUser(event) {
    var emailInput = event.target.value.toLowerCase();
    //send to server to verify
    this.userService.verifyRegistered({email: emailInput}).subscribe(
      response => {
        if (response.body['status']) {
          //user is verified, enable controls
         this.enableOrgControls();
          //update form with server values and disable email
          this.dialogForm.controls['email'].disable();
          this.dialogForm.controls['name'].patchValue(response.body['name']);
          this.dialogForm.controls['phone'].patchValue(response.body['phonenumber']);
          this.showError = false;
          //validate form
          if (this.dialogForm.controls['name'].value !== '' && this.dialogForm.controls['email'].value !== '')  {
            this.formIsValid = true;
          } else {
            this.formIsValid = false;
          }
        } else {
          //user is not found, show error
          let serverError = response.body['code'].split('|');
          this.errorMsg = serverError[0];
          this.errorParams = {'0': serverError[1]};
          this.showError = true;
          this.formIsValid = false;
        }
      }
    )
  }

  formValueChanged() {
    //check for value changes
    this.dialogForm.valueChanges.subscribe(val => {
      if (this.isOrgForm && this.isEdit) {
        if (this.dialogForm.controls['name'].value !== '' && this.dialogForm.controls['email'].value !== '')  {
          this.formIsValid = true;
        } else {
          this.formIsValid = false;
        }
      }
    })
  }

  enableOrgControls() {
    this.dialogForm.controls['name'].enable();
    this.dialogForm.controls['phone'].enable();
    this.dialogForm.controls['title'].enable();
  }

  disableOrgControls() {
    this.dialogForm.controls['name'].disable();
    this.dialogForm.controls['phone'].disable();
    this.dialogForm.controls['title'].disable();
  }

  saveForm() {
    this.dialogRef.close({
      title: this.dialogForm.get('formInput').value,
      required: this.dialogForm.get('isRequired').value
    });
  }

  saveOrgForm() {
    this.dialogRef.close({
      email: this.dialogForm.get('email').value,
      name: this.dialogForm.get('name').value,
      phone: this.dialogForm.get('phone').value,
      title: this.dialogForm.get('title').value
    })
  }

  continue() {
    this.dialogRef.close('continue');
  }
  
  goBack() {
    window.history.back();
  }
  
  closeOrCancel() {
    this.dialogRef.close('closed');
  }
}
