import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.scss']
})
export class EditJobComponent implements OnInit {

  changedVal: boolean = false;

  constructor( private router:Router ) { }
    ngOnInit() { 
    }

    isChanged(event) {
      if (event === true) {
        // console.log('changed');
        this.changedVal = true;
      }
    }
}
