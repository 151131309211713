import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VolunteerService {

  constructor(private http: HttpClient,
    @Inject('BASE_API_URL') private apiURL:string) {}

  getParticipant(volunteerId, activityId, accessKey) {
    var data = {
      'accesskey': accessKey,
      'activity_id': activityId,
      'selected_activity': activityId
    }
    return this.http.get(this.apiURL + '/api/volunteers/' + volunteerId, { params: data, observe: 'response'});
  }

  get(activityId) {
    var data = {
      'selected_activity': activityId
    }
    return this.http.get(this.apiURL + '/api/org_volunteers/', { params: data, observe: 'response'});
  }

  query(activityId, volunteerId) {
    var data = {
      'selected_activity': activityId
    }
    return this.http.get(this.apiURL + '/api/org_volunteers/' + volunteerId, { params: data, observe: 'response'});
  }

  createVolunteer(data) {
    return this.http.post(this.apiURL + '/api/org_volunteers/create', data, { observe: 'response'});
  }

  updateVolunteer(data) {
    return this.http.put(this.apiURL + '/api/org_volunteers/' + data.id, data, { observe: 'response'});
  }

  deleteVolunteer(volId, activityId) {
    var data = {
      selected_activity: activityId
    }
    return this.http.delete(this.apiURL + '/api/org_volunteers/' + volId, {params: data, observe: 'response'});
  }

}
