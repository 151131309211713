import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  constructor() { }

  @Input() width: number;

  // qtyFilled: string;
  getWidth: number;

  ngOnInit() {

    // this.qtyFilled = this.width + '% Filled';
    this.getWidth = (this.width);

  }

}
