<app-header icon="home" title="showLogo" showNav="true"></app-header>
<div class="body-container">
  <div class="list-container">
    <div class="top-header">
      <div class="list-header">{{'_CurrentSignUps_'| translate}}</div>
      <div><i class="material-icons search-icon" (click)="quickSearch()">search</i></div>
    </div>
    <div class="spinner-div" *ngIf="signUpSpinner" >
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <!-- <a class="btn btn-blue ml-3 mb-3" routerLink="/quicksearch">Quick Search</a> -->
    <ul *ngIf="mySignUpsList && mySignUpsList.length" class="ul-list" [ngClass]="{'opacity': signUpSpinner}">
      <li *ngFor="let signup of mySignUpsList|slice:0:showSignups let i=index" (click)="signUpSpinner ? $event.preventDefault() : goToSignup(mySignUpsList[i])">
        <a class="c-pointer">
          <span class="float-left list-main">
            <span class="list-title">
              {{signup.activity_title}}
            </span>
            <span class="list-date" *ngIf="signup.startdate !== '2038/01/01'">
              <span>{{signup.startdate | date: 'EEE, MMM d, yyyy'}}</span>
              <span *ngIf="signup.enddate && signup.startdate !== signup.enddate && signup.enddate !== '2038/01/01'">
                - {{signup.enddate | date: 'EEE, MMM d, yyyy'}}
              </span>
            </span>
          </span>
          <span class="float-right list-icon" [ngStyle]="{'padding-top': signup.startdate && signup.startdate !== '2038/01/01' ? '.8rem' : '0'}">
            <i class="material-icons">keyboard_arrow_right</i>
          </span>
        </a>
      </li>
      <div *ngIf="totalSignUps > showSignups" class="load-more-list">
        <p class="load-more"><a (click)="showSignups = totalSignUps">LOAD {{totalSignUps - showSignups}} MORE
          <span><i class="material-icons">add</i></span>
            </a></p>
      </div>
    </ul>
    <div *ngIf="mySignUpsList && !mySignUpsList.length" class="no-lists">
      <p>{{'_NoSignupMobile_'| translate}}</p>
    </div>
  </div>
  <div class="p-3">
    <a class="btn-blue-dotted" (click)="createNewSignup()">
      <i class="material-icons">add</i> {{'_DashboardClassicWizardText_'| translate}}
    </a>
  </div>

  <!-- MY SPOTS -->
  <div class="list-container">
    <div class="list-header my-spots">{{'_MySpotsSettings_' | translate}}</div>
    <div class="spinner-div" *ngIf="spotsSpinner">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  
    <ul *ngIf="mySpotsList && mySpotsList.length" class="ul-list" [ngClass]="{'opacity': spotsSpinner}">
      <li *ngFor="let spot of mySpotsList|slice:0:showMySpots let i=index" (click)="spotsSpinner ? $event.preventDefault() : goToSpots(mySpotsList[i])">
        <a class="c-pointer">
          <span class="float-left spot-list-main">
            <!-- spot title -->
            <span class="list-title">
              {{spot.jobname}}
            </span>
            <!-- spot activity name under title -->
            <span class="spot-activity-title">
              {{spot.activity_title}}
            </span>
          </span>
          <!-- right angle icon -->
          <span class="float-right list-icon" [ngStyle]="{'padding-top': spot.jobstarttime ? '.5rem' : '0'}">
            <i class="material-icons">keyboard_arrow_right</i>
          </span>
          <!-- date and time -->
          <span class="float-right text-right date-time-list"
            [ngClass]="{'center-angle': !spot.edate || (spot.edate && spot.edate == '2038/01/01')}">
            <span *ngIf="spot.edate && spot.edate !== '2038/01/01'" class="list-date">
              {{spot.edate | date: 'EEE, MMM d'}}
            </span><br />
            <span *ngIf="spot.jobstarttime" class="list-date">{{spot.jobstarttime}}</span>
            <span *ngIf="spot.jobendtime" class="list-date">- {{spot.jobendtime}}</span>
          </span>
        </a>
      </li>
      <div *ngIf="totalSpots > showMySpots" class="load-more-list">
        <p class="load-more">
          <a (click)="showMySpots = totalSpots">LOAD {{totalSpots - showMySpots}} MORE
            <span><i class="material-icons">add</i></span>
          </a>
        </p>
      </div>
    </ul>
    <div *ngIf="mySpotsList && !mySpotsList.length" class="no-lists">
      <p>{{'_NoSpots_'| translate}}</p>
    </div>
  </div>
 

  <!-- MY INVITATIONS -->
  <div class="list-container">
    <div class="list-header">{{'_MyInvitations_' | translate}}</div>
    <div class="clear">
      <div class="spinner-div" *ngIf="invSpinner">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
  
      <ul *ngIf="invitationsList && invitationsList.length" class="ul-list mb-4" [ngClass]="{'opacity': invSpinner}">
        <li *ngFor="let inv of invitationsList|slice:0:showInvitations let i=index"
          (click)="invSpinner ? $event.preventDefault() : goToInvitation(invitationsList[i])">
          <a class="c-pointer">
            <span class="float-left list-main">
              <span class="list-title">
                {{inv.activity_title}}
              </span>
              <span class="list-date" *ngIf="inv.startdate !== '2038/01/01'">
                <span>{{inv.startdate | date: 'EEE, MMM d, yyyy'}}</span>
                <span *ngIf="inv.enddate && inv.startdate !== inv.enddate && inv.enddate !== '2038/01/01'">
                  - {{inv.enddate | date: 'EEE, MMM d, yyyy'}}
                </span>
              </span>
            </span>
            <span class="float-right list-icon" [ngStyle]="{'padding-top': inv.startdate && inv.startdate !== '2038/01/01'? '.8rem' : '0'}">
              <i class="material-icons">keyboard_arrow_right</i>
            </span>
          </a>
        </li>
        <div *ngIf="totalnvitations > showInvitations" class="load-more-list mb-4">
          <p><a (click)="showInvitations = totalnvitations">LOAD {{totalnvitations - showInvitations}} MORE
            <span><i class="material-icons">add</i></span>
              </a></p>
        </div>
      </ul>
      <div *ngIf="invitationsList && !invitationsList.length" class="no-lists">
        <p>{{'_NoInvitations_'| translate}} <span> Click <a href="{{url}}/mobileweb/2.0/vspot.html#find_my_signups_page">here</a> to find your
            SignUp.</span>
        </p>
      </div>
    </div>
  </div>
</div>
