import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StorageService } from '../../shared-services/storage.service';

import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'app-edit-shift-time-dialog',
  templateUrl: './edit-shift-time-dialog.component.html',
  styleUrls: ['./edit-shift-time-dialog.component.scss']
})
export class EditShiftTimeDialogComponent implements OnInit {

  editShiftDialogForm: FormGroup;
  job: {};
  title: string;
  type: string;
  timeValues: {};
  hourArr: string[] = [];
  minuteArr: string[] = [];
  customHour: string;
  customMinute: string;
  //start times
  jobStartHour: string;
  jobStartMinute: string;
  jobStartMeridiem: string;
  //end times
  jobEndHour: string;
  jobEndMinute: string;
  jobEndMeridiem: string;
  //hours, minutes, meridiems
  startHour: string;
  startMinute: string;
  startMeridiem: string;
  endHour: string;
  endMinute: string;
  endMeridiem: string;

  timeError:boolean = false;        t
  shiftTimeError:boolean = false;       


  constructor(private storageService: StorageService,
    public dialogRef: MatDialogRef<EditShiftTimeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {
  }

  ngOnInit() {
    this.job = this.storageService.get('job');
    this.timeValues = this.storageService.get('timeValues');
    this.type = this.data.type ? this.data.type : null;
    this.title = this.data.title ? this.data.title : null;

    if (this.type === 'time') {
      //get start times
      this.jobStartHour = moment(this.data.starthour, "h").format("hh");
      this.jobStartMinute = moment(this.data.startminute, "mm").format("mm");
      this.jobStartMeridiem = moment(this.data.starthour, "h mm a").format("A");

      // get end times
      this.jobEndHour = moment(this.data.endhour, "hh").format("hh");
      this.jobEndMinute = moment(this.data.endminute, "mm").format("mm");
      this.jobEndMeridiem = moment(this.data.endhour, "h mm a").format("A");

      //initialize form
      this.editShiftDialogForm = new FormGroup({
        startHour: new FormControl(this.jobStartHour),
        startMinute: new FormControl(this.jobStartMinute),
        endHour: new FormControl(this.jobEndHour),
        endMinute: new FormControl(this.jobEndMinute)
      })
      this.checkTime();
    } else {
      //type is shift or break, initialize custom hour and minute form 
      this.customHour = this.type === 'shift' ? this.data.shifthour : this.data.breakhour;
      this.customMinute = this.type === 'shift' ? this.data.shiftminute : this.data.breakminute;

      this.editShiftDialogForm = new FormGroup({
        customHour: new FormControl(this.customHour),
        customMinute: new FormControl(this.customMinute)
      })
    }
    this.setTimeArray();
  }

  setTimeArray() {
    //set hour array
    for (let i = 0; i <= 12; i++) {
      let newVal: string;
      if (this.type == 'time') {
        //pad number with 0 only if time form in initialized
        if (i.toString().length === 1) {
          newVal = i.toString().padStart(2, "0");
          this.hourArr.push(newVal);
        } else {
          this.hourArr.push(i.toString());
        }
      } else {
        this.hourArr.push(i.toString());
      }
    }
    //set minute array
    for (let i = 0; i < 60; i+=5) {
      let newVal: string;
      if (this.type == 'time') {
        //pad number with 0 only if time form in initialized
        if (i.toString().length === 1) {
          newVal = i.toString().padStart(2, "0");
          this.minuteArr.push(newVal);
        } else {
          this.minuteArr.push(i.toString());
        }
      } else {
        this.minuteArr.push(i.toString());
      }
    }
  }

  toggleStartMeridiem() {
    if (this.jobStartMeridiem === 'AM') {
      this.jobStartMeridiem = 'PM';
      this.jobEndMeridiem = 'PM';
    } else {
      this.jobStartMeridiem = 'AM';
      this.jobEndMeridiem = 'AM';
    }
      this.checkTime();
  }

  toggleEndMeridiem() {
    if (this.jobEndMeridiem === 'AM') {
      this.jobEndMeridiem = 'PM'
    } else {
      this.jobEndMeridiem = 'AM'
    }
    this.checkTime();
  }

  checkTime() {
    let startTime =  moment(this.jobStartHour + ":" + this.jobStartHour + this.jobStartMeridiem, 'h:mma').format(),
    endTime = moment(this.jobEndHour + ":" + this.jobEndHour + this.jobEndMeridiem, 'h:mma').format();
    if(moment(endTime).isBefore(moment(startTime))) {
      this.timeError = true;
    } else {
      this.timeError = false;
    }
  }

  setShiftTime(type, val) {
    this.editShiftDialogForm.patchValue({type: val});
  }

  setTimeValues(shift, val) {
    if (shift === 'startHour') {
      let updatedEndTime, start;
      this.jobStartHour = val;
      //if start hour is changed, automatically change end hour to be one hour after
      start = parseInt(val);
      if (start < 12) {
        updatedEndTime = (start + 1);
        if (updatedEndTime.toString().length === 1) {
          this.jobEndHour  = updatedEndTime.toString().padStart(2, "0");
        } else {
          this.jobEndHour = updatedEndTime.toString();
        }
      } else if (start == 12) {
        this.jobEndHour = '01';
      }
      this.jobEndMinute = this.jobStartMinute;
      this.jobEndMeridiem = this.jobStartMeridiem;
      this.editShiftDialogForm.patchValue({endHour: this.jobEndHour});
      this.editShiftDialogForm.patchValue({endMinute: this.jobEndMinute});
      if (this.jobStartHour === '11' && this.jobStartMeridiem === 'AM') {
            this.jobEndMeridiem = 'PM';
      }
    } else if (shift === 'startMinute') {
      this.jobStartMinute = val;
    } else if (shift === 'endHour') {
      this.jobEndHour = val;
    } else {
      this.jobEndMinute = val;
    }
    this.checkTime();
}

  closeOrCancel() {
    this.dialogRef.close();
  }

  saveForm() {
    let customShiftHour = this.editShiftDialogForm.get('customHour') ? this.editShiftDialogForm.get('customHour').value : null,
        customShiftMinute = this.editShiftDialogForm.get('customMinute') ? this.editShiftDialogForm.get('customMinute').value : null;
    if (this.type === 'time') {
      //get values for shift time
      this.dialogRef.close({
        startHour: this.editShiftDialogForm.get('startHour').value,
        startMinute: this.editShiftDialogForm.get('startMinute').value,
        startMeridiem: this.jobStartMeridiem,
        endHour: this.editShiftDialogForm.get('endHour').value,
        endMinute: this.editShiftDialogForm.get('endMinute').value,
        endMeridiem: this.jobEndMeridiem
      })
    } else {
      let hourVal, minuteVal;
      if (customShiftHour !== '0' && customShiftHour !== null) {
        hourVal = customShiftHour;
      } else {
        hourVal = null;
      }
      if (customShiftMinute !== '0' && customShiftMinute !== null) {
        minuteVal = customShiftMinute;
      } else {
        minuteVal = null;
      }
      if (this.type === 'shift' && !hourVal && !minuteVal) {
        //both values are zero for shift time, show error
        this.shiftTimeError = true;
      } else {
        this.shiftTimeError = false;
        //close if values are valid
        this.dialogRef.close({
          customHour: hourVal,
          customMinute: minuteVal
        })
      }

    } 
  }
}
