import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { StorageService } from '../../shared-services/storage.service';

@Component({
  selector: 'app-edit-conf-dialog',
  templateUrl: './edit-conf-dialog.component.html',
  styleUrls: ['./edit-conf-dialog.component.scss']
})
export class EditConfDialogComponent implements OnInit {
  type: string;
  force: boolean = false;
  formValid: boolean = true;

  constructor(
    private storageService: StorageService,
    public dialogRef: MatDialogRef<EditConfDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {
  }

  ngOnInit() {
    this.type = this.data.type || null;
    this.force = this.data.force ? true : false;
    if (this.force) {
      this.formValid = false;
    }
  }

  save(instance) {
    this.dialogRef.close({
      saveInstance: instance
    })
  }

  toggleCheck(event) {
    if (event.checked) {
      this.formValid = true;
    } else {
      this.formValid = false;
    }
  }
  
  closeOrCancel() {
    this.dialogRef.close();
  }

}
