<app-header icon="back" title="_MyCustomQuestions_" backText="{{backText}}" showNav="false" changed="{{changedVal}}">
</app-header>

<div class="esf-page body-container">
  <div class="container">
    <p class="intro-text">Select Custom Questions {{activityName}}</p>
    <!-- <p>Check the custom questions below to include on this sign up.
      You may select {{maxExtraFields}} custom questions for this SignUp. Check the questions to include from the list
      below.</p> -->
      <p class="helper-intro">Below is a list of all Custom Questions in your account. You may check <i class="material-icons">done</i> up to {{maxExtraFields}} to include in THIS SignUp.</p>
    <button class="btn-blue-dotted" (click)="addEditFields('addField',$event)">
      <i class="material-icons">add</i>{{'_CreateNewQuestion_' | translate}}</button>

    <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>

    <div>
      <!-- question body -->
      <div *ngIf="extraFieldArr.length">
        <div *ngFor="let field of extraFieldArr; let i = index" class="drag-drop-list">
          <span class="question-outer">
            <mat-checkbox (change)="selectField(i,field,$event)" [checked]="field.checked">
              <span class="question-container">
                <span class="question"><span *ngIf="field.required" class="asterisk">*</span> {{field.title}}</span>
              </span>
            </mat-checkbox>
            <span class="subtext">{{field.format}}</span>
          </span>
          <span class="w-15"><i (click)="addEditFields('editField', $event, field)" class="material-icons">edit</i></span>
          <span class="w-15"><i (click)="removeExtraField(field)" class="material-icons">delete</i></span>
        </div>
      </div>
    </div>
  </div>


  <div class="button-bottom container">
    <button type="button" (click)="submitFields()" class="btn btn-blue wide-btn">{{'_Done_' | translate}}</button>
  </div>
</div>