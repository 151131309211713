import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Router } from "@angular/router";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StorageService } from '../../shared-services/storage.service';
import { QuillModule } from 'ngx-quill';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from './../../shared/alert-dialog/alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MessagesService } from '../../shared-services/messages.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  activity: {};
  selectedParticipants: any[];
  user: string;
  formError: boolean = true;
  changedVal: boolean = false;

  //editor
  messagesForm: FormGroup;
  editorMaxLength: number = 10000;
  subjectParams = null;
  formMessage: string;
  editorCount: number;

  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', { 'align': [] }, 'link'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ]
  }


  constructor(private storageService: StorageService,
    private translate: TranslateService,
    private messageService: MessagesService,
    private router: Router,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.activity = this.storageService.get('activity');
    this.user = this.storageService.get('user');
    this.selectedParticipants = this.storageService.get('selectedParticipants') || [];
    this.formMessage = this.storageService.get('formMessage') || '';
    this.editorCount = this.storageService.get('editorCount') || 0;

    if (this.selectedParticipants.length) {
      this.formError = false;
    } else {
      this.formError = true;
    }
    //remove previous local storage values
    this.storageService.removeMultiple(['volunteerData', 'eventData']);

    // initialize form
    this.messagesForm = new FormGroup({
      editor: new FormControl(this.formMessage, [Validators.required]),
      editorSubject: new FormControl('', [Validators.required])
    })

    //subject string
    this.subjectParams = {
      '0': this.activity["title"],
      '1': this.activity["orgname"]
    }

    this.translate.get('_DefaultMessageSubject_', this.subjectParams).subscribe((text) => {
      this.messagesForm.patchValue({ editorSubject: text });
    })

    //check for changes
    this.formValueChanged();
  }

  formValueChanged() {
    //check for value changes
    this.messagesForm.valueChanges.subscribe(val => {
      this.changedVal = true;
    })
  }

  selectRecipients() {
    // if form already has a message, save it
    if (this.messagesForm.get('editor').value) {
      this.storageService.set('formMessage', this.messagesForm.get('editor').value);
      this.storageService.set('editorCount', this.editorCount);
    }
    this.router.navigateByUrl('recipient-filter');
    this.changedVal = true;
  }


  onEditorSubmit() {
    let editorContent = this.messagesForm.get('editor').value,
      editorSubject = this.messagesForm.get('editorSubject').value || '',
      selectedId = [],
      selectedNames: string = '';

    this.selectedParticipants.forEach(vol => {
      // PWD: after selecting by date and status the object comes in like:
      // checked: true
      // email: "nikabareket@gmail.com"
      // job_name: "Medicinal and Herb Knot Garden"
      // volunteer_id: 88501278
      // who: "Nika"
      // so handle that case (until Tayo gets back and points out how it should have been done)
      if (vol['id']) {
        selectedId.push(vol['id']);
      } else {
        selectedId.push(vol['volunteer_id']);
      }
      if (vol['contactname']) {
        selectedNames += vol['contactname'] + ', ';
      } else {
        selectedNames += vol['who'] + ', ';
      }
      // PWD: end change
    })
    //remove trailing space and comma
    selectedNames = selectedNames.replace(/,\s*$/, "");

    let data = {
      inviteList: selectedNames,
      message: editorContent,
      messagetype: 0,
      name: this.user ? this.user['name'] : null, //added null here to prevent an error, it will still log the user out if there's no data
      recipients: selectedId,
      selected_activity: this.activity['id'],
      subject: editorSubject

    }
    this.messageService.post(data).subscribe(
      response => {
        if (response.status) {
          //cleanup local storage values
          this.storageService.removeMultiple(['selectedParticipants', 'volunteerData', 'eventData']);
          this.showAlert();
          this.changedVal = false;
        }
      },
      error => {
        // console.log('error ', error)
      }
    );
  }

  getMaxLength(e) {
    if (e.editor.root.innerText.length > this.editorMaxLength) {
      e.editor.deleteText(this.editorMaxLength, e.editor.root.innerText.length);
      this.editorCount = this.editorMaxLength;
    } else {
      this.editorCount = e.editor.root.innerText.length;
    }
}

  showAlert() {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      data: {
        isSuccess: true,    //success dialog
        title: 'Success',
        message: 'Message Sent'
      }
    });
  }
}
