import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private http: HttpClient,
    @Inject('BASE_API_URL') private apiURL:string) {}

  post(data) {
    return this.http.post(this.apiURL + '/api/messages/sendmessage/', data, { observe: 'response' });
  }
}
