<app-header icon="back" backText="_Participants_"  title="_EditParticipantDetail_" showNav="false"></app-header>
<div class="edit-participant-detail-page body-container">
  <div class="more-nav">
    <!-- options nav at the top right corner -->
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="editParticipant()" mat-menu-item>
        <span class="more-nav-title">Edit Participant</span>
        <mat-icon class="more-nav-icon">edit</mat-icon>
      </button>
      <button (click)="deleteParticipant()" mat-menu-item>
        <span class="more-nav-title">Delete Participant</span>
        <mat-icon class="more-nav-icon">delete</mat-icon>
      </button>
    </mat-menu>
  </div>

  <!-- info section -->
  <section class="info-container">
    <app-add-edit-participant-detail mode="edit"></app-add-edit-participant-detail>
  </section>

  <!-- message section -->
  <section *ngIf="hasEmail" class="msg-section">
    <form [formGroup]="editorForm" (ngSubmit)="onEditorSubmit()">
      <div class="list-header pl-0">Send Message</div>
      <div class="form-group">
        <label for="Subject"><small>{{'_Subject_'| translate}}</small></label>
        <input type="text" class="form-control" formControlName="editorSubject" name="editorSubject" required>
        <div *ngIf="formSubmitted && name.errors?.required" class="error"> <small><span
              translate>_VALIDATION_REQUIRED_ERROR_</span></small></div>
      </div>
      <div class="form-group">
        <quill-editor formControlName="editor" [modules]="editorConfig" (onContentChanged)="getMaxLength($event)"
          placeholder="Send {{participantFirstName}} a direct message...">
        </quill-editor>
        <span class="ql-editor-count"
          *ngIf="editorForm.get('editor').value != null">{{ editorForm.get('editor').value.length }}/{{editorMaxLength}}</span>
        <span class="ql-editor-count" *ngIf="editorForm.get('editor').value == null">0/{{editorMaxLength}}</span>
      </div>
      <button type="submit" [disabled]="!editorForm.valid" class="btn btn-blue btn-block mt-4 mb-4">Send</button>
    </form>
  </section>
</div>
