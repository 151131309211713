import { Component, OnInit, ChangeDetectorRef, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { forkJoin } from 'rxjs';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { StorageService } from '../../shared-services/storage.service';
import { ActivityService } from './../../shared-services/activity.service';
import { TranslateService } from '@ngx-translate/core';

declare var google: any;

@Component({
  selector: 'app-edit-signup-info',
  templateUrl: './edit-signup-info.component.html',
  styleUrls: ['./edit-signup-info.component.scss']
})
export class EditSignupInfoComponent implements OnInit {

  options: {};  //google maps option
  formSubmitted: false;
  activity: {};
  user: {};
  userIsPremium: boolean = false;
  editSignupForm: FormGroup;
  changedVal: boolean = false;
  location: string = '';

  //activity variables
  activityTitle: string;
  activityCategory: string;
  categoryArr: string[] = [];

  //limit spots
  activityLimit: {};
  limitSpots: number;
  hasLimit: boolean = false;  //is there a limit set on page load?
  limitNum: number;
  limitType: string;
  // requiredError: boolean = false;
  limitError: boolean = false;
  errorParam = { '0': 1 };

  //editor
  editorMaxLength: number = 1000;
  editorCount: number;

  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', { 'align': [] }, 'link'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ]
  }

  // set tooltip defaults
  nagTooltip:string = '';

  tippyProps: NgxTippyProps = {
    allowHTML: true,
    trigger: 'click',
    arrow: true,
    placement: 'auto',
    maxWidth: 230,
  };

  constructor(private storageService: StorageService,
    private activityService: ActivityService,
    private translate: TranslateService,
    private tippyService: NgxTippyService,
    private cdr: ChangeDetectorRef) { }

   @Input() updateLocation: string;

  ngOnInit() {
    //set activity variables
    this.activity = this.storageService.get('activity');
    this.activityTitle = this.activity['title'];
    this.activityLimit = this.activity['activity_limit'];

    //get user status
    this.user = this.storageService.get('user');
    if (this.user) {
      if (this.user['premium']) {
        this.userIsPremium = true;
      } else {
        this.userIsPremium = false;
      }
      // console.log('this.userIsPremium ', this.userIsPremium)
    }

    if (this.activityLimit && Object.keys(this.activityLimit).length) {
      //set limit state
      this.hasLimit = true;
      if (this.activityLimit['limit_type'] === '_LimitSpotsOptionSignup_') {
        this.limitNum = this.activityLimit['jobassignments_per_activity'];
        this.limitType = 'limitBySignup';
      } else if (this.activityLimit['limit_type'] === '_LimitSpotsOptionDay_') {
        this.limitNum = this.activityLimit['jobassignments_per_day'];
        this.limitType = 'limitByDay';
      } else if (this.activityLimit['limit_type'] === '_LimitSpotsOptionWeek_') {
        this.limitNum = this.activityLimit['jobassignments_per_week'];
        this.limitType = 'limitByWeek';
      }
    } else {
      //none is selected, set default
      this.limitType = 'limitBySignup';
    }

    //fill in form fields
    this.editSignupForm = new FormGroup({
      category: new FormControl(this.activityCategory),
      name: new FormControl(this.activityTitle, [Validators.required]),
      editor: new FormControl(this.activity['description']),
      location: new FormControl(this.activity['location']),
      allowShare: new FormControl(this.activity['share']),
      showNames: new FormControl(this.activity['shownames']),
      allowSwap: new FormControl(this.activity['allowswap']),
      allowSuo: new FormControl(this.activity['signup_others']),
      hideComment: new FormControl(this.activity['hide_comments']),
      time24hr: new FormControl(this.activity['time_24hr']),
      nag: new FormControl(this.activity['nag']),
      limitSpots: new FormControl(this.hasLimit),
      limitNum: new FormControl({ value: this.limitNum, disabled: !this.hasLimit }),
      limitType: new FormControl({ value: this.limitType, disabled: !this.hasLimit })
    });

    //get categories
    this.activityService.getActivityTypes().subscribe(response => {
      if (response) {
        let categoryIdx,
          categoryData = response.body['data'];
        categoryData.forEach(item => {
          this.categoryArr.push(item['value']);
        })
        categoryIdx = this.categoryArr.indexOf(this.activity['activitytype']);
        this.activityCategory = this.categoryArr[categoryIdx];
        this.editSignupForm.patchValue({ location: this.activityCategory });
      }
    })

    //check for changes
    this.formValueChanged();

  }

  onUpdateLocation(event) {
    if (typeof event == 'object'&& event['location'] ) {
      console.log('emitted event is', event['location']);
      this.editSignupForm.patchValue({ location: event['location'] });
    }
  }


  ngAfterViewInit() {
    this.setContentForTooltip();
    this.cdr.detectChanges();
  }

  setContentForTooltip() {
      this.translate.get('_NagHelp_').subscribe((text) => {
        this.tippyService.setContent('nagHelp', text);
    })
  }

  toggleLimit(event) {
    //enable or disable limit input if checkbox is checked
    if (event.checked) {
      this.editSignupForm.controls['limitNum'].enable();
      this.editSignupForm.controls['limitType'].enable();
    } else {
      this.editSignupForm.controls['limitNum'].disable();
      this.editSignupForm.controls['limitType'].disable();
      this.limitError = false;
    }
  }

  cancelChanges() {
    window.history.back();
  }

  getMaxLength(e) {
    if (e.editor.root.innerText.length > this.editorMaxLength) {
      e.editor.deleteText(this.editorMaxLength, e.editor.root.innerText.length);
      this.editorCount = this.editorMaxLength;
    } else {
      this.editorCount = e.editor.root.innerText.length;
    }
  }

  editorCreated(editor) {
    // console.log('e', editor)
    this.editorCount = editor.root.innerText.length;
  }

  formValueChanged() {
    //check for value changes
    this.editSignupForm.valueChanges.subscribe(val => {
      let limitStatus,
        limitNumber = this.editSignupForm.get('limitNum'),
        limitType = this.editSignupForm.get('limitType').value;
      this.changedVal = true;
      //set validation, limitNum should be required only if limitSpots is enabled
      limitStatus = this.editSignupForm.get('limitSpots').value;
      if (limitStatus) {
        //require limit number
        limitNumber.setValidators([Validators.required]);
      }
      if (limitNumber.value >= 1) {
        this.limitError = false;
      }
    })
  }

  onSignUpSubmit() {
    var activityData, activityId, limitId;
    var limitChecked = this.editSignupForm.get('limitSpots').value ? true : false;
    var limitNum = parseInt(this.editSignupForm.get('limitNum').value);
    var limitType = this.editSignupForm.get('limitType').value;
    var activityId = this.activity['id'];
    var jobassignments_per_activity = 0,
      jobassignments_per_day = 0,
      jobassignments_per_job = 0,
      jobassignments_per_week = 0;

    if (this.activityLimit && this.activityLimit['id']) {
      limitId = this.activityLimit['id'];
    }

    if (limitChecked == true) {
      if (limitNum < 1) {
        //show validate msg
        this.limitError = true;
      } else {
        this.limitError = false;
        //assign limit type
        if (limitType === 'limitBySignup') {
          jobassignments_per_activity = limitNum;
        } else if (limitType === 'limitByDay') {
          jobassignments_per_day = limitNum;
        } else if (limitType === 'limitByWeek') {
          jobassignments_per_week = limitNum;
        }
      }
    }

    //submit activity changes
    activityData = {
      activitytype: this.editSignupForm.get('category').value,
      title: this.editSignupForm.get('name').value,
      description: this.editSignupForm.get('editor').value,
      location: this.editSignupForm.get('location').value,
      share: this.editSignupForm.get('allowShare').value,
      shownames: this.editSignupForm.get('showNames').value,
      allowswap: this.editSignupForm.get('allowSwap').value,
      signup_others: this.editSignupForm.get('allowSuo').value,
      hide_comments: this.editSignupForm.get('hideComment').value,
      time_24hr: this.editSignupForm.get('time24hr').value,
      nag: this.editSignupForm.get('nag').value,
      limit_checked: limitChecked,
      selected_activity: activityId
    }

    if (limitChecked) {
      if (!this.limitError) {
        if (this.hasLimit) {
          //previously has limit, so update
          this.activityService.updateActivityLimits(limitId, this.activity['id'], jobassignments_per_activity, jobassignments_per_job, jobassignments_per_day, jobassignments_per_week).subscribe(
            response => {
              if (response.status) {
                this.activity['activity_limit'] = response.body['activity_limit'];
                this.storageService.set('activity', this.activity, null);
                this.updateActivity(activityData);
              }
            }
          )
        } else {
          //does not have limits, so create
          this.activityService.createActivityLimits(this.activity['id'], jobassignments_per_activity, jobassignments_per_job, jobassignments_per_day, jobassignments_per_week).subscribe(
            response => {
              if (response.status) {
                this.hasLimit = true;
                this.activity['activity_limit'] = response.body['activity_limit'];
                this.storageService.set('activity', this.activity, null);
                this.updateActivity(activityData);
              }
            }
          )
        }
      }
    } else {
      if (this.hasLimit) {
        //delete activity limits if it exists
        this.activityService.deleteActivityLimits(limitId, this.activity['id']).subscribe(
          response => {
            if (response.status) {
              this.activity['activity_limit'] = [];
              this.hasLimit = false;
              this.storageService.set('activity', this.activity, null);
              this.updateActivity(activityData);
            }
          }
        )
      } else {
        //just update activity data only
        this.updateActivity(activityData);
      }
    }
  }

  updateActivity(data) {
    this.activityService.updateActivity(data).subscribe(
      response => {
        if (response.status) {
          this.storageService.set('activity', response.body['data'], null);
          window.history.back();
        }
      }
    )
  }
}
