import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { StorageService } from '../../shared-services/storage.service';


@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss'],
})
export class WarningDialogComponent implements OnInit {
  title: string;
  message: string;
  isExit: boolean = false;
  isContinue: boolean = false;
  buttonText: string;
  buttonCancelText: string;
  type: string;

  constructor(
    private storageService: StorageService,
    public dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {
  }

  ngOnInit() {
    this.title = this.data.title ? this.data.title : null;
    this.message = this.data.message ? this.data.message : null;
    this.buttonText = this.data.buttonText ? this.data.buttonText : null;
    this.buttonCancelText = this.data.buttonCancelText ? this.data.buttonCancelText : null;
  }

  continue() {
    this.dialogRef.close('continue');
  }
  
  cancel() {
    this.dialogRef.close();
  }
}
