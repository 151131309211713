import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StorageService } from '../../shared-services/storage.service';
import { UserService } from '../../shared-services/user.service';
import { ErrorHandlerService } from '../../shared-services/error-handler.service';
import { Router } from "@angular/router";
import { WarningDialogComponent } from './../../shared/warning-dialog/warning-dialog.component';
import { AlertDialogComponent } from './../../shared/alert-dialog/alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit {

  origEmail: string;

  constructor(private userService: UserService,
              private storageService: StorageService,
              private errorHandlerService:ErrorHandlerService,
              public dialog: MatDialog,
              private translate: TranslateService,
              private router: Router ) { }

  ngOnInit() {
    if(this.storageService.get('user')) {
      this.userInfo = (this.storageService.get('user')); 
        if (this.userInfo['premium'] && this.userInfo['premium_data']) {
        this.UserType = this.userInfo['premium_data']['tier_name'];
      } else if (!this.userInfo['premium']) {
        //change type to complimentary
        this.UserType = 'Complimentary';
      }
      if (moment().isSameOrBefore(this.userInfo['premiumenddate'])) {
        //only show unexpired dates
        this.UserDate = this.userInfo['premiumenddate'];
      }
    }

    //get profile info
    this.userService.getProfile().subscribe(
      response => {
        this.settingsEmail = response.body['email'];
        this.settingsName = response.body['name'];
        this.settingsPhone = response.body['phoneNumber'];
        this.organizerTypes = response.body['organizer_types'];
        this.receiveOffers = response.body['receive_offers'];
        //store original email in case it gets changed
        this.origEmail = this.settingsEmail;
      },
      error => {
        this.errorHandlerService.resolve(error.status);  
      }
    )
  }
  userInfo = {};
  UserType: string = null;
  UserDate: string = null;
  organizerTypes: [];
  orgTypes    = {};
  receiveOffers: number = null;

  //form variables
  settingsEmail: string;
  settingsName: string;
  settingsPhone: string;
  settingsPassword: string;

  formSubmitted: boolean = false;
  showPswd: boolean = false;

  onSettingsSubmit(settingsForm: NgForm) {
    let msg:string, header: string, emailChangedTitle: string, emailChangedMsg: string;
    this.formSubmitted = true;
    //check if email has changed
    if (settingsForm.valid) {
      if (this.settingsEmail.toLowerCase() !== this.origEmail.toLowerCase()) {
        //email has been changed
        forkJoin([
          this.translate.get('_EmailChangedHeader_'),
          this.translate.get('_EmailChanged_', {
            '0': this.settingsEmail, 
          })
        ]).subscribe(
          response => {
            emailChangedTitle = response[0];
            emailChangedMsg = response[1];
            this.userService.verifyEmail({
              email: this.settingsEmail,
              strict: false
            }).subscribe(
              response => {
                if ( response.status && response.body['code'] === '_EMAIL_EXISTS_' ) {
                  //email already exists, warn user
                  forkJoin([
                    this.translate.get('_EmailMergeHeader_'),
                    this.translate.get('_EmailMerge_', {
                      '0': this.settingsEmail, 
                      '1': this.settingsEmail
                    })
                  ]).subscribe(
                    response => {
                      header = response[0];
                      msg = response[1];
                      this.warningDialog(settingsForm, header, msg, emailChangedTitle, emailChangedMsg);
                    }
                  )
                } else {
                  //email does not exist
                  this.saveForm(settingsForm, emailChangedTitle, emailChangedMsg);
                  // this.alertDialog(emailChangedTitle, emailChangedMsg);
                }
              },
              error => {
                this.errorHandlerService.resolve(error.status);  
              }
            )
          }
        )
      } else {
        //email was not changed, proceed with save
        this.saveForm(settingsForm);
      }
    }
  }

  warningDialog(settingsForm, header, msg, emailChangedTitle, emailChangedMsg) {
    const dialogRef = this.dialog.open(WarningDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      autoFocus: false,
      data: {
        buttonText: "_Continue_",
        buttonCancelText: "_Cancel_",
        title: header,
        message: msg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'continue') {
        this.saveForm(settingsForm, emailChangedTitle, emailChangedMsg);
      } else {
        //user clicked cancel, enable submit button
        this.formSubmitted = false;
      }
    }) 
  }

  saveForm(settingsForm, emailChangedTitle?, emailChangedMsg?) {
    var formData = {
      name: settingsForm.value.name,
      email: settingsForm.value.email,
      password: settingsForm.value.password,
      phone: settingsForm.value.phone,
      sendOffers: true,
      receiveOffers: this.receiveOffers,
      organizer_types: this.organizerTypes,
      orgTypes: {}
    }
    //post profile info
    this.userService.setProfile(formData).subscribe(
      response => {
        if (response.status) {
          this.formSubmitted = false;
          this.userInfo['name'] = settingsForm.value.name;
          this.userInfo['email'] = settingsForm.value.email;
          this.userInfo['phone'] = settingsForm.value.phone;
          //set changed values back to local storage
          this.storageService.process('user', this.userInfo); 
          if (emailChangedTitle) {
            this.alertDialog(emailChangedTitle, emailChangedMsg);
          } else {
            window.history.back();
          }
        }
      }
    )
  }

  alertDialog(header, msg) {
    //confirmation alert when email has been changed
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      data: {
        isSuccess: true,    //success dialog
        title: header,
        message: msg,
        param: this.settingsEmail
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'closed') {
        window.history.back();
      } 
    })   
  }

  goBack() {
    window.history.back();
  }

  //toggle show password
  showPassword() {
    this.showPswd = !this.showPswd;
  }
}
