import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private http: HttpClient,
    @Inject('BASE_API_URL') private apiURL:string) {}

  get(activityId, accessKey, jobId) {
    var data = {
      'accesskey': accessKey,
      'activity_id': activityId,
      'selected_activity': activityId,
      'job_id': jobId,
      'version': '1'
    }
    return this.http.get(this.apiURL + '/api/comments/', { params: data, observe: 'response'});
  }

  getJobComment(activityId, jobId) {
    var data = {
      'activity_id': activityId,
      'selected_activity': activityId,
      'job_id': jobId
    }
    return this.http.get(this.apiURL + '/api/comments/', { params: data, observe: 'response'});
  }

  post(accesskey, activityId, jobId, comment) {
    var data = {
      'accesskey': accesskey,
      'activity_id': activityId,
      'selected_activity': activityId,
      'job_id': jobId,
      'text': comment,
      'viewable': 'true'
    }
    return this.http.post(this.apiURL + '/api/comments/', data, {observe: 'response'});
  }

  delete(activityId, accessKey, commentId) {
    var data = {
      'accesskey': accessKey,
      'activity_id': activityId,
      'selected_activity': activityId,
      'id': commentId
    }
    return this.http.delete(this.apiURL + '/api/comments/' + commentId, { params: data, observe: 'response'});
  }

}
