import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { StorageService } from '../../../shared-services/storage.service';
import { UtilitiesService } from '../../../shared-services/utilities.service';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { AlertDialogComponent } from './../../../shared/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DomsanitizerPipe } from '../../../pipes/domsanitizer.pipe';
import { forkJoin } from 'rxjs';
declare var cordova: any;

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  constructor(private router:Router,
    private storageService: StorageService,
    private utilitiesService: UtilitiesService,
    private translate: TranslateService,
    private clipboardService: ClipboardService,
    public dialog: MatDialog,
    private sanitize: DomsanitizerPipe) { }

activity: string;
  activityTitle: string;
  orgEmail: string;
  addSpotsWizard: boolean;

  // social share
  fbShareLink: string;
  shareUrl: string;
  smsUrl: string;
  shareText: string;
  twitterLink: string;
  emailShareText: string;
  remindUrl: string;
  redirectUrl: string;
  socialShareText: string;

  ngOnInit() {
    this.activity = this.storageService.get('activity');
    this.activityTitle = this.activity['title'];
    this.orgEmail = this.activity['orgEmail'];
    this.redirectUrl = 'https://signup.com/';

    //get share url if not available
    if (this.activity && !this.activity['share_url']) {
      this.utilitiesService.generateLink(this.activity['id']).subscribe(
        response => {
          this.shareUrl = response.body['shortlink'];
          this.setMessageBody(this.activityTitle, this.shareUrl);
        }
      )
    } else {
      this.shareUrl = this.activity['share_url'];
      this.setMessageBody(this.activityTitle, this.shareUrl);
    }
  }

  setMessageBody(title, url) {
    let emailSubjectText: string, socialText: string;

     forkJoin([
      //get subject for email
      this.translate.get('_DefaultInviteSubject_'),
      //get message body for email
      this.translate.get('_DefaultInviteBody_'),
      //get social share string
      this.translate.get('_WebInviteRemindShareTest_', {'0': title + ' (' + (this.shareUrl) + ')'})
    ]).subscribe(
      response => {
        emailSubjectText = response[0];
        this.shareText = response[1];
        this.socialShareText = response[2];
        // console.log('this.socialShareText ', this.socialShareText)

      //set twitter link
      this.twitterLink = 'https://twitter.com/intent/tweet?text=' +
      encodeURIComponent(this.socialShareText);

    //set remind link
    this.remindUrl = 'https://www.remind.com/v1/share?url=' + '&referrer=' + encodeURIComponent(window.location.href) + '&text=' + encodeURIComponent(this.socialShareText);

    //set email share subject and body
    this.emailShareText = 'mailto:?subject=' + emailSubjectText + '&body=' + this.shareText + " " + this.shareUrl;
    // console.log(this.emailShareText);

    //set message body for text
    this.getSms(this.socialShareText);
      }
    )
  }

  fbShare() {
    let fbUrl = 'https://www.facebook.com/dialog/feed?app_id=1731142263830615&display=popup&caption=' + this.socialShareText + '!&link=' + encodeURI(this.shareUrl) + '&redirect_uri=' + encodeURIComponent(this.redirectUrl);
    if (this.storageService.get('isCordovaApp') && this.storageService.get('isCordovaApp') === true) {
      cordova.InAppBrowser.open(fbUrl, '_system', 'location=yes');
    } else {
      window.location.href = fbUrl;
    }
  }

  copyToClipboard() {
    this.clipboardService.copyFromContent(this.shareUrl);
    setTimeout(() => {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '80%',
        hasBackdrop: true,
        data: {
          isSuccess: true,    //success dialog
          title: 'Success',
          message: 'The shareable link has been copied to your clipboard'
        }
      });
    })
  }

  getSms(socialShareText) {
    var sms, href,
        ua = navigator.userAgent.toLowerCase();
		if (ua.indexOf("android") > -1)  {
      //android devices
      href = "sms:?body=";
    } else {
      //other devices including ios
      href = "sms:' '&body=";
    }
    this.smsUrl = this.sanitize.transform(href + encodeURIComponent(socialShareText));
    // console.log('this.smsUrl', this.smsUrl);
  }
}
