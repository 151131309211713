import { Component, OnInit, ViewChildren, QueryList, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { StorageService } from '../../shared-services/storage.service';
import { EventService } from '../../shared-services/event.service';
import { UtilitiesService } from '../../shared-services/utilities.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivityService } from '../../shared-services/activity.service';
import { Router } from "@angular/router";
import { forkJoin } from 'rxjs';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';

import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'app-add-edit-spot',
  templateUrl: './add-edit-spot.component.html',
  styleUrls: ['./add-edit-spot.component.scss']
})
export class AddEditSpotComponent implements OnInit {

  constructor(private router: Router,
    private storageService: StorageService,
    private eventService: EventService,
    private utilitiesService: UtilitiesService,
    private activityService: ActivityService,
    private translate: TranslateService) { }

  activity: {};
  volunteerId: {};
  activityThemeFolder: string;
  title: string;
  calcWidth: number;
  isUnlimited: boolean = false;
  totalAssignments: number = 0;
  activityTotalNum: number = 0;
  activityTotal: any;
  showMonth: boolean = false;
  hasSpots: false;  //used to check if activity has spots

  events: {};
  monthData: {};
  monthKeys: string[] = [];
  loading: boolean = true;
  startDate: string;
  endDate: string;
  startDateRange: string;
  endDateRange: string;
  filterOptions: string[] = []; //array of dropdown values
  initFilter: string;           //default filter value if set
  jobToExpand: number;         //month id to expand if coming from the detail page
  openJob: number;
  openMonth: number;
  datedSignup: boolean = true;
  scrollElem: HTMLElement;

  @Input() mode: string;
  @Output() update = new EventEmitter();
  @Output() dateChange = new EventEmitter();


  @ViewChildren('panel', { read: ElementRef }) panels;

  ngOnInit() {
    //scroll to top
    if (!this.jobToExpand) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    this.activity = this.storageService.get('activity');
    // this.hasSpots = this.storageService.get('hasSpots');
    if (this.activity) {
      this.activityThemeFolder = this.activity['theme']['folder'];
      this.datedSignup = this.activity['kind'] === 'nodate' ? false : true;
      if (this.activity['startdate'] !== this.activity['enddate']) {
        this.showMonth = true;
      } else {
        this.showMonth = false;
      }
      if (this.datedSignup) {
        //set initial filter
        this.initFilter = this.storageService.get('spotFilter') || "Upcoming Spots";
      } else {
        this.initFilter = "All Spots";
      }
      this.title = this.activity['title'];
      // call api to check if activity has spots
      if (!this.hasSpots) {
        this.activityService.hasSpots(this.activity['id']).subscribe(result => {
          if (result && result.body['status']) {
            this.hasSpots = result.body['hasSpots'];
            this.storageService.set('hasSpots', this.hasSpots);
          }
        })
      }
    }
    this.jobToExpand = this.storageService.get('jobToExpand');
    //remove unnecessary local storage settings that might bleed through
    this.storageService.removeMultiple(['jobQuantity', 'selectedParticipants', 'formMessage', 'editorCount', 'irregularDaysSelected', 'originalRepeat', 'timeValues']);
    if (this.mode === 'edit') {
      this.setFilterOptions();
    } else {
      //when creating, there is no filter, set default to 'all'
      this.getSignupList(this.initFilter);
    }
  }

  trackByFn(index, item) {
    //set trackby so angular doesnt remove all elements from the dom if still valid
    return index;
  }

  setFilterOptions() {
    let filterArr = [];
    if (this.datedSignup) {
      //show all filter options
      filterArr = ['_AllSpots_', '_PastSpots_', '_UpcomingSpots_', '_UnassignedSpots_', '_AssignedSpots_'];
    } else {
      //signup is undated, show only relevant options
      filterArr = ['_AllSpots_', '_UnassignedSpots_', '_AssignedSpots_'];
    }
    filterArr.forEach(filter => {
      forkJoin([this.translate.get(filter)]).subscribe(
        response => {
          response.forEach(item => {
            this.filterOptions.push(item);
          })
        }
      )
    })
    this.getFilteredData(this.initFilter);
  }

  getFilteredData(val) {
    this.storageService.set('spotFilter', val);
    if(!this.loading) {
      //show spinner when switching views
      this.loading = true;
    }
    this.update.emit('enable-spinner');
    let includeOnly;
    switch (val) {
      case 'Upcoming Spots':
        includeOnly = 'only_future';
        break;
      case 'Past Spots':
        includeOnly = 'only_past';
        break;
      case 'Assigned Spots':
        includeOnly = 'only_assigned';
        break;
      case 'Unassigned Spots':
        includeOnly = 'only_unassigned';
        break;
    }
    this.getSignupList(includeOnly);
  }

  getSignupList(includeOnly) {
    let unsortedKeys, getRatio, job = {};
    this.activityTotalNum = 0;
    this.totalAssignments = 0;
    this.eventService.get(this.activity['id'], true, includeOnly, true).subscribe(
      response => {
        if (response) {
          this.update.emit('disable-spinner');
          this.events = response['body']['data'];
          this.monthData = this.events;
          unsortedKeys = Object.keys(this.events);
          this.monthKeys = Object.keys(this.events);

          // var sorted =  this.monthKeys.sort((a, b) => (b - a));
          this.monthKeys = unsortedKeys.sort ((a, b) => a < b ? -1 : a > b ? 1 : 0);

         

          console.log('this.monthKeys ', this.monthKeys)
          // console.log('sorted ', sorted);
          this.loading = false;
          //check for unlimited spots
          if (this.monthKeys.length > 0) {
            for (var i = 0; i < this.monthKeys.length; i++) {
              let item = this.monthKeys[i];
              for (var j = 0; j < this.monthData[item].length; j++) {
                job = this.monthData[item][j];
                if(i === 0 && j ===0 ) {
                  this.startDate = job['edate'];
                } else if ((i == this.monthKeys.length - 1) && (j == this.monthData[item].length - 1)) {
                  this.endDate = job['edate']
                }
                this.totalAssignments += job['bringstotalassignments'] + job['jobstotalassignments'];
                if (job['bringstotal'] === -1 || job['jobstotal'] === -1) {
                  this.isUnlimited = true;
                  // break;
                } else {
                  this.activityTotalNum += job['jobstotal'] + job['bringstotal'];
                }
              }
            }
            setTimeout(()=> {
              this.dateChange.emit({startDate: this.startDate, endDate: this.endDate });
            },100)
          }
          if (this.isUnlimited) {
            //activity is unlimited
            this.activityTotal = 'Unlimited';
            this.calcWidth = null;
          } else {
            //activity is not unlimited
            getRatio = this.totalAssignments / this.activityTotalNum * 100;
            this.calcWidth = isNaN(getRatio) ? 0 : Math.round(getRatio);
            this.activityTotal = this.activityTotalNum;
          }
          this.expandPanel();
        }
      }
    )
  }

  expandPanel() {
    if (this.jobToExpand) {
      //page has previously been opened
      this.openPreviousListItem();
    } else {
      this.openJob = 0;
      this.openMonth = 0;
      //open first item, this is not called for now
      // this.openFirstListItem();
    }
  }

  openFirstListItem() {
    let panelOpened: boolean = false;
    if (this.monthKeys.length > 1) {
      for (var i = 0; i < this.monthKeys.length; i++) {
        let item = this.monthKeys[i];
        for (var j = 0; j < this.monthData[item].length; j++) {
          let job = this.monthData[item][j];
          if (i == 0 && j == 0) {
            this.openJob = j;
            this.openMonth = i;
            panelOpened = true;
            break;
          }
        }
        if (panelOpened) {
          //if already open, no need to continue iterating
          break;
        }
      }
    } else {
      //there is only one month, open by default
      this.openJob = 0;
      this.openMonth = 0;
      panelOpened = true;
    }
  }

  openPreviousListItem() {
    let elem, elemId, panelOpened: boolean = false;
    for (var i = 0; i < this.monthKeys.length; i++) {
      let item = this.monthKeys[i];
      for (var j = 0; j < this.monthData[item].length; j++) {
        let job = this.monthData[item][j]['jobs'];
        for (var k = 0; k < job.length; k++) {
          if (job[k].id === this.jobToExpand) {
            this.openJob = j;
            this.openMonth = this.datedSignup ? i : 0;
            panelOpened = true;
            break;
          }
        }
      }
      if (panelOpened) {
        //if already open, no need to continue iterating
        break;
      }
    }
    if (!panelOpened) {
      //open first by default
      this.openJob = 0;
      this.openMonth = 0;
      panelOpened = true;
    }
  }

  scrollToItem() {
    let elem;
    setTimeout(() => {
      //  set timeout to be sure the element has been created in the dom
      if (this.jobToExpand) {
        let elemId = 'job-' + this.jobToExpand;
        // elem = document.getElementById(panel.id);
        elem = document.getElementById(elemId);
        if (elem) {
          elem.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
          this.jobToExpand = null;
        }
      } 
    }, 100)
  }

  addNewSpot() {
    this.storageService.removeMultiple(['jobName', 'jobDesc', 'timeValues', 'quickfillShifts', 'repeatingJobs', 'origRepeatingJobs', 'timeInterval', 'irregularDaysSelected', 'jobQuantity', 'originalRepeat', 'repeatingJSONData', 'shiftSummary', 'serverSummary', 'repeatSummary']);   
    if (this.mode === 'create') {
      this.storageService.remove('job');
      this.router.navigate(['/add_new_spot'], { queryParams: { backToPage: 'create' }});
    } else {
      //not coming from create page
      this.router.navigate(['/add_new_spot']);
    }
  }

  getJobTime(job) {
    let time, startTime, endTime;
    if (job['starthour'] !== -1) {
      //start time is given
      startTime = moment(job['starthour'] + ":" + job['startminute'], 'H:mm').format('h:mma');
      if (job['endhour'] !== -1) {
        //end time is given
        endTime = moment(job['endhour'] + ":" + job['endminute'], 'H:mm').format('h:mma');
        time = startTime + " - " + endTime;
      } else {
        //has only start time
        time = startTime;
      }
    }
    return time;
  }

  goToJob(job, month) {
    this.storageService.set('jobToExpand', job['id']);
    this.storageService.set('job', job);
    
    if (this.mode === 'create') {
      //track signups in create mode
      this.router.navigate(['edit_spots_detail/' + job['id']], { queryParams: { mode: 'create' }});
    } else {
      this.router.navigateByUrl('edit_spots_detail/' + job['id']);
    }
  }
}
