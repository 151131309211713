import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import {Router} from "@angular/router";
import { UserService } from '../../../shared-services/user.service';
import { StorageService } from '../../../shared-services/storage.service';
import { GoogleAnalyticsService } from "../../../shared-services/google-analytics.service";
import {RecaptchaComponent} from 'ng-recaptcha';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  showBtns: boolean = false;
  isCordovaApp: boolean = false;
  @Output() errMessage = new EventEmitter<Event>();
  @Output() errParam = new EventEmitter<Event>();

  constructor(
    private userService: UserService,
    private storageService: StorageService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router) { }
 
  ngOnInit() {
    //check if app is run in browser or the mobile app
    if (this.storageService.get('isCordovaApp') && this.storageService.get('isCordovaApp') === true) {
      this.isCordovaApp = true;
    } else {
      this.isCordovaApp = false;
    }
  }

  ngOnDestroy() {
    this.showBtns = true;
    this.resetForm();
  }

  //variable declarations
  recaptchaResponse: string;
  formSubmitted: boolean = false;
  captchaError: boolean = false;
  showPswd = false;
  respStatus: boolean;
  recaptchaRef: RecaptchaComponent;
  loadingDone: boolean;

  resetForm() {
    this.formSubmitted = false;
    this.captchaError = false;
    this.showPswd = false;
    this.errMessage = null;
  };

  //captcha
  resolved(captchaResponse: string) {
    this.recaptchaResponse = captchaResponse;
  };

  //toggle show password
  showPassword() {
    this.showPswd = !this.showPswd;
  }

  onRegisterSubmit(registerForm: NgForm) {
    this.formSubmitted = true;
    this.captchaError = false;
    if (registerForm.valid && (!this.isCordovaApp && this.recaptchaResponse) || (registerForm.valid && (this.isCordovaApp))) {
      //only check captcha is not mobile app
      if (this.isCordovaApp) {
        this.nativeRegister(registerForm);
      } else {
        this.userService.orgRegister({
          name: registerForm.value.name,
          email: registerForm.value.email,
          password: registerForm.value.password,
          // token: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'  //for dev only if it thinks I'm a robot
          token: this.recaptchaResponse  // use this for production
        }).subscribe (
          response => {
            this.googleAnalyticsService.emitEvent('button', 'click', 'm/org_register');
            this.processServerResponse(response);
          },
          err => {console.log('register error')}
        );
      }
    } else {
       if (!this.isCordovaApp && !this.recaptchaResponse) {
        //no captcha response
        this.captchaError = true;
      }
    }
  };

  async nativeRegister(formData) {
    let data = {
      name: formData.value.name,
      email: formData.value.email,
      password: formData.value.password,
    }
    this.userService.nativeOrgRegister(data).then((res: any) => {
      if (res && res.data['status']) {
        // console.log('res', res.data['user']);
        this.storageService.set('email', formData.value.email);
        this.storageService.set('password', formData.value.password);
        this.googleAnalyticsService.emitEvent('button', 'click', 'm/org_register');
        this.processServerResponse(res);
      }
    })
  }


  processServerResponse(res) {
    var userData, errorCode, errParam;
    this.respStatus = res.body && res.body['status'] !== null ? res.body['status'] : res.status;
      if (this.respStatus) {
        let route = this.router;
        this.loadingDone = true;
        //status is true, proceed
        // userData = res['user'] ||  res.body['user'];
        if (res.body) {
          userData = res.body['user'];
        } else if (res.data) {
          userData = res.data['user'];
        } else {
          userData = res['user'];
        } 
          //send event tracking
          this.storageService.process('full_login', true);
          this.storageService.process('role', 'organizer');
          this.storageService.set('newuser', true);
          this.storageService.process('user', userData, function() {
            route.navigateByUrl('dashboard');
          });
      } else {
        //status is false, emit error to main app component
        errorCode = res.body && res.body['code'] ? res.body['code'] : res.code;
        errParam = res.body && res.body['email'] ? res.body['email'] : res.email;
        this.errMessage.emit(errorCode);
        this.errParam.emit(errParam);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.loadingDone = true;
      }         
  };
}
