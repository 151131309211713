<div>
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="{{'_BackgroundThemes_' | translate}}">
      <!-- choose category from dropdown -->
      <div>
        <select class="form-control categories" (change)=setCategory($event)>
          <option value="all">Select a category...</option>
            <option *ngFor="let item of activityCategories; let i = index" [value]="item.name">{{item.name}}
            </option>
        </select>
      </div>
      <!-- background images -->
      <div class="theme-div">
        <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
        <div class="theme-container">
          <div>
            <span class="theme-outer" *ngFor="let theme of activityBg; let i = index">
              <span class="theme-inner" [ngStyle]="{'background-image': 'url(' + urlLocation + '/client/themes/' + theme.folder + '/imgs/wallpaper-thumb.jpg)'}" (click)="selectTheme(theme)" [ngClass]="{'focus-div-blue': selectedTheme[0] === theme}">
              </span>
            </span>  
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{'_LogoImages_' | translate}}">
      <!-- choose logo from dropdown -->
      <div class="badge-section">
        <select class="form-control categories" (change)=setBadge($event)>
          <option value="all">Select an image...</option>
          <!-- <option value="pastLogo">Copy from Past Signups</option> -->
          <option *ngFor="let item of activityCategories; let i = index" [value]="item.name">{{item.name}}
          </option>
        </select>
          <div class="image-upload">
            <label for="file-input">
              <mat-icon class="more-nav-inline-icon">add_photo_alternate</mat-icon>
            </label>
            <input id="file-input" type="file" accept="image/*" (change)="uploadImage($event)">
        </div>
      </div>
      <!-- logo images -->
      <div class="theme-div badge-container">
        <!-- uploaded image div -->
        <div *ngIf="newUpload" class="new-upload">
          <img [src]="newUpload" (click)="selectBadge('upload')" [ngClass]="{'focus-div-blue': selectedBadge[0] === newUpload}">
        </div>
        <mat-spinner *ngIf="loadingBadges" [diameter]="50"></mat-spinner>
        <!-- <mat-spinner *ngIf="uploadProgress" [diameter]="50"></mat-spinner> -->
        <div class="theme-container">
          <ng-container>
            <span class="theme-outer" *ngFor="let badge of activityBadge; let i = index">
              <span class="theme-inner" (click)="selectBadge(badge)">
                <img [src]="urlLocation + badge.mobile_url" [alt]="badge.name" [ngClass]="{'focus-div-blue': selectedBadge[0] === badge}">
              </span>
            </span>
          </ng-container>
          <!-- <ng-container *ngIf="showPastSignups">
            <span class="theme-outer"  *ngFor="let badge of pastLogosArr; let i = index">
              <span class="theme-inner" (click)="selectBadge(badge)">
                <img class="logo-theme" [src]="badge.image.image.thumb.url" [ngClass]="{'focus-div-blue': selectedBadge[0] === badge}">
              </span>
            </span>        
          </ng-container> -->

        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
