import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StorageService } from '../../shared-services/storage.service';
import { FormGroup, FormControl } from '@angular/forms';

declare var google: any;

@Component({
  selector: 'app-google-places-autocomplete',
  templateUrl: './google-places-autocomplete.component.html',
  styleUrls: ['./google-places-autocomplete.component.scss']
})
export class GooglePlacesAutocompleteComponent implements OnInit {

  constructor(private storageService: StorageService) {
  }

    //output and input values passed to and from parent controller
    @Output() updateLocation = new EventEmitter();

  selectedLocation: string = null;
  locationForm: FormGroup;

  ngOnInit() {

    if (this.storageService.get('selectedActivity')) {
      this.selectedLocation = this.storageService.get('selectedActivity')['location'];
      console.log('this.selectedLocation', this.selectedLocation);
    }

    this.locationForm = new FormGroup({
      location: new FormControl('')
    })

    if (this.selectedLocation) {
      this.locationForm.patchValue({location: this.selectedLocation});
    }
  }

  autocompleteFocus(ev) {
    let typedLocation, 
    storageService = this.storageService,
    locationForm = this.locationForm,
    updateLocation = this.updateLocation,
    autocomplete =  new google.maps.places.Autocomplete(
      document.getElementById("create-auto-complete")
    )
    autocomplete.addListener('place_changed', function() {
      var place = autocomplete.getPlace();
      if (place.geometry) {
        //suggested place was selected
        console.log(place.formatted_address);
        typedLocation = place.formatted_address;
      } else {
        //just select whatever was typed in
        typedLocation = locationForm.get('location').value;
      }
      locationForm.patchValue({ location: typedLocation });
      updateLocation.emit({ location: typedLocation });
    });
  }

  setInput(ev) {
    //on blur, set location to whatever was typed
    this.locationForm.patchValue({ location: ev.target.value });
    this.updateLocation.emit({ location: ev.target.value });
  }
}
