<div [formGroup]="editParticipantForm">
  <div class="container" [ngStyle]="{'padding-bottom': creating ? '5rem' : '0'}">
    <div class="form-group flex-column">
      <label class="edit-label-name">{{'_ParticipantInfo_'| translate}}
        <button type="button" class="tooltip-btn" ngxTippy tippyName="addFieldInfo" [tippyProps]="tippyProps">
          <i class="material-icons info-icon">info</i>
        </button>
      </label>
      <mat-checkbox disabled checked="checked">{{'_FullName_' | translate}} <i>({{'_Required_' | translate}})</i>
      </mat-checkbox>
      <mat-checkbox disabled checked="checked">{{'_Email_' | translate}} <i>({{'_Required_' | translate}})</i>
      </mat-checkbox>
      <mat-checkbox formControlName="requirePhone" (change)=isPhoneRequired($event)
        [value]="phoneRequired">{{'_PhoneNumber_' | translate}}
        <span *ngIf="phoneRequired"><i>({{'_Required_' | translate}})</i></span>
      </mat-checkbox>
    </div>
    <div class="form-group">
      <label class="edit-label-name">{{'_ParticipantCustomQuestions_'| translate}}
        <button type="button" class="tooltip-btn" ngxTippy tippyName="addFieldHelp" [tippyProps]="tippyProps">
          <i class="material-icons info-icon">info</i>
        </button>
      </label>
    </div>

    <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>

    <div *ngIf="extraFieldArr.length">
      <!-- new extra fields -->
      <div cdkDropList (cdkDropListDropped)="drop($event)" class="drag-drop-container">
        <div *ngFor="let field of extraFieldArr; let i = index" class="drag-drop-list" cdkDrag>
          <div class="drag-drop-placeholder" *cdkDragPlaceholder></div>
          <span cdkDragHandle class="reorder-icon"><i class="material-icons">reorder</i></span>
          <span class="field-title truncate">
            <span *ngIf="field.required" class="required-asterisk">* </span>
            {{field.title}}
          </span>
        </div>
      </div>
    </div>
    <a *ngIf="isOwner" class="blue-link add-link" (click)="onSubmit('custom_field')">{{'_AddManageQuestions_' |
      translate}} <i class="material-icons">edit</i></a>
  </div>
  <div class="button-bottom" *ngIf="editing">
    <button type="button" (click)="onSubmit()" [disabled]="!editParticipantForm.valid"
      class="btn btn-blue edit-update-btn">{{'_Update_' | translate}}</button>
    <div class="edit-cancel-btn" (click)=cancelChanges()>{{'_Cancel_' | translate}}</div>
  </div>
</div>