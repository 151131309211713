import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import { StorageService } from '../shared-services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private router:Router,
              private storageService: StorageService) { }

  resolve(status) {
    if (status === 403) {
      this.router.navigateByUrl('/organizer/login'); 
      //also log user out to clear local variables
      this.storageService.reset();  
      this.storageService.set('full_login', false, null);  

    }
  }

}
