  <div class="page-header" [ngStyle]="{'border-bottom': icon !== 'logo' ? '1px solid rgba(0,0,0,.1)' : '0'}">
        <span class="logo-container">
          <img *ngIf="icon == 'logo'" class="logo" src="assets/images/vspot_logo.png" alt="" />
          <a *ngIf="icon == 'back'" (click) = "goBack()" class="c-pointer"><img class="back-icon" src="assets/images/back.png" alt=""></a>
          <a *ngIf="icon == 'home'" routerLink="/dashboard"><img class="home-icon" src="assets/images/home-icon.png" alt=""></a>
          <span *ngIf= "backText" (click) = "goBack()" class="back-text truncate">{{backText | translate}}</span>
        </span>
        <!-- <span *ngIf="title" class="title-text">
            <span class="default-blue" *ngIf = "title === 'showLogo'"><img icon="logo" src="assets/images/vspot_logo.png" alt="" class="logo" ></span>
            <h5 *ngIf = "title !== 'showLogo'" class="truncate">{{title | translate}}</h5>
        </span> -->
        <span *ngIf="title &&  title !== 'showLogo'" class="title-text"  [ngStyle]="{'width': userLoggedIn ? '61%' : 'auto'}">
          <h5 class="truncate">{{title | translate}}</h5>
        </span>
        <span *ngIf="title &&  title == 'showLogo'" class="title-logo">
          <span><img icon="logo" src="assets/images/vspot_logo.png" alt="" class="logo" ></span>
        </span>
        <span *ngIf="showNav == 'true'" class="nav-icon">
          <a (click) = "openNav()" class="c-pointer"><img class="nav-icon" src="assets/images/menu.png" alt=""></a>
        </span>
        <!-- <span class="spacer"></span> -->
    <!-- <div *ngIf = "icon !== 'logo'">
        <hr>
    </div> -->
  </div>

  <!-- nav -->
  <div class="clearfix overlay" *ngIf="openMenu" [ngClass]="{'open': openMenu}">
        <div class="open-menu-container">
          <div class="float-left open-menu">
                <a routerLink = "/" class="c-pointer"><img src="assets/images/vspot_logo.png" alt=""></a>
            </div>
            <div class="float-right close-btn">
                <a (click) = "closeNav()" class="c-pointer"><img src="assets/images/close-btn.png" alt=""></a>
              </div>
        </div>
      
      <div class="nav-items">
        <!--logged out menu -->
        <ul *ngIf="!userLoggedIn">
          <li><a (click)="openLink(url + '/Pricing')"> 
            {{'_FeaturesPricing_'| translate}}
            <img src="assets/images/mobile-chevron.png" alt=""></a>
          </li>
          <li><a (click) = "findMySignups()">
            {{'_FindSignUpInvitations_'| translate}}
              <img src="assets/images/mobile-chevron.png" alt=""></a>
          </li>
          <li><a (click)="openLink(url + '/Idea-Center')">
              {{'_IdeaCenter_'| translate}}
              <img src="assets/images/mobile-chevron.png" alt=""></a>
          </li>
          <li><a (click)="openLink(url + '/Why-Use-Us')">
              {{'_AboutSignUp_'| translate}}
              <img src="assets/images/mobile-chevron.png" alt=""></a>
          </li>
          <li><a (click)="openLink(url + '/Help')">
            {{'_Help_'| translate}}
              <img src="assets/images/mobile-chevron.png" alt=""></a>
          </li>
          <li><a (click)="openLink(url + '/?mobile=disable')">
            {{'_DesktopSite_'| translate}}
              <img src="assets/images/mobile-chevron.png" alt=""></a>
          </li>
          <li>
            <a (click) = "signIn()" class="btn btn-blue btn-block">
              {{'_SignIn_'| translate}}
            </a>
          </li>
        </ul>
      
        <!-- logged in menu -->
        <ul *ngIf="userLoggedIn">
            <li><a routerLink="/profile_settings">
              {{'_ProfileSettings_'| translate}}
              <img src="assets/images/mobile-chevron.png" alt=""></a>
            </li>
            <li><a (click)="openLinkInApp(url + '/mobileweb/2.0/vspot.html#volunteer_hours_page')">
              {{'_MyServiceHours_'| translate}}
                <img src="assets/images/mobile-chevron.png" alt=""></a>
            </li>
            <li *ngIf="checkInEnabled"><a (click)="openLinkInApp(url + '/mobileweb/2.0/vspot.html#checkin_steps_page')">
              {{'_Checkin_'| translate}}
              <img src="assets/images/mobile-chevron.png" alt=""></a>
          </li>
            <li><a (click)="openLink(url + '/Help')">
              {{'_Help_'| translate}}
                <img src="assets/images/mobile-chevron.png" alt=""></a>
            </li>
            <li><a (click)="openLink(url + '/?mobile=disable')">
              {{'_DesktopSite_'| translate}}
                <img src="assets/images/mobile-chevron.png" alt=""></a>
            </li>
            <li>
              <a (click) = "signOut()" class="btn btn-gray btn-block">
                {{'_SignOut_'| translate}}
              </a>
            </li>
        </ul>
      </div>
    </div>
