import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { OrganizerComponent } from './homepage/organizer/organizer.component';
import { RegisterComponent} from './homepage/organizer/register/register.component';
import { LoginComponent } from './homepage/organizer/login/login.component';
import { ForgotPswdComponent } from './homepage/forgot-pswd/forgot-pswd.component';
import { HomepageComponent} from './homepage/homepage.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardCurrentComponent } from './dashboard/dashboard-current/dashboard-current.component';
//manage events
import { EditComponent } from './edit-events/edit.component';
import { EditSpotComponent } from './edit-events/edit-spots/edit-spots.component';
import { EditParticipantComponent } from './edit-events/edit-participants/edit-participants.component';
import { EditDetailComponent } from './edit-events/edit-details/edit-details.component'; 
import { EditSpotsDetailComponent} from './edit-events/edit-spots-detail/edit-spots-detail.component';
import { EditJobComponent } from './edit-events/edit-job/edit-job.component';
import { EditJobSetRepeatingComponent } from './edit-events/edit-job-set-repeating/edit-job-set-repeating.component';
import { EditAssignSpotComponent } from './edit-events/edit-assign-spot/edit-assign-spot.component';
import { EditParticipantDetailComponent } from './edit-events/edit-participant-detail/edit-participant-detail.component';
import { EditSelectParticipantsComponent } from './edit-events/edit-select-participants/edit-select-participants.component';
import { EditSpotShiftsComponent } from './edit-events/edit-spot-shifts/edit-spot-shifts.component';
import { AddNewSpotComponent } from './edit-events/add-new-spot/add-new-spot.component';
//edit detail pages
import { EditSignupInfoComponent } from './edit-events/edit-signup-info/edit-signup-info.component';
import { EditParticipantInfoComponent } from './edit-events/edit-participant-info/edit-participant-info.component';
import { EditOrganizerInfoComponent } from './edit-events/edit-organizer-info/edit-organizer-info.component';
import { EditNotificationSettingsComponent } from './edit-events/edit-notification-settings/edit-notification-settings.component';
import { EditThemeInfoComponent } from './edit-events/edit-theme-info/edit-theme-info.component';
import { EditTimezoneComponent } from './edit-events/edit-timezone/edit-timezone.component';
//shared
import { ProfileSettingsComponent } from './shared/profile-settings/profile-settings.component';
import { InviteParticipantsComponent } from './shared/invite-participants/invite-participants.component';
import { ExtraSpecialFieldsComponent } from './shared/extra-special-fields/extra-special-fields.component';        
import { AddEditXtraFieldsComponent } from './shared/add-edit-xtra-fields/add-edit-xtra-fields.component';   
import { QuicksearchComponent } from './shared/quicksearch/quicksearch.component';     
import { QuicksearchDetailComponent } from './shared/quicksearch-detail/quicksearch-detail.component';                              

//messages
import { MessagesComponent } from './shared/messages/messages.component';
import { RecipientFilterComponent } from './shared/messages/recipient-filter/recipient-filter.component';
import { RecipientFilterByDateComponent } from './shared/messages/recipient-filter-by-date/recipient-filter-by-date.component';
import { RecipientFilterByNameComponent } from './shared/messages/recipient-filter-by-name/recipient-filter-by-name.component';
import { RecipientFilterBySpotComponent } from './shared/messages/recipient-filter-by-spot/recipient-filter-by-spot.component';
import { RecipientFilterByStatusComponent } from './shared/messages/recipient-filter-by-status/recipient-filter-by-status.component';
//create spots
import { CreateSignupComponent } from './create-signup/create-signup.component';
import { DonePageComponent } from './create-signup/done-page/done-page.component';

const routes: Routes = [

  //home
  { path: 'organizer', component: OrganizerComponent,
    children: [
      { path: 'register', component: RegisterComponent },
      { path: 'login', component: LoginComponent }
    ]
  },
  { path: 'register',  redirectTo: 'organizer', pathMatch: 'full' }, //redirect previous register page
  { path: 'forgotpswd', component: ForgotPswdComponent },
  { path: '', component: HomepageComponent, pathMatch: 'full'}, 
  //dashboard
  { path: 'dashboard', component: DashboardCurrentComponent },
  //manage events
  { path: 'edit', component: EditComponent,
    children: [
      { path: 'spot/:id', component: EditSpotComponent },
      { path: 'participant/:id', component: EditParticipantComponent},
      { path: 'detail/:id', component: EditDetailComponent }
    ]
  },
  { path: 'edit_spots_detail/:id', component:  EditSpotsDetailComponent },
  { path: 'edit_job/:id', component:  EditJobComponent },
  { path: 'edit_job_set_repeating', component:  EditJobSetRepeatingComponent },
  { path: 'edit_assign_spot/:id', component:  EditAssignSpotComponent },
  { path: 'edit_participant_detail/:id', component:  EditParticipantDetailComponent },
  // { path: 'edit_single_participant/:id', component: EditSingleParticipantComponent },
  { path: 'edit_select_participant/:id', component: EditSelectParticipantsComponent },
  { path: 'edit_spot_shifts', component: EditSpotShiftsComponent },
  { path: 'add_new_spot', component: AddNewSpotComponent},
    //edit detail pages
  { path: 'edit_signup_info/:id', component: EditSignupInfoComponent },
  { path: 'edit_participant_info/:id', component: EditParticipantInfoComponent },
  { path: 'edit_organizer_info/:id', component: EditOrganizerInfoComponent },
  { path: 'edit_theme_info/:id', component: EditThemeInfoComponent },
  { path: 'edit_notification_settings/:id', component: EditNotificationSettingsComponent },
  { path: 'edit_timezone/:id', component: EditTimezoneComponent },
  //shared
  { path: 'profile_settings', component: ProfileSettingsComponent },
  { path: 'invite', component: InviteParticipantsComponent },
  { path: 'extra-special-fields', component: ExtraSpecialFieldsComponent},
  { path: 'addxtrafields', component: AddEditXtraFieldsComponent },
  { path: 'quicksearch', component: QuicksearchComponent },
  { path: 'quicksearch-detail', component: QuicksearchDetailComponent },
  //messages
  { path: 'messages', component: MessagesComponent },
  { path: 'recipient-filter', component: RecipientFilterComponent },
  { path: 'recipient-filter-by-date', component: RecipientFilterByDateComponent },
  { path: 'recipient-filter-by-name', component: RecipientFilterByNameComponent },
  { path: 'recipient-filter-by-spot', component: RecipientFilterBySpotComponent },
  { path: 'recipient-filter-by-status', component: RecipientFilterByStatusComponent },
  //create wizard
  { path: 'create', component: CreateSignupComponent },
  { path: 'done-page', component: DonePageComponent }
  ];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, {
    useHash: true
}),
  RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
