import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { MatCalendar } from '@angular/material/datepicker';
import * as moment_ from 'moment';
import 'moment-recur-ts';

import { StorageService } from '../../shared-services/storage.service';
import { TranslateService } from '@ngx-translate/core';

const moment = moment_;

@Component({
  selector: 'app-edit-job-set-repeating',
  templateUrl: './edit-job-set-repeating.component.html',
  styleUrls: ['./edit-job-set-repeating.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class EditJobSetRepeatingComponent implements OnInit {

  constructor(private storageService: StorageService,
    private translate: TranslateService,
    private router: Router) { }

  editRepeatingJobForm: FormGroup;
  job: {};
  jobQuantity: any;
  jobDate: any;
  jobInterval: string;
  jobIntervalType: string;
  minEndDate: any;
  daysSelected: any[] = [];
  event: any;
  irregularSummary: string;
  customSummary: string;
  repeatSummary: string;
  serverSummary: string;  //slightly different for the server
  endInstance: number = 1;;
  changedVal: boolean = false;
  isEdit: boolean = false;
  repeatingJSONData = {}; //data to send back to server
  dayOfMonth: {};
  dayOfWeek: {};
  formIsValid: boolean = false;

  //repeating info
  repeating: boolean = false;
  repeatIntervalUnit: number;
  repeatInterval: string;
  repeatingJSON: {};
  repeatingJobs: any[] = [];
  origRepeatingJobs: {};  //save original repeating jobs
  repeatingJobsNewArr: string[] = [];

  repeatStartDate: any;
  repeatEndDate: any;
  repeatMonthType: string;
  repeatDayOfWeek: {};
  repeatDayOfWeekArr: string[] = [];   //to push selected days of week
  repeatDayOfMonth: {};
  repeatMonthly: {};
  visibleEndDate: string;
  visibleEndDateUpdated: boolean;
  noEvents: boolean = false;
  repeatError: boolean = false;

  calendarStartDate: any;
  calendarEndDate: any;
  backText: string;

  dateClass = (d: Date) => {
    //apply green color to pre-selected dates
    const date = moment(d).format('YYYY/MM/DD');
    return (this.daysSelected.includes(date)) ? 'selected' : undefined;
  }
  @ViewChild(MatCalendar, {static: true}) calendar: MatCalendar<Date>;

  disableJobDate = (d: Date) => {
      if (this.jobDate) {
        const date = moment(d).format('YYYY/MM/DD');
        let disableDate = moment(this.jobDate).format('YYYY/MM/DD');
        // disable the job date
        if (!this.storageService.get('creating')) {
          return date !== disableDate;
        } else {
          return date;
        }
      }
  }


  select(event: any, calendar: any) {
    const date = moment(event).format('YYYY/MM/DD');
    const index = this.daysSelected.findIndex(x => x == date);
    //add selected days to array, remove if its already added
    if (index < 0) this.daysSelected.push(date);
    else this.daysSelected.splice(index, 1);
    calendar.updateTodaysDate();
    this.getSummaryText();
    this.changedVal = true;
    // console.log('this.daysSelected ', this.daysSelected);
  }

  //this forces the day of week object to sort in the default order and not alphabetical
  sortOrder() { };

  ngOnInit() {
    // set initial values
    let quantity, jobDateFormatted,
        repeatingRemoved = this.storageService.get('originalRepeat');
    this.isEdit = this.job && this.job['id'] ? true : false;
    this.job = this.storageService.get('job');
    //get initial values to be sent back to server
    this.dayOfMonth = this.storageService.get('dayOfMonth');
    this.dayOfWeek = this.storageService.get('dayOfWeek');
    this.calendarEndDate = moment("12-31-2037", "MM-DD-YYYY");
    if (this.storageService.get('jobQuantity')) {
      quantity = this.storageService.get('jobQuantity');
    } 

    if (this.job) {
      //editing mode
      this.jobDate = moment(this.job['date']).format('ddd MMM D, YYYY');
      this.repeating = !repeatingRemoved && this.job['repeating'] && this.job['repeating_jobs'] && this.job['repeating_jobs'].length > 1;
      this.minEndDate = this.jobDate ? moment(this.jobDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      this.calendarStartDate = moment(this.job['date']);
      if (!quantity) {
        quantity = this.job['quantity'];
      }
      this.jobQuantity = quantity !== -1 ? quantity : 'an unlimited number of ';
      jobDateFormatted = moment(this.jobDate).format('YYYY/MM/DD') ||  moment().format('YYYY/MM/DD');
      if (this.repeating === true || this.storageService.get('repeatingJSONData')) {
        this.repeatingJobs = this.storageService.get('repeatingJobs') || this.job['repeating_jobs'];
        this.repeatingJSON = this.storageService.get('repeatingJSONData') || JSON.parse(this.job['repeating_json']);
        this.repeatIntervalUnit = this.repeatingJSON['interval_unit'];
        this.repeatStartDate = this.repeatingJobs[0];
        this.repeatDayOfWeek = this.repeatingJSON['dayOfWeek'];
        this.repeatMonthType = this.repeatingJSON['monthly']['type'];
        this.jobInterval = this.repeatingJSON['interval'];
        if (this.jobInterval === 'monthly' || this.jobInterval === 'weekly' || this.jobInterval === 'daily') {
          this.jobIntervalType = 'custom';
          this.repeatInterval = this.repeatingJSON['interval'];
          this.repeatEndDate = moment(this.repeatingJobs[this.repeatingJobs.length -1]).format('YYYY-MM-DD');
        } else {
          //repeat is irregular
          this.jobIntervalType = this.repeatingJSON['interval'];
          this.repeatInterval = 'weekly';
          this.noEvents = false;
          this.repeatEndDate = moment(this.job['date']).add(1, 'days');   //end date is day after job date
        }
      } else {
        //set all values to default
        this.repeatIntervalUnit = 1;   //repeat interval number defaults to 1
        this.repeatInterval = 'weekly';   //repeat type defaults to weekly
        this.repeatStartDate = jobDateFormatted;  //default to job date or today's date
        this.repeatEndDate = moment(jobDateFormatted).add(1, 'days');   //default to a day after job date
        this.jobIntervalType = 'irregular';  //select irregular repeat by default
        this.repeatMonthType = 'each';  //select day of month by default
        this.repeatDayOfWeek = this.dayOfWeek;
      }
      this.origRepeatingJobs = this.repeatingJobs; //we need to save this in the dates object cos the desktop uses it
      this.backText='_EditSpot_';
    } else {
      //when creating, set defaults
      if (quantity) {
        this.jobQuantity = quantity !== -1 ? quantity : 'an unlimited number of ';
      } else {
        this.jobQuantity = 1;
      }
      this.repeatIntervalUnit = 1;   //repeat interval number defaults to 1
      this.repeatInterval = 'weekly';   //repeat type defaults to weekly
      this.jobDate = moment().format('ddd MMM D, YYYY');
      jobDateFormatted = moment().format('YYYY/MM/DD');
      this.repeatStartDate = jobDateFormatted;  //default to job date
      this.repeatEndDate = moment(jobDateFormatted).add(1, 'days');   //default to a day after job date
      this.jobIntervalType = 'irregular';  //select irregular repeat by default
      this.repeatMonthType = 'each';  //select day of month by default
      this.repeatDayOfWeek = this.dayOfWeek;
      this.backText='_AddNewSpot_';
      this.calendarStartDate = moment();
    }

    //check if the repeating sequence was removed from the edit job ui
    if (!repeatingRemoved) {
      //if selected days were saved in local storage, add them to the daysSelected array
      if (this.storageService.get('irregularDaysSelected')) {
        this.daysSelected = this.storageService.get('irregularDaysSelected');
      } else {
        this.daysSelected = this.repeatingJobs;
      }
    } 
    if (this.daysSelected.length < 1 && this.job) {
      // if empty, add todays date
      this.daysSelected.push(jobDateFormatted);
    }

    //initialize form
    this.editRepeatingJobForm = new FormGroup({
      jobIntervalType: new FormControl(this.jobIntervalType),
      repeatIntervalUnit: new FormControl(this.repeatIntervalUnit),
      customInterval: new FormControl(this.repeatInterval),
      monthInterval: new FormControl(this.repeatMonthType),
      repeatEndsOn: new FormControl('repeatEndsOn'),
      repeatStartDate: new FormControl(this.repeatStartDate),
      repeatEndDate: new FormControl(this.repeatEndDate),
      repeatAfterNum: new FormControl('1')
    })
    this.getRepeatingDays();
    if (!this.storageService.get('originalRepeat')) {
      this.getSummaryText();
    }
    //check for changes
    this.formValueChanged();
  }
  

  onUpdate(event) {
    this.changedVal = true;
    this.repeatError = false;
    this.editRepeatingJobForm.patchValue({ repeatIntervalUnit: event });
    this.repeatIntervalUnit = event;
    this.getSummaryText();
  }

  onError(event) {
    this.repeatError = true;
  }

  isFormValid() {
    if (this.noEvents || this.repeatError) {
      //form is only valid if there are no errors
      this.formIsValid = false;
    } else {
      this.formIsValid = true;
    }
  }

  formValueChanged() {
    //check for value changes
    this.editRepeatingJobForm.valueChanges.subscribe(val => {
      this.changedVal = true;
    })
  }

  getDayofWeek() {
    //eg Sunday, Monday, etc, for summary text
    let weekDayString: string,
      weekDay = moment(this.jobDate).isoWeekday();
    switch (weekDay) {
      case 1:
        weekDayString = 'Monday';
        break;
      case 2:
        weekDayString = 'Tuesday';
        break;
      case 3:
        weekDayString = 'Wednesday';
        break;
      case 4:
        weekDayString = 'Thursday';
        break;
      case 5:
        weekDayString = 'Friday';
        break;
      case 6:
        weekDayString = 'Saturday';
        break;
      case 7:
        weekDayString = 'Sunday';
        break;
    }
    return weekDayString;
  }

  getWeekOfMonth() {
    //eg 1st, 2nd, etc for summary text
    let weekNumberString: string,
      monthWeek = moment(this.jobDate).monthWeek() + 1;  //momentjs shows month week starting from 0
    switch (monthWeek) {
      case 1:
        weekNumberString = 'first';
        break;
      case 2:
        weekNumberString = 'second';
        break;
      case 3:
        weekNumberString = 'third';
        break;
      case 4:
        weekNumberString = 'fourth';
        break;
      case 5:
        weekNumberString = 'fifth';
        break;
    }
    return weekNumberString;
  }

  getRepeatingDays() {
    for (var key in this.repeatDayOfWeek) {
      if (this.repeatDayOfWeek[key] === true) {
        //capitalize first letter and add selected days to array
        key = key.charAt(0).toUpperCase() + key.substr(1);
        this.repeatDayOfWeekArr.push(key);
      }
    }
    if (this.repeatInterval === 'weekly') {
      if (!this.repeatDayOfWeekArr.length) {
        this.noEvents = true;
      } else {
        this.noEvents = false;
      }
    }
  }

  getSummaryText() {
    let repeatString = this.jobQuantity == '1' ? 'repeats' : 'repeat',
      repeatIntervalUnit = this.editRepeatingJobForm.get('repeatIntervalUnit').value,
      startDate = moment(this.repeatStartDate).format('MMM DD, YYYY'),
      endDate = moment(this.repeatEndDate).format('MMM DD, YYYY'),
      repeatInterval = this.repeatInterval,
      repeatDayOfWeekArr = this.repeatDayOfWeekArr.slice(0),  //making a copy cos we dont want to change the array
      repeatType: string,
      repeatIntervalString: string,
      weeklyDates: string,
      lastDay: string,
      endDateString: string,
      endString: string,
      tmpString: string = '';

    if (this.jobIntervalType === 'custom') {
      this.getNewRepeatingJobs(moment.utc(this.repeatStartDate), moment.utc(this.repeatEndDate), repeatInterval, repeatIntervalUnit);
      //custom repeat
      endDateString = ' from ' + startDate + ' until ' + endDate + '.';
      if (repeatIntervalUnit === 0 || null) {
        //no events will be generated since the interval is 0
        this.noEvents = true;
      } else {
        switch (repeatInterval) {
          case 'weekly':
            repeatType = ' weeks ';
            break;
          case 'monthly':
            repeatType = ' months ';
            break;
          case 'daily':
            repeatType = ' days ';
            break;
        }
        if (repeatIntervalUnit === 1) {
          repeatIntervalString = repeatInterval;
        } else {
          repeatIntervalString = 'every ' + repeatIntervalUnit + repeatType;
        }
        endString = repeatIntervalString + tmpString + endDateString;
        if (repeatInterval === 'weekly') {
          //weekly text addition
          // if (repeatDayOfWeekArr.length) {
        if (this.repeatingJobsNewArr.length) {
            this.noEvents = false;
            if (repeatDayOfWeekArr.length > 1) {
              lastDay = repeatDayOfWeekArr.pop();
              weeklyDates = repeatDayOfWeekArr.join(', ') + ' and ' + lastDay;
            } else {
              //
              weeklyDates = repeatDayOfWeekArr.join('');
            }
            tmpString = ' on ' + weeklyDates;
          } else {
            //no days are selected, show no events message
            this.noEvents = true;
            endString = '';
          }
        } else if (repeatInterval === 'monthly') {
          this.noEvents = false;
          if (this.repeatMonthType === 'on') {
            //text includes week of month, eg 1st Saturday, 2nd Sunday, etc
            let weekOfMonth = this.getWeekOfMonth(),
              weekDay = this.getDayofWeek();
            tmpString = ' on the ' + weekOfMonth + ' ' + weekDay + ' of the month ';
          } else {
            //text is on exact month date
            tmpString = '';
          }
          endString = repeatIntervalString + tmpString + endDateString;
        }
        if (endString) {
          endString = repeatIntervalString + tmpString + endDateString;
          this.repeatSummary = 'Repeats ' + endString;
          this.customSummary = 'that ' + repeatString + ' ' + endString;
          this.serverSummary = 'that repeats ' + endString;
        }
      }
    } else {
      //irregular repeat
      this.noEvents = false;
      if (this.daysSelected.length) {
        this.irregularSummary = ' from a group of ' + this.daysSelected.length + ' days';
        this.repeatSummary = 'Repeats' + this.irregularSummary;
      } else if (this.daysSelected.length === 1) {
        this.irregularSummary = '';
        this.repeatSummary = '';
      } else {
        this.irregularSummary = '';
      }
      this.serverSummary = 'on ' + this.daysSelected.length + ' days';
    }
    // console.log(this.repeatSummary);
    //check if form is valid
    this.isFormValid();
  }

  getNewRepeatingJobs(startDate, endDate, repeatInterval, repeatIntervalUnit) {
    if (repeatInterval === 'daily') {
      //daily repeat interval
      let tmpDates, tmpDatesArr = [];
      this.repeatingJobsNewArr = [];
      tmpDatesArr = moment().recur(startDate, endDate).every(repeatIntervalUnit).day().all("YYYY-MM-DD");
      this.repeatingJobsNewArr = tmpDatesArr;
      // console.log('repeatingJobsNewArr ', this.repeatingJobsNewArr);
      if (this.repeatingJobsNewArr.length) {
        //there are job created
        this.noEvents = false;
      }
    } else if (repeatInterval === 'weekly') {
      //weekly repeat interval
      this.getWeeklyRepeats(endDate, repeatIntervalUnit);
    } else if (repeatInterval === 'monthly') {
      //monthly repeat interval
      this.getMonthlyRepeats(startDate, endDate, repeatIntervalUnit);
    } 
  }

  getWeeklyRepeats(endDate, repeatIntervalUnit) {
    let weekDates, tmpWeeklyDates = [], tmpEndDate;
    if (this.repeatDayOfWeekArr.length) {
      let startOfWeek = moment(this.jobDate).startOf('week').format(),
        endOfWeek = moment(this.jobDate).endOf('week').format();
      // the first week is a bit tricky because we have 
      //to search from the beginning of the week but can only
      //save dates that start after the job date
      weekDates = (moment().recur(startOfWeek, endOfWeek).every(this.repeatDayOfWeekArr).daysOfWeek().all("YYYY-MM-DD"));
      weekDates.forEach(item => {
        if (moment(item).isSameOrAfter(moment(this.jobDate).format()) && moment(item).isSameOrBefore(moment(endDate).format())) {
          // tmpWeeklyDates.push({ date: item });
          tmpWeeklyDates.push(item);
        }
      })
      startOfWeek = moment(startOfWeek).add(repeatIntervalUnit, "weeks").format();
      endOfWeek = moment(endOfWeek).add(repeatIntervalUnit, "weeks").format();
      while (moment(endOfWeek).isSameOrBefore(moment(endDate).format())){
        //run do while loop for the other weeks
        weekDates = (moment(startOfWeek).recur(startOfWeek, endOfWeek).every(this.repeatDayOfWeekArr).daysOfWeek().all("YYYY-MM-DD"));
        weekDates.forEach(item => {
          tmpWeeklyDates.push(item);
        })
        startOfWeek = moment(startOfWeek).add(repeatIntervalUnit, "weeks").format();
        tmpEndDate = moment(endOfWeek).add(repeatIntervalUnit, "weeks").format();
        if (moment(tmpEndDate).isoWeek() === moment(moment(endDate).format()).isoWeek() ) {
          // if the end of week and end date are in the same week, make the end date the end of week
          endOfWeek = moment(endDate).format();
        } else {
          endOfWeek = moment(endOfWeek).add(repeatIntervalUnit, "weeks").format();
        }
      } 

      //reset repeating array
      this.repeatingJobsNewArr = [];
      this.repeatingJobsNewArr = tmpWeeklyDates;
      if (this.repeatingJobsNewArr.length) {
        //there are job created
        this.noEvents = false;
      } else {
        this.noEvents = true;
      }
    } else {
      this.repeatingJobsNewArr = [];
      this.noEvents = true;
    }
    // console.log('this.repeatingJobsNewArr ', this.repeatingJobsNewArr)
  }

  getMonthlyRepeats(startDate, endDate, repeatIntervalUnit) {
    let monthWeek;
    if (this.repeatMonthType === 'on') {
      //week of month, eg 1st Saturday, 2nd Sunday, etc
      let dateArr = [], count = 0, nextDate, getWeekDate;
      monthWeek = moment(this.jobDate).monthWeek();
      //find numeric value of the job date, eg 0 for sun, 1 for mon, etc
      getWeekDate = this.nthWeekdayOfMonth(moment(this.jobDate).isoWeekday(), monthWeek, moment(startDate).format());


      do {
        count += repeatIntervalUnit;
        // dateArr.push({ getWeekDate })
        dateArr.push(getWeekDate); 
        nextDate = moment(startDate).add(count, 'months').startOf('week').format();
        getWeekDate = this.nthWeekdayOfMonth(moment(this.jobDate).isoWeekday(), monthWeek, nextDate);
      } while (moment(getWeekDate).isSameOrBefore(moment(endDate).format()))
      this.repeatingJobsNewArr = [];
      this.repeatingJobsNewArr = dateArr;
    } else {
      //date is on exact month date eg every 5th of the month that repeats every 2 months
      let monthDates, monthDatesArr = [];
      this.repeatingJobsNewArr = [];
      monthDates = moment().recur(this.jobDate, endDate).every(repeatIntervalUnit).month().all("YYYY-MM-DD");                                   
      this.repeatingJobsNewArr = monthDates;
    }
    // console.log('repeatingJobsNewArr ', this.repeatingJobsNewArr)
    if (this.repeatingJobsNewArr.length) {
      //there are job created
      this.noEvents = false;
    }
  }

  nthWeekdayOfMonth(weekday, interval, currentDate) {
    //example every 5th Monday that repeats every 2 months
    var count = 0, tmpDate,
      month = moment(currentDate).month(),
      newDate = new Date(moment(currentDate).year(), month, 1),
      fifthWeek: boolean = false;
    // check if month has 5 weeks
    if (moment(currentDate).endOf('month').monthWeek() === 4) {
      fifthWeek = true;
    } else {
      fifthWeek = false;
    }

    while (true) {
      if (moment(newDate).day() === weekday) {
        //check if date is the same week day we're checking
        if (interval === 4) {
          //if interval is 5, let it iterate only 4 times since some months only have 4 weeks
          if (++count == interval) {
            break;
          }
        } else {
          if (++count == interval + 1) {
            //let the counter break when you reach the desired interval
            break;
          }
        }
      }
      newDate.setDate(newDate.getDate() + 1);
    }
    if (fifthWeek && interval === 4) {
      //month has 5 weeks, check to see if the next week is in same month;
      tmpDate = moment(newDate).add(1, 'weeks').toDate();
      if (moment(tmpDate).month() === month) {
        //the next date is in the 5th week,let's make it our new date
        newDate = tmpDate;
      }
    }
    return moment(newDate).format('YYYY-MM-DD');
  }

  setCustomRepeatInterval(val) {
    //set value to monthly, weekly or daily
    this.repeatInterval = val;
    this.getSummaryText();
    // console.log(this.repeatInterval);
    this.changedVal = true;
    //find the new end date
    this.getEndInstance(this.endInstance);
  }

  selectEndDate(val) {
    this.repeatEndDate = moment(val).format('MMM DD, YYYY');
    this.getSummaryText();
    // console.log('end date selected', this.repeatEndDate);
    this.changedVal = true;
  }

  setJobIntervalType(val) {
    //set job interval type to custom or irregular
    this.jobIntervalType = val;
    this.getSummaryText();
    this.changedVal = true;
    if (val ==='irregular') {
      this.noEvents = false;
    }
  }

  setMonthType(val) {
    //set month type to 'on' or 'each'
    // console.log('month type ', val);
    this.repeatMonthType = val;
    this.getSummaryText();
    this.changedVal = true;
  }

  selectDayOfWeek(item) {
    //this is called when you select one of the days under weekly repeat
    this.repeatDayOfWeekArr = [];
    //clear it when a new option is selected so that the array is in 
    //the correct order or it becomes too complicated
    if (item['value'] === true) {
      item['value'] = false;
    } else {
      item['value'] = true;
    }
    // console.log('item value after click', item)
    for (var key in this.repeatDayOfWeek) {
      if (key === item.key) {
        this.repeatDayOfWeek[key] = item['value'];
      }
      if (this.repeatDayOfWeek[key] == true) {
        //values come in lower case, so I have to capitalize
        key = key.charAt(0).toUpperCase() + key.substr(1);
        this.repeatDayOfWeekArr.push(key);
      }
    }
    if (!this.repeatDayOfWeekArr.length) {
      this.noEvents = true;
    } else {
      this.noEvents = false;
    }
    // console.log('repeating array', this.repeatDayOfWeekArr)
    this.getSummaryText();
    this.changedVal = true;
  }

  setRepeatEnd(val) {
    //which repeat end option is selected, on or after?
    // console.log('repeat end ', val)
    if (val === 'repeatEndsAfter') {
      this.getEndInstance(this.endInstance);
    } else {
      this.repeatEndDate = this.editRepeatingJobForm.get('repeatEndDate').value;
    }
    this.getSummaryText();
    // console.log('repeatingJobsNewArr ', this.repeatingJobsNewArr)
    this.changedVal = true;
  }

  getEndInstance(val) {
    //this is called when the number spinner is changed for repeat ends after
    this.endInstance = val;
    if (this.repeatInterval === 'daily') {
      this.repeatEndDate = moment(this.repeatStartDate).add(val, 'days').format('YYYY-MM-DD');
    } else if (this.repeatInterval === 'weekly') {
      this.repeatEndDate = moment(this.repeatStartDate).add(val, 'weeks').format('YYYY-MM-DD');
    } else if (this.repeatInterval === 'monthly') {
      this.repeatEndDate = moment(this.repeatStartDate).add(val, 'months').format('YYYY-MM-DD');
    }
    // console.log('End date instance ', this.repeatEndDate)
    this.getSummaryText();
    this.changedVal = true;
  }

  cancelChanges() {
    window.history.back();
  }

  onFormSubmit() {
    // format irregular date for server
    let startDate, endDate;
    //which interval data are we going to save?
    if (this.jobIntervalType === 'irregular') {
      this.repeatingJobs = this.daysSelected;
      this.storageService.set('repeatAdded', false);
    } else {
      this.repeatingJobs = this.repeatingJobsNewArr;
      this.storageService.set('repeatAdded', true);
    }
    startDate = this.repeatingJobs[0];
    endDate = this.repeatingJobs[this.repeatingJobs.length-1];
    this.storageService.set('repeatingJobs', this.repeatingJobs);
    this.repeatingJSONData = {
      interval: this.jobIntervalType === 'irregular' ? 'irregular' : this.repeatInterval,    // daily, weekly, monthly, irregular
      intervalShowing: false,
      interval_unit: this.repeatIntervalUnit,   // The repeating number, like in 'every 4 days', 4 is the interval_unit
      enddate: moment(endDate).format('YYYY-MM-DD'),
      dayOfWeek: this.repeatInterval === 'weekly' ? this.repeatDayOfWeek : this.dayOfWeek,
      dayOfMonth: this.dayOfMonth,
      monthly: {
        type: this.repeatInterval === 'monthly' ? this.repeatMonthType : 'each',   // 'each' or 'on'
        dayOfMonth: "first",     //  eg 'first', 'second'
        dayOfMonthShowing: false,
        dayOfWeek: "day",    // 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'day', 'weekday', 'weekendday'
        dayOfWeekShowing: false
      },
      visibleEndDate: moment(endDate).toISOString(),
      visibleEndDateUpdated: true,    // This indicates that the user changed it, not the system.
      startdate: moment(startDate).toISOString()
    }
    // console.log('server data', this.repeatingJSONData)
    this.storageService.set('originalRepeat', false);
    this.storageService.set('repeatingJSONData', this.repeatingJSONData, null);
    this.storageService.set('serverSummary', this.serverSummary);
    this.storageService.set('irregularDaysSelected', this.daysSelected);
    this.storageService.set('repeatSummary', this.repeatSummary, function () {
      window.history.back();
    });
  }
}
