<app-header icon="back" title="{{title}}" showNav="false" backText="_CustomQuestions_" changed="{{changedVal}}"></app-header>
<div class="add-esf-page body-container">
  <form [formGroup]="esfForm">
    <div class="container">
      <div class="form-group">
        <!-- questions to ask-->
        <label class="edit-label-name">{{'_Question_' | translate}} <span class="asterisk"> *</span></label>
        <input type="text" class="form-control" formControlName="question" maxLength="255" placeholder="{{'_ManageQuestionPlaceholder_' | translate}}">
        <div *ngIf="formSubmitted && esfForm.controls.question.errors?.required" class="error"> <small><span
          translate>_VALIDATION_REQUIRED_ERROR_</span></small></div>
        <div *ngIf="duplicateTitle && !esfForm.controls.question.errors?.required" class="error"> <small><span translate>_VALIDATION_UNIQUE_ERROR_</span></small></div>
      </div>
      <div class="form-group">
        <!-- answer format-->
        <label class="edit-label-name">{{'_AnswerFormat_' | translate}}<span class="asterisk"> *</span></label>
        <select class="form-control" formControlName="format" (change)="selectFormat()">
          <option value="" disabled>{{'_ChooseFormat_' | translate}}</option>
          <option *ngFor="let item of formatArr" [value]="item.value">{{item.string}}</option>
        </select>
        <div *ngIf="formSubmitted && esfForm.controls.format.errors?.required" class="error"> <small><span
          translate>_VALIDATION_REQUIRED_ERROR_</span></small></div>
      </div>
      <!-- format options-->
      <div class="form-group" *ngIf="this.selectedFormat=='dropdown'">
        <!-- drop down format question -->
        <label class="edit-label-name">{{'_DropdownListValues_' | translate}}<span class="asterisk"> *</span></label>
        <textarea class="form-control" formControlName="dropdown" rows="3" placeholder="{{'_ManageValuesPlaceholder_' | translate}}"></textarea>
        <div *ngIf="formSubmitted && esfForm.controls.dropdown.errors?.required" class="error"> <small><span
          translate>_VALIDATION_REQUIRED_ERROR_</span></small></div>
      </div>
      <div class="form-group" *ngIf="this.selectedFormat=='radio'">
        <!-- radio format question -->
        <label class="edit-label-name">{{'_RadioValues_' | translate}}<span class="asterisk"> *</span></label>
        <textarea class="form-control" formControlName="radio" rows="3" placeholder="{{'_ManageValuesPlaceholder_' | translate}}"></textarea>
        <div *ngIf="formSubmitted && esfForm.controls.radio.errors?.required" class="error"> <small><span
          translate>_VALIDATION_REQUIRED_ERROR_</span></small></div>
      </div>
      <div class="form-group" *ngIf="this.selectedFormat=='checkbox'">
        <!-- checkbox format question -->
        <label class="edit-label-name">{{'_CheckboxValues_' | translate}}<span class="asterisk"> *</span></label>
        <!-- <textarea class="form-control" formControlName="checkbox" rows="3" (keyup)="countWords()" placeholder="{{'_ManageValuesPlaceholder_' | translate}}"></textarea> -->
        <textarea class="form-control" formControlName="checkbox" rows="3" placeholder="{{'_ManageValuesPlaceholder_' | translate}}"></textarea>
      </div>
      <!-- <div class="form-group" *ngIf="this.selectedFormat=='checkbox' && showCheckboxMin"> -->
        <!-- checkbox format requirement -->
        <!-- <label class="edit-label-name">Must Check</label> -->
        <!-- COMMENTED OUT FOR NOW -->
        <!-- <div class="flex-container">
          <span>
            <select class="form-control" formControlName="checkboxMin" (change)="validateCheckedNum()">
              <option *ngFor="let item of checkboxArr" [value]="item">{{item}}</option>
            </select>
            <span class="smaller-text">Min</span>
          </span>
          <span>
            <select class="form-control" formControlName="checkboxMax" (change)="validateCheckedNum()">
              <option *ngFor="let item of checkboxArr" [value]="item">{{item}}</option>
            </select>
            <span class="smaller-text">Max</span>
          </span>
        </div> -->
        <!-- <div *ngIf="chkboxNumError" class="error">The min must be smaller than the max</div> -->
      <!-- </div> -->
      <div class="form-group" *ngIf="this.selectedFormat=='number'">
        <!-- number format question -->
        <label class="edit-label-name">{{'_NumberRange_' | translate}}</label>
        <div class="flex-container">
          <span>
            <input class="form-control" type="number" formControlName="numberMin" (blur)="validateNum($event)" (keyup)="validateNum($event)">
            <span class="smaller-text">{{'_min_' | translate}}</span>
          </span>
          <span>
            <input class="form-control" type="number" formControlName="numberMax" (blur)="validateNum($event)" (keyup)="validateNum($event)">
            <span class="smaller-text">{{'_max_' | translate}}</span>
          </span>
        </div>
        <div *ngIf="numError" class="error">{{'_minError_' | translate}}</div>
      </div>
      <!-- <div class="form-group" *ngIf="this.selectedFormat=='date'"> -->
        <!-- date format question -->
        <!-- <label class="edit-label-name">Date<span class="asterisk"> *</span></label> -->
        <!-- <input type="date" class="form-control" min="minDate" max="maxDate" formControlName="date">
        <div *ngIf="formSubmitted && esfForm.controls.date.errors?.required" class="error"> <small><span
          translate>_VALIDATION_REQUIRED_ERROR_</span></small></div>
          <div *ngIf="formSubmitted && esfForm.controls.date.errors?.pattern" class="error"> <small><span
            translate>Enter a valid date in format mm/dd/yyyy</span></small></div> -->
      <!-- </div> -->


      <div class="form-group">
        <!-- required status -->
        <mat-checkbox formControlName="isRequired">{{'_Required_' | translate}}</mat-checkbox>
      </div>
      <div class="form-group">
        <!-- help comment -->
        <label class="edit-label-name">{{'_HelpComment_' | translate}}</label>
        <input type="text" class="form-control" formControlName="comment" maxLength="255" placeholder="{{'_ManageCommentPlaceholder_' | translate}}">
      </div>
    </div>

    <div class="button-bottom container">
      <button type="submit" [disabled]="numError || chkboxNumError" class="btn btn-blue wide-btn" (click)="submitForm()">{{'_Done_' | translate}}</button>
    </div>
  </form>
</div>