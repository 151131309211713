<app-header icon="back" title="_EditOrganizerInfoHeader_" backText="_Details_" showNav="false" changed="{{changedVal}}"></app-header>

<div class="edit-organizer-info-page body-container">
  <form [formGroup]="editOrganizerForm">
    <div class="container">
      <div class="form-group">
        <!-- primary organizer -->
        <label class="edit-label-name">{{'_PrimaryOrganizer_'| translate}}</label>
        <select class="form-control" formControlName="primaryOrganizer" (change)=setPrimaryOrg($event)>
          <option *ngFor="let item of primaryOrgArr; let i = index" [value]="item.id">{{item.name}}, {{item.email}}
          </option>
        </select>
      <!-- primary organizer info -->
      <div class="drag-drop-list drag-drop-container primary-org-list">
        <div class="field-title">
          <span class="truncate">{{primaryOrg['email']}}</span>
          <span class="list-name">{{primaryOrg['name']}}</span>
        </div>
        <span (click)="openFieldDialog('currentPryOrg', primaryOrg)" class="edit-icon"><i class="material-icons">edit</i></span>
      </div>
      </div>

      <!-- assistant organizers from server -->
      <ng-container>
          <label class="edit-label-name">{{'_AssistantOrganizer_'| translate}}</label>
      </ng-container>
      <div *ngFor="let item of primaryOrgArr; let i = index">
          <ng-container *ngIf="item.email !== primaryOrg['email']">
            <!-- omit primary organizer from this list -->
            <div class="drag-drop-list drag-drop-container">
                <div class="field-title">
                  <span class="truncate">{{item.email}}</span>
                  <span class="list-name">{{item.name}}</span>
                </div>
                <!-- show edit icons -->
                <span (click)="openFieldDialog('pryOrgArray', item, i)" class="edit-icon"><i class="material-icons">edit</i></span>
                <span *ngIf="isPrimary || user['email'] === item.email" (click)="removeOrganizer(item, i)" class="trash-icon"><i class="material-icons">delete</i></span>
            </div>
          </ng-container>
      </div>

      <!-- organizers newly added -->
      <div *ngFor="let item of newAsstOrgArr; let i = index">
          <div class="drag-drop-list drag-drop-container">
            <div class="field-title">
              <span class="truncate">{{item.email}}</span>
              <span class="list-name">{{item.name}}</span>
            </div>
              <!-- show edit icons -->
              <span (click)="openFieldDialog('asstOrgArray', item, i)" class="edit-icon"><i class="material-icons">edit</i></span>
              <span  *ngIf="isPrimary || user['email'] === item.email" (click)="removeOrganizer(item, i)" class="trash-icon"><i class="material-icons">delete</i></span>
          </div>
        </div>
      <div *ngIf="(newAsstOrgArr.length + primaryOrgArr.length < maxOrganizer) && isPrimary">
        <button class="btn-blue-dotted" (click)="openFieldDialog('newOrganizer')">
          <i class="material-icons">add</i> {{'_AddAsstOrganizerBtn_'| translate}}
        </button>
      </div>
    </div>
    
    <div class= "button-bottom">
      <button type="button" 
      class= "btn btn-blue edit-update-btn"
      (click)= "onOrgSubmit()">{{'_Update_' | translate}}</button>
      <div class="edit-cancel-btn" (click)=cancelChanges()>{{'_Cancel_' | translate}}</div>
    </div>
  </form>
</div>
