import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient,
    @Inject('BASE_API_URL') private apiURL:string) {}

  getAllSpots() {
    return this.http.get(this.apiURL + '/signup/get_all_my_spots/', {observe: 'response'});
  }
}
