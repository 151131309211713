import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StorageService } from '../../shared-services/storage.service';
import { ActivityService } from './../../shared-services/activity.service';

@Component({
  selector: 'app-edit-timezone',
  templateUrl: './edit-timezone.component.html',
  styleUrls: ['./edit-timezone.component.scss'],
})
export class EditTimezoneComponent implements OnInit {

  editTimeZoneForm: FormGroup;
  changedVal: boolean = false;
  enableTimeZone: boolean = false;
  activity: {};
  timeZoneValues = {
    'Pacific/Midway': "Midway Island",
    'Pacific/Pago_Pago': "American Samoa",
    'Pacific/Honolulu': "Hawaii",
    'America/Juneau': "Alaska",
    'America/Los_Angeles': "Pacific Time (US & Canada)",
    'America/Denver': "Mountain Time (US & Canada)",
    'America/Chicago': "Central Time (US & Canada)",
    'America/New_York': "Eastern Time (US & Canada)",
    'America/Phoenix': "Arizona",
    'America/Indiana/Indianapolis': "Indiana (East)",
    'America/Chihuahua': "Chihuahua",
    'America/Mazatlan': "Mazatlan",
    'America/Tijuana': "Tijuana",
    'America/Regina': "Saskatchewan",
    'America/Mexico_City': "Mexico City",
    'America/Monterrey': "Monterrey",
    'America/Guatemala': "Central America",
    'America/Bogota': "Bogota",
    'America/Lima': "Quito",
    'America/Halifax': "Atlantic Time (Canada)",
    'America/Caracas': "Caracas",
    'America/La_Paz': "La Paz",
    'America/Santiago': "Santiago",
    'America/St_Johns': "Newfoundland",
    'America/Sao_Paulo': "Brasilia",
    'America/Argentina/Buenos_Aires': "Buenos Aires",
    'America/Montevideo': "Montevideo",
    'America/Guyana': "Georgetown",
    'America/Godthab': "Greenland",
    'Atlantic/South_Georgia': "Mid-Atlantic",
    'Atlantic/Azores': "Azores",
    'Atlantic/Cape_Verde': "Cape Verde Is.",
    'Europe/Dublin': "Dublin",
    'Europe/London': "London",
    'Europe/Lisbon': "Lisbon",
    'Africa/Casablanca': "Casablanca",
    'Africa/Monrovia': "Monrovia",
    'Etc/UTC': "UTC",
    'Europe/Belgrade': "Belgrade",
    'Europe/Bratislava': "Bratislava",
    'Europe/Budapest': "Budapest",
    'Europe/Ljubljana': "Ljubljana",
    'Europe/Prague': "Prague",
    'Europe/Sarajevo': "Sarajevo",
    'Europe/Skopje': "Skopje",
    'Europe/Warsaw': "Warsaw",
    'Europe/Zagreb': "Zagreb",
    'Europe/Brussels': "Brussels",
    'Europe/Copenhagen': "Copenhagen",
    'Europe/Madrid': "Madrid",
    'Europe/Paris': "Paris",
    'Europe/Amsterdam': "Amsterdam",
    'Europe/Berlin': "Bern",
    'Europe/Rome': "Rome",
    'Europe/Stockholm': "Stockholm",
    'Europe/Vienna': "Vienna",
    'Africa/Algiers': "West Central Africa",
    'Europe/Bucharest': "Bucharest",
    'Africa/Cairo': "Cairo",
    'Europe/Helsinki': "Helsinki",
    'Europe/Kiev': "Kyiv",
    'Europe/Riga': "Riga",
    'Europe/Sofia': "Sofia",
    'Europe/Tallinn': "Tallinn",
    'Europe/Vilnius': "Vilnius",
    'Europe/Athens': "Athens",
    'Europe/Istanbul': "Istanbul",
    'Europe/Minsk': "Minsk",
    'Asia/Jerusalem': "Jerusalem",
    'Africa/Harare': "Harare",
    'Africa/Johannesburg': "Pretoria",
    'Europe/Kaliningrad': "Kaliningrad",
    'Europe/Moscow': "St. Petersburg",
    'Europe/Volgograd': "Volgograd",
    'Europe/Samara': "Samara",
    'Asia/Kuwait': "Kuwait",
    'Asia/Riyadh': "Riyadh",
    'Africa/Nairobi': "Nairobi",
    'Asia/Baghdad': "Baghdad",
    'Asia/Tehran': "Tehran",
    'Asia/Muscat': "Muscat",
    'Asia/Baku': "Baku",
    'Asia/Tbilisi': "Tbilisi",
    'Asia/Yerevan': "Yerevan",
    'Asia/Kabul': "Kabul",
    'Asia/Yekaterinburg': "Ekaterinburg",
    'Asia/Karachi': "Karachi",
    'Asia/Tashkent': "Tashkent",
    'Asia/Kolkata': "New Delhi",
    'Asia/Kathmandu': "Kathmandu",
    'Asia/Dhaka': "Dhaka",
    'Asia/Colombo': "Sri Jayawardenepura",
    'Asia/Almaty': "Almaty",
    'Asia/Novosibirsk': "Novosibirsk",
    'Asia/Rangoon': "Rangoon",
    'Asia/Bangkok': "Hanoi",
    'Asia/Jakarta': "Jakarta",
    'Asia/Krasnoyarsk': "Krasnoyarsk",
    'Asia/Shanghai': "Beijing",
    'Asia/Chongqing': "Chongqing",
    'Asia/Hong_Kong': "Hong Kong",
    'Asia/Urumqi': "Urumqi",
    'Asia/Kuala_Lumpur': "Kuala Lumpur",
    'Asia/Singapore': "Singapore",
    'Asia/Taipei': "Taipei",
    'Australia/Perth': "Perth",
    'Asia/Irkutsk': "Irkutsk",
    'Asia/Ulaanbaatar': "Ulaanbaatar",
    'Asia/Seoul': "Seoul",
    'Asia/Tokyo': "Tokyo",
    'Asia/Yakutsk': "Yakutsk",
    'Australia/Darwin': "Darwin",
    'Australia/Adelaide': "Adelaide",
    'Australia/Melbourne': "Melbourne",
    'Australia/Sydney': "Sydney",
    'Australia/Brisbane': "Brisbane",
    'Australia/Hobart': "Hobart",
    'Asia/Vladivostok': "Vladivostok",
    'Pacific/Guam': "Guam",
    'Pacific/Port_Moresby': "Port Moresby",
    'Asia/Magadan': "Magadan",
    'Asia/Srednekolymsk': "Srednekolymsk",
    'Pacific/Guadalcanal': "Solomon Is.",
    'Pacific/Noumea': "New Caledonia",
    'Pacific/Fiji': "Fiji",
    'Asia/Kamchatka': "Kamchatka",
    'Pacific/Majuro': "Marshall Is.",
    'Pacific/Auckland': "Wellington",
    'Pacific/Tongatapu': "Nuku'alofa",
    'Pacific/Fakaofo': "Tokelau Is.",
    'Pacific/Chatham': "Chatham Is.",
    'Pacific/Apia': "Samoa",
  };

  constructor(private storageService: StorageService,
    private activityService: ActivityService) { }

  ngOnInit() {
    let currentTimezone;
    this.activity = this.storageService.get('activity');
    // get current time zone if not already selected
    if (this.activity['timezone']) {
      currentTimezone = this.activity['timezone'];
      this.enableTimeZone = true;
    } else {
      if (this.enableTimeZone) {
        //set to current timezone of user
        currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    }

    //initialize form
    this.editTimeZoneForm = new FormGroup({
      allowTimeZones: new FormControl(this.enableTimeZone),
      selectTimeZone: new FormControl({ value: currentTimezone, disabled: !this.enableTimeZone })
    })
    this.formValueChanged();
  }

  // Preserve original property order
  unsorted(a: any, b: any): number { return 0; }

  formValueChanged() {
    //check for value changes
    this.editTimeZoneForm.valueChanges.subscribe(val => {
      this.changedVal = true;
    })
  }

  onChange(event) {
    if (event.checked) {
      this.enableTimeZone = true;
      this.editTimeZoneForm.controls['selectTimeZone'].enable();
      this.editTimeZoneForm.patchValue({
        'selectTimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
      });

    } else {
      this.enableTimeZone = false;
      this.editTimeZoneForm.controls['selectTimeZone'].disable();
    }
    console.log('this.enableTimeZone', this.enableTimeZone)
  }

  cancelChanges() {
    window.history.back();
  }

  onSubmit() {
    let timeZone;
    if (this.enableTimeZone) {
      timeZone = this.editTimeZoneForm.get('selectTimeZone').value;
    } else {
      timeZone = '';
    }
    this.updateActivity({
      selected_activity: this.activity['id'],
      timezone: timeZone
    });
    console.log(timeZone);
  }

  updateActivity(data) {
    this.activityService.updateActivity(data).subscribe(
      response => {
        if (response.status) {
          this.storageService.set('activity', response.body['data'], null);
          window.history.back();
        }
      }
    )
  }
}
