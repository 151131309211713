<app-header icon="back" title="_Name_" showNav="false" backText="_MessageParticipants_">
</app-header>

<div class="filter-by-name-page message-filter-pages">
  <div class="message-header">{{'_RecipientFilterName_' | translate}}</div>
  <div class="filter-page">
    <div class="filter-header">
      <div class="input-group search-bar">
        <input class="form-control" (keyup)="applyFilter($event)" placeholder="Filter by name or email">
        <span class="input-group-append">
          <div class="input-group-text"><i class="search-icon material-icons">search</i></div>
        </span>
      </div>
    </div>
    <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
    
    <table mat-table [dataSource]="dataSource" class="mat-table dark-blue-border">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle(selection) : null"
            [checked]="selection.hasValue() && isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation(); showSelection(row)"
            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="contactname">
        <th mat-header-cell *matHeaderCellDef> 
          <span *ngIf="dataSource">
            Name ({{dataSource.filteredData.length}})
          </span>
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="participant-name">{{element.contactname}} </span>
          <span *ngIf="element.email" [ngClass]="!element.contactname ? 'participant-name' : participant-email">{{element.email}}</span>

          <span *ngIf="element.jobassignments.length">{{element.jobassignments[0].job_name}}
            <span class="ml-1" *ngIf="element.jobassignments.length > 1"> + {{element.jobassignments.length - 1}} more</span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="dark-blue-bg"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
      </tr>
    </table>
  </div>

  <section class="footer">
    <span (click)="goNext()">Next</span>
  </section>
</div>
