<div class="edit-confirm-dialog alert-dialog">
  <!-- <h2 mat-dialog-title>{{title | translate}}</h2> -->
  <mat-dialog-content>
    <p *ngIf="type==='edit'">{{'_SaveRepeatPatternMobileOrg_' | translate}}</p>
      <div *ngIf="force" class="force">
      <div class="form-group">
        <mat-checkbox (change)="toggleCheck($event)">
          <label>{{'_ComposeJobModalForceRepeating_' | translate}}</label>
        </mat-checkbox>
      </div>
      <div class="force-text" [innerHTML] = "'_ComposeJobModalForceRepeatingHelpChangeNotificationOnActivityComplete_' | translate"></div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button 
    [ngClass]="formValid ? 'default-blue-bg' : 'default-blue-bg-disabled'"    
    (click)="save('one')" 
    [disabled]="!formValid">{{'_SaveOnlyThis_' | translate}}</button>
    <button 
    [ngClass]="formValid ? 'default-blue-border' : 'default-blue-border-disabled'"    
    (click)="save('future')" 
    [disabled]="!formValid">{{'_SaveAllFuture_' | translate}}</button>
    <a (click)="closeOrCancel()" class="cancel">{{'_Cancel_' | translate}}</a>
  </mat-dialog-actions>
</div>
