<div class="edit-spots-page">
  <div class="theme-title"
    [ngStyle]="{'background-image': 'url(' + urlLocation + '/client/themes/' + activityThemeFolder + '/imgs/mobile_background.jpg)'}">
    <div class="theme-title-inner">
      <span class="theme-title-innertext">{{title}}</span>
      <span *ngIf="startDateRange">
        <span>{{startDateRange}}</span> <span *ngIf="endDateRange"> - {{endDateRange}}</span>
      </span>
    </div>
  </div>
  <!-- share icons -->
  <div class="edit-share-icons">
    <ul>
      <li><a routerLink="/invite"><i class="material-icons">share</i>share</a></li>
      <li><a (click)="generateLink()"><i class="material-icons material-icons-outline">visibility</i>preview</a></li>
      <li><a routerLink="/messages"><i class="material-icons">chat_bubble_outline</i>message</a></li>
    </ul>
  </div>
  <!-- <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner> -->
  <div class="add-spot-page">
    <app-add-edit-spot (changed)="isChanged($event)" mode="edit" (update)="onUpdate($event)" (dateChange)="ondateChange($event)"></app-add-edit-spot>
  </div>
</div>
