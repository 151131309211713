import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private http: HttpClient,
    @Inject('BASE_API_URL') private apiURL:string) {}

  getParticipantJob(jobId, activityId, accessKey, showComments, showJobAssignments, ) {
    var data = {
      'selected_activity': activityId,
      'activity_id': activityId,
      'accesskey': accessKey,
      'include_comments': showComments,
      'include_jobassignments': 'true',
      'show_names': showJobAssignments
    }
    return this.http.get(this.apiURL + '/api/jobs/' + jobId, { params: data, observe: 'response' });
  }

  participantQuery(activityId, eventId, accessKey) {
    var data = {
      'selected_activity': activityId,
      'activity_id': activityId,
      'accesskey': accessKey,
      'include_jobs': 'true',
      'include_jobassignments': 'true'
    }
    return this.http.get(this.apiURL + '/api/events/' + eventId, { params: data, observe: 'response' });
  }

  orgJob(eventId, activityId) {
    var data = {
      'selected_event': eventId,
      'selected_activity': activityId
    }
    return this.http.get(this.apiURL + '/api/org_jobs/', { params: data, observe: 'response' });
  }

  saveJob(data) {
    return this.http.post(this.apiURL + '/api/org_jobs/', data, { observe: 'response' });
  }

  updateJob(id, data) {
    return this.http.put(this.apiURL + '/api/org_jobs/' + id, data, { observe: 'response' });
  }

  deleteJob(data) {
    return this.http.post(this.apiURL + '/api/org_jobs/destroy', data, { observe: 'response' });
  }

  saveRepeatingSchedule(data) {
    //called after editing a job
    return this.http.post(this.apiURL + '/api/org_jobs/update_repeating_schedule/', data, { observe: 'response' });
  }

  get(jobId, activityId) {
    var data = {
      'id': jobId,
      'activity_id': activityId,
      'selected_activity': activityId,
      'show_names' : 'true' 
    }
    return this.http.get(this.apiURL + '/api/org_jobs/' + jobId, { params: data, observe: 'response' });
  }

  addJobAssignment(data) {
    return this.http.post(this.apiURL + '/api/org_jobassignments/', data, { observe: 'response' });
  }

  addJobAssignment1(activityId, jobId, volunteerId, accessKey) {
    var data = {
      'selected_activity': activityId,
      'activity_id': activityId,
      'selected_job': jobId,
      'job_id': jobId,
      'volunteer_id': volunteerId,
      'quantity': 1,
      'accesskey': accessKey,
      'force': 'false'
    }
    return this.http.post(this.apiURL + '/api/org_jobassignments/', data, { observe: 'response' });
  }

  updateJobAssignment(activityId, jobId, qty) {
    var data = {
      'selected_activity': activityId,
      'id': jobId,
      'quantity': qty
    }
    return this.http.put(this.apiURL + '/api/org_jobassignments/' + jobId, data, { observe: 'response' });
  }

  deleteJobAssignment(activityId, jobId, deleteAll?) {
    var data = {
      'selected_activity': activityId
    }
    return this.http.delete(this.apiURL + '/api/org_jobassignments/' + jobId, { params: data, observe: 'response' });
  }
}
