<app-header icon="back" backText="{{backText}}" title="_ViewSpot_" showNav="false"></app-header>
<div class="edit-spots-detail-page body-container">

  <div class="more-nav">
    <!-- options nav at the top right corner -->
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="editJob()" mat-menu-item>
        <span class="more-nav-title">{{'_EditSpot_' | translate}}</span>
        <mat-icon class="more-nav-icon">edit</mat-icon>
      </button>
      <button (click)="removeJobDialog()" mat-menu-item>
        <span class="more-nav-title">{{'_RemoveSpot_' | translate}}</span>
        <mat-icon class="more-nav-icon">delete</mat-icon>
      </button>
    </mat-menu>
  </div>

  <section class="top-section">
    <h4>{{jobName}}</h4>
    <div class="job-info">
      <span *ngIf="jobDate">{{jobDate |  date: 'EEE, MMM d'}}</span>
      <span *ngIf="jobDate && jobStartTime"> | </span>
      <span *ngIf="jobStartTime">{{jobStartTime}} <span *ngIf="jobEndTime">-{{jobEndTime}}</span></span>
      <span (click)="editJob()"><i class="material-icons">edit</i></span>
    </div>
    <div class="job-type">{{jobType}}</div>
  </section>

  <section class="participant-section">
    <button type="button" class="tooltip-btn" ngxTippy tippyName="spotDetailTooltip" [tippyProps]="tippyProps">
      <i class="material-icons info-icon">info</i>
    </button>

    <ng-container>
      <div *ngIf="jobQuantity !== -1" class="job-quantity">{{totalAssignment}} of {{jobQuantity}}
        {{'_Filled_' | translate}}</div>
      <div *ngIf="jobQuantity === -1" class="job-quantity">{{totalAssignment}} {{'_Filled_' | translate}}</div>

    </ng-container>
    <mat-spinner class="mat-spinner" *ngIf="loading" [diameter]="50"></mat-spinner>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let job of job['jobassignments']" class="spots-section">
        <mat-expansion-panel-header>
          <!-- header -->
          <span class="avatar-inline">
            <ngx-avatar name="{{job.who}}"></ngx-avatar>
          </span>
          <span class="job-name truncate">{{job.who}} 
            <span *ngIf="job.quantity">
                ({{job.quantity}}<span *ngIf="job.quantity > 1"> Spots)</span>
                <span *ngIf="job.quantity == 1"> Spot)</span>
            </span>
           
        </span>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <!-- body -->
          <div>
            <span class="icon-container">
                <span>
                  <a (click)="editSpotDialog(job.quantity, job.id)"><i class="material-icons">edit</i>Change Spots</a>
                </span>
              <span>
                  <a  (click)="viewParticipant(job.volunteer_id)"><i class="material-icons">person</i>View Participant</a>
              </span>
            </span>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <ng-container *ngIf="totalAssignment">
      <div class="job-quantity mt-4" *ngIf="jobQuantity !== -1 && !isFull">{{openSpots}} of
        {{jobQuantity}} {{'_Open_' | translate}}</div>
    </ng-container>

    <!-- Add participant button -->
    <div *ngIf=!isFull>
      <div class="add-new-plus-icon">
        <span (click)="addParticipant()"><i class="material-icons">add</i>
          <span>{{'_AssignParticipant_' | translate}}</span>
        </span>
        
      </div>
    </div>
    <div *ngIf="isFull" class="filled-text">This Spot is full.</div>
  </section>

  <!-- Comments Section -->
  <section class="comments-section">
      <mat-spinner class="mat-spinner" *ngIf="commentLoading" [diameter]="50"></mat-spinner>
    <mat-accordion>
      <mat-expansion-panel class="comments-panel">
        <mat-expansion-panel-header>
          <div class="panel-blue-header">Comments <span *ngIf="commentTotal">({{commentTotal}})</span></div>
        </mat-expansion-panel-header>
        <div *ngFor="let comment of comments" class="comments-list">
          <p class="comment-name truncate" *ngIf="userId === comment.user_id">Me</p>
          <p class="comment-name truncate" *ngIf="userId !== comment.user_id">{{comment.name}}</p>
          <span *ngIf="userId === comment.user_id" (click)="deleteCommentDialog(comment)"><i
              class="material-icons">delete</i></span>
          <p class="comment-text">{{comment.text}}</p>
        </div>
        <div class="add-comment-form">
          <form [formGroup]="commentForm">
            <input type="text" formControlName="comment" class="comment-text" placeholder="Leave a comment..."
              maxLength="140">
            <button type="submit" (click)="addComment()" class="btn">Post</button>
          </form>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>