import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { AvatarModule } from 'ngx-avatars';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { JobService } from './../../shared-services/job.service';
import { VolunteerService } from '../../shared-services/volunteer.service';
import { StorageService } from '../../shared-services/storage.service';
import { FilterPipe } from '../../pipes/filter.pipe';

@Component({
  selector: 'app-edit-select-participants',
  templateUrl: './edit-select-participants.component.html',
  styleUrls: ['./edit-select-participants.component.scss']
})
export class EditSelectParticipantsComponent implements OnInit {

  constructor(private router:Router,
    private jobService: JobService,
    private volunteerService: VolunteerService,
    private storageService: StorageService) { }

  participants$;
  volunteerId: number;
  activity: {};
  job: {};
  participants: {};
  participantsArr: string[] = [];
  jobName: string;
  jobOpen: number = 0;
  jobOpenText: string;
  jobAssigned: number = 0;
  jobTotal: number = 0;
  inputSearch;
  loading: boolean = true;
  emptyList: boolean = false;
  submitted:boolean = false;

  ngOnInit() {
    this.activity = this.storageService.get('activity');
    //get job title details
    this.job = this.storageService.get('job');
    this.jobName = this.job['name'];
    // this.jobOpen = this.job['name'];
    this.jobTotal = this.job['quantity'];
    //get total number of jobs open
    this.job['jobassignments'].forEach(item => {
      this.jobAssigned += item['quantity'];
    })
    this.jobOpen = this.jobTotal - this.jobAssigned;
    if (this.jobTotal === -1) {
      //job is unlimited
      this.jobOpenText = 'Unlimited Spots';
    } else {
      this.jobOpenText = this.jobOpen + ' of ' + this.jobTotal + ' open ';
    }
    this.participants = this.volunteerService.get(this.activity['id']).subscribe(
      response => {
        if (response) {
          this.participants = response.body['data'];
          //only show those that dont have a job assignment
          Object.keys(this.participants).forEach(key => {
            let thisParticipant = this.participants[key];
            this.job['jobassignments'].forEach(item => {
              if (item['volunteer_id'] && item['volunteer_id'] === thisParticipant.id) {
                //add assign parameter if id matches 
                thisParticipant['isAssigned'] = true;
              } 
            })
          })
          Object.keys(this.participants).forEach(key => {
            let currParticipant =  this.participants[key];
            if (!currParticipant['isAssigned']) {
              this.participantsArr.push(currParticipant);
            }
          })    
          if (!this.participantsArr.length) {
            this.emptyList = true;
          } else {
            this.emptyList = false;
          }    
          this.loading = false; 
        }
      }
    )
  }

  addSelectedParticipant(participant) {
    this.submitted = true;
    let data = {
      contact_id: participant.contact_id,
      max: 9,
      quantity: 1,
      selected_activity: this.activity['id'],
      selected_job: this.job['id'],
      volunteer_id: participant.id
    }
    this.jobService.addJobAssignment(data).subscribe(
      response => {
        if (response) {
          window.history.back();
        }
      }
    )
  }

  addNewParticipant() {
    this.router.navigateByUrl('edit_assign_spot/' + this.job['id'], {skipLocationChange: true});
  }
}
