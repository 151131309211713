import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from '../dashboard.service';
import { UserService } from '../../shared-services/user.service';
import { StorageService } from '../../shared-services/storage.service';
import { ActivityService } from '../../shared-services/activity.service';
import { JobService } from '../../shared-services/job.service';
import { InvitationService } from '../../shared-services/invitation.service';
import { CommentService } from '../../shared-services/comment.service';
import { EventService } from '../../shared-services/event.service';
import { VolunteerService } from '../../shared-services/volunteer.service';
import { ErrorHandlerService } from '../../shared-services/error-handler.service';
import { AlertDialogComponent } from '../../shared/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import * as moment_ from 'moment';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
const moment = moment_;
// declare var cordova: any;

@Component({
  selector: 'app-dashboard-current',
  templateUrl: './dashboard-current.component.html',
  styleUrls: ['./dashboard-current.component.scss']
})
export class DashboardCurrentComponent implements OnInit {

  constructor(private router:Router,
              private dashboardService: DashboardService,
              private userService: UserService,
              private storageService: StorageService,
              private activityService: ActivityService,
              private jobService: JobService,
              private invitationService: InvitationService,
              private commentService: CommentService,
              private eventService: EventService,
              private volunteerService: VolunteerService,
              private errorHandlerService:ErrorHandlerService,
              public dialog: MatDialog,
              private translate: TranslateService
            ) { }

  //declare variables
  accesskey: string = null;
  signUpSpinner: boolean = true;
  spotsSpinner: boolean = true;
  invSpinner: boolean = true;

  //my invitations
  invitationsList: [];
  showInvitations: number;
  totalnvitations: number;
  //my signups
  mySignUpsList: [];
  showSignups: number;
  totalSignUps: number;
  //my spots
  mySpotsList: [];
  showMySpots: number;
  totalSpots: number;

  isCordovaApp:boolean = false;
  url: string;

  ngOnInit() {
    //check if app is run in browser or the mobile app
    if (this.storageService.get('isCordovaApp') && this.storageService.get('isCordovaApp') === true) {
      this.isCordovaApp = true;
    }
    // console.log('this.isCordovaApp ', this.isCordovaApp )
    //set window location
    if (this.isCordovaApp) {
      //native app
      if (this.storageService.get('environment')) {
        this.url = this.storageService.get('environment');
      } else {
        this.url = 'https://signup.com';
      }
    } else {
      //mobile web
      this.url = window.location.origin;
    }
    //reset previously set values
    this.storageService.remove('jobToExpand');
    //set role to organizer
    this.storageService.process('role', 'organizer');
    //clear local storage values that might cause conflicts
    this.storageService.removeMultiple(['spotFilter', 'completeSignup', 'quicksearch-detail', 'quicksearchVol']);

    // get my signups list on init
    this.activityService.orgActivities().subscribe(
      response => {
        if (response.status) {
          this.signUpSpinner = false;
          JSON.stringify(response);
          this.accesskey = response.body['accesskey'];
          //get spots list and invitations list
          this.getMySpots();
          this.getMyInvitations();
          //store in user service
          this.mySignUpsList = response.body['data'];
          this.showSignups = this.mySignUpsList.length <=10 ? this.mySignUpsList.length : 10;
          this.totalSignUps = this.mySignUpsList.length;
          this.storageService.process('activities', this.mySignUpsList);
          this.storageService.process('full_login', true);
        }
      },
      error => {
        this.errorHandlerService.resolve(error.status);
      }
    )

    //set other session variables so the new mobile app can play nice with the current app
    this.storageService.process('referring_url', false);
    this.storageService.process('targeting', {'org':'','act':''});
    this.storageService.process('has_server_session', true);

    if (this.storageService.get('apple_user')) {
      if (this.storageService.get('code')) {
        let displayCode = this.storageService.get('code');
        this.translate.get(displayCode).subscribe((text) => {
          let msg = text;
          this.showAppleAlert(msg);
          this.storageService.remove('code');
        })
      }
    }
  }

  quickSearch() {
    this.router.navigate(['/quicksearch']);

  }

  getMyInvitations() {
    this.invitationService.orgInvitations().subscribe(
      response => {
        if (response.status) {
          JSON.stringify(response);
          // console.log(response.body['data'])
          this.invitationsList = response.body['data'];
          this.showInvitations = this.invitationsList.length <=10 ? this.invitationsList.length : 10;
          this.totalnvitations = this.invitationsList.length;
          this.storageService.process('invitations', this.invitationsList);
          this.invSpinner = false;
        }
      },
      error => {
        this.errorHandlerService.resolve(error.status);
        this.invSpinner = false;
      }
    )
  }

  showAppleAlert(msg) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      data: {
        isSuccess: true,
        title: '',
        message: msg
      }
    });
  }

  getMySpots() {
    this.dashboardService.getAllSpots().subscribe(
      response => {
        if (response.status) {
          this.spotsSpinner = false;
          JSON.stringify(response);
          // console.log(response.body['commitments'])
          this.mySpotsList = response.body['commitments'];
          this.showMySpots = this.mySpotsList.length <=10 ? this.mySpotsList.length : 10;
          this.totalSpots = this.mySpotsList.length;
          this.mySpotsList.forEach(function(item: any) {
            //get start time
            if (item['jobstarthour'] && item['jobstarthour'] != -1 || item['jobstartminute'] && item['jobstartminute'] != -1) {
              item['jobstarttime'] = moment(item['jobstarthour'], "h").format("h") + ':' + moment(item['jobstartminute'], "mm").format("mm") + moment(item['jobstarthour'], "h mm a").format("a");
            } else {
              item['jobstarttime'] = null;
            }
            //get end time
            if (item['jobendhour'] && item['jobendhour'] != -1 || item['jobendminute'] && item['jobendminute'] != -1) {
              item['jobendtime'] = moment(item['jobendhour'], "h").format("h") + ':' + moment(item['jobendminute'], "mm").format("mm") + moment(item['jobendhour'], "h mm a").format("a");
            } else {
              item['jobendtime'] = null;

            }
          })
          this.storageService.process('jobs', this.mySpotsList);
        }
      },
      error => {
        this.errorHandlerService.resolve(error.status);
        this.spotsSpinner = false;
      }
    )
  }

  createNewSignup() {
    //when creating, remove activity and jobs from local storage so as not to cause conflicts
    this.storageService.removeMultiple(['activity', 'active_activity_id', 'job', 'basicInfo', 'participantInfo', 'extraFields', 'jobPageVisited']);
    this.router.navigateByUrl('/create');
  }

    goToSignup(item) {
      if (item['kind'] ==='contribonly') {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '80%',
          hasBackdrop: true,
          data: {
            isSuccess: true,    //success dialog
            title: '',
            message: 'Please go to a computer to edit & report on this signup.'
          }
        });
      } else {
        this.signUpSpinner = true;
        //go to signup
        this.activityService.orgActivityQuery(item.id).subscribe(
          response => {
            if (response.status) {
              let route = this.router, storageService = this.storageService;
              JSON.stringify(response);
              this.storageService.process('role', 'organizer');
              this.storageService.process('active_activity_id', item.id);
              this.storageService.process('activity', response.body['data'], function() {
                if (item['complete']) {
                  route.navigateByUrl('edit/spot/' + item.id);
                } else {
                  //go back to create wizard
                  route.navigateByUrl('create');
                  storageService.set('completeSignup', 'true');
                }
              });
              setTimeout(() => {
                this.signUpSpinner = false;
              }, 100)
            }
          },
          error => {
            this.errorHandlerService.resolve(error.status);
            this.signUpSpinner = false;
          }
        );
      }
    }

    goToSpots(item) {
      // save current user object because it changes when you to participant view
      this.spotsSpinner = true;
      this.storageService.set('userObject',this.storageService.get('user'));
      const spotUrl = this.url + '/mobileweb/2.0/vspot.html?accesskey=' + this.accesskey + '&activity=' +
        item.activity_id + '&prompt=false' + (this.isCordovaApp ? '&isCordovaApp=true' : '') + '#my_spots_page';
        // console.log('spotUrl', spotUrl);
      window.location.href = spotUrl;
      setTimeout(() => {
        this.spotsSpinner = false;
      }, 1000)
    }

    goToInvitation(item) {
      this.invSpinner = true;
      this.storageService.set('userObject', this.storageService.get('user'));
      window.location.href = this.url + item.url + (this.isCordovaApp ? '&isCordovaApp=true' : '');

      setTimeout(() => {
        this.invSpinner = false;
      }, 1000);
    }

    goToJob(item) {
      this.invitationService.get(item.activity_id, this.accesskey).subscribe(
        response => {
          if (response.body) {
            var activityData = response.body['data'];
            this.storageService.process('activity', activityData);
            this.storageService.process('active_activity_id', activityData.id);

            this.eventService.get(item.activity_id, false).subscribe (
              //get event data
              response => {
                if (response.body) {
                  var eventData = response.body['data'];
                  this.storageService.process('all_events', eventData);

                  //get job data
                  this.jobService.participantQuery(activityData.activity_id, eventData[0].id, this.accesskey).subscribe(
                    response => {
                      if (response.body) {
                        this.storageService.process('jobs', response.body['data']);
                        window.location.href = this.url + '/mobileweb/2.0/vspot.html#choose_jobs_page';
                      }
                    }
                  )
                }
              }
            )
          }
        },
        error => {
          this.errorHandlerService.resolve(error.status);
        }
      )
    }

    getEvents(item) {
      this.eventService.get(item.activity_id, false).subscribe (
        response => {
          if (response.body) {
            this.storageService.process('active_activity_id', item.activity_id);
            this.storageService.process('all_events', response.body['data']);
            window.location.href = this.url + '/mobileweb/2.0/vspot.html#choose_event_page';
          }
        },
        error => {
          this.errorHandlerService.resolve(error.status);
        }
      )
    }
}
