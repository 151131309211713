import { NgModule } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from "@angular/router";
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { DashboardCurrentComponent } from './dashboard-current/dashboard-current.component';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    DashboardCurrentComponent,
    DashboardComponent
  ]
})
export class DashboardModule { }
