<app-header icon="back" title="{{'_OrganizeSignup_'| translate}}" showNav="true"></app-header>
<div class="body-container register-page">
  <!-- error div -->
  <div *ngIf="errorMsg" class="error-div">{{errorMsg |translate:errParam}}</div>
  <div class="side-padding">
    <p class="org-text">{{'_CreateSignupText_'| translate}}</p>
    <!-- fb button -->
      <button (click)="signInWithFB()" id="fb_login" type="button" class="btn-wrapper fb-btn">
        <span class="social-btn-inner">
          <img src="assets/images/fb.png" alt="fb-icon">
          <span class="btn-text">Sign in with FB</span>
        </span>
      </button>

    <!-- google button -->
    <div class="g-signin-wrapper">
      <button id="googleBtn" class="btn-wrapper" (click)="signInWithGoogle()">
        <span class="social-btn-inner">
          <span class="icon"> 
            <img class="google-btn"
            src="assets/images/google.png">
          </span>
          <span class="btn-text">Sign in with Google</span>
        </span>
      </button>
    </div>

    <!-- apple button -->
    <div *ngIf="iosApp">
    <!-- <div> -->
      <button id="appleBtn" class="btn-wrapper" (click)="appleSignIn()">
        <span class="social-btn-inner">
          <span class="icon"> 
            <img class="apple-btn"
            src="assets/images/apple-logo.png">
          </span>
          <span class="btn-text">Sign in with Apple</span>
        </span>
      </button>
    </div>

    <mat-spinner *ngIf="loading" [diameter]="50" class="organizer-spinner"></mat-spinner>    
    <div *ngIf="showBtns" class="org-buttons">
      <p class="separator-text"> {{'_Or_'| translate}}</p>
      <a (click)="goToRegister()" class="btn btn-blue btn-block">
        {{'_RegisterWithEmail_'| translate}}</a>
      <a (click)="goToLogin()" class="btn btn-gray btn-block">
        {{'_AlreadyRegistered_'| translate}}</a>
    </div>
    <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"></router-outlet>
  </div>
</div>