  <div class="confirm-dialog alert-dialog">
    <h2 mat-dialog-title>{{title | translate}}</h2>
    <mat-dialog-content>
      <p *ngIf="params">{{message | translate: msgParam}}</p>
      <p *ngIf="warningParam"  [innerHtml]="message | translate:msgParam"></p>
      <p *ngIf="!params && !warningParam">{{message | translate}}</p>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="buttonType && !reverseBtns" class="wide-btns">
        <button *ngIf="isContinue" class="btn exit-btn" (click)="continue()">{{buttonText | translate}}</button>
        <button (click)="closeOrCancel()" class="edit-cancel-btn">{{buttonCancelText | translate}}</button>                               
    </mat-dialog-actions>
    <mat-dialog-actions *ngIf="buttonType && reverseBtns" class="wide-btns">
      <button (click)="closeOrCancel()" class="edit-cancel-btn">{{buttonCancelText | translate}}</button>                               
      <button *ngIf="isContinue" class="btn exit-btn" (click)="continue()">{{buttonText | translate}}</button>
  </mat-dialog-actions>
    <mat-dialog-actions *ngIf = "!buttonType">
        <button *ngIf="isExit" class="btn exit-btn" (click)="goBack()">{{'_Exit_' | translate}}</button>
        <button *ngIf="isContinue" class="btn exit-btn" (click)="continue()">{{buttonText | translate}}</button>
        <button *ngIf="isWarning" class="btn blue-exit-btn" (click)="continue()">{{buttonText | translate}}</button>
        <button (click)="closeOrCancel()" class="edit-cancel-btn">{{buttonCancelText | translate}}</button>
    </mat-dialog-actions>
  </div>
