import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { Http, HttpResponse } from '@capacitor-community/http';


@Injectable({
  providedIn: 'root'
})

export class ActivityService {

  constructor(private http: HttpClient,
    @Inject('BASE_API_URL') private apiURL:string) {}

  orgActivities() {
    var data = {
      'order': '_StartDate_',
      'show': '_UpcomingSignUps_',
      'page': '1',
      'items_per_page': '3'
   }
   return this.http.get(this.apiURL + '/api/org_activities/', { params: data, observe: 'response'});
 }

 orgActivityQuery(id) {
  //return volunteer data
  var data = {
    'selected_activity': id,
    'sendtotals': 'true'
  }
  return this.http.get(this.apiURL + '/api/org_activities/' + id, {params: data, observe: 'response'});
}

createActivity(data) {
  return this.http.post(this.apiURL + '/api/org_activities/', data, {observe:'response'});
}

 getActivity(accessKey, activityId) {
    var data = {
      'selected_activity': activityId,
      'id': activityId,
      'accesskey': accessKey
    }
    return this.http.get(this.apiURL + '/api/activities/' + data.id, { params:data, observe: 'response'});
  }

  getActivityTypes() {
    var data = {
      include_job_titles: 'true'
    }
    return this.http.get(this.apiURL + '/api/activity_types/', { params:data, observe: 'response'});
  }

  participantLogin(accessKey, activityId) {
    var data = {
      'accesskey': accessKey,
      'activity_id': activityId
    }
    return this.http.get(this.apiURL + '/login/accessactivity/', { params: data, observe: 'response'});
  }

  updateActivity(data) {
    return this.http.put(this.apiURL + '/api/org_activities/' + data['selected_activity'], data, {observe:'response'});
  }

  hasSpots(id) {
    var data = {
      selected_activity: id
    }
    return this.http.post(this.apiURL + '/api/org_activities/hasSpots/', data, {observe:'response'});
  }

  // ACTIVIITY LIMITS
  createActivityLimits(activityId, jobassignmentsPerActivity, jobassignmentsPerJob, jobassignmentsPerDay, jobassignmentsPerWeek, ) {
    var data = {
      'selected_activity' : activityId,
      'jobassignments_per_activity' : jobassignmentsPerActivity,
      'jobassignments_per_job' : jobassignmentsPerJob,
      'jobassignments_per_day': jobassignmentsPerDay,
      'jobassignments_per_week': jobassignmentsPerWeek

    }
    return this.http.post(this.apiURL + '/api/activity_limits/create/', data, {observe:'response'});
  }

  updateActivityLimits(limitId, activityId, jobassignmentsPerActivity, jobassignmentsPerJob, jobassignmentsPerDay, jobassignmentsPerWeek) {
    var data = {
      'id' : limitId,
      'selected_activity' : activityId,
      'jobassignments_per_activity' : jobassignmentsPerActivity,
      'jobassignments_per_job' : jobassignmentsPerJob,
      'jobassignments_per_day': jobassignmentsPerDay,
      'jobassignments_per_week': jobassignmentsPerWeek
    }
    return this.http.put(this.apiURL + '/api/activity_limits/' + limitId, data, {observe:'response'});
  }

  deleteActivityLimits(limitId, activityId) {
    var data = {
      'id' : limitId,
      'selected_activity' : activityId
    }
    return this.http.post(this.apiURL + '/api/activity_limits/destroy/' + data['id'], data, {observe:'response'});
  }

  //Extra field suggestions
  extraFieldsSuggestions(activityId?) {
    var data;
    if (activityId) {
       data = {
        'activity_id' : activityId
      }
      return this.http.post(this.apiURL + '/api/org_activities/extra_fields_suggestions', data, {observe:'response'});
    } else {
      return this.http.post(this.apiURL + '/api/org_activities/extra_fields_suggestions', {observe:'response'});

    }
  }

  //New extra field suggestions
  newExtraFieldsSuggestions(data) {
    return this.http.post(this.apiURL + '/api/org_activities/new_extra_fields_suggestions', data, {observe:'response'});
  }

  //create extra special fields
  createExtraSpecialFields(data) {
      return this.http.post(this.apiURL + '/api/org_activities/create_extra_field', data, {observe:'response'});
  }

  //update extra special fields
  updateExtraSpecialFields(data) {
    return this.http.post(this.apiURL + '/api/org_activities/update_extra_field', data, {observe:'response'});
  }

  //delete extra special fields
  deleteExtraSpecialFields(data) {
      return this.http.post(this.apiURL + '/api/org_activities/delete_extra_field', data, {observe:'response'});
  }

  //ORGANIZERS
  getOrganizers(id) {
    //get list of organizers
    var data = {
      selected_activity: id
    }
    return this.http.get(this.apiURL + '/api/organizers/', {params: data, observe: 'response'});
  }

  addNewOrganizer(data) {
    return this.http.post(this.apiURL + '/api/organizers/', data, {observe: 'response'});
  }

  updateOrganizer(data) {
    return this.http.put(this.apiURL + '/api/organizers/' + data['id'], data,  { observe: 'response'});
  }

  deleteOrganizer(orgId, activityId) {
    //delete organizers
    var data = {
      selected_activity: activityId
    }
    return this.http.delete(this.apiURL + '/api/organizers/' + orgId, {params: data, observe: 'response'});
  }

  makePrimaryOrganizer(orgId, activityId) {
    //get list of organizers
    var data = {
      org_id: orgId,
      selected_activity: activityId
    }
    return this.http.post(this.apiURL + '/api/organizers/makeprimaryorganizer/', data, {observe: 'response'});
  }

  //quicksearch

  showAllVolunteers() {
    return this.http.post(this.apiURL + '/api/organizations/show_all_my_volunteers/', {observe: 'response'});
  }

  showMyVolunteer(volEmail) {
    var data = {
      email: volEmail
    }
    return this.http.post(this.apiURL + '/api/organizations/show_my_volunteer/', data, {observe: 'response'});
  }

  orgJobTitles(activityType, jobType) {
    //get job title suggestions
    var data = {
      activitytype: activityType,
      jobtype: jobType
    }
    return this.http.get(this.apiURL + '/api/org_job_titles/', {params: data, observe: 'response'});
  }

  //ACTIVITY THEMES
  getActivityThemes() {
    //get themes
    return this.http.get(this.apiURL + '/api/activity_themes/', {observe: 'response'});
  }

  getActivityBadges() {
    //get badges
    return this.http.get(this.apiURL + '/api/activity_badges/', {observe: 'response'});
  }

  getActivityCategories() {
    //get categories
    return this.http.get(this.apiURL + '/api/categories/', {observe: 'response'});
  }

  getActivityTags() {
    //get tags
    return this.http.get(this.apiURL + '/api/activity_tags/', {observe: 'response'});
  }

  //ACTIVITY BADGES - UPLOAD & COPY

  uploadImage(id, file) {
    // var data = {
    //   activity_id: id,
    //   file: file
    // }

    // Note that setting a content-type header
    // for mutlipart forms breaks some built in
    // request parsers like multer in express.
    const formData = new FormData();

    // Append files to the virtual form.
    formData.append('file', file);    // was file.name but server expects ONE file parameter called file

    // Optional, append other key:val rest data to the form.
    formData.append('activity_id', id);

    return this.http.post(this.apiURL + '/api/org_activities/upload_image/', formData, {observe: 'response'});
  }

  getActivityImage() {
    var data = {
      images_only: 'true',
      include_incomplete: 'false',
      include_past: 'true',
      send_titles: 'false'
    }
    return this.http.get(this.apiURL + '/api/org_activities/getmyactivitiessummary', {params: data, observe: 'response'});
  }

  removeUploadedImage(activityId) {
    var data = {
      activity_id: activityId
    }
    return this.http.post(this.apiURL + '/api/org_activities/remove_image', data, {observe: 'response'});
  }
}




