import { Component, OnInit, Input, getPlatform } from '@angular/core';
import { StorageService } from '../../shared-services/storage.service';
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../../shared-services/google-analytics.service";
import { UserService } from '../../shared-services/user.service';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
// import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { isPlatform } from '@ionic/angular';
import { getPlatforms } from '@ionic/angular';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
// import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Platform } from '@ionic/angular';
import { environment } from './../../../environments/environment';
import { Browser } from '@capacitor/browser';

// declare var cordova: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private storageService: StorageService,
    private router:Router,
    private userService: UserService,
    // private fb: Facebook,
    // private googlePlus: GooglePlus,
    private googleAnalyticsService: GoogleAnalyticsService,
    public dialog: MatDialog,
    public platform: Platform,
    ) { 
      if (!isPlatform('capacitor')) {
        GoogleAuth.initialize();
        // console.log('not capacitor');
        var det = getPlatforms();
        // console.log(det);
      }
    }

  @Input() title: string;
  @Input() backText: string;
  @Input() icon: string;
  @Input() showNav: string;
  @Input() changed: string;     //check if form values were changed

  openMenu:boolean = false;
  userLoggedIn: boolean = false;
  user : {};
  fullLogin : boolean;
  checkInEnabled: boolean = false;
  isCordovaApp:boolean = false;
  url: string;

  ngOnInit() {
    //browser or app
    this.checkNativeApp();
    //set window location
    this.url = this.isCordovaApp ? environment.apiUrl : window.location.origin;
    this.user = this.storageService.get('user');
    this.fullLogin = this.storageService.get('full_login');
    if (this.fullLogin && !this.user) {
      //you are logged in but no user data, signout
      this.signOut();
    } else if (this.fullLogin && (this.user['premium'] && !this.user['premium_data'])) {
      //user data is incomplete, sign out
     this.signOut();
    } else if (this.user && (this.fullLogin || this.storageService.get('fb_user') || this.storageService.get('fb_user'))) {
      this.userLoggedIn = true;
      //check if user has checkin permissions
      this.checkInEnabled = this.user['premium'] ? this.user['premium_data'].features[4].enabled : false;
      this.storageService.set('checkInEnabled', this.checkInEnabled);
     }

     if (this.storageService.get('userObject') &&  this.storageService.get('user') && !this.storageService.get('user')['premium']) {
       //special case where you come back from the participant view and the user data is incomplete
       //but we previously saved it
       let userObject = this.storageService.get('userObject');
       this.storageService.set('user', userObject);                            
     }
  }

  checkNativeApp() {
    //check if app is run in browser or the mobile app
    if (isPlatform('capacitor')) {
      this.isCordovaApp = true;
      //listen for offline connections
      document.addEventListener("offline", this.networkStatus, false);
      document.removeEventListener("offline", this.networkStatus, false);

      if (environment.apiUrl && !this.storageService.get('environment')) {
        this.storageService.set('environment', environment.apiUrl);            
      }
      this.storageService.set('isCordovaApp', true);
    } 
  }

  networkStatus() {
    alert('You are not connected to the internet.');
  }

  openLink(link) {
    if (this.isCordovaApp) {
      // cordova.InAppBrowser.open(link, '_system', 'location=yes');
      Browser.open({ url: link });
    } else {
      window.location.href = link;
    }
  }

  openLinkInApp(link) {
    window.location.href = link;
  }

  findMySignups() {
    this.googleAnalyticsService.emitEvent('button', 'click', 'm/find_my_signups');
    this.openLink(this.url + '/mobileweb/2.0/vspot.html#find_my_signups_page');
  }

  openNav() {
    this.openMenu = true;
  }

  closeNav() {
    this.openMenu = false;
  }

  goBack() {
    if (this.fullLogin === false || window.location.href.indexOf('organizer/login') > -1) {
      this.router.navigateByUrl('/');  
    } else {
      if (this.changed == 'true') {
        //form values were changed, show dialog
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '80%',
          hasBackdrop: true,
          autoFocus: false,
          data: {
            isConfirm: true,  
            isExit: true,
            buttonCancelText: '_Cancel_',
            title: 'Confirm Exit',
            message: '_EditsNotSavingConfirm_'
          }
        });
      } else {
      //saved local storage values that need to be cleared
      if(this.storageService.get('timeInterval') !== null) {
        this.storageService.remove('timeInterval', null);
      }

      //special cases
          if (window.location.hash === '#/extra-special-fields?mode=create') {
            // we need to go back to the right spot in the create wizard from the manage questions page
            this.storageService.set('goToStep2', true);
            this.router.navigateByUrl('/create');
          } else if (window.location.hash === '#/extra-special-fields?mode=edit') {
            // go back to edit participant page
            if (this.storageService.get('activity')) {
              this.router.navigate(['edit_participant_info/' + this.storageService.get('activity')['id']]);
            } else {
              this.router.navigateByUrl('/dashboard'); 
            }
          } else if (window.location.href.includes('edit_participant_info')) {
            // go back to details page, avoid looping through manage questions
            if (this.storageService.get('activity')) {
              this.router.navigate(['edit/detail/' + this.storageService.get('activity')['id']]);
            } else {
              this.router.navigateByUrl('/dashboard'); 
            }
          } else if (location.href.includes("add_new_spot")) {
            //we need to go back to the right spot in the wizard from the add new spot page
            this.storageService.set('jobPageVisited', true);
            window.history.back();
          } else if (location.href.split('?')[0].includes("create")) {
            //check if url is create page without parameters
            this.storageService.remove('jobPageVisited');
            this.router.navigateByUrl('/dashboard'); 
          } else {
            //just go back
            window.history.back();
          }
      }
    }
  }

  signIn() {
    this.closeNav();
    this.googleAnalyticsService.emitEvent('button', 'click', 'm/nav_btn_sign_in');
    this.router.navigateByUrl('/organizer/login');    
  }

  signOut() {
    //clear sessions
    this.closeNav();
    this.userService.logout().subscribe(
      () => {
        if (this.isCordovaApp) {
          this.router.navigateByUrl('/');  
          if (this.storageService.get('google_user')) {
            // this.googlePlus.logout();
            GoogleAuth.signOut();
          } else if (this.storageService.get('fb_user')) {
            // this.fb.logout();         
           } 
        } else {
          this.router.navigateByUrl('/organizer/login');  
        }
        this.storageService.reset();  
        this.storageService.set('full_login', false, null);  
      }
    )
  }
}
