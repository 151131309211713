import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../shared-services/storage.service';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'app-show-preview',
  templateUrl: './show-preview.component.html',
  styleUrls: ['./show-preview.component.scss']
})
export class ShowPreviewComponent implements OnInit {

  activity: {};
  activityThemeFolder: string;
  badgeUrl: string;
  badgeName: string;
  title: string;
  startDateRange: string;
  endDateRange: string;
  selectedBadgeType: string;
  datedSignup: boolean = true;
  isCordovaApp:boolean = false;
  urlLocation: string;
  environment: string;

  constructor(private _bottomSheetRef: MatBottomSheetRef<ShowPreviewComponent>,
              private storageService: StorageService,
              private sanitizer: DomSanitizer) {}

  ngOnInit() {
    //check if app is run in browser or the mobile app
    if (this.storageService.get('isCordovaApp') && this.storageService.get('isCordovaApp') === true) {
      this.isCordovaApp = true;
    }
    //set window location
    this.environment = this.storageService.get('environment');
    if (this.isCordovaApp) {
      if (this.environment) {
        this.urlLocation = this.environment;
      } else {
        this.urlLocation = 'https://signup.com';
      }
    } else {
      //mobile web
      this.urlLocation = window.location.origin;
    }

    this.activity = this.storageService.get('activity');
    this.activityThemeFolder = this.activity['theme']['folder'];
    this.badgeName = this.activity['badge'] ? this.activity['badge']['name'] : null;
    this.datedSignup = this.activity['kind'] === 'nodate' ? false : true;
    this.title = this.activity['title'];
    this.selectedBadgeType = this.storageService.get('selectedBadgeType');

    if (this.activity['badge'] && this.selectedBadgeType === 'badge') {
      this.badgeUrl = this.urlLocation + this.activity['badge']['mobile_url'];
    } else if (this.activity['image_location'] && this.selectedBadgeType === 'upload') {
      this.badgeUrl = this.activity['image_location'];

    }
  }

  closePreview(){
    this._bottomSheetRef.dismiss();
  }

}
