<div class="alert-dialog">
  <div>
    <h2 mat-dialog-title>{{title | translate}}</h2>
    <mat-dialog-content [formGroup]="editJobAlert">
      <p>{{message}}</p>
      <p *ngIf="repeating">{{'_JobInRepeatingSchedule_' | translate}}</p>
      <span class="spot-num-container">
        <span (click)="spotCalc('decrease')" [attr.disabled]="decrDisabled" [ngClass]="{'disabled': decrDisabled}">
          <i class="material-icons" [ngClass]="{'material-icon-disabled': decrDisabled}">remove</i></span>
        <input type="number" (keyup)="spotCalc('keypress', $event)" class="form-control" id="spot-num"
          formControlName="num" maxLength="40" [ngClass]="{'error-input': showError}">
        <span (click)="spotCalc('increase')" [attr.disabled]="incrDisabled" [ngClass]="{'disabled': incrDisabled}">
          <i class="material-icons" [ngClass]="{'material-icon-disabled': incrDisabled}">add</i>
        </span>

      </span>
      <span [ngClass]="{'error-text': showError}" class="open-spots-text">There are {{spotsLeft}} spots open.</span>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="alert-dialog-btn-wide">
        <div *ngIf="!deleteJob">
          <button class="btn btn-blue" (click)="saveForm()" [disabled]="showError">{{'_Save_' | translate}}</button>
        </div>
        <div *ngIf="deleteJob">
          <p class="error">{{'_ConfirmRemoveJobAssignment_' | translate}}</p>
          <button class="btn delete-one" (click)="delete()">{{'_Delete_' | translate}}</button>
        </div>
        <button (click)="closeOrCancel()" class="cancel-btn">{{'_Cancel_' | translate}}</button>
      </div>

    </mat-dialog-actions>
  </div>
</div>