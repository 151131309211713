import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from "@angular/router";
import { StorageService } from '../shared-services/storage.service';
import { UserService } from '../shared-services/user.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})

export class EditComponent implements OnInit {

  constructor(private router:Router,
              private storageService: StorageService,
              private userService: UserService) { }
  
  activity: {};
  title: string;
  user: {};
  isActive: boolean;
  activityId: number;
  accessKey: string;

  ngOnInit() {
    
    this.activity = this.storageService.get('activity');
    this.user = this.storageService.get('user');
    if (!this.activity || !this.user) {
      //no activity or user data, log out
      this.userService.logout().subscribe(
        () => {
          this.router.navigateByUrl('/organizer/login');  
          this.storageService.reset();  
          this.storageService.set('full_login', false, null);  
        }
      )
    } else {
      this.activityId = this.activity['id'];
      this.accessKey = this.storageService.get('user')['accesskey'];
    }
  }

  loadSpots(){
    this.router.navigateByUrl('edit/spot/' + this.activity['id']);
  }

  loadParticipants(){
    this.router.navigateByUrl('edit/participant/' + this.activity['id']);
  }

  loadDetails(){
    this.router.navigateByUrl('edit/detail/' + this.activity['id']);
  }
}
