import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(private http: HttpClient,
    @Inject('BASE_API_URL') private apiURL:string) {}

  printSummary(data) {
    return this.http.post(this.apiURL + '/api/reports/printsummary/', data, { observe: 'response' });
  }

  printSignup(data) {
    return this.http.post(this.apiURL + '/api/reports/printsignup/', data, { observe: 'response' });
  }

  printRoster(data) {
    return this.http.post(this.apiURL + '/api/reports/printroster/', data, { observe: 'response' });
  }

  export(data) {
    return this.http.post(this.apiURL + '/api/reports/exportcsv/', data, { observe: 'response' });
  }
}
