import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { EditSpotComponent } from './edit-spots/edit-spots.component';
import { EditParticipantComponent } from './edit-participants/edit-participants.component';
import { EditDetailComponent } from './edit-details/edit-details.component';
import { EditComponent } from './edit.component';
import { EditParticipantDetailComponent } from './edit-participant-detail/edit-participant-detail.component';
import { EditSignupInfoComponent } from './edit-signup-info/edit-signup-info.component';
import { EditParticipantInfoComponent } from './edit-participant-info/edit-participant-info.component';
import { EditOrganizerInfoComponent } from './edit-organizer-info/edit-organizer-info.component';
import { EditNotificationSettingsComponent } from './edit-notification-settings/edit-notification-settings.component';
import { EditSpotsDetailComponent } from './edit-spots-detail/edit-spots-detail.component';
import { EditAssignSpotComponent } from './edit-assign-spot/edit-assign-spot.component';
import { EditJobComponent } from './edit-job/edit-job.component';
// import { EditSingleParticipantComponent } from './edit-single-participant/edit-single-participant.component';
import { EditSelectParticipantsComponent } from './edit-select-participants/edit-select-participants.component';
import { EditJobSetRepeatingComponent } from './edit-job-set-repeating/edit-job-set-repeating.component';
import { EditSpotShiftsComponent } from './edit-spot-shifts/edit-spot-shifts.component';
import { AddNewSpotComponent } from './add-new-spot/add-new-spot.component';
import { EditThemeInfoComponent } from './edit-theme-info/edit-theme-info.component';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule
  ],
  declarations: [
    EditSpotComponent, 
    EditParticipantComponent, 
    EditDetailComponent, 
    EditComponent, 
    EditParticipantDetailComponent, 
    EditSignupInfoComponent, EditParticipantInfoComponent, EditOrganizerInfoComponent, EditNotificationSettingsComponent, EditSpotsDetailComponent, EditAssignSpotComponent, EditJobComponent, EditSelectParticipantsComponent, EditJobSetRepeatingComponent, EditSpotShiftsComponent, AddNewSpotComponent, EditThemeInfoComponent
  ],
  exports: [
    CommonModule,
    AppRoutingModule,
    SharedModule
  ]
})
export class EditEventsModule { }
