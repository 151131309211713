<div class="invite-buttons">
  <a class="btn default-blue-border btn-block primary-btn" [attr.href] = "emailShareText">
    <span class="float-left"><i class="material-icons material-icons-outlined small-icon">email</i></span>Share via Email
  </a>
  <a class="btn default-blue-border btn-block" (click) = "copyToClipboard()">
    <span class="float-left"><img src="assets/images/copy-icon.png" alt=""></span>Copy Shareable Link</a>
  <a class="btn default-blue-border btn-block" [attr.href] = "smsUrl">
    <span class="float-left"><img src="assets/images/sms-icon.png" alt=""></span>Share via Text</a>
  <a class="btn default-blue-border btn-block" (click) ="fbShare()">
    <span class="float-left"><img src="assets/images/fb-icon.png" alt=""></span>Share on Facebook</a>
  <a class="btn default-blue-border btn-block" [attr.href] = "twitterLink">
    <span class="float-left"><img src="assets/images/twitter-icon.png" alt=""></span>Share on Twitter</a>
  <a class="btn default-blue-border btn-block"  [attr.href] = "remindUrl">
    <span class="float-left"><img src="assets/images/remind-icon.png" alt=""></span>Share on Remind</a>

</div>