import { Component, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from "./shared-services/google-analytics.service";
import { Platform } from '@ionic/angular';
import { StorageService } from './shared-services/storage.service';
import { environment } from './../environments/environment';
import { isPlatform } from '@ionic/angular';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';

declare var google: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private translate: TranslateService,
    public googleAnalyticsService: GoogleAnalyticsService,
    public platform: Platform,
    private storageService: StorageService) {
    this.initializeApp();
    translate.getTranslation('resources-locale_en-US').subscribe((response: any) => {
      //set default translation
      translate.setDefaultLang('resources-locale_en-US');
      translate.use('resources-locale_en-US');
    });

    if (isPlatform('capacitor')) {
      // console.log('platform is capacitor');
      if (environment) {
        this.storageService.set('environment', environment.apiUrl);
        // console.log('environment is ',environment.apiUrl);           
      }
      this.storageService.set('isCordovaApp', true);
      //listen for offline connections
      document.addEventListener("offline", function () {
        alert('You are not connected to the internet.')
      }, false);
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      GoogleAuth.initialize();
    })
  }
}
