import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  constructor(private http: HttpClient,
    @Inject('BASE_API_URL') private apiURL:string) {}

  orgInvitations() {
    var data = {
      'hide_past_events': 'true',
      'hide_archived_invitations': 'true',
      'page': '1',
      'items_per_page': 'all'
    }
    return this.http.get(this.apiURL + '/api/org_invitations/', {params: data, observe: 'response'});
  }

  get(id, accessKey) {
    var data =  {
      'activity_id': id,
      'id': id,
      'accesskey': accessKey
    }
    return this.http.get(this.apiURL + '/api/invitations/' + id, {params: data, observe: 'response'});
  }
}
