import { Component, Input, Output, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { StorageService } from '../../shared-services/storage.service';
import { ActivityService } from './../../shared-services/activity.service';
import { AlertDialogComponent } from './../../shared/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from "@angular/router";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as moment_ from 'moment';
import 'moment-recur-ts';

const moment = moment_;

@Component({
  selector: 'app-add-edit-xtra-fields',
  templateUrl: './add-edit-xtra-fields.component.html',
  styleUrls: ['./add-edit-xtra-fields.component.scss'],
})
export class AddEditXtraFieldsComponent implements OnInit {

  esfForm: FormGroup;
  formatArr: any[] = [];
  selectedFormat: string;
  activity: {};
  // datePattern:string;
  creating: boolean = false;
  editing: boolean = false;
  formSubmitted: boolean = false;
  title: string;
  checkboxArr: number[] = [];
  changedVal: boolean = false;
  editFieldValue: boolean = false;
  customField: any;
  origExtraFields: object[] = [];
  extraFieldArr: object[] = [];
  origTitle: string;
  duplicateTitle: boolean = false;
  showCheckboxMin: boolean = false;
  numError: boolean = false;
  chkboxNumError: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private storageService: StorageService,
    private activityService: ActivityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    //get nav query params
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params['mode'] === 'create') {
        this.creating = true;
      } else {
        this.editing = true;
      }
      if (params && params['type']) {
        let changeType = params['type'];
        if (changeType === 'editField') {
          this.editFieldValue = true;
          this.customField = this.storageService.get('customField');
          this.origTitle = this.customField['title'];
        } else {
          this.editFieldValue = false;
        }
      } 
    })

    if (this.storageService.get('extraFieldArr')) {
      this.extraFieldArr = this.storageService.get('extraFieldArr');
    } else {
      //get from server
      this.activityService.newExtraFieldsSuggestions({selected_activity: this.activity['id']}).subscribe(result => {
        if (result && result.body['fields']) {
          this.extraFieldArr = result.body['fields'];
        }
      })
    }

    if (this.editFieldValue) {
      this.title = "Edit Custom Question"
    } else {
      //creating
      this.title = "Add Custom Question"
    }

    if (this.storageService.get('origExtraFields')) {
      this.origExtraFields = this.storageService.get('origExtraFields');
    }

    if (this.storageService.get('activity')) {
      this.activity = this.storageService.get('activity');
    }

    this.formatArr = [
      {
        value: 'dropdown',
        string: 'List (choose 1 item from drop-down)'
      }, {
        value: 'radio',
        string: 'Radio button (choose 1 item)'
      }, {
        value: 'checkbox',
        string: 'Checkbox (choose multiple items)'
      }, {
        value: 'number',
        string: 'Number Field (enter a number)'
      }, {
        value: 'text',
        string: 'Text Field (type in reply)',
        helperText: '250 characters allowed'
      }, {
        value: 'date',
        string: 'Date (enter a date)'
      },
    ]

    //initialize form
    this.esfForm = new FormGroup({
      question: new FormControl(this.customField && this.customField.title || '', [Validators.required]),
      format: new FormControl(this.customField && this.customField.format || '', [Validators.required]),
      isRequired: new FormControl(this.customField && this.customField.required ? this.customField.required : false),
      comment: new FormControl(this.customField && this.customField.comment ? this.customField.comment : null),
      dropdown: new FormControl(this.customField && this.customField.format === 'dropdown' ? this.customField.values : null),
      number: new FormControl(),
      numberMin: new FormControl(this.customField && this.customField.min || ''),
      numberMax: new FormControl(this.customField && this.customField.max || ''),
      checkbox: new FormControl(this.customField && this.customField.format === 'checkbox' ? this.customField.values : null),
      // checkboxMin: new FormControl(this.customField && this.customField.min || null),
      // checkboxMax: new FormControl(this.customField && this.customField.max || null),
      radio: new FormControl(this.customField && this.customField.format === 'radio' ? this.customField.values : null)
      // date: new FormControl(this.customField && this.customField.format === 'date' ? moment(this.customField.values).format('YYYY-MM-DD') : null)
    })

    if (this.editFieldValue) {
      this.selectFormat();
    }
    //TO DO: suspend for now
    // if (this.customField && this.customField.format === 'checkbox') {
    //   this.countWords();
    // }

    //check for changes
    this.formValueChanged();
  }

  formValueChanged() {
    //check for value changes
    this.esfForm.valueChanges.subscribe(val => {
      this.changedVal = true;
      this.duplicateTitle = false;
    })
  }

  //TO DO: suspend for now
  // countWords() {
  //   let checkboxVal;
  //   //get comma separated values typed in by the user
  //   checkboxVal = this.esfForm.get('checkbox').value.split(",").filter(function (x) { return x });
  //   if (checkboxVal.length > 0) {
  //     this.showCheckboxMin = true;
  //   } else {
  //     this.showCheckboxMin = false;
  //   }
  //   //update dropdown values to set max values
  //   this.checkboxArr = [...Array(checkboxVal.length)].map((_, i) => i + 1);
  //   // console.log('this.checkboxArr', this.checkboxArr);
  // }

  selectFormat() {
    let val = this.esfForm.get('format').value;
    // console.log(val);
    this.selectedFormat = val;
    //set validation depending on which format is selected
    this.setValidation();
  }

  setValidation() {
    //clear previous validators on other dropdown items
    for (const key in this.esfForm.controls) {
        this.esfForm.get(key).clearValidators();
        //make sure question textbox still has validation
        if (key === 'question') {
          this.esfForm.get(key).setValidators([Validators.required]);
        }
        this.esfForm.get(key).updateValueAndValidity();
    }
    //clear min and max errors on non-applicable formats
    if (this.selectedFormat != 'checkbox') {
      this.chkboxNumError = false;
      //TO DO: suspend for now
      // this.esfForm.controls['checkboxMin'].setValue(0);
      // this.esfForm.controls['checkboxMax'].setValue(0);
    }
    if (this.selectedFormat != 'number') {
      this.numError = false;
      this.esfForm.controls['numberMin'].setValue(0);
      this.esfForm.controls['numberMax'].setValue(0);
    }

    //set validator for selected format
    if (this.selectedFormat === 'dropdown') {
      this.esfForm.controls['dropdown'].setValidators([Validators.required]);
    } else if (this.selectedFormat === 'radio') {
      this.esfForm.controls['radio'].setValidators([Validators.required]);
    }  else if (this.selectedFormat === 'number') {
      this.esfForm.controls['numberMin'].setValidators([Validators.min(0), Validators.max(9999)]);
      this.esfForm.controls['numberMax'].setValidators([Validators.min(0), Validators.max(9999)]);         
} 
    // else if (this.selectedFormat === 'checkbox' && this.showCheckboxMin) {
    //   this.esfForm.controls['checkbox'].setValidators([Validators.required]);
    // }
    //  else if (this.selectedFormat === 'number') {
      // this.esfForm.controls['numberMin'].setValidators([Validators.required]);
      // this.esfForm.controls['numberMax'].setValidators([Validators.required]);         
    // } 
  }

  nextFieldNumber() {
    // sort by field number
    let i, sorted, nextFieldNum, found:boolean = false;
    if (this.origExtraFields.length) {
      //sort by field number
      sorted = this.origExtraFields.sort(function (a, b) {
        return a['field_number'] - b['field_number'];
      });
      //find unused field number
      sorted.forEach((field, idx) => {
        i = idx + 1;
        if (!found && field['field_number'] !== i) {
          //gap found
          nextFieldNum = i;
          found = true;
        }
        if (!found) {
          nextFieldNum = sorted.length + 1;
        }
      })
    } else {
      //actiivity has no attached custom field, set to 1
      nextFieldNum = 1;
    }
    return nextFieldNum;
  }

  titleValidation(question) {
    if (this.extraFieldArr.some(x=> (x['title'].toLowerCase() === question.toLowerCase()))) {
      this.duplicateTitle = true;
    } else {
      this.duplicateTitle = false;
    }
  }

  validateNum(e) {
    let min = this.esfForm.get('numberMin').value >= 0 ? (this.esfForm.get('numberMin').value) : '',
      max = this.esfForm.get('numberMax').value >= 0 ? (this.esfForm.get('numberMax').value) : '',
      setMin:number, setMax:number;
        if (min > 0) {
          if (min && min.toString().length >= 4) { 
            e.preventDefault();
            setMin = parseInt(min.toString().substring(0, 4).trim());
            // this.esfForm.patchValue({ numberMin: setMin})
          } else {
            setMin = parseInt(min);
          }
          this.esfForm.patchValue({ numberMin: setMin})
        } else if (min == 0 ) {
          setMin = parseInt(min);
          this.esfForm.patchValue({ numberMin: setMin});
        } else {
          if (this.selectedFormat === 'number') {
            this.numError = true;
          } 
        }

        if (max > 0) {
          if (max && max.toString().length >= 4) { 
            e.preventDefault();
            setMax = parseInt(max.toString().substring(0, 4).trim());
            // this.esfForm.patchValue({ numberMax: setMax});
          } else {
            setMax = parseInt(max);
          }
          this.esfForm.patchValue({ numberMax: setMax})
        }  else if (max == 0 ) {
          setMax = parseInt(max);
          this.esfForm.patchValue({ numberMax:setMax})
        }  else {
          if (this.selectedFormat === 'number') {
            this.numError = true;
          } 
        }

        if (setMin >= 0 || setMax >= 0) {
          if (setMin > setMax || setMin == undefined ||  setMax == undefined) {
            this.numError = true;
          } else {
            this.numError = false;
          }
        } else {
          //both values are empty, remove error
          this.numError = false;
        }
        // if (setMin > setMax || (setMin == undefined && setMax) || (setMin && setMax == undefined)) {
        //   console.log('min is higher than max');
        //   if (this.selectedFormat === 'number') {
        //     this.numError = true;
        //   } else {
        //     this.numError = false;
        //   }
        // } else {
        //   this.numError = false;
        // }
      // }

  }

  //TO DO: suspend for now
  // validateCheckedNum() {
  //   let min = this.esfForm.get('checkboxMin').value,
  //       max = this.esfForm.get('checkboxMax').value;
  //   if (min > max) {
  //     console.log('min is higher than max');
  //     if (this.selectedFormat === 'checkbox') {
  //       this.chkboxNumError = true;
  //     } else {
  //       this.chkboxNumError = false;
  //     }
  //   } else {
  //     this.chkboxNumError = false;
  //   }
  // }

  changeWarning(title) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      autoFocus: false,
      data: {
        isConfirm: true,  //confirm dialog
        isWarning: true,
        buttonText: "Rename",
        buttonStyle: "blue",
        buttonCancelText: "No",
        warningParam: title,
        title: "_ChangeCustomQuestion_",
        message: "_ExtraFieldChangeBody_",
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'continue') {
        this.submitExtraField();
      }
    })
  }

  submitForm() {
    let question = this.esfForm.get('question').value;
    if (this.editFieldValue && question.toLowerCase() !== this.origTitle.toLowerCase()) {
      //question was changed
      if(this.customField['in_use_by_volunteer']) {
        //if field is in use, show warning
        this.changeWarning(this.customField['title']);
        } else {
          //proceed with saving
          this.submitExtraField();
        }
    } else {
      //proceed with saving
      this.submitExtraField();
    }
  }

  submitExtraField() {
    let question: string, helpText: string, isRequired: boolean = false, formData: any;
    this.formSubmitted = true;
    question = this.esfForm.get('question').value;
    //validate question
    if (this.editFieldValue) {
      if (question.toLowerCase() !== this.origTitle.toLowerCase()) {
        //title was changed, run validation
        this.titleValidation(question);
      } else {
        //title is unchanged
        this.duplicateTitle = false;
      }
    } else {
      //run validation for new question
     this.titleValidation(question);
    }

    if (!this.duplicateTitle && question !== '') {
      if (this.esfForm.valid) {
        isRequired = this.esfForm.get('isRequired').value || false;
        helpText = this.esfForm.get('comment').value;
        formData = {
          selected_activity: this.activity['id'],
          order: '',
          field_number: '',
          required: isRequired,
          title: question,
          format: "",
          values: "",
          min: "",
          max: "",
          comment: helpText
        };
        //TO DO: suspend for now
        switch (this.selectedFormat) {
          case 'dropdown':
            formData.format = 'dropdown';
            formData.values = this.esfForm.get('dropdown').value;
            break;
          case 'radio':
            formData.format = 'radio';
            formData.values = this.esfForm.get('radio').value;
            break;
          case 'checkbox':
            formData.format = 'checkbox';
            formData.values = this.esfForm.get('checkbox').value;
            // formData.min = this.esfForm.get('checkboxMin').value != 0 ? this.esfForm.get('checkboxMin').value : null;
            // formData.max = this.esfForm.get('checkboxMax').value != 0 ? this.esfForm.get('checkboxMax').value : null;
            break;
          case 'number':
            formData.format = 'number';
            formData.min = this.esfForm.get('numberMin').value ? this.esfForm.get('numberMin').value : null;
            formData.max = this.esfForm.get('numberMax').value ? this.esfForm.get('numberMax').value : null;
            if (this.esfForm.get('numberMax').value && !this.esfForm.get('numberMin').value) {
              //if max but no min, set min to 0
              formData.min = 0;
            }
            break;
          case 'text':
            formData.format = 'text';
            break;
          case 'date':
            formData.format = 'date';
            // formData.values = moment(this.esfForm.get('date').value).format("YYYY/MM/DD");
            break;
        }
  
        if (this.editFieldValue) {
          //update field
          formData['field_number'] = this.customField['field_number'];
          formData['order'] = this.customField['order'];
          if (this.customField['activity_extra_field_id']) {
            //send activity extra field id OR definition id
            formData['activity_extra_field_id'] = this.customField['activity_extra_field_id'];
          } else {
            formData['definition_id'] = this.customField['definition_id'];
          }
          this.activityService.updateExtraSpecialFields(formData).subscribe(
            response => {
              window.history.back();
            }
          )
        } else {
          if (this.origExtraFields && this.origExtraFields.length) {
            //set field_number and order number
            formData['field_number'] = this.nextFieldNumber();
            formData['order'] = this.origExtraFields.length + 1;
          } else {
            //no previous custom question, set to 1
            formData['field_number'] = formData['order'] = 1;
          }
          //create new field
          this.activityService.createExtraSpecialFields(formData).subscribe(
            response => {
              // console.log('response', response);
              //set router params when going back
              this.setRouterParams();
            }
          )
        }
      } else {
        // console.log('form is not valid')
      }
      // console.log('esf_json', formData)
    }
  }

  setRouterParams() {
    if (this.creating) {
      // this.router.navigate(['/extra-special-fields'], { queryParams: { mode: 'create' }});
      // we need to go back to the right spot in the create wizard from the manage questions page
      this.storageService.set('goToStep2', true);
      this.router.navigateByUrl('/create');
    } else {
      //editing
      this.router.navigate(['edit_participant_info/' + this.activity['id']]);
    }
  }

}
