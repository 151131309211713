import { Component, OnInit } from '@angular/core';
import { VolunteerService } from '../../../shared-services/volunteer.service';
import { StorageService } from '../../../shared-services/storage.service';

@Component({
  selector: 'app-recipient-filter-by-status',
  templateUrl: './recipient-filter-by-status.component.html',
  styleUrls: ['./recipient-filter-by-status.component.scss']
})
export class RecipientFilterByStatusComponent implements OnInit {
  participants = [];
  volunteerId: number;
  activity: {};
  loading: boolean = true;
  viewed: any[] = [];
  notViewed: any[] = [];
  signedUp: any[] = [];
  notSignedUp: any[] = [];
  declined: any[] = [];
  selectedArr: any[] = [];
  checkAllViewed: boolean = false;
  checkAllNotViewed: boolean = false;
  checkAllSignedUp: boolean = false;
  checkAllNotSignedUp: boolean = false;
  checkAllDeclined: boolean = false;


  constructor(private volunteerService: VolunteerService,
    private storageService: StorageService) { }

  ngOnInit() {
    this.activity = this.storageService.get('activity');
    this.selectedArr = this.storageService.get('selectedParticipants') || [];
    let participantsList;

    if (this.storageService.get('volunteerData')) {
      this.participants = this.storageService.get('volunteerData');
      if (this.selectedArr.length) {
        this.isItemSelected();
      } else {
        this.processData(this.participants);
      }
    } else {
      this.volunteerService.get(this.activity['id']).subscribe(res => {
        if (res) {
          participantsList = res.body['data'];
          participantsList.forEach(item => {
            if (item.email) {
              this.participants.push(item); 
            }
          })
          this.storageService.set('volunteerData', this.participants);
          if (this.selectedArr.length) {
            this.isItemSelected();
          } else {
            this.processData(this.participants);
          }

          // this.processData(this.participants);
        }
      })
    }
    this.setPanelCheckedValues();
  }

  isItemSelected() {
    this.participants.map(item => {
      if (this.selectedArr.some(x => (x.id || x.volunteer_id) === item.id)) {
        item.checked = true;
      }
    })
    this.processData(this.participants);
  }

  setPanelCheckedValues() {
    this.checkAllViewed = false;
    this.checkAllNotViewed = false;
    this.checkAllSignedUp = false;
    this.checkAllNotSignedUp = false;
    this.checkAllDeclined = false;
  }
  
  processData(participants) {
    participants.forEach(item => {
      if (item['jobassignments'].length) {
        if (!this.signedUp.some(x => x.id === item.id)) {
          this.filterArr(this.signedUp, item);
        }
      } else if (!item['jobassignments'].length) {
        if (!this.notSignedUp.some(x => x.id === item.id)) {
          this.filterArr(this.notSignedUp, item);
        }
      }
      if (item['lastviewed']) {
        if (!this.viewed.some(x => x.id === item.id)) {
          this.filterArr(this.viewed, item);
        }
      } else if (!item['lastviewed']) {
        if (!this.notViewed.some(x => x.id === item.id)) {
          this.filterArr(this.notViewed, item);
        }
      }
      if (item['response'] === 99) {
        if (!this.declined.some(x => x.id === item.id)) {
          this.filterArr(this.declined, item);
        }
      }
    })
    this.loading = false;
  }

  filterArr(arr, item) {
    // if (this.selectedArr.findIndex(elem => elem['id'] === item['id'])) {
    if (arr.findIndex(elem => elem['id'] === item['id'])) {
      arr.push(item);
    }
  }

  selectAllCheckboxes() {
    let idx;
    this.checkAllViewed = true;
    this.checkAllNotViewed = true;
    this.checkAllSignedUp = true;
    this.checkAllNotSignedUp = true;
    this.checkAllDeclined = true;
    this.participants.forEach(item => {
      item.checked = true;
      idx = this.selectedArr.findIndex(x => (x.id || x.volunteer_id) === item.id)
      if (idx === -1) {
        //only add participants that are not in the selected array
        this.selectedArr.push(item);
      }
    })
    this.saveSelection();
  }

  clearAllCheckboxes() {
    this.checkAllViewed = false;
    this.checkAllNotViewed = false;
    this.checkAllSignedUp = false;
    this.checkAllNotSignedUp = false;
    this.checkAllDeclined = false;
    this.participants.forEach(item => {
      item.checked = false;
      //reset selected array
      this.selectedArr = [];
    })
    this.saveSelection();
  }

  getSelectedArray(arr) {
    let selected: string[] = [];
    switch (arr) {
      case 'viewed':
        selected = this.viewed;
        break;
      case 'notViewed':
        selected = this.notViewed;
        break;
      case 'signedUp':
        selected = this.signedUp;
        break;
      case 'notSignedUp':
        selected = this.notSignedUp;
        break;
      case 'declined':
        selected = this.declined;
        break;
    }
    return selected;
  }

  setArrayCheckedState(event, arr) {
    switch (arr) {
      case 'viewed':
        this.checkAllViewed = event ? true : false;
        break;
      case 'notViewed':
        this.checkAllNotViewed = event ? true : false;
        break;
      case 'signedUp':
        this.checkAllSignedUp = event ? true : false;
        break;
      case 'notSignedUp':
        this.checkAllNotSignedUp = event ? true : false;
        break;
      case 'declined':
        this.checkAllDeclined = event ? true : false;
        break;
    }
  }

  selectAll(event, arr) {
    let selected: string[] = [], idx;
    selected = this.getSelectedArray(arr);
    this.setArrayCheckedState(event.checked, arr);
    selected.forEach(participant => {
      if (this.selectedArr.length) {
        idx = this.selectedArr.findIndex(x => (x.id || x.volunteer_id) === participant['id']);
        if (event.checked) {
          if (idx === -1) {
            participant['checked'] = true;
            this.selectedArr.push(participant);
          }
        } else {
          //event is unchecked
          if (idx != -1) {
            participant['checked'] = false;
            this.selectedArr.splice(idx, 1);
          }
        }
      } else {
        //array is empty, no need to search
        if (event.checked) {
          participant['checked'] = true;
          this.selectedArr.push(participant);
        }
      }
    })
    this.saveSelection();
  }

  select(event, participant, arr) {
    let idx,
        selected = this.getSelectedArray(arr);
    if (this.selectedArr.length) {
      idx = this.selectedArr.findIndex(x => (x.id || x.volunteer_id) === participant['id'])
      if (event.checked) {
        //item was checked
        if (idx === -1) {
          this.selectedArr.push(participant);
        }
      } else {
        //item was unchecked
        if (idx != -1) {
          //found the index, remove from array
          this.selectedArr.splice(idx, 1);
        }
      }
    } else {
      if (event.checked) {
        this.selectedArr.push(participant);
      }
    }
    if (this.selectedArr.length) {
      let allSelected = selected.every(item => this.selectedArr.indexOf(item) != -1);
      //set the panel checkbox state
      if (allSelected) {
        this.setArrayCheckedState(true, arr);
      } else {
        this.setArrayCheckedState(false, arr);
      }
    } else {
      //selected array is empty
      this.setArrayCheckedState(false, arr);
    }
    this.isParticipantChecked(event, participant);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  isParticipantChecked(event, participant) {
    //check if there are other instances of the selected participant
    this.participants.forEach(item => {
      if (item['id'] === participant['id']) {
        //found the index
        if (event.checked === true) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      }
    })
    this.saveSelection();
  }

  saveSelection() {
    this.storageService.set('selectedParticipants', this.selectedArr);
  }

  goNext() {
    //go back to the recipients filter page with the buttons
    this.storageService.set('selectedParticipants', this.selectedArr);
    window.history.back();
  }
}
