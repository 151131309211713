import { Component, OnInit } from '@angular/core';
import { ActivityService } from './../../shared-services/activity.service';
import { StorageService } from '../../shared-services/storage.service';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-add-edit-themes',
  templateUrl: './add-edit-themes.component.html',
  styleUrls: ['./add-edit-themes.component.scss']
})
export class AddEditThemesComponent implements OnInit {

  activity: {};
  activityCategories: string[] = [];
  activityBackgroundArr:  string[] = [];
  activityBg: string[] = [];
  activityBadgeArr: string[] = [];
  activityBadge: string[] = [];
  // showPastSignups: boolean = false;
  pastLogosArr: string[] = [];
  selectedTheme: string[] = [];
  selectedBadge: string[] = [];
  defaultTheme: string[] = [];
  openFile: boolean = false;
  selectedCategory: string;
  newUpload: string;
  // uploadProgress: boolean = false;
  loading: boolean = true;
  loadingBadges: boolean = true;
  isCordovaApp:boolean = false;
  urlLocation: string;
  environment: string;

  constructor(private storageService: StorageService,
             private activityService: ActivityService
             ) { }

  ngOnInit() { 
    //check if app is run in browser or the mobile app
    if (this.storageService.get('isCordovaApp') && this.storageService.get('isCordovaApp') === true) {
      this.isCordovaApp = true;
    }
    //set window location
    this.environment = this.storageService.get('environment');
    if (this.isCordovaApp) {
      if (this.environment) {
        this.urlLocation = this.environment;
      } else {
        this.urlLocation = 'signup.com';
      }
    } else {
      //mobile web
      this.urlLocation = window.location.origin;
    }
    
    if (window.location.href.indexOf('edit') !== -1) {
      //in edit theme view, initialize
      this.init();
    }
  }

  init() {
    this.activity = this.storageService.get('activity');
    this.activity['selected_activity'] = this.activity['id'];
    //save default theme in case theme is removed
    this.defaultTheme = this.activity ? this.activity['theme'] : [];
    this.selectedCategory = 'all';
    let tmpTheme, tmpBadge;
    //in edit mode, badge might already be selected, push in selected array
    if ( this.activity['badge']) {
      this.storageService.set('selectedBadgeType', 'badge');
    } else if (this.activity['image']) {
      this.storageService.set('selectedBadgeType', 'upload');
    }
    //get activity theme categories
    this.activityService.getActivityCategories().subscribe(result => {
      if (result.status) {
        this.activityCategories = result.body['data'];
      }
    })
    //get activity backgrounds
    this.activityService.getActivityThemes().subscribe(result => {
      // if (result.status) {
      //   this.activityBackgroundArr = result.body['data'];
      //   this.loading = false;
      // }
      if (result.status) {
        this.activityBackgroundArr  = result.body['data'].filter(item => item.publish);
        // this.activityBackgroundArr = tmpTheme;
        this.loading = false;
      }
        this.activityBg = this.activityBackgroundArr;
    })
    //get activity badge
    this.activityService.getActivityBadges().subscribe(result => {
      if (result.status) {
        // this.activityBadgeArr = result.body['data'];
        this.activityBadgeArr = result.body['data'].filter(item => item.publish);
        // this.activityBadgeArr = tmpBadge;
        this.loadingBadges = false;
      }
      this.activityBadge = this.activityBadgeArr;
    })
  }

  setCategory(event) {
    this.activityBg = [];
    if (event.target.value === 'all') {
      //show all categories
      this.activityBg = this.activityBackgroundArr;
    } else {
      this.updateThemes(event.target.value);
    }
  }

  updateThemes(filter) {
    let themeArr: string [] = [];
    let selected: string [] = [];
    this.activityBackgroundArr.forEach((theme, idx, arr) => {
      themeArr = theme['categories'];
      if (themeArr.some(item => item['name'] === filter)) {
        selected.push(this.activityBackgroundArr[idx]);
      }
    })
    this.activityBg = selected;
  }

  setBadge(event) {
    this.activityBadge = [];
    if (event.target.value === 'all') {
      //show all badges
      this.activityBadge = this.activityBadgeArr;
    } else {
      this.updateBadge(event.target.value);
    }
  }
  
  getPastLogos() {
    this.pastLogosArr = [];
    this.activityService.getActivityImage().subscribe(result => {
      if (result.body['status']) {
        this.pastLogosArr = result.body['activities'];
      }
    })
  }

  updateBadge(filter) {
    let badgeArr: string [] = [];
    let selected: string [] = [];
    this.activityBadgeArr.forEach((badge, idx, arr) => {
      badgeArr = badge['categories'];
      if (badgeArr.some(item => item['name'] === filter)) {
        selected.push(this.activityBadgeArr[idx]);
      }
    })
    this.activityBadge = selected;
  }

  uploadImage(event) {
    let res;
    this.loadingBadges = true;
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
        // submit to server
        // console.log('file', file)
        this.activityService.uploadImage(this.activity['id'],file).subscribe(result => {
          if (result.body['status']) {
            //image was successfully uploaded
            this.loadingBadges = false;
            this.newUpload = result.body['img_src'];
            this.activity['image_location'] = this.newUpload;
            this.selectedBadge.push(this.newUpload);
            // console.log('result.body', result.body)
            // console.log(' this.activity',  this.activity)
            this.storageService.set('activity', this.activity);
            this.storageService.set('selectedBadgeType', 'upload');
          }
        });
    }
  }

  selectTheme(theme) {
    if (this.selectedTheme.some(item => item['id'] === theme.id)) {
      //theme is already selected, so replace with default theme
      this.selectedTheme = [];
      this.activity['theme'] = this.defaultTheme;
      this.activity['activity_theme_id'] = 2;
      this.activityService.updateActivity(this.activity).subscribe(
        response => {
          if (response.body['status']) {
            this.storageService.set('activity', response.body['data']);
          }
        }
      )
    } else {
      //push theme to array
      this.selectedTheme = [];
      this.selectedTheme.push(theme);
      this.activity['theme'] = theme;
      this.activity['activity_theme_id'] = theme['id'];
      this.activityService.updateActivity(this.activity).subscribe(
        response => {
          if (response.body['status']) {
            this.storageService.set('activity', response.body['data']);
          }
        }
      )
    }
  }

  selectBadge(badge) {
    let selectedBadgeType: string;
    if (badge === 'upload') {
      this.activity['badge'] = null;
      if (!this.selectedBadge.some(item => item === this.activity['image'])) {
        // uploaded badge is not selected
        this.selectedBadge = [];
        this.selectedBadge.push(this.newUpload);
        this.activity['image'] = this.newUpload;
        selectedBadgeType = 'upload';
      } else {
        //uploaded badge is selected, so deselect and remove from server
        this.selectedBadge = [];
        this.activityService.removeUploadedImage(this.activity['id']).subscribe(
          response => {
            if (response.body['status']) {
              // console.log('returned activity', response.body['data']);
            }
          }
        )
      }
    } else if (this.selectedBadge.some(item => item['id'] === badge.id)) {
      //badge is already selected, so deselect and remove from server
      this.selectedBadge = [];
      this.activity['badge'] = null;
      this.activity['activity_badge_id'] = false;
      this.activityService.updateActivity(this.activity).subscribe(
        response => {
          if (response.body['status']) {
            this.storageService.set('activity', response.body['data']);
          }
        }
      )
    } else {
      selectedBadgeType = 'badge';
      //push badge to array
      this.selectedBadge = [];
      this.selectedBadge.push(badge);
      this.activity['badge'] = badge;
      this.activity['activity_badge_id'] = badge['id'];
      //update activity
      this.activityService.updateActivity(this.activity).subscribe(
        response => {
          if (response.body['status']) {
            // console.log('returned activity', response.body['data']);
            this.storageService.set('activity', response.body['data']);
          }
        }
      )
    }
    this.storageService.set('selectedBadgeType', selectedBadgeType);
    this.storageService.set('activity', this.activity);
  }
}