import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../shared-services/storage.service';
import { VolunteerService } from '../../../shared-services/volunteer.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-recipient-filter-by-spot',
  templateUrl: './recipient-filter-by-spot.component.html',
  styleUrls: ['./recipient-filter-by-spot.component.scss']
})
export class RecipientFilterBySpotComponent implements OnInit {

  constructor(private volunteerService: VolunteerService,
    private storageService: StorageService) { }

  activity: {};
  volunteerId: {};
  participants: any[] = [];
  title: string;
  events: {};
  monthData: {};
  monthKeys: string[] = [];
  spotsList: string[] = [];
  loading: boolean = true;
  selected: any[] = [];
  groupedParticipants = [];
  jobKeys = [];
  selectedArr: any[] = [];
  volunteerData: [];

  ngOnInit() {
    this.activity = this.storageService.get('activity');
    this.title = this.activity['title'];
    this.volunteerData = this.storageService.get('volunteerData');
    this.selectedArr = this.storageService.get('selectedParticipants') || [];

    let participantsList;
    if (this.volunteerData && this.volunteerData.length) {
      this.loading = false;
      this.participants = this.storageService.get('volunteerData');
      this.groupedParticipants = this.groupBy(this.participants);
      Object.keys(this.groupedParticipants).map(item => {
          this.jobKeys.push ({
            spot: item,
            length: this.groupedParticipants[item].length,
            checked: false
          })
      })
    } else {
      //data is not in local storage, call from server
      this.volunteerService.get(this.activity['id']).subscribe(res => {
        if (res) {
          this.loading = false;
          participantsList = res.body['data'];
          participantsList.forEach(item => {
            if (item.email) {
              this.participants.push(item); 
            }
          })
          this.storageService.set('volunteerData', this.participants);
          this.groupedParticipants = this.groupBy(this.participants);
          // this.jobKeys = Object.keys(this.groupedParticipants);
          Object.keys(this.groupedParticipants).map(item => {
            this.jobKeys.push ({
              spot: item,
              length: this.groupedParticipants[item].length,
              checked: false
            })
        })
        }
      })
    }
  }

  groupBy(participants) {
    let sortBy, selectedArr = this.selectedArr;
    return participants.reduce(function (accum, vol) {
      if (vol['jobassignments'].length) {
        vol['jobassignments'].map(job => {
          sortBy = job['job_name'];
          //create new key if not already existing
          if (!accum[sortBy]) {
            accum[sortBy] = [];
          }
          //check items already in selected array
          if (selectedArr.some(x => (x.id || x.volunteer_id) === vol.id)) {
            vol.checked = true;
          }
          // add the value to the array
          if ( accum[sortBy].indexOf(vol) === -1) {
            accum[sortBy].push(vol);
          }
        })
      }
      return accum;
    }, {});
  }

  // getTotal(key) {
  //   return this.groupedParticipants[key].length;
  // }

  selectAllCheckboxes() {
    this.jobKeys.forEach(key => {
      this.selectAll(true, key);
    })
    this.saveSelection();
  }

  clearAllCheckboxes() {
    this.jobKeys.forEach(key => {
      this.selectAll(false, key);
    })
    this.saveSelection();
  }

  selectAll(event, key) {
    let idx;
    this.groupedParticipants[key.spot].forEach(participant => {
      if (this.selectedArr.length) {
        idx = this.selectedArr.findIndex(x => (x.id || x.volunteer_id) === participant['id']);
          if (event) {
            if (idx === -1) {
              participant['checked'] = true;
              this.selectedArr.push(participant);
            }
          } else {
            //event is unchecked
            if (idx != -1) {
              participant['checked'] = false;
              this.selectedArr.splice(idx, 1);
            }
          }
      } else {
        //array is empty, no need to search
        if (event) {
          participant['checked'] = true;
          this.selectedArr.push(participant);
        } 
      }
    })
    
    key.checked = event ? true : false;
    this.saveSelection();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  select(event, participant, key) {
    let idx, thisArr = [], allSelected, count;
    if (this.selectedArr.length) {
      idx = this.selectedArr.findIndex(x => (x.id || x.volunteer_id) === participant['id'])
      if (event) {
        if (idx == -1) {
          this.selectedArr.push(participant);
        }
      } else {
        if (idx!= -1) {
          //found the index, remove from array
          this.selectedArr.splice(idx, 1);
        }
      }
    } else {
      if (event) {
        this.selectedArr.push(participant);
      }
    }
    if (this.selectedArr.length) {
      count = 0;
      thisArr = this.groupedParticipants[key.spot];
       allSelected = thisArr.every(item => this.selectedArr.indexOf(item) != -1);
      if (allSelected) {
        key.checked = true;
      } else {
        key.checked = false;
      }
    } else {
      //selected array is empty
      key.checked = false;
    }

    this.isParticipantChecked(event, participant);
  }

  isParticipantChecked(event, participant) {
    //check if there are other instances of the selected participant
    this.participants.forEach(item => {
        if(item['id'] === participant['id']) {
        //found the index
        if (event === true) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      }
    })
    this.saveSelection();
  }

  saveSelection() {
    this.storageService.set('selectedParticipants', this.selectedArr);
  }

  goNext() {
    //go back to the recipients filter page
    this.storageService.set('selectedParticipants', this.selectedArr);
    window.history.back();
  }
}
