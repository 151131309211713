<app-header icon="back" title="_SetSpotToRepeat_" showNav="false" backText="{{backText}}" changed="{{changedVal}}"></app-header>
<div class="edit-job-repeat-page body-container">
  <form [formGroup]="editRepeatingJobForm">
    <div class="select-interval">
      <mat-radio-group class="interval-radio-group" (change)="setJobIntervalType($event.value)"
        formControlName="jobIntervalType">
        <mat-radio-button class="radio-button-column" value="irregular">
          {{'_AddJobSetupDays_' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-button-column" value="custom">
          {{'_AddJobSetupRepeating_' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Custom Repeat Container -->
    <div class="repeat-container" *ngIf="jobIntervalType === 'custom'">
      <p>Repeats Every</p>
      <div class="custom-repeating-row">

        <!-- number spinner -->
        <app-number-spinner min="1" max="9999" showUnlimited="false" quantity="{{repeatIntervalUnit}}"
          (update)="onUpdate($event)" (error)="onError($event)">
        </app-number-spinner>

        <span id="custom-repeating">
          <select class="form-control" (change)="setCustomRepeatInterval($event.target.value)"
            formControlName="customInterval">
            <option value="daily">days</option>
            <option value="weekly">weeks</option>
            <option value="monthly">months</option>
          </select>
        </span>
      </div>
      <div *ngIf="repeatInterval === 'weekly'">
        <p>Repeats On</p>
        <div class="repeat-on">
          <span *ngFor="let item of repeatDayOfWeek | keyvalue: sortOrder" (click)="selectDayOfWeek(item)"
            class="custom-day-interval"
            [ngClass]="{'selected-day': item.value === true}">{{item.key | slice:0:3}}</span>
        </div>
      </div>
      <div *ngIf="repeatInterval === 'monthly'">
        <p>Repeat By</p>
        <mat-radio-group class="repeat-by-radio-group" layout="row" formControlName="monthInterval"
          (change)="setMonthType($event.value)">
          <mat-radio-button class="radio-button-column" value="each">
            day of the month
          </mat-radio-button>
          <mat-radio-button class="radio-button-column" value="on">
            day of week
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- REPEATS ENDS SECTION -->
      <div class="repeat-ends-container">
        <div class="repeat-ends-on">
          <!-- repeat ends on -->
          <p>Repeats Ends</p>
          <mat-radio-group class="repeating-radio-group" formControlName="repeatEndsOn" (change) = "setRepeatEnd($event.value)">
            <mat-radio-button class="radio-button-column" value="repeatEndsOn">
              <span>On</span>
              <span class="repeats-ends">
                <span class="spots-calendar">
                  <mat-form-field>
                    <input class="form-control" matInput readonly [min]="minEndDate" [max]="calendarEndDate" [matDatepicker]="picker"
                      formControlName="repeatEndDate" (click)="picker.open()"
                      (dateChange)="selectEndDate($event.value)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
                  </mat-form-field>
                </span>
              </span>

              <!-- repeat ends after -->
            </mat-radio-button>
            <mat-radio-button *ngIf = "!isEdit"class="radio-button-column repeat-end-after" value="repeatEndsAfter">After
              <span class="repeats-ends-after">
                <!-- number spinner -->
                <app-number-spinner min="1" max="9999" showUnlimited="false" quantity="1" (update)="getEndInstance($event)" (error)="onError($event)">
                </app-number-spinner>

              </span>
              <span>instances</span>
              <span>
              </span>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <!-- Irregular Repeat Container -->
    <div class="repeat-container" *ngIf="jobIntervalType === 'irregular'">
      <div>
        <div (click)="$event.stopPropagation()">
          <mat-calendar *ngIf="jobDate" #calendar (selectedChange)="select($event,calendar)" [dateClass]="dateClass"
            [dateFilter]="disableJobDate" [minDate]="calendarStartDate" [maxDate]="calendarEndDate"></mat-calendar>
            <mat-calendar *ngIf="!jobDate" #calendar [minDate]="calendarStartDate" (selectedChange)="select($event,calendar)" [dateClass]="dateClass"></mat-calendar>
        </div>
        <!-- {{daysSelected|json}} -->
      </div>
    </div>
  </form>

  <!-- summary section -->
  <div class="summary-section">
    <!-- no events -->
    <span *ngIf="noEvents" class="error"> {{'_AddJobModalAddRepeatingPatternFails_' | translate}}</span>
    <!-- irregular summary -->
    <span *ngIf="jobIntervalType === 'irregular' && irregularSummary && !noEvents">
      <span *ngIf="this.job && repeatingJobs.length">{{'_YouAreEditing_' | translate}}  <b>{{jobQuantity}}</b> <span *ngIf="jobQuantity != '1'; else singleSpot">
        Spots</span> on <span class="bold-blue">{{jobDate}}</span> {{irregularSummary}}.</span> 
      <span *ngIf="!this.job || !repeatingJobs.length">{{'_YouAreAdding_' | translate}} <b>{{jobQuantity}}</b> <span *ngIf="jobQuantity != '1'; else singleSpot">
        Spots</span> on {{daysSelected.length}} <span *ngIf="daysSelected.length != '1'; else singleDay">days</span>.</span>
    </span>
    <span *ngIf="jobIntervalType === 'irregular' && !irregularSummary && !jobDate" class="error"> 
      {{'_SELECT_AT_LEAST_ONE_DAY_' | translate}}
    </span>
    <!-- custom summary -->
    <span *ngIf="jobIntervalType === 'custom' && !noEvents">
      <span *ngIf="this.job && repeatingJobs.length">{{'_YouAreEditing_' | translate}} <b>{{jobQuantity}}</b> <span *ngIf="jobQuantity != '1'; else singleSpot">
        Spots</span> on <span class="bold-blue">{{jobDate}}</span> {{customSummary}}</span>
      <span *ngIf="!this.job || !repeatingJobs.length">{{'_YouAreAdding_' | translate}} <b>{{jobQuantity}}</b> <span *ngIf="jobQuantity != '1'; else singleSpot">
        Spots</span> {{customSummary}}</span>
      
    </span>
    <ng-template #singleSpot> Spot</ng-template>
    <ng-template #singleDay> day</ng-template>
  </div>

  <div class="button-bottom">
    <button type="button" class="btn btn-blue edit-update-btn" [disabled]="!formIsValid"
      (click)="onFormSubmit()">{{'_Done_' | translate}}</button>
    <div class="edit-cancel-btn" (click)=cancelChanges()>{{'_Cancel_' | translate}}</div>
  </div>
</div>