import { Component, OnInit } from '@angular/core';
import { AddEditThemesComponent } from './../../shared/add-edit-themes/add-edit-themes.component';
import { ShowPreviewComponent } from './../../create-signup/show-preview/show-preview.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
    selector: 'app-edit-theme-info',
    templateUrl: './edit-theme-info.component.html',
    styleUrls: ['./edit-theme-info.component.scss']
})
export class EditThemeInfoComponent implements OnInit {

  constructor(private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {}

  openPreview() {
    this._bottomSheet.open(ShowPreviewComponent);
  }

  updateTheme() {
    window.history.back();
  }
}
