<div>
  <form [formGroup]="spinnerForm">
    <div class="spots-row">
      <span class="spots-unlimited" *ngIf="showUnlimited == 'true'">
        <div class="form-group">
          <mat-checkbox formControlName="unlimitedSpots" (change)=toggleSpotInput($event)>
            <label>{{'_Unlimited_' | translate}}</label>
          </mat-checkbox>
        </div>
      </span>

      <span class="spot-num-container">
        <span (click)="decreaseSpot()">
          <i class="material-icons" [ngClass]="isUnlimited ? 'disabled-text' : 'enabled-text'">remove_circle_outline</i>
        </span>
        <input type="number" (input)="setVal($event.target.value)" class="form-control" formControlName="formQuantity" [ngClass]="{'error-input' : showError}">
        <span (click)="increaseSpot()" class="pr-0">
          <i class="material-icons"
            [ngClass]="isUnlimited ? 'disabled-text' : 'enabled-text'">add_circle_outline</i></span>
      </span>
    </div>
  </form>

</div>
