import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { StorageService } from '../../shared-services/storage.service';
import { UtilitiesService } from '../../shared-services/utilities.service';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { AlertDialogComponent } from './../../shared/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DomsanitizerPipe } from '../../pipes/domsanitizer.pipe';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-invite-participants',
  templateUrl: './invite-participants.component.html',
  styleUrls: ['./invite-participants.component.scss']
})
export class InviteParticipantsComponent implements OnInit {

  constructor(private router:Router,
    private storageService: StorageService,
    private utilitiesService: UtilitiesService,
    private translate: TranslateService,
    private clipboardService: ClipboardService,
    public dialog: MatDialog,
    private sanitize: DomsanitizerPipe) { }

  ngOnInit() {
  }
}
