import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StorageService } from '../../shared-services/storage.service';
import { ActivityService } from '../../shared-services/activity.service';
import { Router } from "@angular/router";
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

export interface Emails {
  email: string;
  name: string;
}

@Component({
  selector: 'app-quicksearch',
  templateUrl: './quicksearch.component.html',
  styleUrls: ['./quicksearch.component.scss'],
})
export class QuicksearchComponent implements OnInit {

  backText: string = '_Dashboard_';
  participants = [];
  emails: Emails[] = [];
  emailArr: Emails[] = [];
  quickSearchForm: FormGroup;
  filteredOptions: Observable<Emails[]>;
  loading: boolean = false;

  constructor(
    private storageService: StorageService,
    private activityService: ActivityService,
    private router:Router
  ) { }

  ngOnInit() {
    var selectedEmail;
    if (this.storageService.get('quicksearchVol')) {
      //coming from the quicksearch detail page
      this.loading = true;
      selectedEmail = this.storageService.get('quicksearchVol')['email'].toLowerCase();
      this.getVolunteerEmail(selectedEmail);
    } else {
      selectedEmail = '';
      this.getVolunteerEmail();
    }
    this.quickSearchForm = new FormGroup({
      userEmail: new FormControl(selectedEmail)
    })
  }

  getVolunteerEmail(selectedEmail?) {
    var emailsArr = [];
    this.activityService.showAllVolunteers().subscribe(result => {
      if (result['status']) {
        // console.log(result['data'])
        this.emailArr = result['data'];
        this.emailArr.forEach(item => {
          if (item['email']) {
            emailsArr.push({
              email:item['email'].toLowerCase(),
              name: item['contactname'] ? item['contactname'].toLowerCase() : null
            })
          }
        })
        this.emails = emailsArr;
        //watch email field for changes and filter
        this.filteredOptions = this.quickSearchForm.get('userEmail')!.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );    
        if (selectedEmail) {
          //coming from quick search detail page
          this.selectEmail(selectedEmail);
        }
      }
    })
  }

  private _filter(value: string): Emails[] {
    let filterValue;
    if (value) {
      filterValue = value.toLowerCase();
      return this.emails
        .map(item => ({email: item.email, name: item.name}))
        .filter(item => item.email.includes(filterValue));
    }
    return this.emails;

  }

  selectEmail(email) {
    // console.log(email)
    var idx, quicksearchVol;
    this.loading = true;
    idx = this.emailArr.findIndex(item => {
      if (item['email']) {
        return item['email'].toLowerCase() === email.toLowerCase();
      }
    });
    quicksearchVol = this.emailArr[idx];
    // console.log(quicksearchVol);
    this.storageService.set('quicksearchVol', quicksearchVol);
    this.activityService.showMyVolunteer(email).subscribe(result => {
      this.loading = false;
      if (result['status']) {
        this.participants = result.body['data'];
      }
    })
  }

  goToDetail(vol) {
    let router = this.router;
    this.storageService.set('quicksearch-detail', vol, function() {
      router.navigateByUrl('/quicksearch-detail');
    });
  }
}
