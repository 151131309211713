<app-header icon="home" title="_DashboardBetaWizardText_" showNav="false"
  changed="{{changedVal}}"></app-header>

<div class="create-signup-page body-container">
  <mat-spinner *ngIf="loading" class="fixed-spinner" [diameter]="50"></mat-spinner>
  <form [formGroup]="createFormGroup">
    <mat-horizontal-stepper [linear]="isLinear" #stepper formArrayName="formArray" (selectionChange)="onStepChange($event)">
      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>
      <mat-step [stepControl]="formArray?.get([0])" formGroupName="0" [editable]="true" [completed]="step1Completed">
        <!-- STEP 1 Basic Info-->
        <span class="container step-header">
          <span class="step">STEP 1 OF 4</span>
          <span>{{'_BasicInformationHeader_' | translate}}</span>
        </span>
        <div class="container">
          <div class="form-group">
            <!-- signup kind-->
            <label class="edit-label-name">{{'_ActivityKind_'| translate}}</label>
            <select class="form-control" formControlName="kind" (change)="setSignupKind($event)"
              [ngClass]="{'error-input': showError && formArray?.get([0])?.controls['kind'].invalid}">
              <option value=""></option>
              <option value="dated">{{'_ActivityKindOptionDated_' | translate}}</option>
              <option value="nodate">{{'_ActivityKindOptionNoDate_' | translate}}</option>
            </select>
            <span *ngIf="!setUpKind" class="helper-text"
              [innerHTML]="'_ActivityKindHelpUnselected_' | translate"></span>
            <span *ngIf="setUpKind === 'dated'" class="helper-text"
              [innerHTML]="'_ActivityKindHelpSingleDay_' | translate"></span>
            <span *ngIf="setUpKind === 'nodate'" class="helper-text"
              [innerHTML]="'_ActivityKindHelpNoDate_' | translate"></span>
            <span *ngIf="setUpKind === 'contribonly'" class="helper-text"
              [innerHTML]="'_ActivityKindHelpContributionOnly_' | translate"></span>

            <span *ngIf="showError && formArray?.get([0])?.controls['kind'].invalid"
              class="error">{{'_ACTIVITY_KIND_REQUIRED_' | translate}}</span>
          </div>

          <!-- category-->
          <div class="form-group">
            <label class="edit-label-name">{{'_ActivityCategory_'| translate}}</label>
            <select class="form-control capitalize" formControlName="category"
              (change)="updateHelperText($event.target.value)"
              [ngClass]="{'error-input': showError && formArray?.get([0])?.controls['category'].invalid}">
              <option value=""></option>
              <option *ngFor="let item of categoryArr" [value]="item.value">{{item.value}}</option>
            </select>
            <span class="helper-text" [innerHTML]="activityCategoryHelp"></span>
            <span *ngIf="showError && formArray?.get([0])?.controls['category'].invalid"
              class="error">{{'_ACTIVITY_CATEGORY_REQUIRED_' | translate}}</span>
          </div>

          <!-- signup name -->
          <div class="form-group">
            <label class="edit-label-name">{{'_Name_'| translate}}</label>
            <input type="text" class="form-control" formControlName="name" maxLength="40" required
              [ngClass]="{'error-input': showError && formArray?.get([0])?.controls['name'].invalid}">
            <span class="helper-text" [innerHTML]="'_ActivityNameHelp_' | translate"></span>
            <span *ngIf="showError && formArray?.get([0])?.controls['name'].invalid"
              class="error">{{'_ACTIVITY_NAME_REQUIRED_' | translate}}</span>
          </div>
          <div class="form-group">
            <label class="edit-label-name">{{'_WelcomeField_'| translate}}</label>
            <!-- editor welcome message -->
            <quill-editor formControlName="welcomeMsgEditor" [modules]="editorConfig"
              (onContentChanged)="getMaxLength($event)">
            </quill-editor>
            <span class="ql-editor-count" *ngIf="formArray?.get([0]).get('welcomeMsgEditor').value != null">
              {{editorCount}}/{{editorMaxLength}}
            </span>
            <span class="ql-editor-count"
              *ngIf="formArray?.get([0]).get('welcomeMsgEditor').value == null">0/{{editorMaxLength}}</span>
            <span class="helper-text" [innerHTML]="'_ActivityWelcomeHelp_' | translate"></span>
          </div>
          <!-- location -->
          <app-google-places-autocomplete  (updateLocation)="onUpdateLocation($event)"></app-google-places-autocomplete>
        </div>
        <!-- extra settings -->
        <div class="more-settings">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <div class="blue-header">{{'_AddJobModalExtraDetails_' | translate}}</div>
              </mat-expansion-panel-header>
              <div class="options-section">
                <div class="form-group">
                  <mat-checkbox formControlName="allowShare">
                    <label>{{'_ShareInviteWithOthers_' | translate}}</label>
                  </mat-checkbox>
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="showNames">
                    <label>{{'_SeeWhoResponded_' | translate}}</label>
                  </mat-checkbox>
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="allowSwap">
                    <label>{{'_AllowSwaps_' | translate}}</label>
                  </mat-checkbox>
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="allowSuo">
                    <label>{{'_AllowSignUpOthers_' | translate}}</label>
                  </mat-checkbox>
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="hideComment">
                    <label>{{'_HideComments_' | translate}}</label>
                  </mat-checkbox>
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="time24hr">
                    <label>{{'_24HrTime_' | translate}}</label>
                  </mat-checkbox>
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="spots_created_by_participants">
                    <label>{{'_nag_' | translate}}
                      <button type="button" class="tooltip-btn" ngxTippy tippyName="nagHelp" [tippyProps]="tippyProps">
                        <i class="material-icons info-icon">info</i>
                      </button>
                    </label>
                  </mat-checkbox>
                </div>
                <!-- limit spots, only show if user is premium -->
                <div class="form-group">
                  <ng-container *ngIf="userIsPremium">
                    <mat-checkbox formControlName="limitSpots" class="edit-signup-limit-spots"
                      (change)="toggleLimit($event)">
                      <label>{{'_LimitSpotsByNumber_' | translate}}</label>
                    </mat-checkbox>
                    <input type="text" formControlName="limitNum" class="input-limit-spots">
                    <span class="limit-text">{{'_LimitSpotsText_' | translate}}
                      <select class="limit-dropdown" formControlName="limitType">
                        <option value="limitBySignup">{{'_LimitSpotsOptionSignup_' | translate}}</option>
                        <option value="limitByDay">{{'_LimitSpotsOptionDay_' | translate}}</option>
                        <option value="limitByWeek">{{'_LimitSpotsOptionWeek_' | translate}}</option>
                      </select>
                    </span>
                  </ng-container>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="footer" id="right-align">
          <button mat-button [disabled]="submitted" (click)="submitFormData(stepper)">{{'_NextStep_' | translate}}</button>
        </div>
      </mat-step>

      <!-- STEP 2 Participant Info-->
      <mat-step [stepControl]="formArray?.get([1])" formGroupName="1" [editable]="false" [completed]="step2Completed">
        <span class="container step-header">
          <span class="step">STEP 2 OF 4</span>
          <span>{{'_ParticipantInfo_' | translate}}</span>
        </span>
        <app-add-edit-participant-info formControlName="requirePhone" mode="create"></app-add-edit-participant-info>
        <div class="footer">
          <button mat-button (click)="goToDashboard()">{{'_SaveDraft_' | translate}}</button>
          <button mat-button (click)="goToSpots(stepper)">{{'_NextStep_' | translate}}</button>

        </div>
      </mat-step>


      <!-- STEP 3 Create Spots-->
      <mat-step [editable]="false" [completed]="step3Completed">
        <span class="container step-header">
          <span class="step">STEP 3 OF 4</span>
          <span>{{'_AddJobs_' | translate}}</span>
        </span>
        <div class="container create-job-section">
          <ng-container *ngIf="hasSpots">
            <app-add-edit-spot mode="create" backToPage="create"></app-add-edit-spot>
          </ng-container>
          <ng-container *ngIf="!hasSpots">
            <div>
              <a class="btn-blue-dotted" (click)="addNewSpot()">
                <i class="material-icons">add</i> {{'_CreateNewSpot_'| translate}}
              </a>
            </div>
            <div>
              <span [innerHTML]="'_JobsEmptyTitle_' | translate"></span>
              <span [innerHTML]="'_JobsEmptyContent_' | translate"></span>
            </div>
          </ng-container>
        </div>
        <div class="footer">
          <button mat-button (click)="goToDashboard()">{{'_SaveDraft_' | translate}}</button>
          <button [ngClass]="!hasSpots ? 'disabled-text' : 'enabled-text'" [disabled]="!hasSpots" mat-button
            (click)="goForward(stepper)">{{'_NextStep_' | translate}}</button>
        </div>
      </mat-step>

      <!-- STEP 4 -->
      <mat-step [editable]="false" [completed]="step4Completed">
        <span class="container step-header">
          <span class="step">STEP 4 OF 4</span>
          <span>{{'_AddThemeAndImage_' | translate}}</span>
          <span class="skip-text">{{'_AddThemeHeader_' | translate}}</span>
        </span>
        <div class="container create-theme-section">
          <app-add-edit-themes></app-add-edit-themes>
        </div>
        <div class="footer">
          <button mat-button (click)="openPreview()">{{'_PreviewActivity_' | translate}}</button>
          <button mat-button (click)="goToDonePage()">{{'_Done_' | translate}}</button>
        </div>
      </mat-step>

    </mat-horizontal-stepper>
  </form>
</div>