<app-header icon="back" title="_AddSpotShifts_" showNav="false" backText="_EditSpot_" changed="{{changedVal}}">
</app-header>
<div class="edit-spot-shifts-page">
  <div class="info-text light-gray-bg">
    <small>Use the shift builder to create multiple timeslots at once, within a single block of time. Great for
      conferences and appointments.</small>
  </div>
  <div class="container">
    <div class="job-details">
      <span>
        <h5>{{jobTitle}}</h5>
      </span>
      <span class="job-time default-blue" (click)="openDialog('time')">
        <i class="material-icons">edit</i>
        {{jobStartTime}} - {{jobEndTime}}</span>
      <span *ngIf="timeError" class="error">{{'_JobShiftMissing_' | translate}}</span>
    </div>
    <form [formGroup]="shiftBreakForm">
      <div class="shift-duration">
        <h6>Set the number of Participants per Shift</h6>
        <div class="participant-qty-container">
          <app-number-spinner min="1" max="9999" showUnlimited="true" quantity="{{jobQuantity}}"
            (update)="onUpdate($event)" (error)="onError($event)">
          </app-number-spinner>
        </div>

        <h6>Set a Shift Duration</h6>
        <!-- <mat-button-toggle-group #group="matButtonToggleGroup" value="{{selectedShift}}"> -->
        <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="selectedShift">

          <mat-button-toggle value="15" (change)="setShiftDuration($event.value)">
            <span class="toggle-text">
              <span class="larger-font">15</span>
              <span class="smaller-font">MINS</span>
            </span>
          </mat-button-toggle>
          <mat-button-toggle value="30" (change)="setShiftDuration($event.value)">
            <span class="toggle-text">
              <span class="larger-font">30</span>
              <span class="smaller-font">MINS</span>
            </span>
          </mat-button-toggle>
          <mat-button-toggle value="60" (change)="setShiftDuration($event.value)">
            <span class="toggle-text">
              <span class="larger-font">60</span>
              <span class="smaller-font">MINS</span>
            </span>
          </mat-button-toggle>
          <mat-button-toggle value="custom" (click)="openDialog('shift')">
            <span class="toggle-text custom-text-width">
              <span class="larger-font" [innerHtml]="customShift"></span>
              <span class="smaller-font">CUSTOM</span>
            </span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="shift-break">
        <h6>Set Breaks Between Shifts</h6>
        <!-- <mat-button-toggle-group #group2="matButtonToggleGroup" value="{{selectedBreak}}"> -->
        <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="selectedBreak">
          <mat-button-toggle value="none" (change)="setShiftBreak($event.value)">
            <span class="toggle-text" id="center-text">
              <span class="smaller-font">NONE</span>
            </span>
          </mat-button-toggle>
          <mat-button-toggle value="10" (change)="setShiftBreak($event.value)">
            <span class="toggle-text">
              <span class="larger-font">10</span>
              <span class="smaller-font">MINS</span>
            </span>
          </mat-button-toggle>
          <mat-button-toggle value="20" (change)="setShiftBreak($event.value)">
            <span class="toggle-text">
              <span class="larger-font">20</span>
              <span class="smaller-font">MINS</span>
            </span>
          </mat-button-toggle>
          <mat-button-toggle value="custom" (click)="openDialog('break')">
            <span class="toggle-text custom-text-width">
              <span class="larger-font" [innerHtml]="customBreak"></span>
              <span class="smaller-font">CUSTOM</span>
            </span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </form>
    <div class="shift-summary">
      <h6>Shifts</h6>
      <div class="mb-4">
        <small *ngIf="quickfillShifts.length">This will create {{quickfillShifts.length}} Shifts with {{spotNumText}}
          Participant(s) each between {{jobStartTime}} and {{jobEndTime}}</small>
        <small *ngIf="!quickfillShifts.length">No shifts have been created yet.</small>
      </div>
      <div class="shift-row" *ngFor="let shift of quickfillShifts; let i=index" (click)="deleteShift(i)">
        <span class="shift-info">
          <span class="shift-text">
            <span>{{shift.fromTime | date: 'h:mm a'}} - {{shift.toTime | date: 'h:mm a'}}</span>
            <span class="light-gray-text">{{spotNumText}} participant spot(s)</span>
          </span>
          <span class="shift-icon"><i class="material-icons">delete</i></span>
        </span>
      </div>
    </div>
  </div>
  <div class="button-bottom">
    <button type="button" class="btn btn-blue edit-update-btn" [disabled]="timeError || !quickfillShifts.length"
      (click)="onFormSubmit()">{{'_Done_' | translate}}</button>
    <div class="edit-cancel-btn" (click)=cancelChanges()>{{'_Cancel_' | translate}}</div>
  </div>
</div>
