import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from '../../shared-services/utilities.service';
import { StorageService } from '../../shared-services/storage.service';
import { Router, RouterModule } from "@angular/router";
import { Browser } from '@capacitor/browser';

// declare var cordova: any;

@Component({
  selector: 'app-done-page',
  templateUrl: './done-page.component.html',
  styleUrls: ['./done-page.component.scss']
})
export class DonePageComponent implements OnInit {

  constructor(private utilitiesService: UtilitiesService,
             private storageService: StorageService) { }

  activity: {};
  activityId: string;
  activityTitle: string;

  ngOnInit() {
    this.activity = this.storageService.get('activity');
    this.activityId = this.activity['id'];
    this.activityTitle = this.activity['title'];
    //reset previously set values
    this.storageService.remove('jobToExpand');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  generateLink() {
    var link;
    this.utilitiesService.generateLink(this.activityId).subscribe(
      response => {
        //save current user object because it changes when you to participant view
        this.storageService.set('userObject',this.storageService.get('user'));
        link = response.body['longlink'] + '&amp;popup=true&amp;preview=true';
        if (this.storageService.get('isCordovaApp') && this.storageService.get('isCordovaApp') === true) {
          // cordova.InAppBrowser.open(link, '_system', 'location=yes');
          Browser.open({ url: link });
        } else {
          window.location.href = link;
        }
      }
    )
  }
}
