<div class="add-edit-job">
  <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
  <ng-container *ngTemplateOutlet="editmodule"></ng-container>
  <ng-template #editmodule>
    <div [formGroup]="editJobForm">
      <div class="container" [ngClass]="{'opacity': loading}">
        <div class="form-group">
          <h6>{{'_Name_' | translate}}<span class="asterisk"> *</span></h6>
          <input type="text" class="form-control" [ngClass]="{'error-input': showNameError}" formControlName="jobname" maxLength="40" [matAutocomplete]="title">
          <mat-autocomplete #title="matAutocomplete">
            <mat-option *ngFor="let item of jobTitleSuggestions" [value]="item">
              <span>{{item}}</span>
            </mat-option>
          </mat-autocomplete>
          <span *ngIf="showNameError" class="error">Please enter a spot name.</span>
        </div>
        <!-- spots container -->
        <div class="spots-container">
          <!-- spots section -->
          <div class="how-many">
            <p>{{'_EditJobHowMany_' | translate}}<span class="asterisk"> *</span></p>
          </div>

          <!-- number spinner -->
          <app-number-spinner min="1" max="9999" showUnlimited="true" quantity="{{jobQuantity}}"
            (update)="onUpdate($event)" (error)="onError($event)">
          </app-number-spinner>
        </div>

        <!-- calendar section -->
        <div class="calendar-section" *ngIf="!undated">
          <h6>{{'_SpotDate_' | translate}}<span class="asterisk"> *</span>
            <button type="button" class="tooltip-btn" 
            ngxTippy 
            [tippyProps]="tippyProps"
            data-tippy-content="You can choose specific calendar dates, or no date at all by leaving the field blank. Spots dates can be one individual day, or a span of multiple days if you set a spot to repeat.">
              <i class="material-icons info-icon">info</i>
            </button>
          </h6>
            <span *ngIf="jobDate" class="flex-column">
              <span *ngIf="editing"> {{jobDate | date: 'MMM d, yyyy'}}</span>
              <span class="enabled-text set-repeat" [ngStyle]="{'margin-bottom': showDateError ? '0' : '1rem'}" *ngIf="repeatEnabled && !repeatSummary" (click)=setRepeat()>Set Spot to Repeat</span>
            </span>
            <span *ngIf="!jobDate" class="flex-column">
              <span class="enabled-text set-repeat" [ngStyle]="{'margin-bottom': showDateError ? '0' : '1rem'}" *ngIf="!repeatSummary" (click)=setRepeat()>Choose Spot Dates</span>
              <span *ngIf="showDateError && !repeatSummary" class="error">Please select a spot date.</span>
            </span>

          <!-- if repeat has been chosen, show repeat summary -->
          <div *ngIf="repeatSummary" class="mb-4">
            <span class="shift-summary">
              <span (click)="setRepeat()" class="mr-1">{{repeatSummary}}</span>
              <span (click) = "removeSummary('repeat')"class="shift-icon"><i class="material-icons">delete</i></span>
            </span>
          </div>
        </div>
        <!-- time section -->
        <div *ngIf="!shiftSummary">
          <div class="choose-time">
            <h6>{{'_SpotTime_' | translate}}
              <button type="button" class="tooltip-btn" ngxTippy tippyName="spotTimeTooltip" [tippyProps]="tippyProps">
                <i class="material-icons info-icon">info</i>
              </button>
              
            </h6>
            <mat-radio-group class="radio-row" (change)="setTimeInterval($event.value)" formControlName="timeInterval">
              <mat-radio-button value="from" class="md-primary">from</mat-radio-button>
              <mat-radio-button value="at" class="ml-3">at</mat-radio-button>
              <mat-radio-button value="anytime">anytime</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="time-section" *ngIf="timeInterval !== 'anytime'">
            <div class="mb-3">
              <!-- start time -->
              <span class="time-wrapper">
                <span>
                  <select class="form-control" formControlName="startHour"
                    (change)="setTimeValues('startHour', $event.target.value)">
                    <option *ngFor="let item of hourArr" [value]="item">{{item}}</option>
                  </select>
                </span>
                <span class="divider"> : </span>
                <span>
                  <select class="form-control" formControlName="startMinute"
                    (change)="setTimeValues('startMinute', $event.target.value)">
                    <option *ngFor="let item of minuteArr" [value]="item">{{item}}</option>
                  </select>
                </span>
                <span class="time-button">
                  <button type="button" class="btn btn-blue" (click)="toggleStartMeridiem()">{{jobStartMeridiem}}</button>
                </span>
              </span>
              <h6 *ngIf="timeInterval === 'from'">Start</h6>
            </div>

            <!-- end time -->
            <div *ngIf="timeInterval === 'from'">
              <span class="time-wrapper">
                <span>
                  <select class="form-control" formControlName="endHour"
                    (change)="setTimeValues('endHour', $event.target.value)">
                    <option *ngFor="let item of hourArr" [value]="item">{{item}}</option>
                  </select>
                </span>
                <span class="divider"> : </span>
                <span>
                  <select class="form-control" formControlName="endMinute"
                    (change)="setTimeValues('endMinute', $event.target.value)">
                    <option *ngFor="let item of minuteArr" [value]="item">{{item}}</option>
                  </select>
                </span>
                <span class="time-button">
                  <button  type="button" class="btn btn-blue" (click)="toggleEndMeridiem()">{{jobEndMeridiem}}</button>
                </span>
              </span>
              <h6>End
              </h6>
            </div>
          </div>
          <span *ngIf="timeError" class="warning">{{'_AddJobModalTimeWarning_' | translate}}</span>
          <div>
            <p *ngIf="!hasRepeatingData || creating" class="small enabled-text" (click)="setShifts()">{{'_AddShiftsOrAppointments_' | translate}}</p>
          </div>
        </div>

        <!-- if shifts has been chosen, show shift summary -->
        <div *ngIf="shiftSummary">
          <h6>{{'_SpotTime_' | translate}}
            <button type="button" class="tooltip-btn" ngxTippy tippyName="spotTimeTooltip" [tippyProps]="tippyProps">
              <i class="material-icons info-icon">info</i>
            </button>
          </h6>
          <span class="repeat-summary">
            <span class="mr-1">{{shiftSummary}}</span>
            <span (click) = "removeSummary('shift')" class="shift-icon"><i class="material-icons">delete</i></span>
          </span>
        </div>
      </div>

      <!-- additional settings -->
      <div class="more-settings">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <div class="panel-blue-header">{{'_AddJobModalExtraDetails_' | translate}}</div>
            </mat-expansion-panel-header>
            <div class="spot-type">
              <label class="edit-label-name">{{'_AddJobModalJobType_'| translate}}</label>
              <button type="button" class="tooltip-btn" ngxTippy tippyName="spotDetailHeaderTooltip" [tippyProps]="tippyProps">
                <i class="material-icons info-icon">info</i>
              </button>
              <mat-button-toggle-group #group="matButtonToggleGroup" value="{{jobType}}">
                <mat-button-toggle value="0" (change)="setJobType($event.value)">
                  <span class="toggle-text">{{'_ToDoAttend_'|translate}}</span>
                </mat-button-toggle>
                <mat-button-toggle value="1" (change)="setJobType($event.value)">
                  <span class="toggle-text">{{'_ToBring_'|translate}}</span>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="form-group extra-details">
              <label class="edit-label-name">{{'_AddJobModalExtraDetails_'| translate}}</label>
              <button type="button" class="tooltip-btn" ngxTippy tippyName="spotDetailInfoTooltip" [tippyProps]="tippyProps">
                <i class="material-icons info-icon">info</i>
              </button>
              <!-- editor -->
              <quill-editor formControlName="editor" [modules]="editorConfig"
                [placeholder]="'_AddJobExtraDetailsPlaceholder_'|translate" (onContentChanged)="getMaxLength($event)" (onEditorCreated)="editorCreated($event)">
              </quill-editor>
              <span class="ql-editor-count" *ngIf="editJobForm.get('editor').value != null">
                {{ editorCount }}/{{editorMaxLength}}
              </span>
              <span class="ql-editor-count" *ngIf="editJobForm.get('editor').value == null">0/{{editorMaxLength}}</span>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <ng-container>
        <div *ngIf="!showCloseBtn" class="button-bottom">
          <div *ngIf="showError">
            <div *ngIf="!errorMessageParam" class="error-div">{{errorMessage | translate}}</div>
            <div *ngIf="errorMessageParam" class="error-div">{{errorMessage | translate:errorMessageParam}}</div>
          </div>
          <button type="button" [disabled]="numError || submitted" class="btn btn-blue edit-update-btn"
            (click)="onSubmit()"> 
            <span *ngIf="job">{{'_Update_' | translate}}</span>
            <span *ngIf="!job">{{'_Done_' | translate}}</span>
          </button>
          <div class="edit-cancel-btn" (click)=cancelChanges()>{{'_Cancel_' | translate}}</div>
        </div>
        <div *ngIf="showCloseBtn" class="button-bottom">
          <div class="error-div">{{confMessage | translate:confMessageParam}}</div>
          <button type="button" [disabled]="numError" class="btn btn-blue edit-update-btn"
            (click)="cleanUpLocalStorage()">{{'_Close_' | translate}}</button>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
