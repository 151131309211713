const hostname = window && window.location && window.location.origin;
let apiUrl: string = '';

if (window.origin.indexOf("localhost") > -1) {
  apiUrl =  'https://signup.com';
} else {
  apiUrl = '';
}
console.log('apiUrl', apiUrl);
export const environment = {
  production: true,
  apiUrl: apiUrl
};
