import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], inputSearch: string): any[] {
    var firstNameFilter = false;
    var lastNameFilter = false;
    var fullNameFilter = false;
    var jobFilter = false;
    var emailFilter = false;

    if(!items) return [];
    if(!inputSearch) return items;
    inputSearch = inputSearch.toLowerCase();

    return items.filter( it => {
      // filter by job name
      if (it.jobassignments.length) {
        it.jobassignments.map(item => {
          jobFilter = (item.job_name).toLowerCase().includes(inputSearch);
        });
      } else {
        jobFilter = false;
      }
      // filter by first name
      if(it.contact_first_name) {
        firstNameFilter = it.contact_first_name.toLowerCase().includes(inputSearch);
      } else {
        firstNameFilter = false;
      }
      // filter by last name
      if(it.contact_last_name) {
        lastNameFilter = it.contact_last_name.toLowerCase().includes(inputSearch);
      } else {
        lastNameFilter = false;
      }
      // filter by first and last name
      if(it.contactname) {
        fullNameFilter = it.contactname.toLowerCase().includes(inputSearch);
      }else {
        fullNameFilter = false;
      }
      // filter by email
      if (it.email) {
        emailFilter = it.email.toLowerCase().includes(inputSearch);
      } else {
        emailFilter = false;
      }

      return firstNameFilter || lastNameFilter || fullNameFilter || emailFilter || jobFilter;
    });
  }
}
