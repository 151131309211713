<div class="login-page">
  <div class="login-div">
    <p class="separator-text"> {{'_Or_'| translate}}</p>
    <p class="text-center">{{'_LoginWithEmailAddress_'| translate}}</p>
    <form #loginForm="ngForm" (ngSubmit)="onLoginSubmit(loginForm)">
      <div class="form-group">
        <label for="email"><small>{{'_EmailAddress_'| translate}}</small></label>
        <input type="email" 
          class="form-control" 
          name="email" 
          #formEmail = "ngModel" 
          [(ngModel)]="email" 
          required
          autocomplete="email"
          (change)="setInputValue('email', loginForm, $event.target.value)"
          pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$">
        <div *ngIf="formSubmitted && formEmail.errors?.required" class="error"> <small><span
              translate>_VALIDATION_REQUIRED_ERROR_</span></small></div>
        <div *ngIf="formSubmitted && formEmail.errors?.pattern" class="error"> <small><span
              translate>_VALIDATION_EMAIL_ERROR_</span></small></div>
      </div>
      <div class="form-group pswd-input">
        <label for="pwd"><small>{{'_Password_'| translate}}</small></label>
        <input [type]="showPswd ? 'text' : 'password'" 
          class="form-control" 
          #formPassword = "ngModel" 
          name="password"
          [(ngModel)]="password" 
          autocomplete="password"
          (change)="setInputValue('password', loginForm, $event.target.value)"
          required>
        <a *ngIf="!this.showPswd" class="pswd-btn" (click)="showPassword()">{{'Show' | translate}}</a>
        <a *ngIf="this.showPswd" class="pswd-btn" (click)="showPassword()">{{'Hide' | translate}}</a>
        <div *ngIf="formSubmitted && formPassword.errors?.required" class="error"> <small><span
              translate>_VALIDATION_REQUIRED_ERROR_ </span></small></div>
      </div>
      <button type="submit" class="btn btn-blue btn-block mb-4">{{'_Login_'| translate}}</button>
    </form>
  </div>
  <div class="forgot-link">
    <a class="default-blue float-right" routerLink='/forgotpswd'>{{'_ForgotPassword_' | translate}}</a>
  </div>
  <div class="register-button">
    <a class="btn btn-gray btn-block" routerLink="/organizer/register">
      {{'_NotRegistered_'| translate}}
    </a>
  </div>
</div>
