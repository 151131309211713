import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { StorageService } from '../../shared-services/storage.service';
import { VolunteerService } from '../../shared-services/volunteer.service';
import { JobService } from '../../shared-services/job.service';
import { UserService } from '../../shared-services/user.service';
import { ActivityService } from '../../shared-services/activity.service';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
// import { CustomValidators } from './../../shared/validators/custom-chkbox.validators';

@Component({
  selector: 'app-edit-assign-spot',
  templateUrl: './edit-assign-spot.component.html',
  styleUrls: ['./edit-assign-spot.component.scss']
})
export class EditAssignSpotComponent implements OnInit {

  constructor(private router: Router,
    private storageService: StorageService,
    private volunteerService: VolunteerService,
    private jobService: JobService,
    private userService: UserService,
    private activityService: ActivityService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private cdref: ChangeDetectorRef
  ) {}

  assignSpotForm: FormGroup;
  activity: {};
  participant: {};
  user: {};
  job: {};
  accesskey: string;
  extraFields = [];
  radioValues: string[] = [];
  dropdownValues: string[] = [];
  checkboxValues: string[] = [];
  extraFieldsValue: string;
  showError: boolean = false;
  changedVal: boolean = false;
  creating: boolean = false;
  submitted: boolean = false;
  editVol: boolean = false;

  ngOnInit() {   
    this.activity = this.storageService.get('activity');
    this.participant = this.storageService.get('participant');
    let volName, volEmail, volPhone;
    let emailPattern = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$";
    this.user = this.storageService.get('user');
    if (!this.activity || !this.user) {
      //no activity or user data, log out
      this.userService.logout().subscribe(
        () => {
          this.router.navigateByUrl('/organizer/login');  
          this.storageService.reset();  
          this.storageService.set('full_login', false, null);  
        }
      )
    } else {
      this.job = this.storageService.get('job');
      this.accesskey = this.user['accesskey'];
      //get nav query params
      this.activatedRoute.queryParams.subscribe(params => {
        if (params && params['mode'] === 'create') {
          this.creating = true;
          this.storageService.set('jobPageVisited', true);
        }
        if (params && params['editVol'] === 'true') {
          //we are editing the participant
          this.editVol = true;
        }
        if (this.editVol) {
          volName = this.participant['name'];
          volEmail = this.participant['email'];
          volPhone = this.participant['phone'];
        }
      })
      //refresh activity from server in case there are changes
      this.activityService.orgActivityQuery(this.activity['id']).subscribe(
        response => {
          if (response && response.status) {
            this.activity = response.body['data'];
            this.extraFields = this.activity['custom_fields'];
            // console.log('this.extraFields', this.extraFields)
            this.storageService.process('activity', response.body['data']);
            this.assignSpotForm = new FormGroup({
              name: new FormControl(volName),
              email: new FormControl(volEmail, Validators.pattern(emailPattern)),
              phone: new FormControl(volPhone)
            })
            this.getExtraFields();
          }
        }
      );
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getExtraFields() {
    let newControlName, thisVal;
    // loop through extra fields value and create form controls
    this.extraFields.forEach(item => {
      let fieldFormat = item['definition']['format'];
      newControlName = 'field' + item['field_number'];
      if (this.editVol) {
        thisVal = this.getVal(item['field_number'], this.participant);
      }
      if (fieldFormat === 'text' || fieldFormat === 'date' || fieldFormat === 'dropdown' || fieldFormat === 'radio') {
        if (thisVal) {
          this.assignSpotForm.addControl(newControlName, new FormControl(thisVal));
        } else {
          this.assignSpotForm.addControl(newControlName, new FormControl());
        }
        if (item['required']) {
          //add validator
          this.assignSpotForm.get(newControlName).setValidators(Validators.required);               
        }
      } else if (fieldFormat === 'number') {
        if (thisVal) {
          this.assignSpotForm.addControl(newControlName, new FormControl(parseInt(thisVal)));
        } else {
          this.assignSpotForm.addControl(newControlName, new FormControl());
        }
        if (item['required']) {
          //angular formcontrol is weird. Setvalidator removes the previous
          //validator, so I have to repeat to set multiple validators
          this.assignSpotForm.get(newControlName).setValidators(Validators.required);   
          if (item['definition']['min']) {
            this.assignSpotForm.get(newControlName).setValidators([Validators.required, Validators.min(item['definition']['min'])]);               
          }
          if (item['definition']['max']) {
            this.assignSpotForm.get(newControlName).setValidators([Validators.required, Validators.max(item['definition']['max'])]);               
          }            
        } else {
          //set only min and max validators
          if (item['definition']['min']) {
            this.assignSpotForm.get(newControlName).setValidators(Validators.min(item['definition']['min']));               
          }
          if (item['definition']['max']) {
            this.assignSpotForm.get(newControlName).setValidators(Validators.max(item['definition']['max']));               
          } 
        }
        this.assignSpotForm.get(newControlName).updateValueAndValidity();
      } else if (fieldFormat === 'checkbox') {
        let checkboxArr;
        if (thisVal) {
          checkboxArr = thisVal.split(',');
          this.assignSpotForm.addControl(newControlName, this.formBuilder.array(checkboxArr));         
        } else {
          this.assignSpotForm.addControl(newControlName, this.formBuilder.array([]));
        }
        if (item['required']) {
          //add validator
          this.assignSpotForm.get(newControlName).setValidators(Validators.required); 
        }
        this.assignSpotForm.get(newControlName).updateValueAndValidity();

      } 
    })
    this.formValueChanged();
  }

  validateNum(item) {
    let showNumError = true;
    let field = 'field' + item['field_number'];
    if (this.submitted) {
      if (this.assignSpotForm.controls[field].errors) {
        showNumError = true;
      } else {
        showNumError = false;
      }
    } else {
      //form has not been submitted
      showNumError = false;
    }
    return showNumError;
  }

  getVal = function (fieldNumber, participant) {
    var trimmedVal, volExtraFields;
    if (participant.custom_fields) {
      volExtraFields = participant.custom_fields;
      for (var k = 0; k < volExtraFields.length; k++) {
        if (volExtraFields[k].field_number === fieldNumber) {
          trimmedVal = volExtraFields[k].value.replace(/\s*\,\s*/g, ",").trim();
          return trimmedVal;
        }
      }
    }
  };

  formValueChanged() {
    //check for value changes
    this.assignSpotForm.valueChanges.subscribe(val => {
      this.changedVal = true;
      if  (this.assignSpotForm.get('name').value || this.assignSpotForm.get('email').value) {
        this.showError = false;
      } else {
        this.showError = true;
      }
    })
  }

  checkboxSelected(val, fieldNum) {
    let newArr, thisVal, checked = false;
    if (this.editVol) {
      thisVal = this.getVal(fieldNum, this.participant);
      if (thisVal) {
        newArr = thisVal.split(',');
        newArr.forEach(item => {
          if (val === item) {
            checked = true;
          }
        });
      }
    }
    return checked;
  }

  onCheckboxChange(event, val, field, required) {
    let idx;
    idx = this.assignSpotForm.controls[field].value.findIndex(elem => {
      return elem === val;
    });
    if (event.checked) {
      this.assignSpotForm.controls[field].value.push(val);
    } else {
      //item was unchecked, remove from form control
      if (idx > -1) {
        this.assignSpotForm.controls[field].value.splice(idx, 1);
      }
    }
    if (required) {
      //at least one checkbox needs to be checked
      if (this.assignSpotForm.controls[field].value.length) {
        this.assignSpotForm.controls[field].setErrors(null);
      } else {
        this.assignSpotForm.controls[field].setErrors({required:true});
      }
    }
  }

  onSelectChange(event, field) {
    this.assignSpotForm.patchValue({field: event.target.valueal});
    // console.log(this.assignSpotForm);
  }

  onRadioChange(val, field) {
    this.assignSpotForm.patchValue({field: val});
    // console.log(this.assignSpotForm);
  }

  validateContact() {
    let name= this.assignSpotForm.get('name').value,
    email= this.assignSpotForm.get('email').value;
    if (!name && !email) {
      this.showError = true;
    } else {
      this.showError = false;
    }
    return this.showError;
  }


  onAddSpotSubmit(form) {
    // console.log('form', form)
    let control, tmp;
    this.submitted = true;
    let data = {
      name: this.assignSpotForm.get('name').value,
      email: this.assignSpotForm.get('email').value,
      phonenumber: this.assignSpotForm.get('phone').value,
      selected_activity: this.activity['id'],
      quantity: 1,
      extra_fields: {}
    }

    if (!this.assignSpotForm.get('name').value && !this.assignSpotForm.get('email').value) {
      //show error
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.showError = true;
    } else if (form.status === 'INVALID') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (form.status === 'VALID' && !this.showError) {
      // this.showError = false; 
      let formField;
      for (const field in this.assignSpotForm.controls) { // 'field' is a string
        formField = form['value'][field];
        if (formField && typeof formField === 'object') {
          if (formField && formField.length > 0) {
            tmp = formField.join(',');
            formField = tmp;
          } else if (formField.length && formField.length == 0) {
            formField = '';
          }
        }
        data[field] = formField;
        if (field.includes("field")) {
          data.extra_fields['custom'+field] = formField;
        }
      }

      if (this.editVol) {
        //update volunteer
        data['id'] = this.participant['id'];
        this.volunteerService.updateVolunteer(data).subscribe(
          response => {
            if (response) {
              // console.log('server response, ', response.body['volunteer'])
              this.storageService.set('participant', response.body['volunteer'], function () {
                window.history.back();
              });
            }
          }
        )
      } else {
        //create new volunteer
        this.volunteerService.createVolunteer(data).subscribe(
          response => {
            if (response) {
              //assign spot to newly created volunteer
              this.jobService.addJobAssignment1(this.activity['id'], this.job['id'], response.body['volunteer']['id'], this.accesskey).subscribe(
                response => {
                  if (response) {
                    if (window.location.hash.indexOf('create')) {
                      //I came from the creation wizard
                      this.router.navigate(['edit_spots_detail/' + this.job['id']], { queryParams: { mode: 'create' } });
                    } else {
                      this.router.navigate(['edit_spots_detail/' + this.job['id']]);
                    }
                  }
                }
              )
            }
          }
        )
      }
    }
  }
}
