  <div class="add-edit-spot-page">
    <div class="container">
      <!-- progress bar -->
      <div class="progress-bar-header" *ngIf="this.mode === 'edit' && activityTotal">
        <div class="text-center">{{totalAssignments}} / {{activityTotal}} Spots Filled</div>
        <app-progress-bar *ngIf="calcWidth !== null && !isUnlimited" width="{{calcWidth}}"></app-progress-bar>
      </div>
      <a class="btn-blue-dotted" (click)= "addNewSpot()">
        <i class="material-icons">add</i> {{'_CreateNewSpot_'| translate}}
      </a>
    </div>
    <!-- filter spots -->
    <div class="form-group filter-spots" *ngIf="mode === 'edit' && hasSpots">
      <label class="edit-label-name">{{'_Show_'| translate}}</label>
      <select class="form-control" [(ngModel)]="initFilter" (change)="getFilteredData($event.target.value)">
        <option *ngFor="let item of filterOptions" [value]="item">{{item}}</option>
      </select>
    </div>
    
    <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
    <div class="spots-section spots-accordion" *ngIf="monthKeys.length" [ngClass]="{'opacity': loading}">
      <mat-accordion #accordion="matAccordion" [multi]="false" *ngIf="datedSignup">
        <!-- outer panel for dated signups -->
        <mat-expansion-panel class="mat-top-panel" *ngFor="let key of monthKeys; index as i; trackBy: trackByFn"
          [expanded]="openMonth === i">
          <mat-expansion-panel-header class="mat-blue-header">
            <div class="panel-top-header" #panelToOpen>
              <span></span>
              <span></span>
              {{key | date: 'MMMM, yyyy'}}</div>
          </mat-expansion-panel-header>
          <div>
            <mat-accordion class="inner-accordion" [multi]="false">
              <!-- second level panel for month and date -->
              <mat-expansion-panel #panel *ngFor="let month of monthData[key]; index as j; trackBy: trackByFn"
                [expanded]="openMonth === i && openJob == j" (opened)="scrollToItem()">
                <mat-expansion-panel-header class="panel-second-header-top">
                  <span class="panel-second-header" id="{{ 'spot-' + month.id }}">
                    <span class="spots-month">{{month.edate | date: 'EEE, MMM d'}}</span>
                    <ng-container *ngIf="mode === 'edit'">
                      <span class="spots-num"
                      *ngIf="month.bringstotal !== -1 && month.jobstotal !== -1">{{month.bringstotalassignments + month.jobstotalassignments}}/{{month.bringstotal + month.jobstotal}}
                      {{'_Filled_' | translate}}</span>
                    <span class="spots-num"
                      *ngIf="month.bringstotal === -1 || month.jobstotal === -1">{{month.bringstotalassignments + month.jobstotalassignments}}
                      {{'_Filled_' | translate}}</span>
                    </ng-container>
                  </span>
                </mat-expansion-panel-header>
                <span class="job-name" *ngFor="let job of month.jobs; let i = index;" (click)= "goToJob(job, month)" id="job-{{job.id}}">
                  <!-- job -->
                  <span class="job-spot-container">
                    <span class="job-spot-name">{{job.name}}</span>
                    <span class="job-spot-who">
                      <!-- name on first job assignment -->
                      <span *ngIf="job.jobassignments.length">{{job.jobassignments[0].who}}</span>
                      <span *ngIf="job.jobassignments.length > 1">+ {{job.jobassignments.length - 1}} more</span>
                    </span>
                    <span *ngIf="job.starthour && job.starthour !== -1" class="job-spot-who">{{getJobTime(job)}}</span>
                  </span>
                  <ng-container *ngIf="mode === 'edit'">
                    <span class="job-open-spots" *ngIf="job.quantity !== -1">{{job.totalassignments}}/{{job.quantity}}
                      {{'_Filled_' | translate}}<i class="material-icons">keyboard_arrow_right</i></span>
                    <span class="job-open-spots" *ngIf="job.quantity === -1">{{job.totalassignments}}
                      {{'_Filled_' | translate}}<i class="material-icons">keyboard_arrow_right</i></span>
                  </ng-container>

                  <ng-container *ngIf="mode === 'create'">
                    <span class="job-open-spots"><i class="material-icons">keyboard_arrow_right</i></span>
                  </ng-container>
                </span>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion #accordion="matAccordion" [multi]="false" *ngIf="!datedSignup">
        <!-- undated signups-->
        <mat-expansion-panel #panel2 class="mat-top-panel" *ngFor="let key of monthKeys; index as i; trackBy: trackByFn"
          [expanded]="openMonth === i" (opened)="scrollToItem()">
          <mat-expansion-panel-header class="mat-blue-header">
            <div class="panel-top-header" #panelToOpen>{{totalAssignments}}
              <span *ngIf="totalAssignments === 1">Spot</span>
              <span *ngIf="totalAssignments === 0 || totalAssignments > 1">Spots</span>
              <span> {{'_Filled_' | translate}}</span>
            </div>
          </mat-expansion-panel-header>
          <div>
            <mat-accordion class="inner-accordion" [multi]="false">
              <mat-expansion-panel *ngFor="let month of monthData[key]; index as j; trackBy: trackByFn"
                [expanded]="openMonth === i && openJob == j">
                <span class="job-name" *ngFor="let job of month.jobs; let i = index;" id="job-{{job.id}}" (click)= "goToJob(job, month)">
                  <!-- job -->
                  <span class="job-spot-container">
                    <span class="job-spot-name">{{job.name}}</span>
                    <span class="job-spot-who">
                      <!-- name on first job assignment -->
                      <span *ngIf="job.jobassignments.length">{{job.jobassignments[0].who}}</span>
                      <span *ngIf="job.jobassignments.length > 1">+ {{job.jobassignments.length - 1}} more</span>
                    </span>
                    <span *ngIf="job.starthour && job.starthour !== -1" class="job-spot-who">{{getJobTime(job)}}</span>
                  </span>
                  <ng-container *ngIf="mode === 'edit'">
                    <span class="job-open-spots" *ngIf="job.quantity !== -1">{{job.totalassignments}}/{{job.quantity}}
                      {{'_Filled_' | translate}}<i class="material-icons">keyboard_arrow_right</i></span>
                    <span class="job-open-spots" *ngIf="job.quantity === -1">{{job.totalassignments}}
                      {{'_Filled_' | translate}}<i class="material-icons">keyboard_arrow_right</i></span>
                  </ng-container>
                </span>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- list is empty -->
    <div class="empty-text" *ngIf="!monthKeys.length && !loading">
      <ng-container *ngIf="!hasSpots">
        <span [innerHTML]="'_JobsEmptyTitle_' | translate"></span>
        <span [innerHTML]="'_JobsEmptyContent_' | translate"></span>
      </ng-container>
      <ng-container *ngIf="hasSpots">
        <span class="empty-text-header">{{'_JobsViewEmptyTitle_' | translate}}</span>
        <span class="empty-text-body">{{'_JobsViewEmptyContent_' | translate}}</span>
      </ng-container>
    </div>
  </div>