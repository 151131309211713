<app-header icon="back" title="_EditNotifSettingHeader_" showNav="false" backText="_Details_" changed="{{changedVal}}"></app-header>
<div class="edit-notification-settings body-container">
  <form [formGroup]="editSettingsForm" (ngSubmit)="onSubmit()" class="mt-4">
    <div class="container">
      <div class="form-group">
        <!-- send reminders -->
        <label class="edit-label-name">{{'_NotifyReminder_'| translate}}</label>
        <select class="form-control" formControlName="sendReminders">
          <option *ngFor="let item of sendRemindersArr" [value]="item">{{item}}</option>
        </select>
      </div>
      <div class="form-group">
        <!-- notify change-->
        <label class="edit-label-name">{{'_NotifyOnChange_'| translate}}</label>
        <select class="form-control" formControlName="notifyChange">
          <option *ngFor="let item of notifySpotsChangedArr" [value]="item">{{item}}</option>
        </select>
      </div>
      <div class="form-group">
        <!-- comment summary -->
        <label class="edit-label-name">{{'_NotifySummary_'| translate}}</label>
        <select class="form-control" formControlName="commentSummary">
          <option *ngFor="let item of commentsSummaryArr" [value]="item">{{item}}</option>
        </select>
      </div>
      <div class="form-group">
        <!-- notify signup -->
        <label class="edit-label-name">{{'_NotifyOnSignup_'| translate}}</label>
        <select class="form-control" formControlName="notifySignup">
          <ng-container *ngFor="let item of notifySignUpArr; let i = index">
              <option *ngIf="item.length" [value]="item">{{item}}</option>
          </ng-container>
        </select>
      </div>
      <div class="form-group">
        <!-- notify cancel -->
        <label class="edit-label-name">{{'_NotifyOnCancel_'| translate}}</label>
        <select class="form-control" formControlName="notifyCancel">
          <option *ngFor="let item of notifyCancelArr" [value]="item">{{item}}</option>
        </select>
      </div>
      <div class="form-group">
        <!--notify signup is full -->
        <label class="edit-label-name">{{'_NotifyOnFull_'| translate}}</label>
        <select class="form-control" formControlName="notifyFullSignup">
          <option *ngFor="let item of notifyFullSignupArr" [value]="item">{{item}}</option>
        </select>
      </div>
      <div class="form-group">
        <!--extra fields on reminders-->
        <label class="edit-label-name">{{'_AddExtraReminders_'| translate}}</label>
        <textarea class="form-control" formControlName="extraFields" rows="3"></textarea>
      </div>
    </div>

    <div class="button-bottom">
      <button type="submit" [disabled]="!editSettingsForm.valid"
        class="btn btn-blue edit-update-btn">{{'_Update_' | translate}}</button>
      <div class="edit-cancel-btn" (click)=cancelChanges()>{{'_Cancel_' | translate}}</div>
    </div>
  </form>
</div>