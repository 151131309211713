<app-header icon="back" title="_AddNewParticipant_" backText="_AssignParticipant_" showNav="false"></app-header>
<div class="edit-assign-spot-container">
  <div class="container">
    <form *ngIf="assignSpotForm" [formGroup]="assignSpotForm" (ngSubmit)="onAddSpotSubmit(assignSpotForm)">
      <div class="form-group">
        <label class="edit-label-name">{{'_Name_'| translate}}</label>
        <input type="text" class="form-control" formControlName="name">
        <div *ngIf="showError" class="error"> <small><span
          translate>_ContactValidate_</span></small></div>
      </div>
      <div class="form-group">
        <label class="edit-label-name">{{'_Email_'| translate}}</label>
        <input type="text" 
        class="form-control" 
        formControlName="email">
        <div *ngIf="submitted && assignSpotForm.get('email').errors?.pattern" class="error"> <small><span
          translate>_NEW_VOLUNTEER_EMAIL_INVALID_</span></small></div>
      </div>
      <div class="form-group">
        <label class="edit-label-name">{{'_Phone_'| translate}}</label>
        <input type="text" class="form-control" formControlName="phone">
      </div>
      <ng-container *ngIf="extraFields.length">
        <div *ngFor="let item of extraFields; let i = index">
          <span>
            <label class="edit-label-name">{{item.title}}</label>
            <span *ngIf="item.definition.comment"><small> ({{item.definition.comment}})</small></span>
            <span *ngIf="item.required" class="asterisk"> *</span>
          </span>
          <!-- text -->
          <div class="form-group">
            <ng-container *ngIf="item.definition.format === 'text'">
              <div class="flex-column">
                <input 
                type="text" 
                class="form-control" 
                [formControlName]="'field' + item['field_number']"
                [required] = "item.required">
                <div *ngIf="submitted && assignSpotForm.controls['field' + item['field_number']].errors?.required" class="error"> <small><span
                  translate>_VALIDATION_REQUIRED_ERROR_</span></small></div>
              </div>
            </ng-container>
            <!-- number -->
            <ng-container *ngIf="item.definition.format === 'number'">
              <div class="flex-column w-100">
                <input type="number" 
                class="form-control" 
                [formControlName]="'field' + item['field_number']"
                [min] = "item.definition.min ? item.definition.min : null"
                [max] = "item.definition.max ? item.definition.max : null"
                [required] = "item.required"
                [placeholder] = "item.definition.max ? 'enter a number between ' + item.definition.min + ' and ' + item.definition.max : 'enter a number'">
                <div *ngIf="validateNum(item)" 
                class="error"> <small><span
                  translate>Please enter a number between {{item.definition.min}} and {{item.definition.max}}. </span></small></div>
              </div>
            </ng-container>
            <!-- date -->
            <ng-container *ngIf="item.definition.format === 'date'">
              <div class="flex-column">
                <input type="date" 
                class="form-control" 
                [formControlName]="'field' + item['field_number']"
                [required] = "item.required">
                <div *ngIf="assignSpotForm.controls['field' + item['field_number']].errors?.required" class="error"> <small><span
                  translate>_VALIDATION_REQUIRED_ERROR_</span></small></div>
              </div>
            </ng-container>
            <!-- dropdown -->
            <ng-container *ngIf="item.definition.format === 'dropdown'">
              <div class="flex-column">
                <div>
                  <select class="form-control"
                  (change)="onSelectChange($event, 'field' + item['field_number'])"
                  [formControlName]="'field' + item['field_number']" 
                  [required] = "item.required">
                  <option value="" disabled>{{'_PleaseSelectOne_' | translate}}</option>
                    <option *ngFor="let val of item.definition.values.split(','); let i = index" 
                    [value] = "val">
                    {{val}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && assignSpotForm.controls['field' + item['field_number']].errors?.required" class="error"> <small><span
                  translate>_RadioValidate_</span></small></div>
              </div>
            </ng-container>
            <!-- checkbox -->
            <ng-container *ngIf="item.definition.format === 'checkbox'">
              <div class="flex-column">
                <div>
                  <span *ngFor="let val of item.definition.values.split(','); let i = index" 
                  id="{{'field'+item['field_number']}}">
                    <mat-checkbox 
                    [required] = "item.required"
                    [formArrayName]="'field' + item['field_number']"
                    [value] = "val"
                    [checked] = "checkboxSelected(val, item['field_number'], item.required)"
                    (change)="onCheckboxChange($event, val, 'field' + item['field_number'], item.required)">
                      <label>
                        <span class="flex-column truncate">
                          <span class="participant-name">{{val}}</span>
                         </span>
                      </label>
                    </mat-checkbox>
                  </span>
                </div>
                <div *ngIf="submitted && assignSpotForm.controls['field' + item['field_number']].errors?.required" class="error"> <small><span
                  translate>_CheckboxValidate_</span></small></div>
              </div>
            </ng-container>
            <!-- radio -->
            <ng-container *ngIf="item.definition.format === 'radio'">
              <div class="flex-column">
                <div>
                  <mat-radio-group class="interval-radio-group"
                  [formControlName]="'field' + item['field_number']" 
                  [required] = "item.required">
                  <mat-radio-button 
                  *ngFor="let val of item.definition.values.split(','); let i = index"
                  class="radio-button-column" 
                  [value] = "val"
                  (change)="onRadioChange(val, item['field_number'])">
                  {{val}}
                  </mat-radio-button>
                </mat-radio-group>
                </div>
                <div *ngIf="submitted && assignSpotForm.controls['field' + item['field_number']].errors?.required" class="error"> <small><span
                  translate>_RadioValidate_</span></small></div>
              </div>            
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div class="button-bottom">
        <!-- <div class="error-div" *ngIf="showError">You need to specify either a name or an email.</div> -->
        <button type="submit"
          class="btn btn-blue edit-update-btn">{{'_Save_' | translate}}</button>
      </div>
    </form>
  </div>
</div>