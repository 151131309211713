<app-header icon="logo" showNav="true"></app-header>
<div class="homepage-container">
    <section class="p-3 top-section">
      <!-- top section -->
      <div style="padding-left: 1rem">
          <p class="homepage-header default-blue font-weight-thin">We handle the busy work, so you can enjoy the moments.</p>
        <p>Easily create free online Signup sheets, manage volunteer scheduling, and more!</p>
        <p><a (click)="openLink(url + '/Holiday')">{{'_HomepageHeaderSocialDistancingText_'| translate}}</a></p>
      </div>
      <div class="btn-container">
        <a (click)="onRegisterClick()" class="btn btn-blue btn-block">
            {{'_OrganizeSignup_'| translate}}
        </a>
        <a (click) = "findMySignups()" class="btn btn-gray btn-block">
          {{'_JoinExistingEvent_'| translate}}
          </a>
      </div>
    </section>

    <section class="light-gray-bg mt-3 mb-3 planning-section">
      <!-- planning section -->
      <div class="p-3">
          <h2 class="text-center pb-5"><small>{{'_HowItWorks_'| translate}}</small></h2>
          <div class="plan-div">
              <img src="assets/images/planTheAwesome.png" alt="">
              <h5 class="text-center font-weight-light">{{'_PlanTheAwesome_'| translate}}</h5>
              <img src="assets/images/everyoneSignsUp.png" alt="">
              <h5 class="text-center font-weight-light">{{'_EveryoneSignsUp_'| translate}}</h5>
              <img src="assets/images/remindLeftEnvelope.png" alt="">
              <h5 class="text-center font-weight-light">{{'_RemindCrew_'| translate}}</h5>
          </div>
      </div>
    </section>

    <section class="preview-section">
        <!-- preview section -->
        <h4 class="text-center"><a class="default-blue" (click)="openLink(url + '/Demo')">{{'_PreviewDemo_'| translate}}</a></h4>
      <img class="pt-3 pr-3 pl-3 img-fluid" src="assets/images/iphone.png" alt="">
    </section>
</div>
 <div>
    <a (click)="openLink(url + '/Demo')"><img class="w-100" src="assets/images/DemoImage.png" alt=""></a>
    <section class="footer-section dark-gray-bg">
      <!-- footer -->
      <div>
        <p class="text-center"><a class="color-inherit" (click)="openLink(url + '/?mobile=disable')">{{'_DesktopSite_'| translate}}</a></p>
        <hr>
        <ul class="text-center">
          <li><a (click)="openLink(url + '/Why-Use-Us')">{{'_About_'| translate}}</a></li>
          <li><a routerLink = "/organizer/register">{{'_Register_'| translate}}</a></li>
          <li><a (click) = "findMySignups()">{{'_Join_'| translate}}</a></li>
          <li><a (click)="openLink(url + '/Idea-Center')">{{'_Ideas_'| translate}}</a></li>
          <li><a (click)="openLink(url + '/Help')">{{'_Help_'| translate}}</a></li>
        </ul>
      </div>
      <p class="text-center"><small>{{currentYear}}, VolunteerSpot, Inc. All Rights Reserved.</small></p>
    </section>
</div>
