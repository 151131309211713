import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {GoogleAnalyticsService} from "../shared-services/google-analytics.service";
import { StorageService } from '../shared-services/storage.service';
import { environment } from './../../environments/environment';
import { isPlatform } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { SocialAuthService } from "@abacritt/angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "@abacritt/angularx-social-login";

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  constructor(private router:Router,
              private route: ActivatedRoute,
              private googleAnalyticsService: GoogleAnalyticsService,
              private authService: SocialAuthService,
              private storageService: StorageService) { }

  isCordovaApp:boolean = false;
  url: string;
  
  ngOnInit() {
    //check if app is run in browser or the mobile app
    if (isPlatform('capacitor')) {
      this.isCordovaApp = true;
    //check native app?
    if (this.storageService.get('environment')) {
      this.url = this.storageService.get('environment');
    } else {
      //environment is not set, set it again
      if (environment) {  
        this.storageService.set('environment', environment.apiUrl); 
        this.url = environment.apiUrl;
      } else {
        //default to production, on app
        this.url = 'https://signup.com';
      }
    }
      if (this.storageService.get('email') && this.storageService.get('password')) {
        //user was previously logged in via email
        this.router.navigateByUrl('/organizer/login');  
      }
      if (this.isCordovaApp && (this.storageService.get('google_user') || this.storageService.get('fb_user'))) {
        //user was previously logged in via fb or google
        this.router.navigateByUrl('/organizer');  
      }
    } else {
      this.url = window.location.origin;
    }      
  }

  currentYear:number = new Date().getFullYear();

  findMySignups() {
    this.googleAnalyticsService.emitEvent('button', 'click', 'm/find_my_signups');
    this.openLink(this.url + '/mobileweb/2.0/vspot.html#find_my_signups_page');
  }

  onRegisterClick() {
    this.googleAnalyticsService.emitEvent('button', 'click', 'm/organize_with_signup');
    if (this.storageService.get('user')) {
      this.router.navigateByUrl('dashboard');
    } else {
      this.router.navigateByUrl('organizer');
    }
  }

  openLink(link) {
    if (this.isCordovaApp) {
      Browser.open({ url: link });
    } else {
      window.location.href = link;
    }
  }
}
