import { Component, Input, Output, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { StorageService } from '../../shared-services/storage.service';
import { ActivityService } from './../../shared-services/activity.service';
import { AlertDialogComponent } from './../../shared/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-extra-special-fields',
  templateUrl: './extra-special-fields.component.html',
  styleUrls: ['./extra-special-fields.component.scss'],
})
export class ExtraSpecialFieldsComponent implements OnInit {

  editParticipantForm: FormGroup;
  subscriptions: Subscription[] = [];
  activity: {};
  activityName: string;
  user: {};
  extraFieldArr: object[] = [];
  origExtraFields: object[] = [];
  extraFieldChecked: any[] = [];
  extraFieldToDelete: string[] = [];
  maxExtraFields: number;
  changedVal: boolean = false;
  fieldSuggestions: [];
  mode: string;
  deleted: boolean = false;
  backText: string;
  loading: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private storageService: StorageService,
    private activityService: ActivityService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    //get nav query params
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params['mode'] === 'create') {
        this.mode = 'create';
        this.backText = '_Create_';
      } else {
        this.mode = 'edit';
        this.backText = '_EditParticipantInfoHeader_';
      }
    })

    this.user = this.storageService.get('user');
    this.activity = this.storageService.get('activity');
    this.activityName = 'For ' + this.activity['title'];
    //get extra field suggestions
    this.getActivityFields();

    //get max extra fields
    if (this.user) {
      if (this.user['premium'] && this.user['premium_data']) {
        this.maxExtraFields = this.user['premium_data'].features[1].count;
        // this.maxExtraFields = 10;
      } else {
        //user is not premium
        this.maxExtraFields = 1;
      }
    }
    this.formValueChanged();
  }

  getActivityFields() {
    this.origExtraFields = [];
      this.activityService.orgActivityQuery(this.activity['id']).subscribe(
        response => {
          if (response.status) {
            JSON.stringify(response);
              if (this.activity && this.activity['custom_fields'].length) {
                this.activity['custom_fields'].forEach(item => {
                  this.origExtraFields.push(item);
                });
                //sort by order in ui
                this.origExtraFields.sort(function (a, b) {
                  return a['order'] - b['order'];
                });
                this.storageService.set('origExtraFields', this.origExtraFields);
              }
            this.getExtraFields();
          }
        },
      )
  }

  getExtraFields() {
    this.loading = true;
    this.extraFieldArr = [];
    this.extraFieldChecked = [];
    let data = {
      selected_activity: this.activity['id']
    }
    //get all fields from server
    this.activityService.newExtraFieldsSuggestions(data).subscribe(result => {
      if (result && result.body['fields']) {
        this.loading = false;
        this.extraFieldArr = result.body['fields'];
        this.storageService.set('extraFieldArr', this.extraFieldArr);
        this.extraFieldArr.forEach(field => {
          if (field['activity_extra_field_id']) {
            field['checked'] = true;
            this.origExtraFields.forEach(orig => {
              if (field['activity_extra_field_id'] === orig['id']) {
                field['field_number'] = orig['field_number'];
                field['order'] = orig['order'];
              }
            })
            this.extraFieldChecked.push(field);
          } 
        })
        // console.log('this.extraFieldChecked', this.extraFieldChecked);
      }
    })
  }

  addEditFields(type, event, field?) {
    event.stopPropagation();
    // console.log(event)
    if (this.extraFieldChecked.length >= this.maxExtraFields && type ==='addField') {
      //show max dialog
      this.maxFieldWarning();
    } else {
      if (type === 'editField') {
        if (field) {
          this.storageService.set('customField', field);
        }
      }
      if (this.extraFieldArr.length || this.extraFieldToDelete.length) {
        //changes were made, submit first
        this.submitFields('addEditField', type);
      } else {
        this.router.navigate(['/addxtrafields'], { queryParams: { mode: this.mode, type: type } })
      }
    }
  }

  selectField(idx, item, event) {
    let removedIdx, removedField, 
    checkedIdx = this.extraFieldChecked.findIndex(x => x['definition_id'] === item['definition_id']);
    if (event.checked) {
      if (this.extraFieldChecked.length >= this.maxExtraFields) {
        //show warning and uncheck selected field      
        this.extraFieldArr[idx]['checked'] = false;
        this.maxFieldWarning(idx);
      } else {
        if (checkedIdx == -1) {
          //add item
          this.extraFieldChecked.push(item);
          this.extraFieldArr[idx]['checked'] = true;
          removedField = this.extraFieldArr[idx];
          if (removedField['activity_extra_field_id']) {
            //if item has extra field id, remove from extraFieldToDelete array
            removedIdx = this.extraFieldToDelete.findIndex(x => x['activity_extra_field_id'] === removedField['activity_extra_field_id']);
            this.extraFieldToDelete.splice(removedIdx, 1);
            }
        }
      }
    } else {
      //item was already selected, remove from array
      if (item['in_use_by_volunteer_on_this_activity']) {
        delete this.extraFieldArr[idx]['checked'];
        this.removeWarning(idx, checkedIdx);
      } else {
        if (checkedIdx != -1) {
          removedField = this.extraFieldArr[idx];
          this.extraFieldChecked.splice(checkedIdx, 1);
          if (removedField['activity_extra_field_id']) {
          // item was already in use, add to extraFieldToDelete array
          delete removedField['checked'];
          this.extraFieldToDelete.push(removedField);
          }
        }
      }
    }
    // console.log('this.extraFieldToDelete', this.extraFieldToDelete);
    // console.log('this.extraFieldChecked', this.extraFieldChecked);
  }

  removeWarning(idx, checkedIdx) {
    let removedField;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      autoFocus: false,
      data: {
        isConfirm: true,  //confirm dialog
        isContinue: true,
        buttonText: "Yes",
        buttonCancelText: "No",
        buttonType: "wide",
        title: "Are you sure?",
        reverseBtns: true,
        message: "_ExtraFieldRemoveQuestion_",
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'continue') {
        if (checkedIdx != -1) {
          removedField = this.extraFieldArr[idx];
          this.extraFieldChecked.splice(checkedIdx, 1);
          if (removedField['activity_extra_field_id']) {
          // item was already in use, add to extraFieldToDelete array
          delete removedField['checked'];
          this.extraFieldToDelete.push(removedField);
          }
        }
      } else {
        //do not uncheck field
        this.extraFieldArr[idx]['checked'] = true;
      }
    })
  }

  maxFieldWarning(idx?) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      data: {
        isSuccess: true,    //success dialog
        title: '',
        message: 'Whoops! You\'ve already checked the maximum number of questions allowed under your Plan. To add this question, either uncheck another question first, or upgrade to a higher-level Premium Plan.'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('this.extraFieldChecked', this.extraFieldChecked);
      if (idx) {
        delete this.extraFieldArr[idx]['checked'];
      }
    })
  }

  removeExtraField(field) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '80%',
      hasBackdrop: true,
      autoFocus: false,
      data: {
        isConfirm: true,  //confirm dialog
        isContinue: true,
        buttonText: "Permanently Delete",
        buttonCancelText: "Cancel",
        buttonType: "wide",
        title: "Are you sure?",
        reverseBtns: true,
        message: "_ExtraFieldDeleteAllBody_",
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'continue') {
        // this.deleted  =true;
        this.deleteExtraField(field);
      }
    })
  }

  deleteExtraField(field) {
    let fieldIdx, checkedIdx,
      data = {
        selected_activity: this.activity['id'],
        definition_id: field['definition_id'],
        activity_extra_field_id: field['activity_extra_field_id']
      }
    this.activityService.deleteExtraSpecialFields(data).subscribe(result => {
      if (result) {
        this.getActivityFields();
      }
    })
  }

  formValueChanged() {
    //check for value changes
    // this.editParticipantForm.valueChanges.subscribe(val => {
    //   this.changedVal = true;
    // })
  }

  nextFieldNumber() {
    // sort by field number
    let i, sorted, nextFieldNum, found:boolean = false;
    sorted = this.extraFieldChecked.filter(item => item!== '')
    .sort(function (a, b) {
      return (a['field_number'] - b['field_number']);
    });
    sorted.forEach((field, idx) => {
        i = idx + 1;
        if (!found && field['field_number'] !== i) {
          //gap found
          nextFieldNum = i;
          found = true;
        }
        if (!found) {
          nextFieldNum = sorted.length + 1;
        }
      })
    return nextFieldNum;
  }

  submitFields(route?, type?) {
    let activityData = {
      selected_activity: this.activity['id'],
      id: this.activity['id'],
      additional_fields: this.extraFieldChecked.length ? this.extraFieldChecked : [],
      additional_fields_to_be_deleted: this.extraFieldToDelete.length ? this.extraFieldToDelete : []
    };

    if (this.extraFieldChecked.length) {
        //check if any field does not have a field number
        this.extraFieldChecked.forEach((field, idx) => {
          if (!field['field_number']) {
            field['field_number'] = this.nextFieldNumber();
          }
          // field['order'] = idx + 1;
          if (!field['order']) {
            field['order'] = this.extraFieldChecked.length + 1;
          }
        })
    }

    if (this.storageService.get('require_phone')) {
      activityData['require_phone'] = this.storageService.get('require_phone');
    }
    
    this.activityService.updateActivity(activityData).subscribe(
      response => {
        if (response.status) {
          if (response.body['data']) {
            this.storageService.set('activity', response.body['data'], null);
            this.storageService.set('origExtraFields', response.body['data']['custom_fields']);
          }
          if (route === 'addEditField') {
            //route to the correct page
            this.router.navigate(['/addxtrafields'], { queryParams: { mode: this.mode, type: type } })
          } else {
            if (this.mode === 'create') {
              //go back to create page step 2
              this.storageService.set('goToStep2', true);
              this.router.navigateByUrl('/create');
            } else {
              this.router.navigate(['edit_participant_info/' + this.activity['id']]);
            }
          }
        }
      }
    )
  }
}
